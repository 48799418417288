import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const SubTitle = ({ subtitle }) => (
  <h2 className={classes.subtitle}>{subtitle}</h2>
)

SubTitle.propTypes = {
  subtitle: PropTypes.string,
}
