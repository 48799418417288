import React, { useState, useEffect } from 'react'
import { useAPI } from '../../Contexts/APIContext'
import {
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import Loader from '../../Components/GeneralComponents/Loader'
import Collection from '../../Components/CollectionsComponents/Collection/Collection'
import Prompt from '../../Components/DashboardComponents/Prompt/Prompt'
import CollectionAdd from '../../Components/CollectionsComponents/CollectionAdd/CollectionAdd'
import classes from './index.module.css'
import useUserInfo from '../../services/hooks/useUserInfo'

export default function Collections() {
  const pageLimit = 10
  const queryClient = useQueryClient()
  const { getCollections, deleteCollection } = useAPI()
  const [ref, inView] = useInView()
  const { name, email } = useUserInfo()

  const [isPromptDelete, setIsPromptDelete] = useState(false)
  const [chosenCollection, setChosenCollection] = useState(null)

  const {
    data,
    isError,
    error,
    isLoading,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['collections'],
    queryFn: ({ pageParam = 1 }) => getCollections(pageParam, pageLimit),
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.lists_count ? nextPage : undefined
      return newPage
    },
  })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  // DELETE COLLECTION
  function handleOnDeleteCollection(collection) {
    setChosenCollection(collection)
    setIsPromptDelete(true)
  }

  const deleteCollectionMutation = useMutation({
    mutationFn: data => deleteCollection(chosenCollection),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['collections'] })
      setIsPromptDelete(false)
    },
    onError: e => {
      setIsPromptDelete(false)
    },
  })

  return (
    <div className={classes.wrapper}>
      {isPromptDelete && (
        <Prompt
          title={'Are you sure?'}
          subtitle={'This will delete collection'}
          primaryAction={'CANCEL'}
          onPrimaryClick={() => {
            setIsPromptDelete(false)
          }}
          secondaryAction={'DELETE'}
          onSecondaryClick={() => {
            deleteCollectionMutation.mutate()
          }}
          isSecondaryActionDestructive={true}
          onCloseClick={() => {
            setIsPromptDelete(false)
          }}
        />
      )}
      <div className={classes.header}>
        <h1>Collections</h1>
      </div>

      {data && data?.pages[0]?.lists_count < 15 && (
        <CollectionAdd collectionLength={data?.pages[0]?.lists_count} />
      )}

      {data &&
        data.pages.map((page, index) => (
          <React.Fragment key={index}>
            {page.lists.map((collection, innerIndex) => (
              <div
                className={classes.collection}
                key={collection.f_id}
                ref={ref}
              >
                <Collection
                  isFetchingCollections={isFetching && !isFetchingNextPage}
                  feelsCount={collection.feels_count}
                  name={collection.name}
                  location={null}
                  collectionId={collection.f_id}
                  collectionLength={data?.pages[0]?.lists_count}
                  isPublic={collection.is_public}
                  onDeleteCollection={handleOnDeleteCollection}
                />
              </div>
            ))}
          </React.Fragment>
        ))}
      <Loader isLoading={isLoading || isFetchingNextPage} />
      {isError && (
        <h1 className={classes.errorText}>{`Failed to fetch data with code: ${
          error?.status ? error.status : 'unknown'
        }`}</h1>
      )}
    </div>
  )
}
