import { useMutation } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export const useCreateOrganization = () => {
  const { createOrganization } = useAPI()

  return useMutation({
    mutationFn: data =>
      createOrganization({
        ...data,
      }),
  })
}
