import React from 'react'
import PropTypes from 'prop-types'
import NotFound from '../Pages/Helpers/404'
import useAccessLevel from '../Pages/Organizations/hooks/useAccessLevel'

export default function OnlyInternalRoute({ children }) {
  const { isInternalAdmin } = useAccessLevel()

  if (!isInternalAdmin) {
    return <NotFound />
  }
  return children
}

OnlyInternalRoute.propTypes = {
  children: PropTypes.node,
}
