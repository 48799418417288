import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import Loader from '../../../../Components/GeneralComponents/Loader'

import { useInView } from 'react-intersection-observer'

export default function CreateOrganizationArtistsList({
  artists,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  handleAddArtists,
  handleRemoveArtist,
  stepData,
}) {
  const [ref, inView] = useInView()

  const handleDisable = id => stepData.some(a => a.f_id === id)

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage])

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <Loader isLoading={isLoading} />
      </div>
    )
  }
  if (!isLoading && !artists?.length) {
    return (
      <ul className={classes.list}>
        <li className={classes.list__item}>
          <button
            type="button"
            className={`${classes.button} ${classes.button__artist}`}
            disabled
          >
            No artists found
          </button>
        </li>
      </ul>
    )
  }
  return (
    <ul className={classes.list}>
      <li className={classes.list__item}>
        <button
          type="button"
          className={`${classes.button} ${classes.button__add}`}
          onClick={() => handleAddArtists(artists)}
        >
          + Add All Artists
        </button>
      </li>
      {artists &&
        artists.map((artist, _, self) => {
          return (
            <li className={classes.list__item} ref={ref} key={artist.f_id}>
              <button
                type="button"
                className={`${classes.button} ${classes.button__artist} ${
                  handleDisable(artist.f_id) ? classes.selected : ''
                }`.trim()}
                onClick={() =>
                  handleDisable(artist.f_id)
                    ? handleRemoveArtist(artist)
                    : handleAddArtists(artist)
                }
              >
                {artist.name}
              </button>
            </li>
          )
        })}
      {isFetchingNextPage ? (
        <li className={classes.list__item}>
          <Loader isLoading={isFetchingNextPage} />
        </li>
      ) : null}
    </ul>
  )
}

CreateOrganizationArtistsList.propTypes = {
  artists: PropTypes.array,
  stepData: PropTypes.array,
  fetchNextPage: PropTypes.func,
  handleAddArtists: PropTypes.func,
  handleRemoveArtist: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
}
