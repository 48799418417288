import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const Button = ({ label, ...rest }) => (
  <button className={classes.button} {...rest}>
    {label}
  </button>
)

Button.propTypes = {
  label: PropTypes.string,
}
