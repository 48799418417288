import React, { useState } from 'react'
import { useAuth } from '../../Contexts/AuthContext'
import feelsLogo from '../../Components/icons/feelsLogo.png'
import './Login.css'
import { useEffect } from 'react'

export default function LoginPage() {
  const { handleGoogle, login, error, setError } = useAuth()

  async function handleGoogleClick(response) {
    let googleJWT = await handleGoogle(response)
    if (googleJWT === undefined || googleJWT === null) {
      setError('Something has failed, try again')
    } else {
      login(googleJWT)
    }
  }

  useEffect(() => {
    const googleScript = document.getElementById('google-map-script')

    googleScript.addEventListener('load', () => {
      // Patiently waiting to do the thing
      setupGoogle()
    })

    setupGoogle()
  }, [window.google])

  function setupGoogle() {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleClick,
      })

      google.accounts.id.renderButton(document.getElementById('sign-in-div'), {
        // type: "standard",
        theme: 'filled_black',
        // size: "small",
        text: 'continue_with',
        shape: 'pill',
      })

      // google.accounts.id.prompt();
    }
  }

  return (
    <>
      <div className="parent-login-container">
        <div className="login-container">
          <img id="login-logo" height="35px" src={feelsLogo} alt="logo" />
          <h1 className="login-welcome-text" style={{ color: 'white' }}>
            Welcome to Admin Dashboard
          </h1>
          {error && <p className="login-error-text">{error.message}</p>}
          <div id="sign-in-div"></div>
        </div>
      </div>
    </>
  )
}
