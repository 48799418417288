import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function TeasePopup({ children, isOpen }) {
  if (!isOpen) return null
  return (
    <div className={classes.wrapper}>
      <div className={classes.popup}>{children}</div>
    </div>
  )
}

TeasePopup.propTypes = {
  children: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  isOpen: PropTypes.bool,
}
