import React, { useState } from 'react'
import classes from './index.module.css'
import uploadIcon from '../../icons/uploadIcon.svg'
import PropTypes from 'prop-types'
import StickerUploader from '../StickerUploader/StickerUploader'

export default function Sticker({
  stickerTitles,
  previewSticker,
  setPreviewSticker,
  file,
  setFile,
}) {
  const [showUploader, setShowUploader] = useState(false)

  const toggleUploader = () => {
    setShowUploader(!showUploader)
  }

  return (
    <div className={classes.sticker}>
      <span className={classes.stickerText}>{stickerTitles}</span>
      {showUploader && (
        <StickerUploader
          setShowUploader={setShowUploader}
          file={file}
          setFile={setFile}
          setPreviewSticker={setPreviewSticker}
        />
      )}
      {previewSticker === null ? (
        <img
          style={{ cursor: 'pointer' }}
          onClick={toggleUploader}
          width="35"
          height="35"
          src={uploadIcon}
          alt="upload_icon"
        />
      ) : typeof previewSticker === 'string' ? (
        <div className={classes.stickerFrame} onClick={toggleUploader}>
          <img
            className={classes.stickerPreview}
            src={previewSticker}
            alt="sticker"
          />
        </div>
      ) : (
        <div className={classes.stickerFrame} onClick={toggleUploader}>
          <img
            className={classes.stickerPreview}
            src={URL.createObjectURL(previewSticker[0])}
            alt="sticker"
          />
        </div>
      )}
    </div>
  )
}

Sticker.propTypes = {
  stickerTitles: PropTypes.string,
  previewSticker: PropTypes.any,
  setPreviewSticker: PropTypes.any,
  file: PropTypes.any,
  setFile: PropTypes.any,
}
