import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { useAuth } from '../../../Contexts/AuthContext'
import { formatStringInNav } from '../../../Helpers/String+Extension'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import ArtistAndSongName from '../../../Components/GeneralComponents/ArtistAndSongName/ArtistAndSongName'
import Loader from '../../../Components/GeneralComponents/Loader'
import FeelGridItem from '../../../Components/DashboardComponents/FeelGridItem/FeelGridItem'
import SongFeelsHeader from '../../../Components/SongsComponents/SongFeelsHeader/SongFeelsHeader'
import OptionsButton from '../../../Components/GeneralComponents/OptionsButton/OptionsButton'
import checkmark_full from '../../../Components/icons/checkmark_full.png'
import checkmark_empty from '../../../Components/icons/checkmark_empty.png'

import classes from './index.module.css'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { Navigation } from '../../../Components/page/Navigation'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import { Actions } from '../../../Components/page/Actions'

import { permissions } from '../../../constants/roles'
import useReadOnly from '../../../services/hooks/useReadOnly'
import { usePreventFullscreen } from '../../../Helpers/hooks/usePreventFullscreen'

export default function SongDetails() {
  const pageParam = 1
  const pageLimit = 3
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { getCurrentUser } = useAuth()
  const {
    saveSongLockState,
    saveSongHideState,
    cancelRequest,
    getSongData,
    getFeelsForSong,
  } = useAPI()

  const { id } = useParams()

  const videoElement = useRef()
  const [feelsMuted, setFeelsMuted] = useState(true)
  const [isSavingSong, setIsSavingSong] = useState(false)

  const { isReadOnly: isSongReadOnly } = useReadOnly(permissions.r.music_song)
  usePreventFullscreen(videoElement)

  const {
    data: dataSong,
    isLoading: isLoadingSong,
    isFetching: isFetchingSong,
    isError: isErrorSong,
    error: errorSong,
  } = useQuery({
    queryKey: ['songData', id],
    queryFn: () => getSongData(id),
  })

  const {
    data: dataFeels,
    isLoading: isLoadingFeels,
    isFetching: isFetchingFeels,
    isError: isErrorFeels,
    error: errorFeels,
  } = useQuery({
    queryKey: ['songFeels', id],
    queryFn: () => getFeelsForSong(id, pageParam, pageLimit),
  })

  const lockSongMutation = useMutation({
    mutationFn: () =>
      saveSongLockState(id, !dataSong?.feels_locked, getCurrentUser()?.email),
    onSuccess: data => {
      queryClient.clear()
      setIsSavingSong(false)
    },
    onError: e => {
      setIsSavingSong(false)
    },
  })

  const handleOnLock = () => {
    setIsSavingSong(true)
    lockSongMutation.mutate()
  }

  const hideStatusMutation = useMutation({
    mutationFn: () =>
      saveSongHideState(
        id,
        !dataSong?.is_public_visible,
        getCurrentUser()?.email
      ),
    onSuccess: data => {
      queryClient.clear()
      setIsSavingSong(false)
    },
    onError: e => {
      setIsSavingSong(false)
    },
  })

  const handleOnHide = () => {
    setIsSavingSong(true)
    hideStatusMutation.mutate()
  }

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  const breadcrumbs = dataSong?.artist?.name
    ? [
        {
          title: 'All Songs',
          link: '/songs/all',
        },
        {
          title: formatStringInNav(dataSong?.artist?.name),
          link: '/artist/' + dataSong?.artist?.artist_name,
        },
        {
          title: 'Songs',
          link: '/songs/artist/' + dataSong?.artist?.artist_name,
        },
        {
          title: formatStringInNav(dataSong?.name),
        },
      ]
    : [
        [
          {
            title: 'All Songs',
            link: '/songs/all',
          },
        ],
      ]

  return (
    <Wrapper>
      {!isLoadingSong && (
        <Header>
          <Navigation breadcrumbs={breadcrumbs} />
          {dataSong && (
            <HeaderInfo>
              <ArtistAndSongName song={dataSong} />
              <Actions>
                <ButtonOutline
                  color={'pink'}
                  size="small"
                  text={'MODIFY BACKDROP'}
                  disable={isSongReadOnly}
                  onClick={() =>
                    navigate(
                      '/song/' +
                        dataSong?.f_id +
                        '/backdrop/' +
                        dataSong?.artist.artist_name
                    )
                  }
                />
                <ButtonOutline
                  disable={isSongReadOnly}
                  color={'pink'}
                  size="small"
                  icon="createIcon"
                  text={'CREATE MUSIC MESSAGE'}
                  onClick={() => {
                    navigate('/create-new-feel-step-2/' + dataSong?.f_id)
                  }}
                />

                <ButtonOutline
                  disable={isSongReadOnly}
                  color={'pink'}
                  size="small"
                  text={'EDIT TIMING'}
                  onClick={() => {
                    navigate('/edit-song/' + dataSong?.f_id)
                  }}
                />
                <div style={{ marginTop: '3px', position: 'relative' }}>
                  <OptionsButton
                    disabled={isSongReadOnly}
                    options={[
                      {
                        title: dataSong?.feels_locked
                          ? 'UNLOCK SONG'
                          : 'LOCK SONG',
                        func: handleOnLock,
                        disable: isSavingSong,
                      },
                      {
                        title: dataSong?.is_public_visible
                          ? 'MAKE HIDDEN'
                          : 'MAKE VISIBLE',
                        func: handleOnHide,
                        disable: isSavingSong,
                      },
                    ]}
                  />
                </div>
              </Actions>
            </HeaderInfo>
          )}
        </Header>
      )}
      {!isLoadingSong && dataSong && (
        <>
          <main className={classes.main}>
            <div className={classes.video}>
              <div className={classes.video__verified}>
                <div className={classes.video__verified_image}>
                  <img
                    width="24"
                    height="24"
                    src={
                      dataSong?.is_sync_verified
                        ? checkmark_full
                        : checkmark_empty
                    }
                  />
                </div>
                <div className={classes.video__verified_text}>
                  Sync Verified
                </div>
              </div>
              <div className={classes.videoPlayer}>
                <video
                  ref={videoElement}
                  controls
                  autoPlay={true}
                  muted={true}
                  onTimeUpdate={() => {
                    handleOnTimeUpdate()
                  }}
                  src={dataSong?.video?.video?.url}
                  width={267}
                  height={465}
                  controlsList="nofullscreen"
                />
              </div>
            </div>

            {!isLoadingFeels && dataFeels?.feels?.length > 0 ? (
              <>
                <div className={classes.musicMessageWrapper}>
                  <SongFeelsHeader
                    totalFeels={dataFeels?.feels_count}
                    onClick={() => {
                      navigate('/song/feels/' + dataSong?.f_id)
                    }}
                  />
                  <div className={classes.feels}>
                    {dataFeels.feels.map(item => (
                      <FeelGridItem
                        key={item.f_id}
                        feel={item}
                        isMutedOnHover={feelsMuted}
                        onClick={feel => {
                          navigate('/feel/' + feel.f_id)
                        }}
                        onMuteUnmuteClick={() => {
                          setFeelsMuted(!feelsMuted)
                        }}
                      />
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                {isLoadingFeels && <Loader isLoading={isLoadingFeels} />}
                <p className={classes.emptyFeelErrorText}>
                  {!isLoadingFeels &&
                    (isErrorFeels
                      ? `Error ${errorFeels.status}`
                      : 'No Music Messages created yet')}
                </p>
              </>
            )}
          </main>
        </>
      )}

      {isErrorSong && (
        <h1 className={classes.errorText}>Error {errorSong.status}</h1>
      )}
      {isLoadingSong ? <Loader isLoading={isLoadingSong} /> : null}
    </Wrapper>
  )
}
