import React from 'react'
import { toast } from 'react-toastify'

const successToast = (message, options = {}) => {
  const defaultOptions = {
    draggable: false,
    progress: undefined,
  }
  const mergedOptions = { ...defaultOptions, ...options }

  toast.success(message, mergedOptions)
}

export default successToast
