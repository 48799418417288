import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function StickerSidebarButton({
  isPublished = false,
  isEdit,
  isCreate,
  isCurrent,
  text,
  onClick,
  disabled = false,
}) {
  const circleClass = isPublished
    ? classes.circlePublished
    : classes.circleUnpublished

  const buttonRef = useRef(null)

  useEffect(() => {
    if (isCurrent && buttonRef.current) {
      const element = buttonRef.current
      const bounding = element.getBoundingClientRect()
      const isInViewport =
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth)

      if (!isInViewport) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [isCurrent])

  return (
    <>
      {text && text?.trim() !== '' ? (
        <div
          className={isCurrent ? classes.editButton : classes.button}
          ref={buttonRef}
          tabIndex={isCurrent ? 0 : -1}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: isCreate ? 'pointer' : 'grab',
          }}
          onClick={disabled ? null : onClick}
        >
          {text}
          <div
            className={isEdit || isCreate ? classes.circleEdit : circleClass}
          ></div>
        </div>
      ) : (
        <div
          className={isCurrent ? classes.editButton : classes.button}
          ref={buttonRef}
          tabIndex={isCurrent ? 0 : -1}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: isCreate ? 'pointer' : 'grab',
            color: '#767676',
          }}
          onClick={disabled ? null : onClick}
        >
          Set Name
          <div
            className={isEdit || isCreate ? classes.circleEdit : circleClass}
          ></div>
        </div>
      )}
    </>
  )
}

StickerSidebarButton.propTypes = {
  isPublished: PropTypes.bool,
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
  isCurrent: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
