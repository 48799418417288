import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function LyricsField(props) {
  const { label, helperText, name, isRequired, ...rest } = props
  return (
    <div className={classes.wrapper}>
      {label ? (
        <label className={classes.label} htmlFor={name}>
          {label}{' '}
          {isRequired ? <span className={classes.label__required}>*</span> : null}
        </label>
      ) : null}
      <textarea
        className={classes.input}
        name={name}
        {...rest}
      />
      {helperText ? <span className={classes.text}>{helperText}</span> : null}
    </div>
  )
}

LyricsField.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
}
