import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CreateOrganizationConfirmationPopup({
  text,
  buttonText,
  confirmAction,
  closeAction,
  isOpen,
}) {
  const ref = useRef()
  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       closeAction()
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [ref])
  if (!isOpen) return null

  return (
    <div className={classes.popup} ref={ref}>
      <p className={classes.popup__text}>{text}</p>
      <div className={classes.popup__actions}>
        <button
          className={`${classes.button} ${classes.button__confirm}`}
          onClick={confirmAction}
        >
          {buttonText}
        </button>
        <button
          className={`${classes.button} ${classes.button__cancel}`}
          onClick={closeAction}
        >
          cancel
        </button>
      </div>
    </div>
  )
}

CreateOrganizationConfirmationPopup.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  confirmAction: PropTypes.func,
  closeAction: PropTypes.func,
  isOpen: PropTypes.bool,
}
