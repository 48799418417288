import React from 'react'
import classes from "./index.module.css"
import {ReactComponent as ErrorIcon} from "../../icons/error-icon.svg"
export default function DevicesRestrictionsNotification() {
  return (
    <p className={classes.text}>
      <span>
        <ErrorIcon className={classes.icon} />
      </span> The ‘Edit Timing’ feature is designed to work seamlessly in the desktop
      version of Google Chrome for precision and performance. At this time,
      mobile devices and Safari are not supported for this feature.
    </p>
  )
}
