import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TeaseFormActions from '../TeaseFormActions'
import classes from './index.module.css'
import TextField from '../TextField'
import LyricsField from '../LyricsField'
import VideoUpload from '../VideoUpload'
import errorToast from '../../../Components/Toasts/ErrorToast'
import { useCreateTeaseStore } from '../../../Storage/createTeaseStore/useCreateTeaseStore'
import { useNavigate } from 'react-router-dom'
import { useCreateTease } from '../hooks/useCreateTease'
import { useCreateTeaseVideo } from '../hooks/useCreateTeaseVideo'
import { useUploadTeaseVideo } from '../hooks/useUploadTeaseVideo'
import successToast from '../../../Components/Toasts/SuccessToast'
import { useEditTeaseLyrics } from '../hooks/useEditTeaseLyrics'
import { useAuth } from '../../../Contexts/AuthContext'
import { processNewLyricsText } from '../helpers/processNewLyricsText'
import { CustomSelect } from '../CustomSelect'
import { URL_TEXT_OPTIONS } from '../helpers/constants'
import { isValidUrl } from '../helpers/isValidUrl'
export default function TeaseForm({ closePopup, artist }) {
  const { getCurrentUser } = useAuth()
  const navigate = useNavigate()
  const defaultTease = useCreateTeaseStore(state => state.defaultTease)
  const [values, setValues] = useState(defaultTease)
  const [teaseId, setTeaseId] = useState(null)

  const { mutate: createTease, isPending: isLoadingCreateTease } =
    useCreateTease()

  const { mutate: createTeaseVideo, isPending: isLoadingCreateTeaseVideo } =
    useCreateTeaseVideo()

  const {
    mutate: uploadTeaseVideo,
    isPending: isLoadingUploadTeaseVideo,
    isSuccess: isSuccessUploadTeaseVideo,
  } = useUploadTeaseVideo()

  const { mutate: editTeaseLyrics, isPending: isLoadingEditTeaseLyrics } =
    useEditTeaseLyrics()

  const handleUploadTeaseVideo = videoData => {
    uploadTeaseVideo(videoData, {
      onSuccess: () => {
        successToast('Video uploaded successfully')
      },
      onError: error => {
        errorToast('Error uploading video', error)
      },
    })
  }

  const handleCreateTeaseVideo = videoData => {
    createTeaseVideo(videoData, {
      onSuccess: video => {
        successToast('Video created successfully')
        handleUploadTeaseVideo({
          uploadURL: video.upload_url[0],
          file: values.video.file,
        })
      },
      onError: error => {
        errorToast('Error creating video', error)
      },
    })
  }

  const handleEditTeaseLyrics = lyricsData => {
    editTeaseLyrics(lyricsData, {
      onSuccess: lyrics => {
        successToast('Lyrics created successfully')
        handleCreateTeaseVideo({
          payload: {
            video_type: values.video.video.video_type,
            filesize: values.video.video.filesize,
            dimensions: values.video.video.dimensions,
            image_at: values.video.video.duration * 10,
            orientation: 'vertical',
          },
          tease_id: lyrics.song_f_id,
        })
      },
      onError: error => {
        errorToast('Error creating video', error)
      },
    })
  }

  const handleCreateTease = teaseData => {
    createTease(teaseData, {
      onSuccess: tease => {
        successToast('Tease created successfully')
        setTeaseId(tease.f_id)
        const lyrics_sync = processNewLyricsText(values.lyrics)
        handleEditTeaseLyrics({
          payload: {
            lyrics_sync,
            edit_user_id: getCurrentUser().email,
          },
          tease_id: tease.f_id,
        })
      },
      onError: error => {
        errorToast('Error creating tease', error)
      },
    })
  }

  const onTextFieldValueChange = e => {
    setValues(values => ({ ...values, [e.target.name]: e.target.value }))
  }
  const handleOptionSelect = selectedOption => {
    setValues({
      ...values,
      promotional_url_display_text: selectedOption,
    })
  }
  const onFileFieldValueChange = props => {
    setValues(values => ({
      ...values,
      video: props
        ? {
            file: props.file,
            video: {
              video_type: props.video_type,
              filesize: props.filesize,
              dimensions: props.dimensions,
              url: props.url,
              duration: props.duration,
            },
          }
        : null,
    }))
  }

  const onCancelClick = () => {
    closePopup()
  }
  const isNextButtonEnabled = !!values.name && !!values.lyrics && !!values.video
  const isLoading =
    isLoadingCreateTease ||
    isLoadingCreateTeaseVideo ||
    isLoadingUploadTeaseVideo ||
    isLoadingEditTeaseLyrics

  const onSubmit = e => {
    e.preventDefault()
    const { name, lyrics, video } = values
    if (!name) {
      errorToast('Title is required')
      return
    }
    if (!lyrics) {
      errorToast('Lyrics is required')
      return
    }
    if (!video) {
      errorToast('Video is required')
      return
    }
    if (values.promotional_url && !isValidUrl(values.promotional_url)) {
      errorToast(
        'The URL you entered is not valid. Please provide a valid URL starting with http:// or https://'
      )
      return
    }

    const teaseData = {
      name: values.name,
      name_full: values.name,
      artist_f_id: artist.f_id,
      promotional_url: values.promotional_url || null,
      promotional_url_display_text: values.promotional_url_display_text || null,
    }

    handleCreateTease(teaseData)
  }

  useEffect(() => {
    isSuccessUploadTeaseVideo && navigate(`/create-tease/${teaseId}`)
  }, [isSuccessUploadTeaseVideo])

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.form__fields}>
        <div className={`${classes.form__column} ${classes.form__column_16}`}>
          <TextField
            label={'Tease Title'}
            name={'name'}
            onChange={onTextFieldValueChange}
            isRequired
            value={values.name}
          />
          <LyricsField
            label={'Lyrics'}
            name={'lyrics'}
            onChange={onTextFieldValueChange}
            isRequired
            value={values.lyrics}
            helperText={'Note: Each lyric line should be on a separate line.'}
            placeholder={'Enter the tease lyrics here...'}
          />
        </div>
        <div className={`${classes.form__column} ${classes.form__column_24}`}>
          <TextField
            label={'URL'}
            name={'promotional_url'}
            helperText={
              'Viewers can tap the Display Text on your Tease Music Message to open this link.'
            }
            placeholder={'pre-save/promo.com'}
            onChange={onTextFieldValueChange}
            value={values.promotional_url}
          />
          <CustomSelect
            options={URL_TEXT_OPTIONS}
            onOptionSelect={handleOptionSelect}
            label={'URL Display Text'}
            helperText={
              'This text will appear with the Music Message instead of the full URL.'
            }
            defaultOption={values.promotional_url_display_text}
            disabled={!values.promotional_url}
          />
          <VideoUpload
            label={'Upload Video'}
            name={'video'}
            helperText={['Dimensions: 720px x 1280px', 'Video must have audio']}
            required
            onFileFieldValueChange={onFileFieldValueChange}
            video={values.video}
          />
        </div>
      </div>
      <TeaseFormActions
        isNextButtonEnabled={isNextButtonEnabled}
        onCancelClick={onCancelClick}
        isLoading={isLoading}
      />
    </form>
  )
}

TeaseForm.propTypes = {
  closePopup: PropTypes.func,
  artist: PropTypes.object,
  file: PropTypes.any,
  video_type: PropTypes.any,
  filesize: PropTypes.any,
  dimensions: PropTypes.any,
  url: PropTypes.any,
  duration: PropTypes.any,
}
