import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { useArtistsStore } from '../../../Storage/artistsPage/useArtistsStore'
import { useAPI } from '../../../Contexts/APIContext'
import ArtistGridItem from '../../../Components/ArtistProfileComponents/ArtistGridItem/ArtistGridItem'
import DebounceSearch from '../../../Components/GeneralComponents/DebounceSearch/DebounceSearch'
import Loader from '../../../Components/GeneralComponents/Loader'
import classes from './index.module.css'
import { useArtistSelection } from '../../../Helpers/hooks/useArtistSelection'

export default function AllArtists() {
  const pageLimit = 50
  const pageLimitSearch = 20
  const { getArtists, searchArtists } = useAPI()
  const [ref, inView] = useInView()
  const [refSearch, inViewSearch] = useInView()
  const { handleArtistSelection } = useArtistSelection()

  const [isDev] = useState(process.env.REACT_APP_IS_DEV)
  const [searchTimer, setSearchTimer] = useState(null)

  const searchTerm = useArtistsStore(state => state.searchTerm)
  const setSearchTerm = useArtistsStore(state => state.setSearchTerm)

  const inputValue = useArtistsStore(state => state.inputValue)
  const setInputValue = useArtistsStore(state => state.setInputValue)

  const {
    data,
    isError,
    error,
    isLoading,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['artists'],
    queryFn: ({ pageParam = 1 }) => getArtists(pageParam, pageLimit),
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.artists_count ? nextPage : undefined
      return newPage
    },
  })

  // SEARCH
  const {
    data: dataSearch,
    isError: isErrorSearch,
    error: errorSearch,
    isLoading: isLoadingSearch,
    fetchNextPage: fetchNextPageSearch,
    isFetching: isFetchingSearch,
    isFetchingNextPage: isFetchingNextPageSearch,
  } = useInfiniteQuery({
    queryKey: ['artistsSearch', searchTerm],
    queryFn: ({ pageParam = 1 }) =>
      searchArtists(searchTerm, pageParam, pageLimitSearch),
    enabled: searchTerm !== '',
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.artists_count ? nextPage : undefined
      return newPage
    },
  })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  useEffect(() => {
    if (inViewSearch) {
      fetchNextPageSearch()
    }
  }, [inViewSearch])

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <h1 className={classes.title}>Artists</h1>
        <div className={classes.searchWrapper}>
          <DebounceSearch
            inputValue={inputValue}
            setInputValue={setInputValue}
            searchValue={searchTerm}
            setSearchValue={setSearchTerm}
            placeholder={'Search Artists...'}
          />
        </div>
      </div>

      {!isError && !isErrorSearch && !searchTerm && data && (
        <div className={classes.artists}>
          {data.pages.map((page, index) => (
            <React.Fragment key={index}>
              {page.artists.map((artist, innerIndex) => (
                <React.Fragment key={innerIndex}>
                  {isDev ? (
                    <div key={artist.f_id} ref={ref}>
                      <ArtistGridItem
                        artist={artist}
                        onClick={() => {
                          handleArtistSelection(artist)
                        }}
                      />
                    </div>
                  ) : (
                    artist.image.url !== null &&
                    artist.image.url !== {} &&
                    artist.image.url !== undefined && (
                      <div key={artist.f_id} ref={ref}>
                        <ArtistGridItem
                          artist={artist}
                          onClick={() => {
                            handleArtistSelection(artist)
                          }}
                        />
                      </div>
                    )
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}

      {!isError && !isErrorSearch && searchTerm && dataSearch && (
        <div className={classes.artists}>
          {dataSearch.pages.map((page, index) => (
            <React.Fragment key={index}>
              {page.artists.map((artist, innerIndex) => (
                <div key={artist.f_id} ref={refSearch}>
                  <ArtistGridItem
                    artist={artist}
                    onClick={() => {
                      handleArtistSelection(artist)
                    }}
                  />
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}

      <Loader
        isLoading={
          isLoading ||
          isFetchingNextPage ||
          isLoadingSearch ||
          isFetchingNextPageSearch
        }
      />

      {isError && (
        <h1 className={classes.errorText}>{`Failed to fetch data with code: ${
          error?.status ? error.status : 'unknown'
        }`}</h1>
      )}

      {isErrorSearch && (
        <h1 className={classes.errorText}>{`Failed to fetch data with code: ${
          errorSearch?.status ? errorSearch.status : 'unknown'
        }`}</h1>
      )}
    </div>
  )
}

// const artistsToUse = [
//   "50cent",
//   "adele",
//   "AliciaKeys",
//   "ArianaGrande",
//   "BadBunnyPR",
//   "beastieboys",
//   "beyonce",
//   "BillieEilish",
//   "BlackEyedPeas",
//   "BLACKPINK",
//   "blakeshelton",
//   "bonjovi",
//   "brunomars",
//   "BTS",
//   "chrisbrowntv",
//   "chrisstapleton",
//   "coldplay",
//   "DaddyYankee",
//   "davematthewsband",
//   "davidbowie",
//   "wearedelasoul",
//   "demilovato",
//   "diddy",
//   "diplo",
//   "dmx",
//   "dojacat",
//   "drdreyt",
//   "DrakeOfficial",
//   "EdSheeran",
//   "EltonJohn",
//   "eminem",
//   "fergie",
//   "Finneasofficial",
//   "fleetwoodmac",
//   "floridageorgialine",
//   "officialfuturevideos",
//   "xxxibgdrgn",
//   "theghostfacekillah",
//   "gunsnroses",
//   "gwenstefani",
//   "gza4868",
//   "hermusic",
//   "halsey",
//   "HarryStyles",
//   "ImagineDragons",
//   "dlwlrma",
//   "IVEstarship",
//   "JackHarlowofficial",
//   "jayzslifeandtimes",
//   "JenniferLopez",
//   "johnmayer",
//   "justinbieber",
//   "JustinTimberlake",
//   "KALIUCHIS",
//   "KaneBrown",
//   "KarolGMusic",
//   "KatyPerry",
//   "kendricklamar5461",
//   "kevingatestv",
//   "labrinth",
//   "LadyGaga",
//   "LanaDelRey",
//   "lilwayne",
//   "Lizzo",
//   "LLCoolJ",
//   "LudacrisTV",
//   "m.i.a.3768",
//   "madonna",
//   "MariahCarey",
//   "Maroon5",
//   "maryjbligemusic",
//   "methodmanofficial",
//   "michaeljackson",
//   "miguel5654",
//   "MileyCyrus",
//   "missyelliott",
//   "nerd",
//   "nas1205",
//   "neildiamondofficial",
//   "nelly",
//   "nickiminaj",
//   "NileRodgersOfficial",
//   "nirvana",
//   "nodoubttv",
//   "olddirtybastard",
//   "OliviaRodrigo",
//   "OneRepublic",
//   "outkastofficial_",
//   "PinkVideoVault",
//   "Pharrell",
//   "Pitbull",
//   "polog",
//   "postmalone",
//   "Queen",
//   "raesremmurd",
//   "RedHotChiliPeppers",
//   "richboy",
//   "rihanna",
//   "rosalia",
//   "rundmc",
//   "ryantedder",
//   "rza1235",
//   "samsmith",
//   "selenagomez",
//   "pledis17",
//   "shaggy",
//   "Shakira",
//   "sia",
//   "officialslickrick",
//   "snoopdogg",
//   "StevieNicks",
//   "TaylorSwift",
//   "NotoriousBIG",
//   "atribecalledquest",
//   "tylerthecreator",
//   "U2official",
//   "Usher",
//   "whitneyhoustonmusic",
//   "illwilly",
//   "WuTangClan"
// ]
