import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import WaveSurfer from 'wavesurfer.js'
import classes from './index.module.css'

export default function WaveForm({ videoUrl, videoElement }) {
  const waveformElement = useRef()
  const isDragging = useRef(false)

  const [waveSurfer, setWaveSurfer] = useState(null)

  useEffect(() => {
    if (!waveformElement.current || !videoElement.current || !videoUrl) return

    if (!waveSurfer) {
      const ws = WaveSurfer.create({
        container: waveformElement.current,
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        barMinHeight: 1,
        cursorWidth: 2,
        height: 80,
        waveColor: '#C4C4C4',
        progressColor: '#FF00FF',
        cursorColor: 'white',
        hideScrollbar: true,
        backend: 'MediaElement',
        mediaControls: false,
        responsive: true,
        interact: true,
        muted: true,
      })

      ws.on('ready', () => {
        videoElement.current.controls = 'controls'
        videoElement.current.muted = true
        videoElement.current.play().catch(error => {
          console.error('Error playing video:', error)
        })
      })

      setWaveSurfer(ws)
    }

    if (waveSurfer) {
      waveSurfer.load(videoElement.current)
    }
  }, [videoUrl, waveSurfer])

  useEffect(() => {
    if (!waveSurfer) return

    const handleMouseDown = event => {
      isDragging.current = true
      event.preventDefault()
    }

    const handleMouseMove = event => {
      if (!isDragging.current) return

      const rect = waveformElement.current.getBoundingClientRect()
      const x = event.clientX - rect.left
      const percentage = Math.max(
        0,
        Math.min(1, x / waveformElement.current.clientWidth)
      )
      waveSurfer.seekTo(percentage)
    }

    const handleMouseUp = () => {
      isDragging.current = false
    }

    waveformElement.current.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      waveformElement.current?.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [waveSurfer])

  return <div id="wave" className={classes.wave} ref={waveformElement} />
}

WaveForm.propTypes = {
  videoUrl: PropTypes.string,
  videoElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}
