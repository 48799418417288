import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { useSearchArtists } from '../../../hooks/useSearchArtists'
import CreateOrganizationSortedArtistsList from '../../CreateOrganizationSortedArtistsList'
import CreateOrganizationPopupArtistsCount from '../../CreateOrganizationPopupArtistsCount'
import CreateOrganizationPopupArtistsSearch from '../../CreateOrganizationPopupArtistsSearch'
import usePopup from '../../../hooks/usePopup'
import { useGetOrgContent } from '../../../hooks/useGetOrgContent'

function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
export default function CreateOrganizationPopupFormStep2({
  setStepData,
  stepData,
  organization,
}) {
  const [searchString, setSearchString] = useState('')

  const {
    openPopup: showOptions,
    closePopup: hideOptions,
    isOpen: isOptionsOpen,
  } = usePopup()

  const handleSetSearchString = debounce(
    e => setSearchString(e.target.value),
    500
  )

  const handleRemoveAllArtists = () => {
    setStepData([])
  }

  const handleAddArtists = artists => {
    const newArtists = Array.isArray(artists) ? artists : [artists]
    setStepData(prev => [
      ...new Map(
        [...prev, ...newArtists].map(item => [item.f_id, item])
      ).values(),
    ])
  }
  const handleRemoveArtist = artist => {
    setStepData(prev => prev.filter(item => item.f_id !== artist.f_id))
  }

  const {
    data: artistsList,
    isLoading: isArtistsListLoading,
    fetchNextPage: fetchNextArtists,
    hasNextPage: hasNextPageArtists,
    isFetchingNextPage: isFetchingNextPageArtists,
  } = useGetOrgContent({
    o_f_id: organization.f_id,
    content_object: 'artist',
    n: 15,
  })

  const artists = searchString
    ? artistsList?.pages
        .map(page => page.artist)
        .flat()
        .filter(item => item.artist_name.includes(searchString))
    : artistsList?.pages.map(page => page.artist).flat()
  const fetchNextPage = fetchNextArtists
  const hasNextPage = hasNextPageArtists

  const isFetchingNextPage = isFetchingNextPageArtists

  const isLoading = isArtistsListLoading

  useEffect(() => {
    if (hasNextPageArtists && !isFetchingNextPageArtists) {
      fetchNextArtists()
    }
  }, [hasNextPageArtists, isFetchingNextPageArtists, fetchNextArtists])

  return (
    <div className={classes.wrapper}>
      <div className={classes.search}>
        <CreateOrganizationPopupArtistsSearch
          handleSetSearchString={handleSetSearchString}
          artists={artists}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          handleAddArtists={handleAddArtists}
          handleRemoveArtist={handleRemoveArtist}
          stepData={stepData}
          showOptions={showOptions}
          hideOptions={hideOptions}
          isOptionsOpen={isOptionsOpen}
        />
      </div>

      <div className={classes.count}>
        <CreateOrganizationPopupArtistsCount
          count={stepData.length}
          action={handleRemoveAllArtists}
        />
      </div>
      <div className={classes.sorted}>
        <CreateOrganizationSortedArtistsList
          artists={stepData}
          handleRemoveArtist={handleRemoveArtist}
        />
      </div>
    </div>
  )
}

CreateOrganizationPopupFormStep2.propTypes = {
  setStepData: PropTypes.func,
  stepData: PropTypes.array,
  organization: PropTypes.object,
}
