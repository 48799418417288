import React, { useState } from 'react'
import classes from './index.module.css'
import { useGetOrgList } from './hooks/useGetOrgList'
import { Wrapper } from '../../Components/page/Wrapper'
import OrganizationsPreviewsList from './components/OrganizationsPreviewsList'
import { Header } from '../../Components/page/Header'
import PageTitle from './components/PageTitle'
import { HeaderInfo } from '../../Components/page/HeaderInfo'
import usePopup from './hooks/usePopup'
import OrganizationsPopup from './components/OrganizationsPopup'
import CreateOrganizationPopupForm from './components/CreateOrganizationPopupForm'
import CreateOrganizationHeaderActions from './components/CreateOrganizationHeaderActions'
import Loader from '../../Components/GeneralComponents/Loader'

export default function Organizations() {
  const { openPopup, closePopup, isOpen } = usePopup()

  const {
    data: orgList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
  } = useGetOrgList()

  const organizations = orgList?.pages.map(page => page.organizations).flat()

  if (isLoading) return <Loader isLoading={isLoading} />

  if (isError) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
            <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={classes.wrapper}>
      <OrganizationsPopup isOpen={isOpen} closePopup={closePopup}>
        <CreateOrganizationPopupForm />
      </OrganizationsPopup>
      <Header className={classes.header}>
        <HeaderInfo className={classes.header__info}>
          <PageTitle title={'Organizations'} />
          <CreateOrganizationHeaderActions openPopup={openPopup} />
        </HeaderInfo>
      </Header>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <div className={classes.list}>
          <OrganizationsPreviewsList
            list={[
              ...new Map(organizations.map(item => [item.f_id, item])).values(),
            ]}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
      )}
    </Wrapper>
  )
}
