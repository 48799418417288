import { useNavigate } from 'react-router-dom'

export const useArtistSelection = () => {
  const navigate = useNavigate()

  const handleArtistSelection = artist => {
    navigate('/artist/' + artist.artist_name, { state: { artist } })
  }

  return { handleArtistSelection }
}
