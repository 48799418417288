import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function OrganizationDropdown({
  options,
  selectedOption,
  setSelectedOption,
  disabled = false,
}) {
  const [showOptionList, setShowOptionList] = useState(false)
  const selectContainerRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(e.target)
      ) {
        setShowOptionList(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedOption, showOptionList])

  const handleListDisplay = () => {
    setShowOptionList(prevState => !prevState)
  }

  const handleOptionClick = option => {
    if (option !== selectedOption) {
      setSelectedOption(option)
    }
    setShowOptionList(false)
  }

  return (
    <div
      className={`${classes.container} ${showOptionList ? classes.active : ''}`}
      ref={selectContainerRef}
    >
      <button
        className={`${classes.selectedText} ${
          showOptionList ? classes.active : ''
        }`}
        onClick={handleListDisplay}
        disabled={disabled}
      >
        {selectedOption}
      </button>
      {showOptionList && (
        <ul className={classes.selectOptions}>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              className={selectedOption === option ? classes.active : ''}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

OrganizationDropdown.propTypes = {
  options: PropTypes.any,
  selectedOption: PropTypes.any,
  setSelectedOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
