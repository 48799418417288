import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useGetOrgUsers({ o_f_id, u_f_id }) {
  const { getOrganizationUsers } = useAPI()

  return useQuery({
    queryKey: ['organization', 'users', o_f_id],
    queryFn: () => getOrganizationUsers({ o_f_id, u_f_id }),
  })
}
