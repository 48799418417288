import React from 'react'
import classes from './index.module.css'
import Loader from '../../../Components/GeneralComponents/Loader'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'


import PageTitle from '../components/PageTitle'

import successToast from '../../../Components/Toasts/SuccessToast'
import { useQueryClient } from '@tanstack/react-query'

import { useChangeOrganizationUsersRole } from '../hooks/useChangeOrganizationUsersRole'
import MyTeamsTable from '../components/MyTeamsTable'
import { useFetchAccount } from '../hooks/useFetchAccount'

export default function MyTeams() {
  const {
    data: userData,
    isError: userDataError,
    isLoading: userDataIsLoading,
    isFetching: userDataIsFetching,
  } = useFetchAccount()

  const queryClient = useQueryClient()

  const groups =
    userData &&
    userData?.organizations?.reduce((acc, organization) => {
      const orgId = organization.f_id
      const orgName = organization.name
      const userId = organization.organization_user.f_id
      const groups = organization.organization_user.organization_groups

      const newGroups = groups.map(group => ({
        ...group,
        orgId,
        orgName,
        userId,
      }))
      return [...acc, ...newGroups]
    }, [])

  const { mutate: changeOrganizationUsersRole, isPending } =
    useChangeOrganizationUsersRole()

  const handleDeleteGroupUser = data => {
    const newData = {
      ...data,
      _action: 'delete',
    }
    changeOrganizationUsersRole(newData, {
      onSuccess: async () => {
        successToast('User deleted')
        await queryClient.invalidateQueries(['organization'])
      },
      onError: error => {
        errorToast('Error deleting User', error)
      },
    })
  }

  if (userDataError) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
          <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={classes.wrapper}>
      <Header>
        <HeaderInfo>
          <PageTitle title={'Teams'} />
        </HeaderInfo>
        {userDataIsLoading ? (
          <Loader isLoading={userDataIsLoading || userDataIsFetching} />
        ) : null}
      </Header>
      <main className={classes.main}>
        {groups && (
          <MyTeamsTable groups={groups} onDelete={handleDeleteGroupUser} />
        )}
        {!userDataIsLoading && (userDataIsFetching || isPending) ? (
          <Loader isLoading={userDataIsFetching || isPending} />
        ) : null}
      </main>
    </Wrapper>
  )
}
