import React, { useState } from 'react'
import CreateOrganizationPopupContent from '../CreateOrganizationPopupContent'
import CreateOrganizationPopupTitle from '../CreateOrganizationPopupTitle'
import CreateOrganizationPopupFormActions from '../CreateOrganizationPopupFormActions'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import successToast from '../../../../Components/Toasts/SuccessToast'
import { isValidEmail } from '../../../../Helpers/isValidEmail'
import { useCreateOrganizationUser } from '../../hooks/useCreateOrganizationUser'
import { useChangeOrganizationUsersRole } from '../../hooks/useChangeOrganizationUsersRole'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import AddAdminOrganizationPopupFormStep from '../AddAdminOrganizationPopupFormStep'

export default function AddAdminOrganizationPopupForm({
  organization,
  closePopup,
  groups,
}) {
  const [data, setData] = useState({
    owners: [],
  })

  const groupId = groups[0].f_id
  const queryClient = useQueryClient()
  const {
    mutateAsync: changeOrganizationUsersRole,
    isPending: isLoadingChangeOrganizationUsersRole,
  } = useChangeOrganizationUsersRole()

  const {
    mutateAsync: createOrganizationUser,
    isPending: isLoadingCreateOrganizationUser,
  } = useCreateOrganizationUser()

  const addContent = arr => {
    try {
      return Promise.allSettled(arr)
    } catch (error) {
      console.error('Error adding content:', error)
    }
  }

  const createUsersOneByOne = async ({ users, o_f_id }) => {
    try {
      return await Promise.allSettled(
        users.map(user =>
          createOrganizationUser({
            o_f_id,
            email: user,
          })
        )
      )
    } catch (error) {
      console.error('Error creating users:', error)
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    const { owners } = data
    const newOwners = [...new Set(owners)].filter(item => item)
    const isValidNewOwners = newOwners.every(item => isValidEmail(item))

    if (!newOwners.length) {
      errorToast('At least one Organization Owner is required')
      return
    }

    if (!isValidNewOwners) {
      errorToast('Organization Owner email must be valid email')
      return
    }

    const arr = [
      createUsersOneByOne({
        users: newOwners,
        o_f_id: organization.f_id,
      }),
    ]
    addContent(arr)
      .then(data => {
        if ((data[0].status = 'fulfilled')) {
          const newMembersIds = data[0].value
            .filter(item => item.status === 'fulfilled')
            .reduce((acc, item) => [...acc, item.value.f_id], [])

          return changeOrganizationUsersRole({
            o_f_id: organization.f_id,
            g_f_id: groupId,
            role: 'Organization Administrator',
            _action: 'add',
            organization_users: newMembersIds,
          })
        }
      })
      .then(() => {
        // successToast('Organization Administrators added successfully')
        closePopup()
        queryClient.invalidateQueries([
          'organization',
          'details',
          organization.f_id,
        ])
      })
  }

  return (
    <CreateOrganizationPopupContent onSubmit={onSubmit}>
      <CreateOrganizationPopupTitle
        title={`Add Admin to ${organization.name}`}
      />
      <AddAdminOrganizationPopupFormStep setData={setData} data={data} />
      <CreateOrganizationPopupFormActions>
        <ButtonOutline
          color={'pink'}
          size="small"
          text={'INVITE'}
          type="submit"
          disable={
            isLoadingChangeOrganizationUsersRole ||
            isLoadingCreateOrganizationUser
          }
        />
      </CreateOrganizationPopupFormActions>
    </CreateOrganizationPopupContent>
  )
}

AddAdminOrganizationPopupForm.propTypes = {
  organization: PropTypes.object,
  groups: PropTypes.array,
  closePopup: PropTypes.func,
}
