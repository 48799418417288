import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useAPI } from '../../../Contexts/APIContext'
import { useAuth } from '../../../Contexts/AuthContext'
import { useMutation } from '@tanstack/react-query'
import Loader from '../../../Components/GeneralComponents/Loader'
import errorToast from '../../../Components/Toasts/ErrorToast'
import FeelDropdownList from '../../../Components/DashboardComponents/FeelDropdownList/FeelDropdownList'
import Button from './Button'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { Navigation } from '../../../Components/page/Navigation'
import { renderBreadcrumbs } from './breadcrumbs'
import AdaptiveVideo from './AdaptiveVideo'
import classes from './index.module.css'
import { options } from './options'
import { previews } from './previews'
import { permissions } from '../../../constants/roles'
import useReadOnly from '../../../services/hooks/useReadOnly'

export default function ExportFeel() {
  const { requestExportFeel } = useAPI()
  const { id } = useParams()
  const videoElement = useRef()
  const [feelData, setFeelData] = useState(null)
  const [data, setData] = useState(null)
  const { getCurrentUser } = useAuth()

  const { isReadOnly: isFeelsReadOnly } = useReadOnly(permissions.r.feels_mm)

  // selectedOption: 'stories', 'tiktok', 'post', 'shorts'
  const [selectedOption, setSelectedOption] = useState(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const [isExportRequested, setIsExportRequested] = useState(false)
  let requestedInterval
  const handleClearExportRequest = () => {
    clearInterval(requestedInterval)
    setIsExportRequested(false)
  }
  const {
    mutate,
    isError,
    isPending: isLoading,
  } = useMutation({
    mutationFn: () => requestExportFeel(id),
    onSuccess: data => {
      setData(data)
    },
    onError: () => {
      handleClearExportRequest()
    },
    gcTime: 0,
  })

  const downloadVideo = async () => {
    setIsDownloading(true)
    try {
      const response = await fetch(data.export.url)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        data.song_name +
          `_${data.export.dimensions[0]}` +
          `x${data.export.dimensions[1]}` +
          '.mp4'
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading video:', error)
      errorToast('Export link not available')
    } finally {
      setIsDownloading(false)
    }
  }

  useEffect(() => {
    data && data?.export.status === 'ready' && setFeelData(data)
  }, [data])

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  useEffect(() => {
    mutate()
    return () => {
      handleClearExportRequest()
    }
  }, [])

  useEffect(() => {
    if (id && data && data?.export?.status !== 'ready') {
      !isExportRequested && setIsExportRequested(true)

      requestedInterval = setInterval(() => {
        mutate()
        clearInterval(requestedInterval)
      }, 1000)
    } else {
      handleClearExportRequest()
    }
  }, [data, id])

  return (
    <Wrapper>
      {!isLoading && !isExportRequested && (
        <Header>
          <Navigation breadcrumbs={renderBreadcrumbs(feelData)} />
        </Header>
      )}
      {feelData && !isError && !isLoading && !isExportRequested && (
        <div className={classes.wrapper}>
          <div className={classes.actions}>
            <FeelDropdownList
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              collectionLength={3}
              disabled={
                isDownloading || feelData?.export?.status === 'requested'
              }
            />

            {isDownloading ? (
              <div className={classes.loader}>
                <Loader isLoading={isDownloading} />
              </div>
            ) : (
              <Button
                disabled={
                  isFeelsReadOnly ||
                  !selectedOption ||
                  isDownloading ||
                  isExportRequested ||
                  feelData?.export?.status === 'requested'
                }
                onClick={downloadVideo}
                label="Download"
              />
            )}

            {feelData?.export?.status === 'ready' && selectedOption && (
              <div
                className={`${classes.container} ${classes.container__mobile}`}
              >
                <div className={classes.previewWrapper}>
                  <h2 className={classes.previewTitle}>PREVIEW</h2>
                  <div className={classes.preview}>
                    {previews.map(({ key, Component }) => {
                      return (
                        <div
                          key={key}
                          className={classes.previewItem}
                          style={{
                            visibility:
                              selectedOption === key ? 'visible' : 'hidden',
                          }}
                        >
                          <Component
                            data={data}
                            getCurrentUser={getCurrentUser}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          {feelData?.export?.status === 'ready' && (
            <AdaptiveVideo
              videoRef={videoElement}
              maxWidth={309}
              minWidth={170}
              controls
              autoPlay={true}
              muted={true}
              onTimeUpdate={() => {
                handleOnTimeUpdate()
              }}
              src={feelData?.export?.url}
            />
          )}

          {feelData?.export?.status === 'ready' && selectedOption && (
            <div
              className={`${classes.container} ${classes.container__desktop}`}
            >
              <div className={classes.previewWrapper}>
                <h2 className={classes.previewTitle}>PREVIEW</h2>
                <div className={classes.preview}>
                  {previews.map(({ key, Component }) => {
                    return (
                      <div
                        key={key}
                        className={classes.previewItem}
                        style={{
                          visibility:
                            selectedOption === key ? 'visible' : 'hidden',
                        }}
                      >
                        <Component
                          data={data}
                          getCurrentUser={getCurrentUser}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {((feelData?.export.status === 'requested' &&
        !isLoading &&
        !isExportRequested) ||
        isError) &&
        errorToast('Something went wrong')}
      {isError && <h1 style={{ color: 'white' }}>Something went wrong</h1>}
      {(isLoading || isExportRequested) && (
        <Loader isLoading={isLoading || isExportRequested} />
      )}
    </Wrapper>
  )
}
