import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function OptionsList({
  selectedOption,
  setSelectedOption,
  options,
  label,
  disabled,
}) {
  const [showOptionList, setShowOptionList] = useState(false)
  const selectContainerRef = useRef(null)

  useEffect(() => {
    disabled && setShowOptionList(false)
    const handleClickOutside = e => {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(e.target)
      ) {
        setShowOptionList(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedOption, showOptionList, disabled])

  const handleListDisplay = () => {
    !disabled && setShowOptionList(prevState => !prevState)
  }

  const handleOptionClick = option => {
    !disabled && (setSelectedOption(option), setShowOptionList(false))
  }

  return (
    <div
      className={classes.container}
      style={{ opacity: disabled ? '0.4' : '1' }}
    >
      <span className={classes.labelText}>{label}</span>
      <div className={classes.containerList} ref={selectContainerRef}>
        <div
          className={`${classes.selectedText} ${
            showOptionList ? classes.active : ''
          }`}
          onClick={handleListDisplay}
        >
          {selectedOption !== null ? selectedOption.text : 'select option'}
        </div>
        {showOptionList && (
          <ul className={classes.selectOptions}>
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className={
                  selectedOption.id === option.id ? classes.active : ''
                }
              >
                {option.text}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

OptionsList.propTypes = {
  selectedOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  setSelectedOption: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}
