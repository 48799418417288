import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from './AuthContext'
import { prepareHeaders } from '../Helpers/prepareHeaders'

const APIContext = createContext()

export function useAPI() {
  return useContext(APIContext)
}

export function APIProvider({ children }) {
  const { logout } = useAuth()
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL
  const GET = 'get'
  const POST = 'post'
  const PUT = 'put'
  const DELETE = 'delete'

  const INCLUDE = 'include'
  const OMIT = 'omit'

  // function prepareHeaders() {
  //   let headers = new Headers()

  //   headers.append('Content-Type', 'application/json')
  //   headers.append('Accept', 'application/json')

  //   headers.append('X-MMUF-API-Version', '1.0.0')
  //   headers.append('X-MMUF-Client', 'MMUF/1.0.0')

  //   return headers
  // }

  function fetchData(
    url,
    method,
    headers,
    body,
    credentials,
    success,
    failure
  ) {
    const newController = new AbortController()
    const signal = newController.signal

    fetch(url, {
      method: method,
      headers: headers,
      body: body,
      signal,
      credentials: credentials,
    })
      .then(data => {
        if (data.status === 200) {
          success(data)
        } else if (data.status === 401) {
          logout()
        } else {
          failure('Failed to fetch data with code: ' + data.status)
        }
      })
      .catch(e => {
        console.log(url)
        if (!(e instanceof DOMException)) {
          failure(e.message)
        }
      })
    return newController
  }

  async function simpleFetchData(url, method, headers, body, credentials) {
    try {
      const res = await fetch(url, {
        method: method,
        headers: headers,
        body: body,
        credentials: credentials,
      })
      if (!res?.ok) {
        throw {
          status: res?.status,
          message: res?.statusText,
          type: res?.type,
        }
      } else if (res.status === 401) {
        logout()
      } else if (res) {
        const contentType = res.headers.get('content-type')
        if (contentType && contentType.includes('application/json')) {
          const response = await res.json()
          return response
        } else {
          // Handle cases where response is not JSON
          const responseText = await res.text()
          return responseText
        }
      }
    } catch (e) {
      throw e
    }
  }

  function cancelRequest(controller) {
    if (controller !== undefined && controller !== null) {
      controller.abort()
    }
  }

  function getSongData(songFID) {
    const url = baseURL + '/music/song/' + songFID
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function fetchAllSongs(pageNumber, pageLimit, success, failure) {
    const url = baseURL + '/music/song?n=' + pageLimit + '&p=' + pageNumber
    return fetchData(
      url,
      GET,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  function getAllSongs(pageNumber, pageLimit) {
    const url = baseURL + '/music/song?n=' + pageLimit + '&p=' + pageNumber
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function searchAllSongs(searchTerm, pageNumber, pageLimit, success, failure) {
    const url = baseURL + '/music/song/_search'
    let body = JSON.stringify({
      search: searchTerm,
      page: pageNumber,
      limit: pageLimit,
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }
  function getSearchAllSongs(searchTerm, pageNumber, pageLimit, artistId = '') {
    const url = baseURL + '/music/song/_search'
    let body = JSON.stringify({
      search: searchTerm,
      page: pageNumber,
      limit: pageLimit,
      artist_f_id: artistId,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function getArtistSongs(artistUsername, pageNumber, pageLimit, sort) {
    const url =
      baseURL +
      '/music/artist/' +
      artistUsername +
      '/song?pr=0' +
      '&n=' +
      pageLimit +
      '&p=' +
      pageNumber +
      '&s=' +
      (sort ?? 'alphabet')
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }
  function getArtistTeases(artistUsername, pageNumber, pageLimit, sort) {
    const url =
      baseURL +
      '/music/artist/' +
      artistUsername +
      '/song?pr=1' +
      '&n=' +
      pageLimit +
      '&p=' +
      pageNumber +
      '&s=' +
      (sort ?? 'alphabet')
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getAlbumSongs(albumFID) {
    const url = baseURL + '/music/album/' + albumFID + '/song'
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getArtists(pageNumber, pageLimit) {
    const url = baseURL + '/music/artist?n=' + pageLimit + '&p=' + pageNumber
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getArtistData(artistName) {
    const url = baseURL + '/music/artist/' + artistName
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function searchArtists(searchTerm, pageNumber, pageLimit) {
    const url = baseURL + '/music/artist/_search'
    let body = JSON.stringify({
      search: searchTerm,
      page: pageNumber,
      limit: pageLimit,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function getBackdropList(artistName, pageNumber, pageLimit, songFID) {
    const url =
      baseURL +
      '/music/artist/' +
      artistName +
      '/backdrop?n=' +
      pageLimit +
      '&p=' +
      pageNumber +
      '&s=' +
      songFID
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getBackdrop(f_id) {
    const url = baseURL + '/music/backdrop/media/' + f_id
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getUrlNewBackdrop(artistFID, backdropType, mediaType) {
    const url = baseURL + '/music/backdrop/media'
    const body = JSON.stringify({
      backdrop_type: backdropType,
      artist_f_id: artistFID,
      media_type: mediaType,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function deleteBackdrop(backdropFID) {
    const url = baseURL + '/music/backdrop/' + backdropFID
    return simpleFetchData(url, DELETE, prepareHeaders(), null, INCLUDE)
  }

  function uploadBackdropMedia(uploadURL, file, mediaType) {
    let headers = new Headers()
    headers.append('Content-Type', mediaType)
    return simpleFetchData(uploadURL, PUT, headers, file, OMIT)
  }

  function updateBackdrop(songFID, backdropFID) {
    const url = baseURL + '/music/song/' + songFID + '/backdrop'
    const body = JSON.stringify({
      backdrop_f_id: backdropFID,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function fetchLyricsToEdit(songFID, success, failure) {
    const url = baseURL + '/music/song/' + songFID + '/lyrics?t=admin'
    return fetchData(
      url,
      GET,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  function fetchLyricsToCreateFeel(songFID, success, failure) {
    const url = baseURL + '/music/song/' + songFID + '/lyrics?t=feels'
    return fetchData(
      url,
      GET,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  function getAlbums(artistName, pageNumber, pageLimit) {
    const url =
      baseURL +
      '/music/artist/' +
      artistName +
      '/album?n=' +
      pageLimit +
      '&p=' +
      pageNumber
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function saveAlbumHideState(albumFID, isHidden) {
    let url = baseURL + '/music/album/' + albumFID
    const body = JSON.stringify({
      is_public_visible: isHidden,
      is_published: isHidden,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function saveSongEdits(
    songFID,
    videoOffset,
    lyrics,
    isSyncVerified,
    isLocked,
    editorEmail,
    success,
    failure
  ) {
    let url = baseURL + '/music/song/' + songFID + '/sync'
    const body = JSON.stringify({
      video_offset: videoOffset,
      lyrics_sync: lyrics,
      is_sync_verified: isSyncVerified,
      sync_user_id: editorEmail,
      feels_locked: isLocked,
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }

  function saveSongLyricsEdits(songFID, lyrics, editorEmail, success, failure) {
    let url = baseURL + '/music/song/' + songFID + '/lyrics/edit'
    const body = JSON.stringify({
      _publish: 'publish',
      edit_type: 'compose',
      lyrics_sync: lyrics,
      edit_user_id: editorEmail,
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }

  function saveSongLockState(songFID, isLocked, editorEmail) {
    let url = baseURL + '/music/song/' + songFID
    const body = JSON.stringify({
      sync_user_id: editorEmail,
      feels_locked: isLocked,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function saveSongHideState(songFID, isHidden, editorEmail) {
    let url = baseURL + '/music/song/' + songFID
    const body = JSON.stringify({
      sync_user_id: editorEmail,
      is_public_visible: isHidden,
      is_published: isHidden,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function sendSongFeedback(songFID, comment, success, failure) {
    let url = baseURL + '/music/song/' + songFID + '/report'
    const body = JSON.stringify({
      comment: comment,
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }

  function uploadArtistData(
    artistName,
    fullName,
    instagramURL,
    twitterURL,
    youtubeURL,
    tiktokURL,
    mechURL,
    tourURL,
    impactURL
  ) {
    let url = baseURL + '/music/artist/' + artistName
    var socialMedia = [
      { social_f_id: 'instagram', url: instagramURL },
      { social_f_id: 'twitter', url: twitterURL },
      { social_f_id: 'youtube', url: youtubeURL },
      { social_f_id: 'tiktok', url: tiktokURL },
    ]
    var links = [
      { link_type_f_id: 'merchandise', url: mechURL },
      { link_type_f_id: 'tour', url: tourURL },
      { link_type_f_id: 'impact', url: impactURL },
    ]
    const body = JSON.stringify({
      artist_name: artistName,
      name: fullName,
      social_media: socialMedia,
      links: links,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function getArtistFeels(artistUsername, pageNumber, pageLimit, sort) {
    const url =
      baseURL +
      '/music/artist/' +
      artistUsername +
      '/mm?n=' +
      pageLimit +
      '&p=' +
      pageNumber +
      '&s=' +
      (sort ?? 'alphabet')
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getFeelsForSong(songFID, pageNumber, pageLimit) {
    const url =
      baseURL +
      '/music/song/' +
      songFID +
      '/mm?n=' +
      pageLimit +
      '&p=' +
      pageNumber
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getFeelData(feelFID) {
    const url = baseURL + '/feels/mm/' + feelFID
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getFeelTags({
    feelFID,
    isUserTags = false,
    isSuggestedTags = false,
  }) {
    const url = `${baseURL}/feels/mm/${feelFID}/tags${
      isUserTags ? '/users' : isSuggestedTags ? '/suggested' : ''
    }`
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }
  function generateFeelTags({ song_f_id, lyrics_lines, regenerate = true }) {
    const url = `${baseURL}/feels/tags/suggested`
    const body = JSON.stringify({ song_f_id, lyrics_lines, regenerate })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function requestExportFeel(feelFID) {
    const url = baseURL + '/feels/mm/' + feelFID + '/export'
    const body = JSON.stringify({ _export: true })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function exportFeel(feelFID) {
    const url = baseURL + '/feels/mm/' + feelFID + '/export'
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getFeelsCount(status) {
    const url = baseURL + '/feels/mm?ff=' + status + '&c=1'
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function fetchFeelsByStatus(status, pageNumber, pageLimit, success, failure) {
    const url =
      baseURL +
      '/feels/mm?ff=' +
      status +
      '&n=' +
      pageLimit +
      '&p=' +
      pageNumber
    return fetchData(
      url,
      GET,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  function getFeels(status, pageNumber, pageLimit) {
    const url =
      baseURL +
      '/feels/mm?ff=' +
      status +
      '&n=' +
      pageLimit +
      '&p=' +
      pageNumber
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function searchFeels(
    search,
    pageNumber,
    pageLimit,
    statusPage,
    artistId = '',
    isDB = false
  ) {
    const url = `${baseURL}/feels/mm/_search${isDB ? '?s=database' : ''}`
    const requestBody = {
      search: search,
      page: pageNumber,
      limit: pageLimit,
      ...(statusPage && { status: [statusPage] }),
      artist_f_id: artistId,
    }
    const body = JSON.stringify(requestBody)
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function deleteFeel(feelFID) {
    const url = baseURL + '/feels/mm/' + feelFID
    return simpleFetchData(url, DELETE, prepareHeaders(), null, INCLUDE)
  }

  function createFeel(songFID, lyricsLines, tags, success, failure) {
    const url = baseURL + '/feels/mm/'
    const body = JSON.stringify({
      song_f_id: songFID,
      lyrics_lines: lyricsLines,
      tags: tags,
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }

  function createFeelPreview(songFID, lyricsLines, success, failure) {
    const url = baseURL + '/feels/preview'
    const body = JSON.stringify({
      song_f_id: songFID,
      lyrics_lines: lyricsLines,
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }

  function fetchFeelPreview(feelFID, success, failure) {
    const url = baseURL + '/feels/preview/' + feelFID
    console.log(url)
    return fetchData(
      url,
      GET,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  function publishFeel(feelFID) {
    const url = baseURL + '/feels/mm/' + feelFID + '/publish'
    const body = JSON.stringify({ _publish: true })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function unpublishFeel(feelFID) {
    const url = baseURL + '/feels/mm/' + feelFID + '/status'
    const body = JSON.stringify({ status: 'pending_review' })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function updateTagsOnFeel(feelFID, tags) {
    const url = baseURL + '/feels/mm/' + feelFID + '/tags'
    const body = JSON.stringify({ _action: 'update', tags: tags })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function reportSong(songFID, reportText, reporterEmail, success, failure) {
    const url = baseURL + '/music/song/' + songFID + '/report'
    const body = JSON.stringify({
      report_text: reportText,
      report_user_id: reporterEmail,
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }

  function getArtistImageUrl(artistName, fileType, fileSize) {
    const url = baseURL + '/music/artist/' + artistName + '/image'
    const body = JSON.stringify({
      image_type: fileType,
      file_size: fileSize,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function uploadArtistImage(uploadURL, file, fileType) {
    let headers = new Headers()
    headers.append('Content-Type', fileType)
    return simpleFetchData(uploadURL, PUT, headers, file, OMIT)
  }

  function fetchGuestToken(success, failure) {
    const url = baseURL + '/service/superset/embed_guest_token'
    return fetchData(
      url,
      GET,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  // COLLECTIONS

  function getCollections(pageNumber, pageLimit) {
    const url = baseURL + '/collection/list?n=' + pageLimit + '&p=' + pageNumber
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getCollection(collectionFID, pageNumber, pageLimit) {
    const url =
      baseURL +
      '/collection/list/' +
      collectionFID +
      '/object?n=' +
      pageLimit +
      '&p=' +
      pageNumber
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function updateCollection(collectionFID, name, isPublic, feelsFID) {
    const url = baseURL + '/collection/list/' + collectionFID
    const body = JSON.stringify({
      name: name,
      global_type: 'featured',
      collection_object: 'mm',
      is_public: isPublic,
      object_f_ids: feelsFID,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function addCollection(name, isPublic, feelsFID) {
    const url = baseURL + '/collection/list'
    const body = JSON.stringify({
      name: name,
      global_type: 'featured',
      collection_object: 'mm',
      is_public: isPublic,
      object_f_ids: feelsFID,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function publishToggleCollection(collectionFID, isPublic) {
    const url = baseURL + '/collection/list/' + collectionFID
    const body = JSON.stringify({ _action: 'update', is_public: isPublic })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function deleteCollectionFeel(feelFID, songFID) {
    const url = baseURL + '/collection/list/' + feelFID + '/object'
    const body = JSON.stringify({ _action: 'delete', object_f_ids: [songFID] })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function deleteCollection(collectionFID) {
    const url = baseURL + '/collection/list/' + collectionFID
    return simpleFetchData(url, DELETE, prepareHeaders(), null, INCLUDE)
  }

  function getCollectionImageUrl(collectionFID, type, size) {
    const url = baseURL + '/collection/list/' + collectionFID + '/image'
    const body = JSON.stringify({
      image_type: type,
      file_size: size,
      preserve_aspect_ratio: true,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function uploadCollectionImage(uploadURL, file, fileType) {
    let headers = new Headers()
    headers.append('Content-Type', fileType)
    // const body = JSON.stringify({ file, preserve_aspect_ratio: true })
    return simpleFetchData(uploadURL, PUT, headers, file, OMIT)
  }

  // SMART STICKERS

  function getStickers(pageNumber, pageLimit) {
    const url = baseURL + '/feels/stickers?n=' + pageLimit + '&p=' + pageNumber
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getStickersPack(stickersPackFID) {
    const url = baseURL + '/feels/stickers/' + stickersPackFID
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function updatePackDetails(stickersPackFID, name, description) {
    const url = baseURL + '/feels/stickers/' + stickersPackFID
    const body = JSON.stringify({ name: name, description: description })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function updateStickersStatus(stickersPackFID, status) {
    const url = baseURL + '/feels/stickers/' + stickersPackFID + '/publish'
    const body = JSON.stringify({ _publish: status })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function getStickersUrls(stickersPackFID) {
    const url = baseURL + '/feels/stickers/' + stickersPackFID + '/stickers'
    return simpleFetchData(url, POST, prepareHeaders(), null, INCLUDE)
  }

  function addStickersPack(token, name, description, type) {
    const url = baseURL + '/feels/stickers'
    const body = JSON.stringify({
      token: token,
      name: name,
      description: description,
      tag_type: type,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function deleteStickersPack(stickersPackFID) {
    const url = baseURL + '/feels/stickers/' + stickersPackFID
    return simpleFetchData(url, DELETE, prepareHeaders(), null, INCLUDE)
  }

  function uploadStickerItem(uploadURL, file, fileType) {
    let headers = new Headers()
    headers.append('Content-Type', fileType)
    return simpleFetchData(uploadURL, PUT, headers, file, OMIT)
  }

  // ORGANIZATIONS

  // function fecthOrgAccount(success, failure) {
  //   const url = baseURL + '/organization/account'
  //   return fetchData(
  //     url,
  //     GET,
  //     prepareHeaders(),
  //     null,
  //     INCLUDE,
  //     success,
  //     failure
  //   )
  // }

  // function fetchOrgUserList(orgFId, success, failure) {
  //   const url = baseURL + '/organization/' + orgFId + '/user'
  //   return fetchData(
  //     url,
  //     GET,
  //     prepareHeaders(),
  //     null,
  //     INCLUDE,
  //     success,
  //     failure
  //   )
  // }

  function createNewOrgUser(orgFId, email, success, failure) {
    const url = baseURL + '/organization/' + orgFId + '/user'
    const body = JSON.stringify({
      email: email,
      login_type: 'google',
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      data => {
        data.json().then(json => {
          if (json.f_id !== undefined && json.f_id !== null) {
            const userFId = json.f_id

            fetchGroupID(
              orgFId,
              data => {
                data.json().then(json => {
                  if (
                    json.organization_groups !== undefined &&
                    json.organization_groups !== null &&
                    json.organization_groups.length > 0 &&
                    json.organization_groups[0].f_id !== undefined &&
                    json.organization_groups[0].f_id !== null
                  ) {
                    const groupFId = json.organization_groups[0].f_id
                    console.log(groupFId)

                    changeOrgRole(
                      orgFId,
                      groupFId,
                      'viewer',
                      'add',
                      userFId,
                      success,
                      () => {
                        // In either case this is a failure to the higher callback
                        deleteOrgUser(orgFId, userFId, failure, failure)
                      }
                    )
                  } else {
                    // In either case this is a failure to the higher callback
                    deleteOrgUser(orgFId, userFId, failure, failure)
                  }
                })
              },
              failure
            )
          } else {
            // In either case this is a failure to the higher callback
            deleteOrgUser(orgFId, userFId, failure, failure)
          }
        })
      },
      failure
    )
  }

  function changeOrgRole(
    orgFId,
    groupFId,
    role,
    action,
    userFId,
    success,
    failure
  ) {
    const url =
      baseURL +
      '/organization/' +
      orgFId +
      '/group/' +
      groupFId +
      '/role/' +
      role +
      '/user'
    const body = JSON.stringify({
      _action: action,
      organization_users: [userFId],
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }

  function deleteOrgUser(orgFId, userFId, success, failure) {
    const url = baseURL + '/organization/' + orgFId + '/user/' + userFId
    return fetchData(
      url,
      DELETE,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  function fetchGroupID(orgFId, success, failure) {
    const url = baseURL + '/organization/' + orgFId + '/group?n=Organization'
    return fetchData(
      url,
      GET,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  // ADMIN

  // function fecthAdminAccount(success, failure) {
  //   const url = baseURL + '/admin/account'
  //   return fetchData(
  //     url,
  //     GET,
  //     prepareHeaders(),
  //     null,
  //     INCLUDE,
  //     success,
  //     failure
  //   )
  // }

  // function fetchAdminUserList(success, failure) {
  //   const url = baseURL + '/admin/user'
  //   return fetchData(
  //     url,
  //     GET,
  //     prepareHeaders(),
  //     null,
  //     INCLUDE,
  //     success,
  //     failure
  //   )
  // }

  function createNewAdminUser(email, success, failure) {
    const url = baseURL + '/admin/user'
    const body = JSON.stringify({
      email: email,
      login_type: 'google',
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      data => {
        data.json().then(json => {
          if (json.f_id !== undefined && json.f_id !== null) {
            const userFId = json.f_id

            changeAdminRole('viewer', 'add', userFId, success, () => {
              // In either case this is a failure to the higher callback
              deleteAdminUser(userFId, failure, failure)
            })
          } else {
            // In either case this is a failure to the higher callback
            deleteAdminUser(userFId, failure, failure)
          }
        })
      },
      failure
    )
  }

  function changeAdminRole(role, action, userFId, success, failure) {
    const url = baseURL + '/admin/role/' + role + '/user'
    const body = JSON.stringify({
      _action: action,
      admin_users: [userFId],
    })
    return fetchData(
      url,
      POST,
      prepareHeaders(),
      body,
      INCLUDE,
      success,
      failure
    )
  }

  function deleteAdminUser(userFId, success, failure) {
    const url = baseURL + '/admin/user/' + userFId
    return fetchData(
      url,
      DELETE,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  function fetchCurrentUser(success, failure) {
    const url = baseURL + '/account'
    return fetchData(
      url,
      GET,
      prepareHeaders(),
      null,
      INCLUDE,
      success,
      failure
    )
  }

  // Tease

  async function getData(url, method, headers, body, credentials) {
    try {
      const newController = new AbortController()
      const signal = newController.signal
      const response = await fetch(url, {
        method,
        headers,
        body,
        signal,
        credentials,
      })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.json()
      return data
    } catch (error) {
      throw error
    }
  }

  async function createTease({
    is_promotional = true,
    songFID = null,
    ...rest
  }) {
    const song_id = songFID ? `/${songFID}` : ''
    const url = baseURL + '/music/song' + song_id
    const body = JSON.stringify({
      is_promotional,
      ...rest,
    })
    return getData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  async function createTeaseVideo({ payload, tease_id }) {
    const url = baseURL + `/music/song/${tease_id}/video`
    const body = JSON.stringify(payload)
    return getData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  async function uploadTeaseVideo({ uploadURL, file }) {
    let headers = new Headers()
    headers.append('Content-Type', file.type)
    return simpleFetchData(uploadURL, PUT, headers, file, OMIT)
  }

  async function editTeaseLyrics({ payload, tease_id }) {
    const url = baseURL + `/music/song/${tease_id}/lyrics/edit`
    const body = JSON.stringify({
      _publish: 'publish',
      edit_type: 'compose',
      ...payload,
    })
    return getData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  async function fetchTease(tease_id) {
    const url = `${baseURL}/music/song/${tease_id}/lyrics?t=admin`
    return getData(url, 'GET', prepareHeaders(), null, INCLUDE)
  }

  async function saveTeaseEdits({ songFID, ...rest }) {
    let url = baseURL + '/music/song/' + songFID + '/sync'
    const body = JSON.stringify({
      ...rest,
    })
    return getData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  // Organizations

  function getOrganizationsList({ p, n }) {
    const url = baseURL + '/organization?p=' + p + '&n=' + n
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }
  function getOrganizationsAccount() {
    const url = `${baseURL}/organization/account`
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getOrganizationDetails({ id }) {
    const url = `${baseURL}/organization/${id}`
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getTeamDetails({ o_f_id, g_f_id }) {
    const url = `${baseURL}/organization/${o_f_id}/group/${g_f_id}`
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getOrganizationGroups({ o_f_id, n }) {
    const url = `${baseURL}/organization/${o_f_id}/group${n ? '/?n=' + n : ''}`
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getOrganizationUsers({ o_f_id, u_f_id }) {
    const url = `${baseURL}/organization/${o_f_id}/user${
      u_f_id ? '/' + u_f_id : ''
    }`
    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getOrganizationGroupUsers({ o_f_id, role, g_f_id }) {
    const url = `${baseURL}/organization/${o_f_id}/group/${g_f_id}/role/${role}/user`

    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function createOrganization({ name, o_f_id = null, ...rest }) {
    const url = `${baseURL}/organization${o_f_id ? '/' + o_f_id : ''}`
    const body = JSON.stringify({
      name,
      ...rest,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function createOrganizationGroup({ name, o_f_id, g_f_id, ...rest }) {
    const url = `${baseURL}/organization/${o_f_id}/group${
      g_f_id ? '/' + g_f_id : ''
    }`
    const body = JSON.stringify({
      name,
      ...rest,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function createOrganizationUser({ o_f_id, ...rest }) {
    const url = `${baseURL}/organization/${o_f_id}/user`
    const body = JSON.stringify({
      login_type: 'google',
      ...rest,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }
  function deleteOrganizationUser({ o_f_id, u_f_id }) {
    const url = `${baseURL}/organization/${o_f_id}/user/${u_f_id}`

    return simpleFetchData(url, DELETE, prepareHeaders(), null, INCLUDE)
  }

  function changeOrganizationUsersRole({ o_f_id, g_f_id, role, ...rest }) {
    const url = `${baseURL}/organization/${o_f_id}/group/${g_f_id}/role/${role}/user`
    const body = JSON.stringify({
      ...rest,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function manageOrganizationContent({ o_f_id, content_object, ...rest }) {
    const url = `${baseURL}/organization/${o_f_id}/content/${content_object}`
    const body = JSON.stringify({
      ...rest,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }
  function manageOrganizationGroupContent({
    o_f_id,
    g_f_id,
    content_object,
    ...rest
  }) {
    const url = `${baseURL}/organization/${o_f_id}/group/${g_f_id}/content/${content_object}`
    const body = JSON.stringify({
      ...rest,
    })
    return simpleFetchData(url, POST, prepareHeaders(), body, INCLUDE)
  }

  function getOrganizationContent({
    o_f_id,
    content_object,
    pageParam,
    perPage,
  }) {
    const url = `${baseURL}/organization/${o_f_id}/content/${content_object}?n=${perPage}&p=${pageParam}`

    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  function getOrganizationGroupContent({
    o_f_id,
    g_f_id,
    content_object,
    pageParam,
    perPage,
  }) {
    const url = `${baseURL}/organization/${o_f_id}/group/${g_f_id}/content/${content_object}?n=${perPage}&p=${pageParam}`

    return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  }

  // function getOrganizationGroupContent({ o_f_id, g_f_id, content_object, n }) {
  //   const url = `${baseURL}/organization/${o_f_id}/group/${g_f_id}/content/${content_object}`
  //   return simpleFetchData(url, GET, prepareHeaders(), null, INCLUDE)
  // }

  const value = {
    getSongData,
    fetchAllSongs,
    getAllSongs,
    searchAllSongs,
    getSearchAllSongs,
    getAlbumSongs,
    getArtists,
    getArtistData,
    searchArtists,
    getBackdropList,
    getUrlNewBackdrop,
    deleteBackdrop,
    uploadBackdropMedia,
    updateBackdrop,
    fetchLyricsToEdit,
    fetchLyricsToCreateFeel,
    getAlbums,
    saveAlbumHideState,
    saveSongEdits,
    saveSongLyricsEdits,
    saveSongLockState,
    saveSongHideState,
    getArtistSongs,
    sendSongFeedback,
    uploadArtistData,
    getFeelsForSong,
    getFeelData,
    requestExportFeel,
    exportFeel,
    getFeelsCount,
    fetchFeelsByStatus,
    deleteFeel,
    createFeel,
    createFeelPreview,
    fetchFeelPreview,
    publishFeel,
    unpublishFeel,
    updateTagsOnFeel,
    reportSong,
    getArtistImageUrl,
    uploadArtistImage,
    fetchGuestToken,
    createNewOrgUser,
    changeOrgRole,
    deleteOrgUser,
    fetchGroupID,
    createNewAdminUser,
    changeAdminRole,
    deleteAdminUser,
    fetchCurrentUser,
    cancelRequest,
    getCollections,
    getCollection,
    deleteCollectionFeel,
    deleteCollection,
    publishToggleCollection,
    addCollection,
    getStickers,
    getStickersPack,
    updatePackDetails,
    updateStickersStatus,
    getStickersUrls,
    addStickersPack,
    deleteStickersPack,
    uploadStickerItem,
    getFeels,
    searchFeels,
    updateCollection,
    getCollectionImageUrl,
    uploadCollectionImage,
    getFeelTags,
    getArtistFeels,
    createTease,
    createTeaseVideo,
    uploadTeaseVideo,
    editTeaseLyrics,
    fetchTease,
    saveTeaseEdits,
    getArtistTeases,
    generateFeelTags,
    getOrganizationsList,
    createOrganization,
    manageOrganizationContent,
    getOrganizationGroups,
    createOrganizationUser,
    changeOrganizationUsersRole,
    getOrganizationDetails,
    getOrganizationUsers,
    getOrganizationContent,
    deleteOrganizationUser,
    createOrganizationGroup,
    getOrganizationGroupContent,
    manageOrganizationGroupContent,
    getTeamDetails,
    getOrganizationGroupUsers,
    getOrganizationsAccount,
    getBackdrop,
  }
  return <APIContext.Provider value={value}>{children}</APIContext.Provider>
}

APIProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
