import React, { useEffect, useRef, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { useInView } from 'react-intersection-observer'
import Loader from '../../GeneralComponents/Loader'
import CollectionFeelsModal from '../CollectionFeelsModal/CollectionFeelsModal'
import SearchInput from '../../DashboardComponents/SearchInput/SearchInput'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CollectionModal({
  closeModal,
  setToNewCollection,
  reservedFeels,
}) {
  const section = ''
  const pageLimit = 10
  const { searchFeels, getFeels } = useAPI()
  const [ref, inView] = useInView()
  const [isOpen, setIsOpen] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTimer, setSearchTimer] = useState(null)

  const {
    data,
    isError,
    error,
    isLoading,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['collection-add-feels', searchTerm],
    queryFn: ({ pageParam = 1 }) =>
      searchTerm === ''
        ? getFeels(section, pageParam, pageLimit)
        : searchFeels(searchTerm, pageParam, pageLimit, undefined, '', true),
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.feels_count ? nextPage : undefined
      return newPage
    },
  })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const handleCloseClick = () => {
    setIsOpen(false)
  }

  function handleSearchInput(e) {
    if (searchTimer !== null) {
      clearTimeout(searchTimer)
      setSearchTimer(null)
    }
    setSearchTimer(
      setTimeout(() => {
        setSearchTerm(e.target.value)
        setSearchTimer(null)
      }, 500)
    )
  }

  return (
    <>
      {isOpen && (
        <div
          className={classes.bgContainer}
          onClick={() => {
            closeModal()
          }}
        >
          <div className={classes.container} onClick={e => e.stopPropagation()}>
            <div>
              <SearchInput
                fullWidth
                placeholder={'Search'}
                onSearchInput={handleSearchInput}
              />
            </div>
            <div className={classes.feelsWrapper}>
              {data &&
                data.pages.map((page, index) => (
                  <React.Fragment key={index}>
                    {page.feels.map((feel, innerIndex) => (
                      <div key={feel.f_id} ref={ref}>
                        <CollectionFeelsModal
                          feel={feel}
                          setToNewCollection={setToNewCollection}
                          reservedFeels={reservedFeels}
                          closeModal={closeModal}
                        />
                      </div>
                    ))}
                  </React.Fragment>
                ))}
            </div>
            {!isError && <Loader isLoading={isLoading || isFetchingNextPage} />}
            {isError && (
              <h1 className={classes.errorText}>
                {'Oops, something went wrong'}
              </h1>
            )}

            {/* <Loader isLoading={isLoading || isFetchingNextPage} /> */}
          </div>
        </div>
      )}
    </>
  )
}

CollectionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setToNewCollection: PropTypes.func,
  reservedFeels: PropTypes.arrayOf(PropTypes.string),
}
