import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useGetOrgDetails({ id }) {
  const { getOrganizationDetails } = useAPI()

  return useQuery({
    queryKey: ['organization', 'details', id],
    queryFn: () => getOrganizationDetails({id}),
  })
}
