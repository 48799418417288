import React from 'react'
import PropTypes from 'prop-types'

import classes from './index.module.css'

export default function InviteButton({ handleInvite }) {
  return (
    <button className={classes.button} onClick={handleInvite}>
      INVITE
    </button>
  )
}

InviteButton.propTypes = {
  handleInvite: PropTypes.func.isRequired,
}
