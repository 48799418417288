import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'


export const Actions = ({ children }) => (
  <div className={classes.actions}>{children}</div>
)

Actions.propTypes = {
  children: PropTypes.node,
}
