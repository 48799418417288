import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const Title = ({ title }) => (
  <h1 className={classes.title}>{title}</h1>
)

Title.propTypes = {
  title: PropTypes.string,
}
