import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import CreateOrganizationSortedArtistsList from '../../CreateOrganizationSortedArtistsList'
import CreateOrganizationPopupArtistsCount from '../../CreateOrganizationPopupArtistsCount'
import ManageOrganizationPopupArtistsSearch from '../../CreateOrganizationPopupArtistsSearch'
import usePopup from '../../../hooks/usePopup'
import { useGetOrgContent } from '../../../hooks/useGetOrgContent'
import { useParams } from 'react-router-dom'

function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
export default function Step({
  setData,
  data,
  handleManageGroupArtists,
  group,
}) {
  const {o_f_id, g_f_id} = useParams()
  const [searchString, setSearchString] = useState('')

  const {
    openPopup: showOptions,
    closePopup: hideOptions,
    isOpen: isOptionsOpen,
  } = usePopup()

  const handleSetSearchString = debounce(
    e => setSearchString(e.target.value),
    500
  )

  const handleRemoveAllArtists = () => {
    setData([])
    const content_object_f_ids = data.map(({ f_id }) => f_id)
    handleManageGroupArtists({
      o_f_id,
      g_f_id,
      content_object: 'artist',
      is_cascade: true,
      content_object_f_ids,
      _action: 'delete',
    })
  }

  const handleAddArtists = artists => {
    const newArtists = Array.isArray(artists) ? artists : [artists]

    setData(prev => [
      ...new Map(
        [...prev, ...newArtists].map(item => [item.f_id, item])
      ).values(),
    ])

    const content_object_f_ids = [
      ...new Map(
        [...data, ...newArtists].map(item => [item.f_id, item])
      ).values(),
    ].map(({ f_id }) => f_id)

    handleManageGroupArtists({
      o_f_id,
      g_f_id,
      content_object: 'artist',
      is_cascade: true,
      content_object_f_ids,
      _action: 'add',
    })
  }

  const handleRemoveArtist = artist => {
    setData(prev => prev.filter(item => item.f_id !== artist.f_id))
    const content_object_f_ids = data
      .filter(item => item.f_id === artist.f_id)
      .map(({ f_id }) => f_id)
    handleManageGroupArtists({
      o_f_id,
      g_f_id,
      content_object: 'artist',
      is_cascade: true,
      content_object_f_ids,
      _action: 'delete',
    })
  }

  const {
    data: artistsList,
    isLoading: isArtistsListLoading,
    fetchNextPage: fetchNextArtists,
    hasNextPage: hasNextPageArtists,
    isFetchingNextPage: isFetchingNextPageArtists,
  } = useGetOrgContent({
    o_f_id,
    content_object: 'artist',
    n: 15,
  })

  const artists = searchString
    ? artistsList?.pages
        .map(page => page.artist)
        .flat()
        .filter(item => item.artist_name.includes(searchString))
    : artistsList?.pages.map(page => page.artist).flat()
  const fetchNextPage = fetchNextArtists
  const hasNextPage = hasNextPageArtists

  const isFetchingNextPage = isFetchingNextPageArtists

  const isLoading = isArtistsListLoading

  useEffect(() => {
    if (hasNextPageArtists && !isFetchingNextPageArtists) {
      fetchNextArtists()
    }
  }, [hasNextPageArtists, isFetchingNextPageArtists, fetchNextArtists])

  return (
    <div className={classes.wrapper}>
      <div className={classes.search}>
        <ManageOrganizationPopupArtistsSearch
          handleSetSearchString={handleSetSearchString}
          artists={artists}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          handleAddArtists={handleAddArtists}
          handleRemoveArtist={handleRemoveArtist}
          stepData={data}
          showOptions={showOptions}
          hideOptions={hideOptions}
          isOptionsOpen={isOptionsOpen}
        />
      </div>

      <div className={classes.count}>
        <CreateOrganizationPopupArtistsCount
          count={data.length}
          action={handleRemoveAllArtists}
        />
      </div>
      <div className={classes.sorted}>
        <CreateOrganizationSortedArtistsList
          artists={data}
          handleRemoveArtist={handleRemoveArtist}
        />
      </div>
    </div>
  )
}

Step.propTypes = {
  setData: PropTypes.func,
  handleManageGroupArtists: PropTypes.func,
  data: PropTypes.any,
  group: PropTypes.any,
}
