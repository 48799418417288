import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import CreateOrganizationDynamicInputsTextFields from '../../CreateOrganizationDynamicInputsTextFields'

export default function Step({ setData }) {


  const onArrayChange = newOwners =>
    setData(prev => {
      return {
        ...prev,
        owners: newOwners,
      }
    })

  return (
    <div className={classes.wrapper}>
      <CreateOrganizationDynamicInputsTextFields
        placeholder={'Enter admin email'}
        label={'Assign Admin'}
        onChange={onArrayChange}
        buttonText="Add More Admins"
      />
    </div>
  )
}

Step.propTypes = {
  setData: PropTypes.func,
}
