import { useState, useEffect } from 'react'
import { isDesktop } from 'react-device-detect'

const useDeviceAccessControl = () => {
  const [hasAccess, setHasAccess] = useState(false)

  useEffect(() => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    setHasAccess(isDesktop && isChrome)
  }, [])

  return hasAccess
}

export default useDeviceAccessControl
