import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { useFeelsStore } from '../../../Storage/feelsPage/useFeelsStore'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import FeelSectionButton from '../../../Components/DashboardComponents/SectionButton/FeelSectionButton'
import Loader from '../../../Components/GeneralComponents/Loader'
import FeelsOnDashboard from '../FeelsOnDashboard/FeelsOnDashboard'
import classes from './index.module.css'
import { permissions } from '../../../constants/roles'
import useReadOnly from '../../../services/hooks/useReadOnly'
import useUserInfo from '../../../services/hooks/useUserInfo'

export default function Dashboard() {
  const navigate = useNavigate()
  const { getFeelsCount } = useAPI()
  const { name, email } = useUserInfo()

  const { isReadOnly: isFeelsFactoryReadOnly } = useReadOnly(
    permissions.r.feels_factory
  )

  // ZUSTAND STORAGE
  const currentlySelectedSection = useFeelsStore(
    state => state.currentlySelectedSection
  )
  const setCurrentlySelectedSection = useFeelsStore(
    state => state.setCurrentlySelectedSection
  )
  const [sections, setSections] = useState([
    {
      status: '',
      title: 'Total',
      count: 0,
    },
    {
      status: 'published',
      title: 'Published',
      count: 0,
    },
    {
      status: 'pending_review',
      title: 'Pending',
      count: 0,
    },
    // {
    //   status: "deleted",
    //   title: "Inactive Feels",
    //   count: 0,
    // },
  ])

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['feelsCount'],
    queryFn: () => {
      const promises = sections.map(section => getFeelsCount(section.status))
      return Promise.all(promises).then(values => {
        const modifiedValues = values.map(value => ({
          status: value.factory_status ? value.factory_status[0] : '',
          count: value.feels_count,
        }))
        const newSections = modifiedValues.map(value => {
          const index = sections.findIndex(
            section => section.status === value.status
          )
          if (index !== -1) {
            return { ...sections[index], count: value.count }
          }
          return null
        })
        setSections(newSections)
        return newSections
      })
    },
  })

  function handleCreate() {
    navigate('/create-new-feel-step-1')
  }

  function handleSectionSelected(sectionTitle) {
    const indexOfNewSelectedSection = sections
      .map(item => item.title)
      .indexOf(sectionTitle)
    if (indexOfNewSelectedSection !== undefined) {
      setCurrentlySelectedSection(sections[indexOfNewSelectedSection])
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={classes.welcomeMessage}>
          Hi, {name ? name ?? email : ''}
        </span>
        <ButtonOutline
          disable={isFeelsFactoryReadOnly}
          color={'pink'}
          size="small"
          icon="createIcon"
          text={'CREATE MUSIC MESSAGE'}
          onClick={() => {
            navigate('/create-new-feel-step-1')
          }}
        />
        {/* TO DO: - Changes based on the roles? */}
      </div>
      <>
        {isLoading && <Loader isLoading={isLoading} />}
        {isError && (
          <h1 className={classes.errorText}>Something failed. Try again</h1>
        )}
        {data && (
          <>
            <div className={classes.buttonsWrapper}>
              {data.map(section => (
                <FeelSectionButton
                  key={section.title}
                  isSelected={
                    currentlySelectedSection !== undefined
                      ? section.title === currentlySelectedSection.title
                      : false
                  }
                  amount={section.count}
                  title={section.title}
                  onSelected={handleSectionSelected}
                />
              ))}
            </div>

            <FeelsOnDashboard section={currentlySelectedSection} />
          </>
        )}
      </>
    </div>
  )
}
