import React from 'react'
import PropTypes from 'prop-types'

import classes from './index.module.css'

export default function RemoveButton({ user, onDelete, ...rest }) {
  const handleDelete = () => {
    onDelete(user)
  }
  return (
    <button className={classes.button} onClick={handleDelete} {...rest}>
      Remove
    </button>
  )
}

RemoveButton.propTypes = {
  user: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
}
