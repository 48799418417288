import React, { useEffect, useState } from 'react'
import CreateOrganizationPopupContent from '../CreateOrganizationPopupContent'
import CreateOrganizationPopupTitle from '../CreateOrganizationPopupTitle'
import CreateOrganizationPopupFormSteps from '../CreateOrganizationPopupFormSteps'
import CreateOrganizationPopupFormActions from '../CreateOrganizationPopupFormActions'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import successToast from '../../../../Components/Toasts/SuccessToast'
import { isValidEmail } from '../../../../Helpers/isValidEmail'
import { useCreateOrganization } from '../../hooks/useCreateOrganization'
import { useManageOrganizationContent } from '../../hooks/useManageOrganizationContent'
import { useGetOrganizationGroups } from '../../hooks/useGetOrganizationGroups'
import { useCreateOrganizationUser } from '../../hooks/useCreateOrganizationUser'
import { useChangeOrganizationUsersRole } from '../../hooks/useChangeOrganizationUsersRole'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export default function CreateOrganizationPopupForm() {
  const [step, setStep] = useState(1)
  const [orgId, setOrgId] = useState('')
  const [orgGroupId, setOrgGroupId] = useState('')

  const [step1Data, setStep1Data] = useState({
    name: '',
    owners: [],
  })
  const [step2Data, setStep2Data] = useState([])

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {
    data: organizationGroups,
    isError: organizationGroupsError,
    isLoading: organizationGroupsIsLoading,
    isFetching: isOrganizationGroupsFetching,
    isSuccess: isOrganizationGroupsSuccess,
  } = useGetOrganizationGroups({
    o_f_id: orgId,
    n: 'Organization',
    enabled: !!orgId,
  })

  const {
    mutate: createOrganization,
    isPending: isLoadingCreateOrganization,
  } = useCreateOrganization()

  const {
    mutateAsync: changeOrganizationUsersRole,
    isPending: isLoadingChangeOrganizationUsersRole,
  } = useChangeOrganizationUsersRole()

  const {
    mutateAsync: createOrganizationUser,
    isPending: isLoadingCreateOrganizationUser,
  } = useCreateOrganizationUser()

  const {
    mutateAsync: addOrganizationArtists,
    isPending: isLoadingOrganizationArtists,
  } = useManageOrganizationContent()

  const handleCreateOrganization = organizationData => {
    createOrganization(organizationData, {
      onSuccess: data => {
        setOrgId(data.f_id)
      },
      onError: error => {
        errorToast('Error creating Organization', error)
      },
    })
  }

  const addContent = arr => {
    try {
      return Promise.allSettled(arr)
    } catch (error) {
      console.error('Error adding content:', error)
    }
  }

  const createUsersOneByOne = async ({ users, o_f_id }) => {
    try {
      return await Promise.allSettled(
        users.map(user =>
          createOrganizationUser({
            o_f_id,
            email: user,
          })
        )
      )
    } catch (error) {
      console.error('Error creating users:', error)
    }
  }

  const handleAddOrganizationArtists = async data => {
    try {
      return await addOrganizationArtists(data)
    } catch (error) {
      console.error('Error adding artists:', error)
    }
  }

  const onSubmitStep1 = e => {
    e.preventDefault()
    const { name, owners } = step1Data
    const newOwners = [...new Set(owners)].filter(item => item)
    const isValidNewOwners = newOwners.every(item => isValidEmail(item))

    if (!name) {
      errorToast('Organization Name is required')
      return
    }
    if (!newOwners.length) {
      errorToast('At least one Organization Owner is required')
      return
    }

    if (!isValidNewOwners) {
      errorToast('Organization Owner email must be valid email')
      return
    }

    setStep1Data({ name, owners: newOwners })
    setStep(2)
  }
  const onSubmitStep2 = e => {
    e.preventDefault()
    handleCreateOrganization({
      name: step1Data.name,
    })
  }

  const onSubmit = [onSubmitStep1, onSubmitStep2].find(
    (_, index) => index + 1 === step
  )

  const setStepData = [setStep1Data, setStep2Data].find(
    (_, index) => index + 1 === step
  )

  const stepData = [step1Data, step2Data].find((_, index) => index + 1 === step)

  const renderPopupTitle = step => {
    if (step === 1) return 'Create Organization'
    if (step === 2)
      return `Assign Artists to the ${step1Data.name} Organization`
  }

  useEffect(() => {
    if (isOrganizationGroupsSuccess) {
      setOrgGroupId(organizationGroups.organization_groups[0].f_id)
    }
  }, [isOrganizationGroupsSuccess])

  useEffect(() => {
    if (organizationGroupsError) {
      queryClient.invalidateQueries(['organizations'])
      successToast('Organization created successfully')
      errorToast('Error fetching groups')
      navigate(`/organizations/${orgId}`)
    }
  }, [organizationGroupsError])

  useEffect(() => {
    if (orgId && orgGroupId) {
      const content_object_f_ids = step2Data.map(({ f_id }) => f_id)

      const arr = [
        createUsersOneByOne({
          users: step1Data.owners,
          o_f_id: orgId,
        }),
        content_object_f_ids.length
          ? handleAddOrganizationArtists({
              o_f_id: orgId,
              content_object: 'artist',
              _action: 'add',
              is_cascade: true,
              content_object_f_ids,
            })
          : null,
      ]
      addContent(arr)
        .then(data => {
          if ((data[0].status = 'fulfilled')) {
            const newMembersIds = data[0].value
              .filter(item => item.status === 'fulfilled')
              .reduce((acc, item) => [...acc, item.value.f_id], [])

            return changeOrganizationUsersRole({
              o_f_id: orgId,
              g_f_id: orgGroupId,
              role: 'Organization Owner',
              _action: 'add',
              organization_users: newMembersIds,
            })
          }
        })
        .then(() => {
          queryClient.invalidateQueries(['organizations'])
          successToast('Organization created successfully')
          navigate(`/organizations/${orgId}`)
        })
    }
  }, [orgId, orgGroupId])

  return (
    <CreateOrganizationPopupContent onSubmit={onSubmit}>
      <CreateOrganizationPopupTitle title={renderPopupTitle(step)} />
      <CreateOrganizationPopupFormSteps
        step={step}
        setStepData={setStepData}
        stepData={stepData}
      />
      <CreateOrganizationPopupFormActions>
        <ButtonOutline
          color={'pink'}
          size="small"
          text={step === 2 ? 'CREATE' : 'NEXT'}
          type="submit"
          disable={
            organizationGroupsIsLoading ||
            isOrganizationGroupsFetching ||
            isLoadingCreateOrganization ||
            isLoadingChangeOrganizationUsersRole ||
            isLoadingCreateOrganizationUser ||
            isLoadingOrganizationArtists
          }
        />
      </CreateOrganizationPopupFormActions>
    </CreateOrganizationPopupContent>
  )
}

CreateOrganizationPopupForm.propTypes = {}
