import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import MyTeamsTableRow from '../MyTeamsTableRow'

export default function MyTeamsTable({ groups, onDelete }) {
  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.row}>
          <th>
            <p className={classes.text}>Team Name</p>
          </th>
          <th>
            <p className={classes.text}>Organization</p>
          </th>
          <th>
            <p className={classes.text}>Artists</p>
          </th>
          <th>
            <p className={classes.text}>Access Level</p>
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        {groups.map((group, index, self) => {
          return (
            <MyTeamsTableRow
              key={group.f_id}
              group={group}
              onDelete={onDelete}
              zIndex={self.length - index}
            />
          )
        })}
      </tbody>
    </table>
  )
}

MyTeamsTable.propTypes = {
  groups: PropTypes.array,
  onDelete: PropTypes.func,
}
