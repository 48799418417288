import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { handleActivity } from '../../../../services/functions'
import RolesSelect from '../RolesSelect'
import usePopup from '../../hooks/usePopup'
import RemovePopup from '../RemovePopup'
import { useParams } from 'react-router-dom'
import useAccessLevel from '../../hooks/useAccessLevel'

export default function TeamUsersTable({
  user,
  options,
  onDelete,
  onChangeRole,
  zIndex,
}) {
  const { closePopup, openPopup, isOpen } = usePopup()
  const { o_f_id, g_f_id } = useParams()
  const { hasOrgAdminAccess, groupRole, isInternalAdmin } = useAccessLevel({
    o_f_id,
    g_f_id,
  })

  return (
    <tr className={classes.row} style={{ zIndex, position: 'relative' }}>
      <td>
        <p className={`${classes.text}`}>{user.name ? user.name : '-'}</p>
      </td>
      <td>
        <p className={`${classes.text}`}>{user.email}</p>
      </td>
      <td>
        <p className={`${classes.text}`}>
          {user.date_last_active
            ? handleActivity({ dateTo: user.date_last_active })
            : '-'}
        </p>
      </td>
      <td>
        <RolesSelect
          options={options}
          defaultOption={
            user.role === 'Group Administrator'
              ? 'Team Administrator'
              : user.role
          }
          disabled={
            !isInternalAdmin &&
            !hasOrgAdminAccess &&
            groupRole !== 'Group Administrator'
          }
          onOptionSelect={onChangeRole}
          userId={user.f_id}
        />
      </td>
      <td>
        {hasOrgAdminAccess ||
        groupRole === 'Group Administrator' ||
        isInternalAdmin ? (
          <button
            disabled={
              !hasOrgAdminAccess &&
              groupRole !== 'Group Administrator' &&
              !isInternalAdmin
            }
            onClick={openPopup}
            className={`${classes.text}`}
          >
            ...
            <div className={classes.remove}>
              <RemovePopup
                closePopup={closePopup}
                isOpen={isOpen}
                action={() => {
                  onDelete({
                    role: user.role,
                    organization_users: [user.f_id],
                  })
                  closePopup()
                }}
                title={'REMOVE TEAM ACCESS'}
              />
            </div>
          </button>
        ) : null}
      </td>
    </tr>
  )
}

TeamUsersTable.propTypes = {
  user: PropTypes.object.isRequired,
  currentRole: PropTypes.string,
  currentUserRole: PropTypes.string.isRequired,
  isCurrentUserAdmin: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  zIndex: PropTypes.any,
}
