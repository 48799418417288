import React from 'react'
import PropTypes from 'prop-types'

import classes from './index.module.css'
import { Link } from 'react-router-dom'

export default function OrganizationPreview({
  image,
  name,
  artists,
  members,
  id,
}) {
  const isPlural = item => item !== 1
  return (
    <Link
      to={`/organizations/${id}`}
      className={classes.card}
      style={{ justifyContent: image ? 'flex-start' : 'center' }}
    >
      {image ? (
        <div className={classes.card__image}>
          <img src={image} alt={name} width={140} height={56.25} />
        </div>
      ) : null}
      <div>
        <h3 className={classes.card__title}>{name}</h3>
        <div className={classes.card__details}>
          <div className={classes.card__text}>
            {artists} Artist{isPlural(artists) ? 's' : ''}
          </div>
          <span>|</span>
          <div className={classes.card__text}>
            {members} Member{isPlural(members) ? 's' : ''}
          </div>
        </div>
      </div>
    </Link>
  )
}

OrganizationPreview.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  artists: PropTypes.string,
  members: PropTypes.string,
  id: PropTypes.string,
}
