import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { handleActivity } from '../../../../services/functions'
import { orgRoles, orgRolesAdmin, roles } from '../../../../constants/roles'
import RolesSelect from '../RolesSelect'
import DeleteBtn from './DeleteBtn'
import useAccessLevel from '../../hooks/useAccessLevel'
import { useParams } from 'react-router-dom'

export default function UsersTableRow({
  user,
  optionsOrg,
  optionsGroup,
  onTeamUserDelete,
  onOrgUserDelete,
  onChangeRole,
  zIndex,
}) {
  const { id } = useParams()
  const { isInternalAdmin, hasOrgAdminAccess, groupOrganizationUserRole } =
    useAccessLevel({
      o_f_id: id,
    })
  const isOrgAdmin = user => {
    const org = user?.organization_groups?.find(
      group => group.name === 'Organization'
    )
    const role = org?.roles[0]?.role

    return orgRolesAdmin.includes(role)
  }

  const handleDisableSelect = role => {
    const access =
      groupOrganizationUserRole === 'Organization Administrator' &&
      (role === 'Organization Administrator' || role === 'Organization Owner')

    return (!isInternalAdmin && !hasOrgAdminAccess) || access
  }

  return (
    <tr className={classes.row} style={{ zIndex, position: 'relative' }}>
      <td>
        <p className={`${classes.text}`}>{user.name ? user.name : '-'}</p>
      </td>
      <td>
        <p className={`${classes.text}`}>{user.email}</p>
      </td>
      <td>
        <p className={`${classes.text}`}>
          {user.date_last_active
            ? handleActivity({ dateTo: user.date_last_active })
            : '-'}
        </p>
      </td>
      <td>
        {isOrgAdmin(user) ? (
          <p className={`${classes.text}`}>-</p>
        ) : (
          user?.organization_groups.map((group, index) => (
            <p key={index} className={`${classes.text}`}>
              {group.name === 'Organization' ? '-' : group.name}
            </p>
          ))
        )}
      </td>
      <td>
        {(isOrgAdmin(user)
          ? user.organization_groups.slice(0, 1)
          : user.organization_groups
        ).map((group, index, self) => (
          <div
            style={{ zIndex: self.length - index, position: 'relative' }}
            key={index}
            className={`${classes.text}`}
          >
            <RolesSelect
              options={
                group.name === 'Organization' ? optionsOrg : optionsGroup
              }
              defaultOption={
                group.roles[0].role === 'Group Administrator'
                  ? 'Team Administrator'
                  : group.roles[0].role
              }
              disabled={handleDisableSelect(group.roles[0].role)}
              onOptionSelect={onChangeRole}
              userId={user.f_id}
              groupId={group.f_id}
            />
          </div>
        ))}
      </td>
      <td>
        {(isOrgAdmin(user)
          ? user.organization_groups.slice(0, 1)
          : user.organization_groups
        ).map((group, index) => (
          <div key={index} className={`${classes.text}`}>
            <DeleteBtn
              user={user}
              disabled={handleDisableSelect(group.roles[0].role)}
              onTeamUserDelete={onTeamUserDelete}
              onOrgUserDelete={onOrgUserDelete}
              group={group}
            />
          </div>
        ))}

        {user.organization_groups.length === 0 ? (
          <div className={`${classes.text}`}>
            <DeleteBtn
              user={user}
              disabled={false}
              onTeamUserDelete={onTeamUserDelete}
              onOrgUserDelete={onOrgUserDelete}
              group={null}
            />
          </div>
        ) : null}
      </td>
    </tr>
  )
}

UsersTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  currentRole: PropTypes.string,
  currentUserRole: PropTypes.string.isRequired,
  isCurrentUserAdmin: PropTypes.bool.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  zIndex: PropTypes.any,
  optionsOrg: PropTypes.array.isRequired,
  optionsGroup: PropTypes.array.isRequired,
  onTeamUserDelete: PropTypes.func.isRequired,
  onOrgUserDelete: PropTypes.func.isRequired,
}
