import React from 'react'
import PropTypes from 'prop-types'
import TeaseFormTitle from '../TeaseFormTitle'
import TeaseForm from '../TeaseForm'
import classes from './index.module.css'

export default function TeasePopupContent({ closePopup, artist }) {
  return (
    <div className={classes.wrapper}>
      <TeaseFormTitle title={'Create Tease Content'} />
      <TeaseForm closePopup={closePopup} artist={artist} />
    </div>
  )
}

TeasePopupContent.propTypes = {
  closePopup: PropTypes.func,
  artist: PropTypes.shape({
    artist_name: PropTypes.string,
    f_id: PropTypes.string,
  }),
}
