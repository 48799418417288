import React, { useState } from 'react'
import CreateOrganizationPopupContent from '../CreateOrganizationPopupContent'
import CreateOrganizationPopupTitle from '../CreateOrganizationPopupTitle'
import CreateOrganizationPopupFormActions from '../CreateOrganizationPopupFormActions'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import successToast from '../../../../Components/Toasts/SuccessToast'
import { isValidEmail } from '../../../../Helpers/isValidEmail'
import { useCreateOrganizationUser } from '../../hooks/useCreateOrganizationUser'
import { useChangeOrganizationUsersRole } from '../../hooks/useChangeOrganizationUsersRole'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import AddAdminGroupPopupFormStep from '../AddAdminGroupPopupFormStep'
import { useGetOrgUsers } from '../../hooks/useGetOrgUsers'
import { useParams } from 'react-router-dom'

export default function AddAdminGroupPopupForm({ group, closePopup }) {
  const [data, setData] = useState({
    owners: [],
  })

  const {o_f_id, g_f_id} = useParams()

  const queryClient = useQueryClient()
  const {
    mutateAsync: changeOrganizationUsersRole,
    isPending: isLoadingChangeOrganizationUsersRole,
  } = useChangeOrganizationUsersRole()

  const {
    data: orgUsers,
    isError: orgUsersError,
    isLoading: orgUsersIsLoading,
    isFetching: orgUsersIsFetching,
  } = useGetOrgUsers({
    o_f_id,
  })

  const {
    mutateAsync: createOrganizationUser,
    isPending: isLoadingCreateOrganizationUser,
  } = useCreateOrganizationUser()

  const addContent = arr => {
    try {
      return Promise.allSettled(arr)
    } catch (error) {
      console.error('Error adding content:', error)
    }
  }

  const createUsersOneByOne = async ({ users }) => {
    try {
      return await Promise.allSettled(
        users.map(user =>
          createOrganizationUser({
            o_f_id,
            email: user.email,
          }).then(data => {
            return changeOrganizationUsersRole({
              o_f_id,
              g_f_id,
              role:
                user.role === 'Team Administrator'
                  ? 'Group Administrator'
                  : user.role,
              _action: 'add',
              organization_users: [data.f_id],
            })
          })
        )
      )
    } catch (error) {
      console.error('Error creating users:', error)
    }
  }
  const createExistingUsersOneByOne = async ({ users }) => {
    try {
      return await Promise.allSettled(
        users.map(user =>
          changeOrganizationUsersRole({
            o_f_id,
            g_f_id,
            role:
              user.role === 'Team Administrator'
                ? 'Group Administrator'
                : user.role,
            _action: 'add',
            organization_users: [user.f_id],
          })
        )
      )
    } catch (error) {
      console.error('Error creating users:', error)
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    const { owners } = data
    const newOwners = [
      ...new Map(owners.map(item => [item.email, item]))
        .values()
        .filter(item => item.email),
    ]
    const isValidNewOwnersEmail = newOwners.every(item =>
      isValidEmail(item.email)
    )
    const isValidNewOwnersRoles = newOwners.every(item => item.role)

    if (!newOwners.length) {
      errorToast('At least one Team member is required')
      return
    }

    if (!isValidNewOwnersEmail) {
      errorToast('Team member email must be valid email')
      return
    }
    if (!isValidNewOwnersRoles) {
      errorToast('Team member role is required')
      return
    }

    const users = newOwners.reduce(
      (acc, owner) => {
        const user = orgUsers.organization_users.find(
          user => user.email === owner.email
        )

        if (!user) {
          acc.new.push(owner)
        } else {
          const existingGroup = user.organization_groups.find(
            g => g.f_id === g_f_id
          )
          !existingGroup &&
            acc.existing.push({ f_id: user.f_id, role: owner.role })
        }

        return acc
      },
      {
        new: [],
        existing: [],
      }
    )

    const arr = [
      users.new.length
        ? createUsersOneByOne({
            users: users.new,
            o_f_id,
          })
        : null,
      users.existing.length
        ? createExistingUsersOneByOne({
            users: users.existing,
            o_f_id,
          })
        : null,
    ]
    addContent(arr)
      .then(() => {
        successToast('Team members added successfully')
        queryClient.invalidateQueries(['organization'])
      })
      .then(() => closePopup())
  }

  return (
    <CreateOrganizationPopupContent onSubmit={onSubmit}>
      <CreateOrganizationPopupTitle title={`Add Member to ${group.name}`} />
      <AddAdminGroupPopupFormStep setData={setData} data={data} />
      <CreateOrganizationPopupFormActions>
        <ButtonOutline
          color={'pink'}
          size="small"
          text={'INVITE'}
          type="submit"
          disable={
            isLoadingChangeOrganizationUsersRole ||
            isLoadingCreateOrganizationUser ||
            orgUsersIsLoading
          }
        />
      </CreateOrganizationPopupFormActions>
    </CreateOrganizationPopupContent>
  )
}

AddAdminGroupPopupForm.propTypes = {
  group: PropTypes.object,
  closePopup: PropTypes.func,
}
