import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function RemovePopup({ title, isOpen, closePopup, action }) {
  const ref = useRef()

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        closePopup()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  if (!isOpen) {
    return null
  }
  return (
    <span role='button' onClick={action} ref={ref} className={classes.button}>
      {title}
    </span>
  )
}

RemovePopup.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closePopup: PropTypes.func,
  action: PropTypes.func,
}
