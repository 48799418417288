import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import Input from './Input'
import { groupRoles } from '../../../../constants/roles'
import { CustomSelect } from '../CustomSelect'

export default function CreateTeamDynamicInputsTextFields({
  onChange = () => false,
  required = true,
  label,
  buttonText = 'Add More',
  inputType = 'rounded',
  placeholder,
}) {
  const [owners, setOwners] = useState([{
    email:"",
    role:""
  }])

  const addOwner = () => {
    setOwners([
      ...owners,
      {
        email: '',
        role: '',
      },
    ]) // Adds a new empty email field
  }

  const updateEmail = (index, value) => {
    const newOwners = [...owners]
    newOwners[index].email = value
    setOwners(newOwners)
  }
  const updateRole = (index, value) => {
    const newOwners = [...owners]
    newOwners[index].role = value
    setOwners(newOwners)
  }

  useEffect(() => {
    onChange(owners)
  }, [owners])

  return (
    <div>
      {label ? (
        <p className={classes.label}>
          {label} {required ? <span>*</span> : null}
        </p>
      ) : null}
      <div className={classes.inputs}>
        {owners.map((_, index) => {
          return (
            <div
              key={index}
              className={classes.textfields}
              style={{ zIndex: owners.length - index }}
            >
              <Input
                index={index}
                updateOwner={updateEmail}
                inputType={inputType}
                placeholder={placeholder}
              />
              <CustomSelect
                index={index}
                onOptionSelect={updateRole}
                options={groupRoles}
                disabled={false}
              />
            </div>
          )
        })}
      </div>
      {owners.length < 5 ? (
        <button onClick={addOwner} type="button" className={classes.button}>
          + {buttonText}
        </button>
      ) : null}
    </div>
  )
}

CreateTeamDynamicInputsTextFields.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  inputType: PropTypes.string,
  placeholder: PropTypes.string,
}
