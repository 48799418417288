/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import TagsList from './TagsList'
import TagInput from './TagInput'
import useTags from './hooks'
import classes from './index.module.css'

export default function TagInputSection({
  officialTags,
  userTags,
  handleTagsUpdated,
  isFetchingOfficialTags,
  isFetchingUserTags,
  isFetchingSuggestedTags,
  disabled,
  suggestedTags,
  handleSuggestionTagClick,
  retchSuggestedTags,
  song,
  isTagsUpdatingSuccess,
}) {
  const {
    tags,
    currentlyEnteredTag,
    handleOnEnterPressed,
    handleTagInput,
    handleDeleteTag,
  } = useTags({ officialTags, handleTagsUpdated })

  const onSuggestedTagClick = tag =>
    handleSuggestionTagClick.mutate([...tags, tag])

  return (
    <div className={classes.wrapper}>
      <TagInput
        disabled={disabled}
        currentlyEnteredTag={currentlyEnteredTag}
        onTagInput={e => {
          handleTagInput(e)
        }}
        onEnterPressed={e => {
          handleOnEnterPressed(e)
        }}
        isTagsUpdatingSuccess={isTagsUpdatingSuccess}
      />

      <TagsList
        disabled={disabled}
        tags={[...new Set([...tags])]}
        title="Official Tags"
        editable={true}
        handleDeleteTag={handleDeleteTag}
        isOfficial={true}
        isFetching={isFetchingOfficialTags}
      />
      <TagsList
        disabled={disabled}
        tags={userTags}
        title="User Tags"
        isFetching={isFetchingUserTags}
        isUser={true}
        handleSuggestedTagClick={onSuggestedTagClick}
        officialTags={tags}
      />
      <TagsList
        disabled={disabled}
        tags={suggestedTags}
        title="Suggested Tags"
        isSuggested={true}
        isFetching={isFetchingSuggestedTags}
        handleSuggestedTagClick={onSuggestedTagClick}
        officialTags={tags}
        retchSuggestedTags={retchSuggestedTags}
        song={song}
      />
    </div>
  )
}

TagInputSection.propTypes = {
  officialTags: PropTypes.array,
  userTags: PropTypes.array,
  suggestedTags: PropTypes.array,
  handleTagsUpdated: PropTypes.func,
  retchSuggestedTags: PropTypes.func,
  isFetchingOfficialTags: PropTypes.bool,
  isFetchingUserTags: PropTypes.bool,
  disabled: PropTypes.bool,
  isFetchingSuggestedTags: PropTypes.bool,
  isTagsUpdatingSuccess: PropTypes.bool,
  isFetchingSuggestedTags: PropTypes.any,
  song: PropTypes.any,
}
