import { useInfiniteQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useGetOrgContent({ o_f_id, content_object, n = undefined }) {
  const { getOrganizationContent } = useAPI()

  const CONTENT_PER_PAGE = n || 50

  return useInfiniteQuery({
    queryKey: [
      'organization',
      'content',
      o_f_id,
      content_object,
      CONTENT_PER_PAGE,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getOrganizationContent({
        pageParam,
        perPage: CONTENT_PER_PAGE,
        o_f_id,
        content_object,
      }),
    initialPageParam: 1,

    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * CONTENT_PER_PAGE < pageData[`${content_object}_count`]
          ? nextPage
          : undefined
      return newPage
    },
  })
}
