import { ReactComponent as InstagramIcon } from '../../../Components/icons/mm_export/instagram-icon.svg'
import { ReactComponent as YoutubeIcon } from '../../../Components/icons/mm_export/youtube-icon.svg'
import { ReactComponent as TiktokIcon } from '../../../Components/icons/mm_export/tiktok-icon.svg'

export const options = [
  {
    value: 'stories',
    label: 'Stories/Reels - 1080px x 1920px',
    icon: InstagramIcon,
  },
  {
    value: 'post',
    label: 'Post - 1080px x 1350px',
    icon: InstagramIcon,
  },
  {
    value: 'shorts',
    label: 'Shorts - 1080px x 1920px',
    icon: YoutubeIcon,
  },
  {
    value: 'tiktok',
    label: 'Video - 1080px x 1920px',
    icon: TiktokIcon,
  },
]
