import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Lyrics from '../../../../Components/DashboardComponents/Lyrics/Lyrics'
import ArtistAndSongName from '../../../../Components/GeneralComponents/ArtistAndSongName/ArtistAndSongName'
import Loader from '../../../../Components/GeneralComponents/Loader'
import { useAPI } from '../../../../Contexts/APIContext'
import { sleep } from '../../../../Helpers/Timer+Sleep'
import TagsList from '../../../../Components/DashboardComponents/TagInputSection/TagsList'
import useTags from '../../../../Components/DashboardComponents/TagInputSection/hooks'
import TagInput from '../../../../Components/DashboardComponents/TagInputSection/TagInput'
import classes from './index.module.css'
import { Wrapper } from '../../../../Components/page/Wrapper'
import { Header } from '../../../../Components/page/Header'
import { Navigation } from '../../../../Components/page/Navigation'
import { breadcrumbs } from './breadcrumbs'
import { Heading } from '../../../../Components/page/Heading'
import { GeneratingLoader } from '../../../../Components/page/GeneratingLoader'
import { ErrorGeneratingMessage } from '../../../../Components/page/ErrorGeneratingMessage'
import { Actions } from '../../../../Components/page/Actions'
import { Button } from '../../../../Components/page/Button'
import { HeaderInfo } from '../../../../Components/page/HeaderInfo'
import { permissions } from '../../../../constants/roles'
import useReadOnly from '../../../../services/hooks/useReadOnly'
import { useGenerateTags } from './useGenerateTags'
import successToast from '../../../../Components/Toasts/SuccessToast'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import { usePreventFullscreen } from '../../../../Helpers/hooks/usePreventFullscreen'

export const Step2 = () => {
  const queryClient = useQueryClient()
  const {
    fetchLyricsToCreateFeel,
    createFeel,
    createFeelPreview,
    fetchFeelPreview,
    cancelRequest,
    updateTagsOnFeel,
  } = useAPI()

  const {
    mutate: generateSuggestedTags,
    isPending: isLoadingGenerateSuggestedTags,
    isError: isErrorGenerateSuggestedTags,
  } = useGenerateTags()

  const [officialTags, setOfficialTags] = useState([])

  function handleTagsUpdated(newTags) {
    setOfficialTags(newTags)
  }
  const {
    tags,
    currentlyEnteredTag,
    handleOnEnterPressed,
    handleTagInput,
    handleDeleteTag,
    setTags,
  } = useTags({ officialTags, handleTagsUpdated })
  const [song, setSong] = useState()
  const [feelPreview, setFeelPreview] = useState()
  const navigate = useNavigate()
  const { id } = useParams()
  const { isReadOnly: isFeelsFactoryReadOnly } = useReadOnly(
    permissions.r.feels_factory
  )

  const videoElement = useRef()
  usePreventFullscreen(videoElement)

  const [lyrics, setLyrics] = useState()

  const [initialFullVideoSource, setInitialFullVideoSource] = useState()
  const [videoSource, setVideoSource] = useState()

  const [lyricsColumnOrder] = useState(0)
  const lyricsColumnRef = useRef(null)
  const [videoColumnOrder] = useState(1)
  const videoColumnRef = useRef(null)
  const [tagsColumnOrder] = useState(2)
  const tagsColumnRef = useRef(null)
  const [gridDimensions, setGridDimensions] = useState('1fr 1.2fr')

  const [showLyrics, setShowLyrics] = useState(true)
  const [showTags, setShowTags] = useState(false)
  const [showVideoEditingElements, setShowVideoEditingElements] = useState(true)

  const [isGeneratingFeel, setIsGeneratingFeel] = useState(false)
  const [generatingMessage, setGeneratingMessage] = useState(null)
  const [genertingPreviewFetches, setGenertingPreviewFetches] = useState(0)
  const [errorGeneratingMessage, setErrorGeneratingMessage] = useState(null)

  const [selectedVideoDuration, setSelectedVideoDuration] = useState(0)

  const [isLoading, setIsLoading] = useState(true)
  const [controller, setController] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [suggestedTags, setSuggestedTags] = useState(null)

  const [timer, setTimer] = useState(null)
  const [videoDuration, setVideoDuration] = useState(0)

  const handleGenerateTags = data => {
    generateSuggestedTags(data, {
      onSuccess: data => {
        successToast('Suggested tags generated successfully')
        setSuggestedTags(data.tags)
        // queryClient.invalidateQueries(['tease', songFID])
      },
      onError: error => {
        errorToast('Error generating Suggested tags', error)
      },
    })
  }

  useEffect(() => {
    if (feelPreview && !suggestedTags) {
      handleGenerateTags({
        song_f_id: feelPreview.song_f_id,
        lyrics_lines: feelPreview.lyrics_lines,
      })
    }
  }, [!!feelPreview, !!suggestedTags])

  useEffect(() => {
    fetchData()
    handleClearSelected()
  }, [])

  useEffect(() => {
    if (timer === null) {
      const newTimer = setInterval(() => {
        handleOnTimeUpdate()
      }, 30)
      setTimer(newTimer)
    }
    return () => {
      clearInterval(timer)
      setTimer(null)
    }
  }, [lyrics])

  useEffect(() => {
    if (feelPreview !== null && feelPreview !== undefined) {
      if (
        feelPreview.preview?.video?.video?.url !== null &&
        feelPreview.preview?.video?.video?.url !== undefined
      ) {
        setGeneratingMessage(null)
        setGenertingPreviewFetches(0)
        setVideoSource(feelPreview.preview?.video?.video?.url)
        setIsGeneratingFeel(false)
        videoFinishedProcessingSuccesfully()
      } else {
        if (genertingPreviewFetches >= 5 && genertingPreviewFetches < 10) {
          setGeneratingMessage('Still working on it')
        } else if (
          genertingPreviewFetches >= 10 &&
          genertingPreviewFetches < 15
        ) {
          setGeneratingMessage('Getting closer to the result')
        } else if (
          genertingPreviewFetches >= 15 &&
          genertingPreviewFetches < 20
        ) {
          setGeneratingMessage('Almost there')
        } else if (
          genertingPreviewFetches >= 20 &&
          genertingPreviewFetches < 25
        ) {
          setGeneratingMessage('Retuning the instruments')
        } else if (genertingPreviewFetches >= 25) {
          setGeneratingMessage('Sorry for the delay, you are creating emotion through music, sometimes that takes a moment')
        }
        setGenertingPreviewFetches(prev => {
          return prev + 1
        })
        sleep(1000).then(() => {
          startFetchingTheVideoPreview()
        })
      }
    }
  }, [feelPreview])

  function fetchData() {
    setErrorMessage(null)
    cancelRequest(controller)
    setIsLoading(true)

    const newController = fetchLyricsToCreateFeel(
      id,
      data => {
        setIsLoading(false)
        setController(null)
        data
          .json()
          .then(json => {
            setSong(json)
            const lyrics = [...json.lyrics_sync]
            for (let i = 0; i < lyrics.length; i++) {
              lyrics[i].isSelected = false
              lyrics[i].index = i
            }
            setLyrics(lyrics)
            setInitialFullVideoSource(json.video?.video?.url)
            setVideoSource(json.video?.video?.url)
            setIsLoading(false)
          })
          .catch(e => {
            setErrorMessage(e.message)
            setController(null)
            setIsLoading(false)
          })
      },
      error => {
        setController(null)
        setIsLoading(false)
        setErrorMessage(error)
      }
    )
    setController(newController)
  }

  function convertVideo() {
    processFeelCreationRequest(
      true,
      data => {
        data
          .json()
          .then(json => {
            setFeelPreview(json)
          })
          .catch(e => {
            finishedGeneratingWithError(e.message)
          })
      },
      error => {
        finishedGeneratingWithError(error)
      }
    )
  }

  function startFetchingTheVideoPreview() {
    const newController = fetchFeelPreview(
      feelPreview.f_id,
      data => {
        setController(null)
        data
          .json()
          .then(json => {
            setFeelPreview(json)
          })
          .catch(e => {
            finishedGeneratingWithError(e.message)
          })
      },
      error => {
        finishedGeneratingWithError(error)
      }
    )

    setController(newController)
  }

  function handleOnCreateFeel() {
    processFeelCreationRequest(
      false,
      () => {
        setIsGeneratingFeel(false)
        setController(null)
        queryClient.clear()
        navigate('/song/feels/' + song.f_id)
      },
      error => {
        finishedGeneratingWithError(error)
      }
    )
  }

  function finishedGeneratingWithError(error) {
    setGeneratingMessage(null)
    setGenertingPreviewFetches(0)
    if (error.includes('409')) {
      setErrorGeneratingMessage(
        error + '; Most likely the song is locked. Unlock on song details page.'
      )
    } else {
      setErrorGeneratingMessage(error)
    }
    setController(null)
    setIsGeneratingFeel(false)
  }

  function processFeelCreationRequest(isPreview, success, failure) {
    let selectedLyricIndices = getSelectedLyrics().map(item => {
      return item.index
    })
    if (selectedLyricIndices.length > 0) {
      var selectedLinesObject = [
        selectedLyricIndices[0],
        selectedLyricIndices[selectedLyricIndices.length - 1],
      ]

      setGeneratingMessage(null)
      setGenertingPreviewFetches(0)
      setErrorGeneratingMessage(null)
      cancelRequest(controller)
      setIsGeneratingFeel(true)

      var newController = null
      if (isPreview) {
        newController = createFeelPreview(
          song.f_id,
          selectedLinesObject,
          success,
          failure
        )
      } else {
        newController = createFeel(
          song.f_id,
          selectedLinesObject,
          tags,
          success,
          failure
        )
      }
      setController(newController)
    }
  }

  function videoFinishedProcessingSuccesfully() {
    if (
      lyricsColumnRef.current !== null &&
      lyricsColumnRef.current !== undefined
    ) {
      lyricsColumnRef.current.style.transform =
        'translate(' + `${-lyricsColumnRef.current.clientWidth * 3}` + 'px)'
    }
    if (
      videoColumnRef.current !== null &&
      videoColumnRef.current !== undefined &&
      lyricsColumnRef.current !== null &&
      lyricsColumnRef.current !== undefined
    ) {
      videoColumnRef.current.style.transform =
        'translate(' +
        `${-lyricsColumnRef.current.clientWidth - 20}` +
        'px, 0px)'
    }
    setShowVideoEditingElements(false)
    setIsGeneratingFeel(false)

    setTimeout(() => {
      setShowLyrics(false)
      videoColumnRef.current.style.transition = 'none'
      videoColumnRef.current.style.transform = 'translate(0px)'
      videoElement.current.play()
      setGridDimensions('1fr 1.2fr')
      setShowTags(true)
    }, 2200)
    setTimeout(() => {
      videoColumnRef.current.style.transition = '2s ease'
      tagsColumnRef.current.style.top = '0px'
      tagsColumnRef.current.style.height = '100%'
    }, 2300)
  }

  function getFormattedDuration() {
    const selectedVideoDurationInSeconds = selectedVideoDuration / 1000
    let seconds = 0
    if (selectedVideoDurationInSeconds < 10) {
      seconds = parseFloat(selectedVideoDurationInSeconds).toPrecision(2)
    } else {
      seconds = parseFloat(selectedVideoDurationInSeconds).toPrecision(3)
    }
    return String(seconds) + 's'
  }

  function handleLyricSelected(line) {
    const indexOfSelectedLine = lyrics.indexOf(line)
    const newLyrics = [...lyrics]
    newLyrics[indexOfSelectedLine].isSelected =
      !newLyrics[indexOfSelectedLine].isSelected
    setLyrics(newLyrics)
    const selectedLyrics = getSelectedLyrics()
    if (selectedLyrics !== undefined && selectedLyrics.length > 0) {
      const durationOfSelectedLyrics = calculateDurationOfSelectedLine()
      setSelectedVideoDuration(durationOfSelectedLyrics)
      const firstSelectedLyric = selectedLyrics.reduce((prev, curr) =>
        parseInt(prev.milliseconds) < parseInt(curr.milliseconds) ? prev : curr
      )
      videoElement.current.currentTime = firstSelectedLyric.milliseconds
    } else {
      videoElement.current.currentTime = 0
      videoElement.current.play()
      setSelectedVideoDuration(0)
    }
  }

  function calculateDurationOfSelectedLine() {
    const selectedLyrics = getSelectedLyrics()
    if (selectedLyrics.length > 0) {
      const firstSelectedLyric = selectedLyrics.reduce((prev, curr) =>
        parseInt(prev.milliseconds) < parseInt(curr.milliseconds) ? prev : curr
      )
      const lastSelectedLyric = selectedLyrics.reduce((prev, curr) =>
        parseInt(prev.milliseconds) > parseInt(curr.milliseconds) ? prev : curr
      )
      return (
        parseInt(lastSelectedLyric.milliseconds) +
        parseInt(lastSelectedLyric.duration) -
        parseInt(firstSelectedLyric.milliseconds)
      )
    } else {
      return 0
    }
  }

  function getSelectedLyrics() {
    if (lyrics !== undefined && lyrics !== null) {
      return lyrics.filter(item => item.isSelected === true)
    } else {
      return []
    }
  }

  function handleClearSelected() {
    if (lyrics !== undefined && lyrics !== null) {
      const newSelectedLyrics = [...lyrics]
      for (let i = 0; i < newSelectedLyrics.length; i++) {
        newSelectedLyrics[i].isSelected = false
      }
      setLyrics(newSelectedLyrics)
      setSelectedVideoDuration(0)
      if (videoElement.current !== undefined) {
        videoElement.current.currentTime = 0
        videoElement.current.play()
      }
    }
  }

  function handleOnTimeUpdate() {
    if (videoElement.current !== null && videoElement.current !== undefined) {
      const selectedLyrics = getSelectedLyrics()
      if (selectedLyrics.length > 0) {
        const lastSelectedLyric = selectedLyrics.reduce((prev, curr) =>
          parseInt(prev.milliseconds) > parseInt(curr.milliseconds)
            ? prev
            : curr
        )
        if (
          videoElement.current.currentTime >=
          (parseInt(lastSelectedLyric.milliseconds) +
            parseInt(lastSelectedLyric.duration)) /
            1000
        ) {
          const firstSelectedLyric = selectedLyrics.reduce((prev, curr) =>
            parseInt(prev.milliseconds) < parseInt(curr.milliseconds)
              ? prev
              : curr
          )
          videoElement.current.currentTime =
            firstSelectedLyric.milliseconds / 1000
        }
      } else if (
        videoElement.current.currentTime >= videoElement.current.duration
      ) {
        videoElement.current.currentTime = 0
        videoElement.current.play()
      }
    }
  }

  function goBackToLyrics() {
    setVideoSource(initialFullVideoSource)
    setShowLyrics(true)
    setShowVideoEditingElements(true)
    setShowTags(false)
    setGridDimensions('0.8fr 1.2fr')
    setTags([])
    setFeelPreview(null)
    setErrorGeneratingMessage(null)
    setErrorMessage('')
    handleClearSelected()
  }

  const onSuggestedTagClick = tag => setTags([...tags, tag])


useEffect(() => {
  const videoEl = videoElement.current

  const handleLoadedMetadata = () => {
    if (videoEl?.duration) {
      setVideoDuration(Math.floor(videoEl.duration) * 1000 - 1)
    }
  }

  if (videoEl) {
    videoEl.load()
    videoEl.addEventListener('loadedmetadata', handleLoadedMetadata)
  }

  return () => {
    if (videoEl) {
      videoEl.removeEventListener('loadedmetadata', handleLoadedMetadata)
    }
  }
}, [videoElement.current])


  return (
    <Wrapper>
      <Header>
        <Navigation breadcrumbs={breadcrumbs} />
        <Heading
          title={'Create Music Message'}
          subtitle={
            showTags
              ? 'Add tags to your music message'
              : 'Select the lyrics that you wish to include in your Music Message'
          }
        />
        {song && (
          <HeaderInfo>
            <ArtistAndSongName song={song} />
            {isGeneratingFeel ? (
              <GeneratingLoader
                isGenerating={isGeneratingFeel}
                generatingMessage={generatingMessage}
              />
            ) : (
              <div className={classes.actions}>
                <Actions>
                  {showTags && (
                    <Button
                      onClick={() => {
                        goBackToLyrics()
                      }}
                      label={'BACK'}
                    />
                  )}
                  {showVideoEditingElements && (
                    <Button
                      disabled={
                        !selectedVideoDuration || isFeelsFactoryReadOnly
                      }
                      onClick={() => {
                        if (selectedVideoDuration > 0) {
                          handleClearSelected()
                        }
                      }}
                      label={'CLEAR'}
                    />
                  )}
                  <Button
                    disabled={
                      isFeelsFactoryReadOnly ||
                      (showTags && !tags.length) ||
                      !lyrics.filter(item => {
                        return item.isSelected
                      }).length
                    }
                    onClick={() => {
                      if (showTags) {
                        if (tags.length > 0) {
                          handleOnCreateFeel()
                        }
                      } else {
                        if (selectedVideoDuration > 0) {
                          convertVideo()
                        }
                      }
                    }}
                    label={showTags ? 'CREATE' : 'NEXT'}
                  />
                </Actions>
                {errorGeneratingMessage && (
                  <ErrorGeneratingMessage message={errorGeneratingMessage} />
                )}
              </div>
            )}
          </HeaderInfo>
        )}
      </Header>

      {!isLoading && song ? (
        <main
          className={classes.main}
          style={{
            display: 'grid',
            gap: '1.25rem',
            gridTemplateColumns: gridDimensions,
          }}
        >
          {showLyrics && (
            <div
              className={classes['lyrics-column']}
              ref={lyricsColumnRef}
              style={{ order: `${lyricsColumnOrder}` }}
            >
              <div className={classes['lyrics']}>
                <Lyrics
                  onLyricSelected={handleLyricSelected}
                  lyrics={lyrics.filter(item => {
                    return item.line !== ''
                  })}
                  videoDuration={videoDuration}
                />
              </div>
            </div>
          )}
          <div
            className={classes['video-column']}
            ref={videoColumnRef}
            style={{ order: `${videoColumnOrder}` }}
          >
            <div className={classes['video-wrapper']}>
              {showVideoEditingElements && (
                <span className={classes.duration}>
                  {getFormattedDuration()}
                </span>
              )}
              <video
                ref={videoElement}
                className={classes['player']}
                controls
                autoPlay={true}
                muted={true}
                src={videoSource}
                width={352}
                height={528}
                controlsList="nofullscreen"
              />
            </div>
            {showVideoEditingElements && (
              <span className={classes['duration-text']}>
                {'Currently selected duration: ' +
                  getFormattedDuration() +
                  '. Max duration: 15s'}
              </span>
            )}
          </div>
          {showTags && (
            <div
              className={classes['tags-column']}
              ref={tagsColumnRef}
              style={{ order: `${tagsColumnOrder}` }}
            >
              <TagInput
                disabled={isFeelsFactoryReadOnly}
                currentlyEnteredTag={currentlyEnteredTag}
                onTagInput={e => {
                  handleTagInput(e)
                }}
                onEnterPressed={e => {
                  handleOnEnterPressed(e)
                }}
              />
              <div className={classes.tags}>
                <TagsList
                  disabled={isFeelsFactoryReadOnly}
                  tags={tags}
                  title="Official Tags"
                  editable={true}
                  handleDeleteTag={handleDeleteTag}
                  isOfficial={true}
                />
                <TagsList
                  disabled={isFeelsFactoryReadOnly}
                  tags={suggestedTags}
                  title="Suggested Tags"
                  isSuggested={true}
                  isFetching={isLoadingGenerateSuggestedTags}
                  handleSuggestedTagClick={onSuggestedTagClick}
                />
              </div>
            </div>
          )}
        </main>
      ) : (
        <>
          {!isLoading && (
            <h1 style={{ color: 'white' }}>
              {errorMessage ? errorMessage : ''}
            </h1>
          )}
          {isLoading && <Loader isLoading={isLoading} />}
        </>
      )}
    </Wrapper>
  )
}
