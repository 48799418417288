import React from 'react'
import PropTypes from 'prop-types'
import './SearchInput.css'

export default function SearchInput({ onSearchInput, placeholder, fullWidth }) {
  const inputClassName = `search-box-input ${fullWidth ? 'full-width' : ''}`

  return (
    <input
      className={inputClassName}
      placeholder={placeholder}
      onInput={e => {
        onSearchInput(e)
      }}
    />
  )
}

SearchInput.propTypes = {
  onSearchInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
}
