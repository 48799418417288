import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import TextField from '../../../../../Components/GeneralComponents/TextField'

export default function Step({ setData, data }) {
  const onTextChange = e =>
    setData(prev => ({ ...prev, [e.target.name]: e.target.value }))

  return (
    <div className={classes.wrapper}>
      <TextField
        label={'Organization Name'}
        name={'name'}
        onChange={onTextChange}
        isRequired
        variant={'rounded'}
        placeholder={'Enter organization name'}
        defaultValue={data.name}
      />
    </div>
  )
}

Step.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.any,
}
