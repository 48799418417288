import React from 'react'
import TextField from '../../TextField'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { CustomSelect } from '../../CustomSelect'
import { URL_TEXT_OPTIONS } from '../../helpers/constants'

export default function TextFields({
  onChange,
  tease,
  handleOptionSelect,
  handleSave,
  disabled = false,
}) {
    

  return (
    <div className={classes.wrapper}>
      <div className={classes.input}>
        <label htmlFor="url" className={classes.input__label}>
          URL
        </label>
        <TextField
          name={'url'}
          placeholder={'pre-save/promo.com'}
          onChange={onChange}
          value={tease.promoting?.url || ''}
          onBlur={handleSave}
          disabled={disabled}
        />
      </div>

      <div className={classes.input}>
        <label htmlFor="url_display_text" className={classes.input__label}>
          URL Display Text
        </label>

        <CustomSelect
          options={URL_TEXT_OPTIONS}
          onOptionSelect={handleOptionSelect}
          defaultOption={tease.promoting?.url_display_text}
          disabled={!tease.promoting?.url || disabled}
        />
      </div>
    </div>
  )
}

TextFields.propTypes = {
  onChange: PropTypes.func,
  handleSave: PropTypes.func,
  handleOptionSelect: PropTypes.func,
  tease: PropTypes.object,
  disabled: PropTypes.bool,
}
