import React, { useEffect } from 'react'
import classes from './index.module.css'
import Loader from '../../../Components/GeneralComponents/Loader'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import PageTitle from '../components/PageTitle'
import { useParams } from 'react-router-dom'
import { useGetOrgDetails } from '../hooks/useGetOrgDetails'
import { Navigation } from '../../../Components/page/Navigation'
import { renderBreadcrumbs } from './breadcrumbs'
import { useGetOrgContent } from '../hooks/useGetOrgContent'
import OrganizationSortedArtistsList from '../components/OrganizationSortedArtistsList'
import CreateOrganizationPopupArtistsCount from '../components/CreateOrganizationPopupArtistsCount'
import { useInView } from 'react-intersection-observer'
import { Actions } from '../../../Components/page/Actions'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import usePopup from '../hooks/usePopup'
import OrganizationsPopup from '../components/OrganizationsPopup'
import ManageOrganizationArtistsPopupForm from '../components/ManageOrganizationArtistsPopupForm'
import { useAuth } from '../../../Contexts/AuthContext'
import useAccessLevel from '../hooks/useAccessLevel'

export default function OrganizationArtists() {
  const { id } = useParams()
  const [ref, inView] = useInView()
  const { isInternalAdmin } = useAccessLevel()

  const {
    openPopup: manageArtistsOpenPopup,
    closePopup: manageArtistsClosePopup,
    isOpen: manageArtistsIsOpen,
  } = usePopup()

  const {
    data: orgDetails,
    isError: orgDetailsError,
    isLoading: orgDetailsIsLoading,
    isFetching: orgDetailsFetching,
  } = useGetOrgDetails({
    id,
  })

  const {
    data: orgContent,
    isError: orgContentError,
    isLoading: orgContentIsLoading,
    isFetching: orgContentIsFetching,
    fetchNextPage: orgContentFetchNextPage,
    hasNextPage: orgContentHasNextPage,
  } = useGetOrgContent({
    o_f_id: id,
    content_object: 'artist',
    n: 15,
  })

  const artists = orgContent?.pages.reduce((acc, page) => {
    return [...acc, ...page.artist]
  }, [])
  const allArtists = orgDetails?.artists_count

  useEffect(() => {
    if (inView && orgContentHasNextPage) {
      orgContentFetchNextPage()
    }
  }, [inView, orgContentHasNextPage])

  if (orgDetailsIsLoading || orgContentIsLoading) {
    return <Loader isLoading={orgDetailsIsLoading || orgContentIsLoading} />
  }

  if (orgDetailsError || orgContentError) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
            <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={classes.wrapper}>
      <OrganizationsPopup
        isOpen={manageArtistsIsOpen}
        closePopup={manageArtistsClosePopup}
      >
        <ManageOrganizationArtistsPopupForm
          closePopup={manageArtistsClosePopup}
          organization={orgDetails}
          artists={artists}
        />
      </OrganizationsPopup>
      <Header>
        {orgDetails ? (
          <Navigation breadcrumbs={renderBreadcrumbs(orgDetails)} />
        ) : null}
        <HeaderInfo>
          <PageTitle title={orgDetails?.name} />
          <Actions>
            <ButtonOutline
              disable={!isInternalAdmin}
              color={'pink'}
              size="medium"
              text={'MANAGE ARTISTS'}
              onClick={manageArtistsOpenPopup}
            />
          </Actions>
        </HeaderInfo>
        <div className={classes.section}>
          <div className={classes.section__header}>
            <h2 className={classes.section__title}>Associated Artists</h2>
          </div>
          <CreateOrganizationPopupArtistsCount count={allArtists} />
        </div>
      </Header>
      <main className={classes.main}>
        {artists?.length ? (
          <OrganizationSortedArtistsList artists={artists} />
        ) : null}
        <div ref={ref}></div>
      </main>
    </Wrapper>
  )
}
