import React from 'react'
import PropTypes from 'prop-types'
import UsersTableRow from '../UsersTableRow'
// import InviteButton from '../InviteButton'
import classes from './index.module.css'

export default function UsersTable({
  isCurrentUserAnAdmin,
  currentUserRole,
  users,
  getUserRole,
  onChangeRole,
  optionsOrg,
  optionsGroup,
  onTeamUserDelete,
  onOrgUserDelete,
}) {
  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.row}>
          <th>
            <p className={classes.text}>Name</p>
          </th>
          <th>
            <p className={classes.text}>Email</p>
          </th>
          <th>
            <p className={classes.text}>Last Active</p>
          </th>
          <th>
            <p className={classes.text}>Team</p>
          </th>
          <th>
            <p className={classes.text}>Access Level</p>
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index, self) => {
          return (
            <UsersTableRow
              key={user.f_id}
              user={user}
              currentRole={getUserRole(user)}
              currentUserRole={currentUserRole}
              isCurrentUserAdmin={isCurrentUserAnAdmin}
              onChangeRole={onChangeRole}
              zIndex={self.length - index}
              optionsOrg={optionsOrg}
              optionsGroup={optionsGroup}
              onTeamUserDelete={onTeamUserDelete}
              onOrgUserDelete={onOrgUserDelete}
            />
          )
        })}
      </tbody>
    </table>
  )
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  isCurrentUserAnAdmin: PropTypes.bool.isRequired,
  getUserRole: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  optionsOrg: PropTypes.array.isRequired,
  optionsGroup: PropTypes.array.isRequired,
  onTeamUserDelete: PropTypes.func.isRequired,
  onOrgUserDelete: PropTypes.func.isRequired,
}
