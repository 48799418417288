import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Chevron } from '../../../Components/icons/chevron.svg'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const CustomSelect = ({
  options,
  placeholder,
  defaultOption,
  onOptionSelect,
  label,
  helperText,
  isRequired = false,
  disabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption || null)
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  // Toggle the dropdown when clicking the select element
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  // Handle option click and notify parent
  const handleOptionClick = option => {
    if (onOptionSelect) {
      onOptionSelect(option)
    }
    setSelectedOption(option)
    setIsOpen(false)
  }

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  // Close the dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={classes.wrapper}>
      {label ? (
        <label className={classes.label}>
          {label}{' '}
          {isRequired ? (
            <span className={classes.label__required}>*</span>
          ) : null}
        </label>
      ) : null}

      <div className={classes['custom-select']} ref={selectRef}>
        <button
          type="button"
          disabled={disabled}
          className={classes['select-box']}
          onClick={toggleDropdown}
        >
          {selectedOption || (
            <span className={classes['placeholder']}>{placeholder || ''}</span>
          )}
          <Chevron
            className={`${classes.chevron} ${
              isOpen ? classes.chevron__active : ''
            }`.trim()}
          />
        </button>

        {isOpen && (
          <ul className={classes['options-list']}>
            {options.map((option, index) => (
              <li
                key={index}
                className={`${classes['option-item']} ${
                  option === selectedOption ? classes['selected'] : ''
                }`.trim()}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
      {helperText ? <span className={classes.text}>{helperText}</span> : null}
    </div>
  )
}

CustomSelect.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  defaultOption: PropTypes.string,
  onOptionSelect: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
}
