import React from 'react'
import PropTypes from 'prop-types'
import ButtonArrow from '../../GeneralComponents/ButtonArrow'
import classes from './index.module.css'

export default function ArtistTopSongsHeader({
  totalSongs,
  onClick,
  title = 'Top Songs',
}) {
  return (
    <div className={classes.topSongsHeaderWrapper}>
      <p className={classes.topSongsHeaderText}>{title}</p>
      <ButtonArrow
        text={'View All' + (totalSongs ? ' (' + totalSongs + ')' : '')}
        onClick={() => onClick()}
      />
    </div>
  )
}

ArtistTopSongsHeader.propTypes = {
  totalSongs: PropTypes.number.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}
