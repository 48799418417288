import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../../../GeneralComponents/Loader'
import { placeholderText, placeholderText2 } from './placeholder'
import TagBubble from '../TagBubble'
import classes from './index.module.css'
import SuggestedTag from './SuggestedTag'
import UsersTag from './UsersTag'
import { ReactComponent as RefreshIcon } from '../../../icons/accessibility-icon.svg'

export default function TagsList({
  tags,
  title,
  editable = false,
  handleDeleteTag = () => false,
  handleSuggestedTagClick = () => false,
  isOfficial = false,
  isUser = false,
  isSuggested = false,
  isFetching = false,
  disabled,
  officialTags,
  retchSuggestedTags,
  song,
}) {
  const isTagDisabled = (tag, array) => !!array && array.includes(tag)
  if (isFetching) {
    return <Loader isLoading={isFetching} />
  }
  if (isOfficial && (!tags || !tags.length)) {
    return (
      <p className={classes.placeholder}>
        {placeholderText}
        <br />
        <br />
        {placeholderText2}
      </p>
    )
  }
  if ((!tags || !tags.length) && !isSuggested) {
    return null
  }

  return (
    <div className={classes.tags}>
      <div className={classes.titleWrapper}>
        <h3 className={classes.tags__text}>{title.toLowerCase()}</h3>
        {isSuggested ? (
          <button
            className={classes.regenerateBth}
            onClick={() =>
              retchSuggestedTags({
                song_f_id: song?.song_f_id,
                lyrics_lines: song.lyrics_lines,
                regenerate: true,
              })
            }
          >
            <RefreshIcon />
          </button>
        ) : null}
      </div>
      {tags && tags.length ? (
        <div className={classes.tags__list}>
          {tags.map((item, index) => {
            if (isSuggested)
              return (
                <SuggestedTag
                  key={index}
                  tag={item}
                  onClick={handleSuggestedTagClick}
                  disabled={disabled || isTagDisabled(item, officialTags)}
                />
              )
            if (isUser)
              return (
                <UsersTag
                  key={index}
                  tag={item}
                  onClick={handleSuggestedTagClick}
                  disabled={disabled || isTagDisabled(item.tag, officialTags)}
                />
              )
            if (isOfficial)
              return (
                <button
                  disabled={disabled}
                  key={index}
                  className={classes.tags__item}
                >
                  <TagBubble
                    submissions={item?.submissions}
                    editable={editable}
                    tag={isOfficial ? item : item.tag}
                    onTagSelected={tag => {
                      handleDeleteTag(tag)
                    }}
                  />
                </button>
              )
          })}
        </div>
      ) : null}
    </div>
  )
}

TagsList.propTypes = {
  handleDeleteTag: PropTypes.func,
  handleSuggestedTagClick: PropTypes.func,
  retchSuggestedTags: PropTypes.func,
  tags: PropTypes.array,
  officialTags: PropTypes.array,
  editable: PropTypes.bool,
  isOfficial: PropTypes.bool,
  isFetching: PropTypes.bool,
  isUser: PropTypes.bool,
  disabled: PropTypes.bool,
  isSuggested: PropTypes.bool,
  title: PropTypes.string,
  song: PropTypes.object,
}
