import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CreateOrganizationPopupFormActions({ children }) {
  return <div className={classes.actions}>{children}</div>
}

CreateOrganizationPopupFormActions.propTypes = {
  children: PropTypes.node,
}
