import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../Contexts/AuthContext'
import './Sidebar.css'
import useSidebar from './hooks/useSidebar'

export default function SideBar() {
  const { getCurrentUser } = useAuth()

  const { sidebarLinks } = useSidebar()

  const listElement = React.createRef()
  const selectedItemCaretHeight = 35
  const expandedSidebarWidth = 205
  const collapsedSidebarWidth = 90
  const toCollapseButtonIcon = './icons/collapse.png'
  const toExpandButtonIcon = './icons/expand.png'

  const [sidebarWidth, setSidebarWidth] = useState(expandedSidebarWidth)
  const [yoffset, setYoffset] = useState()
  const [currentlySelectedItem, setCurrentlySelectedItem] = useState(-1)
  const [currentlySelectedSubitem, setCurrentlySelectedSubitem] = useState(-1)
  const [collapseExpandButtonIcon, setCollapseExpandButtonIcon] =
    useState(toCollapseButtonIcon)
  const [sidebarExpanded, setSidebarExpanded] = useState(true)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    //Set the default sidebar selection caret position!

    setCurrentlySelectedSubitem(-1)
    let index = -1
    const pathComponents = location.pathname.split('/')
    for (let i = 0; i < sidebarLinks.length; i++) {
      if (pathComponents.length > 1) {
        for (let j = 1; j < pathComponents.length; j++) {
          if (
            sidebarLinks[i].selectedIfMatchesRoutes.includes(
              '/' + pathComponents[j]
            )
          ) {
            index = i
            if (sidebarLinks[i].subitems?.length > 0) {
              for (let k = 0; k < sidebarLinks[i].subitems.length; k++) {
                for (let l = 1; l < pathComponents.length; l++) {
                  if (
                    sidebarLinks[i].subitems[
                      k
                    ].selectedIfMatchesRoutes.includes('/' + pathComponents[l])
                  ) {
                    setCurrentlySelectedSubitem(k)
                  }
                }
              }
            }
            break
          }
        }
      } else {
        index = 0
      }
      if (index === i) {
        break
      }
    }
    if (index !== -1) {
      setCurrentlySelectedItem(index)
      updateCaret(listElement.current.children[index])
    } else {
      setCurrentlySelectedItem(null)
      setYoffset(-100)
    }
  }, [location, sidebarLinks])

  function handleSidebarItemClick(e, item, subitem) {
    if (subitem) {
      navigate(subitem.route)
    } else {
      if (item.route === '/faq') {
        window
          .open('https://musicmakesusfeels.zendesk.com/hc/en-us', '_blank')
          .focus()
      } else {
        updateCaret(e.target)
        navigate(item.route)
      }
    }
  }

  function updateCaret(element) {
    if (element !== undefined) {
      const rect = element.getBoundingClientRect()
      const height = rect.bottom - rect.top
      setYoffset(rect.top - (selectedItemCaretHeight - height) / 2)
    }
  }

  function handleCollapse() {
    if (sidebarExpanded) {
      setSidebarExpanded(false)
      setSidebarWidth(collapsedSidebarWidth)
      setCollapseExpandButtonIcon(toExpandButtonIcon)
      const main = document.getElementById('main')
      main.style.transition = '0.5s'
      main.style.marginLeft = collapsedSidebarWidth + 'px'
      main.style.width = 'calc(100% - ' + collapsedSidebarWidth + 'px)'
      const navbar = document.getElementById('navbar')
      navbar.style.transition = '0.5s'
      navbar.style.left = collapsedSidebarWidth + 'px'
    } else {
      setSidebarExpanded(true)
      setSidebarWidth(expandedSidebarWidth)
      setCollapseExpandButtonIcon(toCollapseButtonIcon)
      const main = document.getElementById('main')
      main.style.transition = '0.5s'
      main.style.marginLeft = expandedSidebarWidth + 'px'
      main.style.width = 'calc(100% - ' + expandedSidebarWidth + 'px)'
      const navbar = document.getElementById('navbar')
      navbar.style.transition = '0.5s'
      navbar.style.left = expandedSidebarWidth + 'px'
    }
  }

  return (
    <>
      <div
        id="sidebar"
        style={{
          width: `${sidebarWidth}px`,
          transition: '0.5s',
        }}
      >
        <button id="sidebar-btn-collapse-expand" onClick={handleCollapse}>
          <img
            width="24"
            height="24"
            src={require(`${collapseExpandButtonIcon}`)}
            alt="expand"
          />
        </button>
        <div
          id="sidebar-cursor-selected-item"
          style={{
            position: 'absolute',
            height: `${selectedItemCaretHeight}px`,
            top: `${yoffset}px`,
            transition: 'top 0.5s',
            opacity: sidebarLinks.length ? 1 : 0,
          }}
        ></div>
        <nav>
          <ul ref={listElement}>
            {sidebarLinks.length &&
              sidebarLinks.map(item => (
                <div key={item.index}>
                  <li>
                    <div
                      id="vertical-aligned-li"
                      onClick={e => {
                        handleSidebarItemClick(e, item, null)
                      }}
                    >
                      <img
                        width="24"
                        height="24"
                        src={require(`${
                          item.index === currentlySelectedItem
                            ? item.iconSelected
                            : item.iconUnselected
                        }`)}
                        alt={item.alt}
                      />
                      {sidebarExpanded && (
                        <span
                          id={
                            item.index === currentlySelectedItem
                              ? 'sidebar-text-item-selected'
                              : 'sidebar-text-item-unselected'
                          }
                        >
                          {item.name}
                        </span>
                      )}
                    </div>
                  </li>
                  {getCurrentUser()?.is_admin &&
                    item.subitems?.length > 0 &&
                    item.subitems?.map(subitem => (
                      <li key={subitem.index}>
                        {sidebarExpanded && (
                          <div
                            id="vertical-aligned-li"
                            onClick={e => {
                              handleSidebarItemClick(e, item, subitem)
                            }}
                          >
                            <span></span>
                            <span
                              id={
                                subitem.index === currentlySelectedSubitem
                                  ? 'sidebar-text-subitem-selected'
                                  : 'sidebar-text-subitem-unselected'
                              }
                            >
                              {subitem.name}
                            </span>
                          </div>
                        )}
                      </li>
                    ))}
                </div>
              ))}
          </ul>
        </nav>
        {/* <div id="sidebar-footer">
          <img
            width="100%"
            height="24"
            src={require('./Interscope Records.png')}
            alt="Label logo"
          />
        </div> */}
      </div>
    </>
  )
}
