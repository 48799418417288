import InstagramPost from '../../../Components/DashboardComponents/MobileComponentsRedesign/Instagram/InstagramPost/InstagramPost'
import InstagramStories from '../../../Components/DashboardComponents/MobileComponentsRedesign/Instagram/InstagramStories/InstagramStories'
import YoutubeShorts from '../../../Components/DashboardComponents/MobileComponentsRedesign/Youtube/YoutubeShorts'
import TikTok from '../../../Components/DashboardComponents/MobileComponents/Tiktok/Tiktok'

export const previews = [
  {
    key: 'post',
    Component: InstagramPost,
  },
  {
    key: 'stories',
    Component: InstagramStories,
  },
  {
    key: 'shorts',
    Component: YoutubeShorts,
  },
  {
    key: 'tiktok',
    Component: TikTok,
  },
]
