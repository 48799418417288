import React, { useEffect, useState } from 'react'
import CreateOrganizationPopupContent from '../CreateOrganizationPopupContent'
import CreateOrganizationPopupTitle from '../CreateOrganizationPopupTitle'
import CreateOrganizationPopupFormActions from '../CreateOrganizationPopupFormActions'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import successToast from '../../../../Components/Toasts/SuccessToast'
import PropTypes from 'prop-types'

import { useManageOrganizationContent } from '../../hooks/useManageOrganizationContent'

import { useQueryClient } from '@tanstack/react-query'
import ManageOrganizationArtistsPopupFormStep from '../ManageOrganizationArtistsPopupFormStep'
import { useGetOrgContent } from '../../hooks/useGetOrgContent'
import Loader from '../../../../Components/GeneralComponents/Loader'
import { useParams } from 'react-router-dom'

export default function ManageOrganizationArtistsPopupForm({
  organization,
  closePopup,
}) {
  const [data, setData] = useState([])
  const { id } = useParams()
  const queryClient = useQueryClient()

  const {
    data: orgContent,
    isLoading: orgContentIsLoading,
    isFetching: orgContentIsFetching,
    fetchNextPage: orgContentFetchNextPage,
    hasNextPage: orgContentHasNextPage,
  } = useGetOrgContent({
    o_f_id: id,
    content_object: 'artist',
    n: 15,
  })

  const {
    mutate: addOrganizationArtists,
    isPending: isLoadingOrganizationArtists,
  } = useManageOrganizationContent()

  const handleManageOrganizationArtists = data => {
    addOrganizationArtists(data, {
      onSuccess: async () => {
        successToast('Artists updated successfully')

        await queryClient.invalidateQueries(['organization'])
      },
      onError: error => {
        errorToast('Error updating Artists', error)
      },
    })
  }

  const onSubmit = e => {
    e.preventDefault()
  }

  useEffect(() => {
    if (orgContent) {
      const artists = orgContent?.pages.reduce((acc, page) => {
        return [...acc, ...page.artist]
      }, [])

      setData(artists)
    }
  }, [orgContent])

  useEffect(() => {
    if (orgContentHasNextPage && !orgContentIsFetching) {
      orgContentFetchNextPage()
    }
  }, [orgContentFetchNextPage, orgContentIsFetching, orgContentHasNextPage])

  return (
    <CreateOrganizationPopupContent onSubmit={onSubmit}>
      <CreateOrganizationPopupTitle
        title={`Assign Artists to the ${organization.name} Organization`}
      />
      {orgContentIsLoading ? (
        <Loader isLoading={orgContentIsLoading} />
      ) : (
        <ManageOrganizationArtistsPopupFormStep
          setData={setData}
          data={data}
          handleManageOrganizationArtists={handleManageOrganizationArtists}
          organization={organization}
        />
      )}
      <CreateOrganizationPopupFormActions>
        <ButtonOutline
          color={'pink'}
          size="small"
          text={'DONE'}
          type="button"
          disable={isLoadingOrganizationArtists}
          onClick={closePopup}
        />
      </CreateOrganizationPopupFormActions>
    </CreateOrganizationPopupContent>
  )
}

ManageOrganizationArtistsPopupForm.propTypes = {
  organization: PropTypes.object,
  closePopup: PropTypes.func,
  artists: PropTypes.array,
}
