import React, { useEffect, useState } from 'react'
import CreateOrganizationPopupContent from '../CreateOrganizationPopupContent'
import CreateOrganizationPopupTitle from '../CreateOrganizationPopupTitle'
import CreateOrganizationPopupFormActions from '../CreateOrganizationPopupFormActions'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import successToast from '../../../../Components/Toasts/SuccessToast'
import PropTypes from 'prop-types'
import { useQueryClient } from '@tanstack/react-query'
import ManageGroupArtistsPopupFormStep from '../ManageGroupArtistsPopupFormStep'
import Loader from '../../../../Components/GeneralComponents/Loader'
import { useGetOrgGroupContent } from '../../hooks/useGetOrgGroupContent'
import { useManageOrganizationGroupContent } from '../../hooks/useManageOrganizationGroupContent'
import { useParams } from 'react-router-dom'

export default function ManageGroupArtistsPopupForm({ closePopup, group }) {
  const [data, setData] = useState([])

  const { o_f_id, g_f_id } = useParams()

  const queryClient = useQueryClient()

  const {
    data: groupContent,
    isLoading: groupContentIsLoading,
    isFetching: groupContentIsFetching,
    fetchNextPage: groupContentFetchNextPage,
    hasNextPage: groupContentHasNextPage,
  } = useGetOrgGroupContent({
    o_f_id,
    g_f_id,
    content_object: 'artist',
    n: 15,
  })

  const { mutate: addGroupArtists, isPending: isLoadingGroupArtists } =
    useManageOrganizationGroupContent()

  const handleManageGroupArtists = data => {
    addGroupArtists(data, {
      onSuccess: async () => {
        successToast('Artists updated successfully')
        await queryClient.invalidateQueries(['organization'])
      },
      onError: error => {
        errorToast('Error updating Artists', error)
      },
    })
  }

  const onSubmit = e => {
    e.preventDefault()
  }

  useEffect(() => {
    if (groupContent) {
      const artists = groupContent?.pages.reduce((acc, page) => {
        return [...acc, ...page.artist].filter(artist => artist.f_id)
      }, [])

      setData(artists)
    }
  }, [groupContent])

  useEffect(() => {
    if (groupContentHasNextPage && !groupContentIsFetching) {
      groupContentFetchNextPage()
    }
  }, [
    groupContentFetchNextPage,
    groupContentIsFetching,
    groupContentHasNextPage,
  ])

  return (
    <CreateOrganizationPopupContent onSubmit={onSubmit}>
      <CreateOrganizationPopupTitle
        title={`Assign Artists to the ${group.name} Team`}
      />
      {groupContentIsLoading ? (
        <Loader isLoading={groupContentIsLoading} />
      ) : (
        <ManageGroupArtistsPopupFormStep
          setData={setData}
          data={data}
          handleManageGroupArtists={handleManageGroupArtists}
          group={group}
        />
      )}
      <CreateOrganizationPopupFormActions>
        <ButtonOutline
          color={'pink'}
          size="small"
          text={'DONE'}
          type="button"
          disable={isLoadingGroupArtists}
          onClick={closePopup}
        />
      </CreateOrganizationPopupFormActions>
    </CreateOrganizationPopupContent>
  )
}

ManageGroupArtistsPopupForm.propTypes = {
  group: PropTypes.object,
  closePopup: PropTypes.func,
  artists: PropTypes.array,
}
