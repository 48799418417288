import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CreateOrganizationPopupTitle({ title }) {
  return <h2 className={classes.title}>{title}</h2>
}

CreateOrganizationPopupTitle.propTypes = {
  title: PropTypes.string,
}
