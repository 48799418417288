import React from 'react'
import { toast } from 'react-toastify'

const errorToast = (message, options = {}) => {
  const defaultOptions = {
    draggable: false,
    progress: undefined,
  }
  const mergedOptions = { ...defaultOptions, ...options }

  toast.error(message, mergedOptions)
}

export default errorToast
