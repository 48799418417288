import { useInfiniteQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useGetArtists( n = undefined ) {
  const { getArtists } = useAPI()
  const ARTISTS_PER_PAGE = n || 50

  return useInfiniteQuery({
    queryKey: ['organizations', 'artists', 'all'],
    queryFn: ({ pageParam = 1 }) => getArtists(pageParam, ARTISTS_PER_PAGE),
    initialPageParam: 1,

    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * ARTISTS_PER_PAGE < pageData.artists_count
          ? nextPage
          : undefined
      return newPage
    },
  })
}
