import React, { useState } from 'react'
import PropTypes from 'prop-types'
import copyIcon from '../../icons/copy.png'
import classes from './index.module.css'
import CollectionModal from '../CollectionModal/CollectionModal'

export default function FeelAddItem({
  setIsEdit = () => {},
  isDragging,
  setToNewCollection,
  reservedFeels,
  disable = false,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false)
  return (
    <>
      {isOpenModal && (
        <CollectionModal
          setToNewCollection={setToNewCollection}
          closeModal={() => {
            setIsOpenModal(false)
            setIsEdit(false)
          }}
          reservedFeels={reservedFeels}
        />
      )}
      <div
        style={{
          borderLeft: isDragging ? '1px dotted #d9d9d9' : 'none',
          height: '100%',
          opacity: disable ? '0.2' : '1',
        }}
        className={classes.wrapper}
        onClick={() => {
          !disable && (setIsOpenModal(true), setIsEdit(true))
        }}
      >
        <div className={classes.skeletonBlockOne}>
          <img src={copyIcon} alt="add icon" />
        </div>
        <div className={classes.skeletonBlockTwo}></div>
      </div>
    </>
  )
}
FeelAddItem.propTypes = {
  setIsEdit: PropTypes.func,
  isDragging: PropTypes.bool,
  disable: PropTypes.bool,
  setToNewCollection: PropTypes.func,
  reservedFeels: PropTypes.arrayOf(PropTypes.string),
}
