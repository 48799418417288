import { useRef, useCallback, useEffect, useState } from 'react'

export default function usePopup() {
  const popupRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const openPopup = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closePopup = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    const handleOutsideClick = e => {
      if (popupRef && popupRef?.current) {
        const dialogDimensions = popupRef.current.getBoundingClientRect()
        if (
          e.clientX < dialogDimensions.left ||
          e.clientX > dialogDimensions.right ||
          e.clientY < dialogDimensions.top ||
          e.clientY > dialogDimensions.bottom
        ) {
          closePopup()
        }
      }
    }

  popupRef && popupRef?.current &&  popupRef.current.addEventListener('click', handleOutsideClick)
  }, [popupRef])

  return { popupRef, openPopup, closePopup, isOpen }
}
