import React, { useEffect } from 'react'
import classes from './index.module.css'
import Loader from '../../../Components/GeneralComponents/Loader'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import { Actions } from '../../../Components/page/Actions'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import { Navigation } from '../../../Components/page/Navigation'

import PageTitle from '../components/PageTitle'
import { useParams } from 'react-router-dom'
import { useGetTeamDetails } from '../hooks/useGetTeamDetails'
import { useInView } from 'react-intersection-observer'
import { useFetchAccount } from '../../Organization/hooks'
import OrganizationsPopup from '../components/OrganizationsPopup'
import usePopup from '../hooks/usePopup'
import { useGetOrgGroupContent } from '../hooks/useGetOrgGroupContent'
import { useGetOrgDetails } from '../hooks/useGetOrgDetails'
import ManageGroupArtistsPopupForm from '../components/ManageGroupArtistsPopupForm'

import { renderBreadcrumbs } from './breadcrumbs'
import CreateOrganizationPopupArtistsCount from '../components/CreateOrganizationPopupArtistsCount'
import OrganizationSortedArtistsList from '../components/OrganizationSortedArtistsList'
import useAccessLevel from '../hooks/useAccessLevel'
import { renderBreadcrumbsTeam } from './breadcrumbsTeam'

export default function TeamDetailsArtists() {
  const { o_f_id, g_f_id } = useParams()
  const [ref, inView] = useInView()
  const { isInternalAdmin, hasOrgAdminAccess } = useAccessLevel({
    o_f_id,
    g_f_id,
  })

  const {
    openPopup: manageArtistsOpenPopup,
    closePopup: manageArtistsClosePopup,
    isOpen: manageArtistsIsOpen,
  } = usePopup()

  const {
    data: userData,
    isError: userDataError,
    isLoading: userDataIsLoading,
    isFetching: userDataIsFetching,
  } = useFetchAccount()

  const {
    data: orgDetails,
    isError: orgDetailsError,
    isLoading: orgDetailsIsLoading,
    isFetching: orgDetailsFetching,
  } = useGetOrgDetails({
    id: o_f_id,
  })

  const {
    data: organizationGroup,
    isError: organizationGroupError,
    isLoading: organizationGroupIsLoading,
    isFetching: isOrganizationGroupFetching,
  } = useGetTeamDetails({
    o_f_id,
    g_f_id,
  })

  const {
    data: orgContent,
    isError: orgContentError,
    isLoading: orgContentIsLoading,
    isFetching: orgContentIsFetching,
    fetchNextPage: orgContentFetchNextPage,
    hasNextPage: orgContentHasNextPage,
  } = useGetOrgGroupContent({
    o_f_id,
    g_f_id,
    content_object: 'artist',
    n: 15,
  })

  const artists = orgContent?.pages.reduce((acc, page) => {
    return [...acc, ...page.artist].filter(artist => artist.f_id)
  }, [])
  const allArtists = orgContent?.pages[0]?.artist_count || 0

  useEffect(() => {
    if (inView && orgContentHasNextPage) {
      orgContentFetchNextPage()
    }
  }, [inView, orgContentHasNextPage])

  if (
    userDataIsLoading ||
    orgDetailsIsLoading ||
    organizationGroupIsLoading ||
    orgContentIsLoading
  ) {
    return (
      <Loader
        isLoading={
          userDataIsLoading ||
          orgDetailsIsLoading ||
          organizationGroupIsLoading ||
          orgContentIsLoading
        }
      />
    )
  }

  if (
    userDataError ||
    orgDetailsError ||
    organizationGroupError ||
    orgContentError
  ) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
            <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={classes.wrapper}>
      <OrganizationsPopup
        isOpen={manageArtistsIsOpen}
        closePopup={manageArtistsClosePopup}
      >
        <ManageGroupArtistsPopupForm
          closePopup={manageArtistsClosePopup}
          group={organizationGroup}
          artists={artists}
        />
      </OrganizationsPopup>

      <Header>
        {orgDetails && (isInternalAdmin || hasOrgAdminAccess) ? (
          <Navigation
            breadcrumbs={renderBreadcrumbs({
              orgName: orgDetails.name,
              teamName: organizationGroup.name,
              o_f_id,
              g_f_id,
            })}
          />
        ) : (
          <Navigation
            breadcrumbs={renderBreadcrumbsTeam({
              orgName: orgDetails.name,
              teamName: organizationGroup.name,
              o_f_id,
              g_f_id,
            })}
          />
        )}
        <HeaderInfo>
          <div className={classes.header}>
            <PageTitle title={organizationGroup?.name} />
            {orgDetails ? (
              <p className={classes.subtitle}>
                A team within the {orgDetails?.name} organization.
              </p>
            ) : null}
          </div>
          {isInternalAdmin || hasOrgAdminAccess ? (
            <Actions>
              <ButtonOutline
                color={'pink'}
                size="medium"
                text={'MANAGE ARTISTS'}
                onClick={manageArtistsOpenPopup}
                disable={!isInternalAdmin && !hasOrgAdminAccess}
              />
            </Actions>
          ) : null}
        </HeaderInfo>
        <div className={classes.section}>
          <div className={classes.section__header}>
            <h2 className={classes.section__title}>Associated Artists</h2>
          </div>
          <CreateOrganizationPopupArtistsCount count={allArtists} />
        </div>
      </Header>
      <main className={classes.main}>
        {artists?.length ? (
          <OrganizationSortedArtistsList artists={artists} />
        ) : null}
        <div ref={ref}></div>
      </main>
    </Wrapper>
  )
}
