import React, { useEffect, useState } from 'react'
import CreateOrganizationPopupContent from '../CreateOrganizationPopupContent'
import CreateOrganizationPopupTitle from '../CreateOrganizationPopupTitle'
import CreateOrganizationPopupFormActions from '../CreateOrganizationPopupFormActions'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import successToast from '../../../../Components/Toasts/SuccessToast'
import { isValidEmail } from '../../../../Helpers/isValidEmail'
import { useCreateOrganizationUser } from '../../hooks/useCreateOrganizationUser'
import { useChangeOrganizationUsersRole } from '../../hooks/useChangeOrganizationUsersRole'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import CreateTeamPopupFormSteps from '../CreateTeamPopupFormSteps'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { useCreateOrganizationGroup } from '../../hooks/useCreateOrganizationGroup'
import { useManageOrganizationGroupContent } from '../../hooks/useManageOrganizationGroupContent'
import { useGetOrgUsers } from '../../hooks/useGetOrgUsers'

export default function CreateTeamPopupForm({ organization }) {
  const [step, setStep] = useState(1)
  const [orgGroupId, setOrgGroupId] = useState('')

  const [step1Data, setStep1Data] = useState({
    name: '',
    owners: [],
  })
  const [step2Data, setStep2Data] = useState([])

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {
    data: orgUsers,
    isError: orgUsersError,
    isLoading: orgUsersIsLoading,
    isFetching: orgUsersIsFetching,
  } = useGetOrgUsers({
    o_f_id: organization.f_id,
  })

  const {
    mutate: createOrganizationGroup,
    isPending: isLoadingCreateOrganization,
  } = useCreateOrganizationGroup()

  const {
    mutateAsync: changeOrganizationUsersRole,
    isPending: isLoadingChangeOrganizationUsersRole,
  } = useChangeOrganizationUsersRole()

  const {
    mutateAsync: createOrganizationUser,
    isPending: isLoadingCreateOrganizationUser,
  } = useCreateOrganizationUser()

  const {
    mutateAsync: addOrganizationArtists,
    isPending: isLoadingOrganizationArtists,
  } = useManageOrganizationGroupContent()

  const handleCreateOrganizationGroup = groupData => {
    createOrganizationGroup(groupData, {
      onSuccess: data => {
        setOrgGroupId(data.f_id)
      },
      onError: error => {
        errorToast('Error creating Group', error)
      },
    })
  }

  const addContent = arr => {
    try {
      return Promise.allSettled(arr)
    } catch (error) {
      console.error('Error adding content:', error)
    }
  }

  const createUsersOneByOne = async ({ users, o_f_id }) => {
    try {
      return await Promise.allSettled(
        users.map(user =>
          createOrganizationUser({
            o_f_id,
            email: user.email,
          }).then(data => {
            return changeOrganizationUsersRole({
              o_f_id: organization.f_id,
              g_f_id: orgGroupId,
              role:
                user.role === 'Team Administrator'
                  ? 'Group Administrator'
                  : user.role,
              _action: 'add',
              organization_users: [data.f_id],
            })
          })
        )
      )
    } catch (error) {
      console.error('Error creating users:', error)
    }
  }

  const createExistingUsersOneByOne = async ({ users }) => {
    try {
      return await Promise.allSettled(
        users.map(user =>
          changeOrganizationUsersRole({
            o_f_id: organization.f_id,
            g_f_id: orgGroupId,
            role:
              user.role === 'Team Administrator'
                ? 'Group Administrator'
                : user.role,
            _action: 'add',
            organization_users: [user.f_id],
          })
        )
      )
    } catch (error) {
      console.error('Error creating users:', error)
    }
  }

  const handleAddOrganizationArtists = async data => {
    try {
      return await addOrganizationArtists(data)
    } catch (error) {
      console.error('Error adding artists:', error)
    }
  }

  const onSubmitStep1 = e => {
    e.preventDefault()
    const { name, owners } = step1Data

    const newOwners = [
      ...new Map(owners.map(item => [item.email, item]))
        .values()
        .filter(item => item.email),
    ]
    const isValidNewOwnersEmail = newOwners.every(item =>
      isValidEmail(item.email)
    )
    const isValidNewOwnersRoles = newOwners.every(item => item.role)

    if (!name) {
      errorToast('Team Name is required')
      return
    }
    if (!newOwners.length) {
      errorToast('At least one Team member is required')
      return
    }

    if (!isValidNewOwnersEmail) {
      errorToast('Team member email must be valid email')
      return
    }
    if (!isValidNewOwnersRoles) {
      errorToast('Team member role is required')
      return
    }

    setStep1Data({ name, owners: newOwners })
    setStep(2)
  }
  const onSubmitStep2 = e => {
    e.preventDefault()
    handleCreateOrganizationGroup({
      name: step1Data.name,
      o_f_id: organization.f_id,
    })
  }

  const onSubmit = [onSubmitStep1, onSubmitStep2].find(
    (_, index) => index + 1 === step
  )

  const setStepData = [setStep1Data, setStep2Data].find(
    (_, index) => index + 1 === step
  )

  const stepData = [step1Data, step2Data].find((_, index) => index + 1 === step)

  const renderPopupTitle = step => {
    if (step === 1) return 'Create Team'
    if (step === 2) return `Assign Artists to the ${step1Data?.name} Team`
  }

  useEffect(() => {
    if (orgGroupId) {
      const content_object_f_ids = step2Data.map(({ f_id }) => f_id)
      const users = step1Data.owners.reduce(
        (acc, owner) => {
          const user = orgUsers.organization_users.find(
            user => user.email === owner.email
          )

          if (!user) {
            acc.new.push(owner)
          } else {
            const existingGroup = user.organization_groups.find(
              g => g.f_id === orgGroupId
            )
            !existingGroup &&
              acc.existing.push({ f_id: user.f_id, role: owner.role })
          }

          return acc
        },
        {
          new: [],
          existing: [],
        }
      )

      const arr = [
        users.new.length
          ? createUsersOneByOne({
              users: users.new,
              o_f_id: organization.f_id,
            })
          : null,
        users.existing.length
          ? createExistingUsersOneByOne({
              users: users.existing,
              o_f_id: organization.f_id,
            })
          : null,
        content_object_f_ids.length
          ? handleAddOrganizationArtists({
              o_f_id: organization.f_id,
              g_f_id: orgGroupId,
              content_object: 'artist',
              _action: 'add',
              is_cascade: true,
              content_object_f_ids,
            })
          : null,
      ]
      addContent(arr).then(() => {
        queryClient.invalidateQueries(['organization'])
        successToast('Team created successfully')
        navigate(`/organizations/${organization.f_id}/teams/${orgGroupId}`)
      })
    }
  }, [orgGroupId])

  return (
    <CreateOrganizationPopupContent onSubmit={onSubmit}>
      <div className={classes.header}>
        <CreateOrganizationPopupTitle title={renderPopupTitle(step)} />
        {step === 1 ? (
          <p className={classes.subtitle}>
            The team will be created within the {organization?.name}{' '}
            organization.
          </p>
        ) : null}
      </div>
      <CreateTeamPopupFormSteps
        step={step}
        setStepData={setStepData}
        stepData={stepData}
        organization={organization}
      />
      <CreateOrganizationPopupFormActions>
        <ButtonOutline
          color={'pink'}
          size="small"
          text={step === 2 ? 'CREATE' : 'NEXT'}
          type="submit"
          disable={
            isLoadingCreateOrganization ||
            isLoadingChangeOrganizationUsersRole ||
            isLoadingCreateOrganizationUser ||
            isLoadingOrganizationArtists ||
            orgUsersIsLoading
          }
        />
      </CreateOrganizationPopupFormActions>
    </CreateOrganizationPopupContent>
  )
}

CreateTeamPopupForm.propTypes = {
  organization: PropTypes.object,
}
