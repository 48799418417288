import { formatStringInNav } from '../../../Helpers/String+Extension'

export const renderBreadcrumbs = data =>
  !data
    ? [
        {
          title: 'All Artists',
          link: `/artists`,
        },
      ]
    : [
        {
          title: 'All Artists',
          link: `/artists`,
        },

        {
          title: formatStringInNav(data?.artist.name),
          link: `/artist/${data?.artist.artist_name}`,
        },
        {
          title: 'Teases',
          link: '/teases/artist/' + data?.artist.artist_name,
        },
        {
          title: formatStringInNav(data?.name),
        },
      ]
