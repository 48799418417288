import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL
  const cookies = new Cookies()
  const queryClient = useQueryClient()
  const [isLoggedOut, setIsLoggedOut] = useState(false) // Track logout state
  const [error, setError] = useState(null)

  // Function to prepare headers for fetch requests
  const prepareHeaders = () => {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    headers.append('X-MMUF-API-Version', '1.0.0')
    headers.append('X-MMUF-Client', 'MMUF/1.0.0')
    return headers
  }

  // Fetch current user using useQuery
  const {
    data: currentAuthUser,
    isError,
    isLoading: isCurrenAuthUserLoading,
  } = useQuery({
    queryKey: ['currentAuthUser'],
    queryFn: () =>
      fetch(`${baseURL}/account`, {
        method: 'GET',
        headers: prepareHeaders(),
        credentials: 'include',
      }).then(res => {
        if (res.ok) return res.json()
        if (res.status === 401) {
          userLoggedOut()
        } else if (res.status === 403) {
          setError({ message: "This user doesn't have access to the website" })
          throw new Error("This user doesn't have access to the website")
        } else {
          setError({ message: 'Failed to fetch data with code: ' + res.status })
          throw new Error('Failed to fetch data with code: ' + res.status)
        }
      }),
    enabled: !isLoggedOut, // Prevent query if logged out
    retry: false, // Disable automatic retries on failure
  })

  // Handle side effects when currentAuthUser or isError changes
  useEffect(() => {
    if (currentAuthUser) {
      cookies.set('user', currentAuthUser, { path: '/' })
      if (pathname === '/login' || pathname === '/login/admin') {
        navigate('/', { replace: true })
      }
    }

    if (isError) {
      cookies.remove('user', { path: '/' })
      setIsLoggedOut(true) // Set logout state to true
      navigate('/login')
    }
  }, [currentAuthUser, isError, pathname, navigate])

  // useMutation for login
  const loginMutation = useMutation({
    mutationFn: googleJWT =>
      fetch(`${baseURL}/login`, {
        method: 'POST',
        headers: prepareHeaders(),
        body: JSON.stringify(googleJWT),
        credentials: 'include',
      }).then(res => {
        if (res.ok) return res.json()
        if (res.status === 401) {
          userLoggedOut()
        } else if (res.status === 403) {
          throw new Error("This user doesn't have access to the website")
        } else {
          throw new Error('Failed to fetch data with code: ' + res.status)
        }
      }),
    onSuccess: data => {
      queryClient.setQueryData(['currentAuthUser'], data)
      setIsLoggedOut(false) // Reset logout state on successful login
    },
    onError: error => {
      setError({ message: error.message })
    },
  })

  // useMutation for logout
  const logoutMutation = useMutation({
    mutationFn: () =>
      fetch(`${baseURL}/logout`, {
        method: 'POST',
        headers: prepareHeaders(),
        body: JSON.stringify({ logout: 'logout' }),
        credentials: 'include',
      }).then(res => {
        if (!res.ok) throw new Error('Logout filed with code: ' + res.status)
      }),
    onSuccess: () => {
      userLoggedOut()
    },
    onError: error => {
      setError(error.message)
    },
  })

  function userLoggedOut() {
    cookies.remove('user', { path: '/' })
    queryClient.clear()
    setIsLoggedOut(true) // Set logout state to true
    navigate('/login')
  }

  const handleGoogle = async response => {
    let googleJWT = { accessToken: response.credential }
    return googleJWT
  }

  const value = {
    getCurrentUser: () => cookies.get('user'),
    login: googleJWT => loginMutation.mutate(googleJWT),
    logout: () => logoutMutation.mutate(),
    handleGoogle,
    currentAuthUser,
    error,
    setError,
    isCurrenAuthUserLoading,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
