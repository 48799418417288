import { create } from 'zustand'

const stickerPackStore = {}

export const createStoreForStickers = (stickerFId, name) => {
  if (!stickerPackStore[stickerFId]) {
    stickerPackStore[stickerFId] = create(set => ({
      packName: name,
      packInitialName: null,

      packDescription: null,
      packInitialDescription: null,

      isEditDetails: {
        name: false,
        description: false,
      },
      isEditStickers: false,

      stickersGrid: null,
      stickersGridInitial: null,

      isFullStickers: false,

      setPackName: newTitle => set({ packName: newTitle }),
      setPackInitialName: newValue => set({ packInitialName: newValue }),

      setPackDescription: newDescription => set({ packDescription: newDescription }),
      setPackInitialDescription: newValue =>
        set({ packInitialDescription: newValue }),

      setIsEditName: newValue => set(state => ({ isEditDetails: { ...state.isEditDetails, name: newValue } })),
      setIsEditDescription: newValue => set(state => ({ isEditDetails: { ...state.isEditDetails, description: newValue } })),
      setIsEditStickers: newValue =>
        set({ isEditStickers: newValue }),

      setStickersGrid: newValue =>
        set({ stickersGrid: newValue }),
      setStickersGridInitial: newValue =>
        set({ stickersGridInitial: newValue }),

      setStickersUploadUrls: (stickersUrls) =>
        set((state) => {
          const updatedStickersGrid = { ...state.stickersGrid };
          for (const key in stickersUrls) {
            if (stickersUrls.hasOwnProperty(key) && updatedStickersGrid.hasOwnProperty(key)) {
              updatedStickersGrid[key].uploadUrl = stickersUrls[key].upload_url[0];
            }
          }
          return { stickersGrid: updatedStickersGrid };
        }),

      setIsFullStickers: newState => set({ isFullStickers: newState }),
    }))
  }

  return stickerPackStore[stickerFId]
}