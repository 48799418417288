import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const StickyHeader = ({ children, className = '', ...rest }) => {
  return (
    <div className={`${classes.header} ${className}`.trim()} {...rest}>
      {children}
    </div>
  )
}

StickyHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
