import React from 'react'
import PropTypes from 'prop-types'
import ButtonArrow from '../../GeneralComponents/ButtonArrow'
import classes from './index.module.css'
import PublicationBadge from '../../GeneralComponents/PublicationBadge'

export default function ArtistTopTeaseGridItem({ song, onClick }) {
  const date = new Date(song.date_published)

  const formattedDate = song.date_published
    ? date.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      })
    : null
  return (
    <div className={classes.wrapper} onClick={() => onClick(song)}>
      {!song.is_published ? <div className={classes.layout}></div> : null}
      <div
        className={`${classes.itemWrapper} ${
          !song.is_published ? classes.itemWrapper__unpublished : ''
        }`.trim()}
      >
        <div className={classes.imageWrapper}>
          <img
            src={song?.image?.url}
            alt="sound image"
            className={classes.image}
          />
        </div>
        <div className={classes.textWrapper}>
          <p className={classes.itemTitle}>{song?.name}</p>
          {formattedDate ? (
            <p className={classes.itemSubtitle}>Launched: {formattedDate}</p>
          ) : null}
          <p className={classes.itemMessageCount}>{`${
            song?.feels_count
          } Music Message${song?.feels_count == 1 ? '' : 's'}`}</p>
          {song?.is_promotional ? (
            <div className={classes.badge}>
              <PublicationBadge
                size="sm"
                status={'published'}
                text={song.is_published ? 'Published' : 'Unpublished'}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

ArtistTopTeaseGridItem.propTypes = {
  song: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}
