import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { Link } from 'react-router-dom'

export const Navigation = ({ breadcrumbs }) => (
  <nav className={classes.navigation}>
    {breadcrumbs.map(({ link, title }, index) => {
      if (!link) {
        return (
          <Link
            key={index}
            to={'#'}
            className={`${classes.navigation__link} ${classes.navigation__link_empty}`}
          >
            {title}
          </Link>
        )
      }
      return (
        <React.Fragment key={index}>
          <Link to={link} className={classes.navigation__link}>
            {title}
          </Link>
          <div className={classes.navigation__icon}>{'>'}</div>
        </React.Fragment>
      )
    })}
  </nav>
)

Navigation.propTypes = {
  breadcrumbs: PropTypes.array,
}
