import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import Step from './Step'


export default function ManageOrganizationArtistsPopupFormStep({
  setData,
  data,
  handleManageOrganizationArtists,
  organization ,
}) {
  return (
    <div className={classes.wrapper}>
      <Step
        setData={setData}
        data={data}
        handleManageOrganizationArtists={handleManageOrganizationArtists}
        organization={organization}
      />
    </div>
  )
}

ManageOrganizationArtistsPopupFormStep.propTypes = {
  setData: PropTypes.func,
  handleManageOrganizationArtists: PropTypes.func,
  data: PropTypes.any,
  organization: PropTypes.any,
}
