import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const Wrapper = ({ children, className = '' }) => (
  <div className={`${classes.wrapper} ${className}`.trim()}>{children}</div>
)

Wrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
