import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CloseIcon } from '../../../../Components/icons/x-icon-straight.svg'
import classes from './index.module.css'

export default function OrganizationsPopup({ children, isOpen, closePopup }) {
  if (!isOpen) return null
  return (
    <div className={classes.wrapper}>
      <div className={classes.popup}>
        <button onClick={closePopup} className={classes.button}>
          <CloseIcon />
        </button>
        {children}
      </div>
    </div>
  )
}

OrganizationsPopup.propTypes = {
  children: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  isOpen: PropTypes.bool,
  closePopup: PropTypes.func,
}
