import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import NotFound from '../Pages/Helpers/404'
import useAccessLevel from '../Pages/Organizations/hooks/useAccessLevel'

export default function OrganizationGroupRoute({ children }) {
  const { o_f_id, g_f_id } = useParams()

  const { isInternalAdmin, hasOrgAdminAccess, hasGroupAccess } = useAccessLevel({
    o_f_id,
    g_f_id,
  })

  if (isInternalAdmin || hasOrgAdminAccess || hasGroupAccess) {
    return children
  }

  return <NotFound />
}

OrganizationGroupRoute.propTypes = {
  children: PropTypes.node,
}
