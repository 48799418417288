import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function AlbumGridItem({ album, onClick, hidden = false }) {
  return (
    <>
      <div
        className={classes.itemWrapper}
        style={{ opacity: '1' }}
        onClick={() => {
          onClick(album)
        }}
      >
        <img className={classes.itemImage} src={album?.image?.url} />
        <div className={classes.itemTextWrapper}>
          <p className={classes.itemTitle}>{album?.name}</p>
          <p className={classes.itemSubtitle}>{album?.year_release}</p>
          <p className={classes.itemSubtitle}>
            {album?.song_count} {album?.song_count !== 1 ? 'Songs' : 'Song'}
          </p>
        </div>
      </div>
    </>
  )
}

AlbumGridItem.propTypes = {
  album: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
}
