import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function UsersTag({ tag, disabled, onClick }) {
  return (
    <button
      onClick={() => onClick(tag.tag)}
      disabled={disabled}
      className={classes.button}
    >
      <span>#{tag.tag}</span>
      <span>{tag.submissions}</span>
    </button>
  )
}

UsersTag.propTypes = {
  tag: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
