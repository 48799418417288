import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../Contexts/APIContext'

export default function useGetArtistData({ artistName, enabled = true }) {
  const { getArtistData } = useAPI()
  return useQuery({
    queryKey: ['artist', artistName],
    queryFn: () => getArtistData(artistName),
    enabled,
  })
}
