import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { formatStringInNav } from '../../../Helpers/String+Extension'
import { Breadcrumbs } from '../../../Components/GeneralComponents/Breadcrumbs/Breadcrumbs'
import SongGridItem from '../../../Components/DashboardComponents/SongGridItem/SongGridItem'
import OptionsButton from '../../../Components/GeneralComponents/OptionsButton/OptionsButton'
import Loader from '../../../Components/GeneralComponents/Loader'
import classes from './index.module.css'
import { StickyHeader } from '../../../Components/StickyHeader'
import { permissions } from '../../../constants/roles'
import useReadOnly from '../../../services/hooks/useReadOnly'
import PublicationBadge from '../../../Components/GeneralComponents/PublicationBadge'

export default function AlbumSongs() {
  const navigate = useNavigate()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { getAlbumSongs, saveAlbumHideState } = useAPI()
  const [isSavingAlbum, setIsSavingAlbum] = useState(false)

  const { isReadOnly: isSongReadOnly } = useReadOnly(permissions.r.music_song)

  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['albumSongs', id],
    queryFn: () => getAlbumSongs(id),
  })

  const hideAlbumMutation = useMutation({
    mutationFn: () => saveAlbumHideState(id, !data?.is_public_visible),
    onSuccess: data => {
      queryClient.clear()
      setIsSavingAlbum(false)
    },
    onError: e => {
      setIsSavingAlbum(false)
    },
  })

  const handleOnHide = () => {
    setIsSavingAlbum(true)
    hideAlbumMutation.mutate()
  }

  return (
    <div className={classes.wrapper}>
      <>
        {!isLoading && (
          <StickyHeader>
            <Breadcrumbs
              items={
                !data?.album_artist_name
                  ? [
                      {
                        text: 'Artists',
                        onClick: () => {
                          navigate('/artists')
                        },
                      },
                    ]
                  : [
                      {
                        text: 'Artists',
                        onClick: () => {
                          navigate('/artists')
                        },
                      },
                      {
                        text: formatStringInNav(data?.album_artist_name),
                        onClick: () => {
                          navigate('/artist/' + data?.artist?.artist_name)
                        },
                      },
                      {
                        text: ' Albums',
                        onClick: () => {
                          navigate('/albums/' + data?.artist?.artist_name)
                        },
                      },
                      {
                        text: 'Songs',
                        onClick: () => {},
                      },
                    ]
              }
            />
            <div className={classes.headerWrapper}>
              <div>
                <div className={classes.heading}>
                  <h1 className={classes.title}>
                    {'Songs in ' + (data?.name ?? 'unknown')}
                  </h1>
                  {!data?.is_public_visible ? (
                    <PublicationBadge
                      size="sm"
                      status={'published'}
                      text={'Hidden'}
                      className={classes.heading__badge}
                    />
                  ) : null}
                </div>
                {data?.songs_count && (
                  <p className={classes.subtitle}>
                    {'Total Songs ' + data?.songs_count}
                  </p>
                )}
              </div>
              <OptionsButton
                disabled={isSongReadOnly}
                options={[
                  {
                    title: data?.is_public_visible
                      ? 'HIDE ALBUM'
                      : 'SHOW ALBUM',
                    func: handleOnHide,
                    disable: isSavingAlbum,
                  },
                ]}
              />
            </div>
          </StickyHeader>
        )}
      </>
      <div className={classes.songs}>
        {data && data.songs.length > 0 ? (
          data.songs.map(item => (
            <SongGridItem
              key={item.f_id}
              song={item}
              onClick={song => {
                navigate('/song/' + song.f_id)
              }}
            />
          ))
        ) : (
          <>
            {!isFetching && (
              <h1 className={classes.errorText}>
                {isError ? `Error ${error.status}` : 'No Results'}
              </h1>
            )}
          </>
        )}
      </div>
      <Loader isLoading={isLoading} />
    </div>
  )
}
