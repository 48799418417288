/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import OrganizationPreview from '../OrganizationPreview'
import classes from './index.module.css'
import { useInView } from 'react-intersection-observer'
import Loader from '../../../../Components/GeneralComponents/Loader'

export default function OrganizationsPreviewsList({
  list,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) {
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage])
  return (
    <ul className={classes.list}>
      {list.map((item, index) => (
        <li ref={ref} className={classes.list__item} key={index}>
          <OrganizationPreview
            image={item.image_url}
            name={item.name}
            artists={item.artists_count}
            members={item.organization_users_count}
            id={item.f_id}
          />
        </li>
      ))}
      {isFetchingNextPage ? (
        <li className={classes.list__item}>
          <Loader isLoading={isFetchingNextPage} />
        </li>
      ) : null}
    </ul>
  )
}

OrganizationPreview.propTypes = {
  list: PropTypes.array,
}
