import React, { useRef } from 'react'
import classes from './index.module.css'
import PropTypes from 'prop-types'
import frame from '../MobileFrames/frameWithIcons.png'
import mask from './youtubeMask.svg'
import { usePreventFullscreen } from '../../../../Helpers/hooks/usePreventFullscreen'

const YoutubeShorts = ({ data, getCurrentUser }) => {
  const videoElement = useRef()
  usePreventFullscreen(videoElement)

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  const videoDimensionWidth = data?.export?.dimensions[0]
  const videoDimensionHeight = data?.export?.dimensions[1]
  let videoClass = classes.videoS

  if (videoDimensionHeight === 640 && videoDimensionWidth === 640) {
    videoClass = classes.videoL
  } else if (videoDimensionHeight === 180) {
    videoClass = classes.videoM
  } else if (videoDimensionWidth === 640 && videoDimensionHeight === 480) {
    videoClass = classes.videoSm
  } else if (videoDimensionWidth === 720 && videoDimensionHeight === 720) {
    videoClass = classes.videoXXL
  } else if (videoDimensionWidth === 720 && videoDimensionHeight === 1280) {
    videoClass = classes.verticalVideoXXL
  } else if (videoDimensionWidth === 1280) {
    videoClass = classes.videoXl
  }

  return (
    <div className={classes.container}>
      <div className={classes.blackScreen} />
      <video
        muted={true}
        autoPlay={true}
        ref={videoElement}
        onTimeUpdate={handleOnTimeUpdate}
        className={`${videoClass} ${classes.video}`}
        src={data?.export?.url}
        controlsList="nofullscreen"
      />
      <div className={classes.tags}>
        <span className={classes.tag}>{data?.tags?.join(' ')}</span>
      </div>
      <div className={classes.userInfo}>
        <img
          src={getCurrentUser()?.image_url}
          className={classes.footerImage}
        />
        <span className={classes.footerName}>{getCurrentUser()?.name}</span>
      </div>
      <div className={classes.maskContainer}>
        <img src={mask} />
      </div>
      <div className={classes.frameContainer}>
        <img src={frame} className={classes.frame} />
      </div>
    </div>
  )
}

YoutubeShorts.propTypes = {
  data: PropTypes.object,
  getCurrentUser: PropTypes.func,
}

export default YoutubeShorts
