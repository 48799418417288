import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Prompt.css'
import note from './note.png'

export default function Prompt({
  title,
  subtitle,
  primaryAction,
  onPrimaryClick,
  secondaryAction,
  onSecondaryClick,
  isSecondaryActionDestructive,
}) {
  return (
    <>
      {
        <div
          className="prompt-bg-container"
          onClick={() => {
            onPrimaryClick()
          }}
        >
          <div className="prompt-container" onClick={e => e.stopPropagation()}>
            <div className="prompt-title-subtitle-container">
              <div className="prompt-title-container">
                <p className="prompt-title-text">{title}</p>
              </div>
              {subtitle && <p className="prompt-subtitle-text">{subtitle}</p>}
            </div>
            <div className="prompt-actions">
              {secondaryAction && (
                <button
                  className={`prompt-action-button ${
                    isSecondaryActionDestructive
                      ? 'prompt-destructive-action-button'
                      : ''
                  }`}
                  onClick={() => {
                    onSecondaryClick()
                  }}
                >
                  {secondaryAction}
                </button>
              )}
              <button
                className="prompt-action-button"
                onClick={() => {
                  onPrimaryClick()
                }}
              >
                {primaryAction}
              </button>
            </div>
          </div>
        </div>
      }
    </>
  )
}

Prompt.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  primaryAction: PropTypes.string.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  secondaryAction: PropTypes.string,
  onSecondaryClick: PropTypes.func,
  isSecondaryActionDestructive: PropTypes.bool,
}
