import React from 'react'
import PropTypes from 'prop-types'
import ButtonArrow from '../../GeneralComponents/ButtonArrow'
import classes from './index.module.css'

export default function ArtistAlbumsHeader({ totalAlbums, onClick }) {
  return (
    <div className={classes.headerWrapper}>
      <p className={classes.headerText}>Albums</p>
      <ButtonArrow
        text={'View All' + (totalAlbums ? ' (' + totalAlbums + ')' : '')}
        onClick={() => onClick()}
      />
    </div>
  )
}

ArtistAlbumsHeader.propTypes = {
  totalAlbums: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}
