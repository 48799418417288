import React, { useEffect, useState } from 'react'
import { ReactComponent as XIcon } from '../../../Components/icons/x-icon.svg'
import { ReactComponent as PlusIcon } from '../../../Components/icons/plus-icon.svg'
import AdaptiveVideo from '../../Feels/ExportFeel/AdaptiveVideo'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import errorToast from '../../../Components/Toasts/ErrorToast'

export default function VideoUpload(props) {
  const {
    label,
    helperText,
    name,
    required,
    onFileFieldValueChange,
    video,
    ...rest
  } = props

  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null)

  // Function to handle video selection
  const handleVideoRender = video => {
    if (!video) {
      return
    }

    if (video.video) {
      const { url } = video.video
      setVideoPreviewUrl(url)
      return
    }

    if (video.file) {
      const { file } = video
      const videoUrl = URL.createObjectURL(file)
      const videoElement = document.createElement('video')

      videoElement.src = videoUrl

      videoElement.onloadedmetadata = () => {
        setVideoPreviewUrl(videoUrl)
        URL.revokeObjectURL(videoUrl)
      }

      return
    }
  }

  const handleVideoChange = async event => {
    const file = event.target.files[0]

    if (!file) {
      return
    }

    // Check file size (100MB = 100 * 1024 * 1024 bytes)
    if (file.size > 100 * 1024 * 1024) {
      errorToast('File size must be less than 100MB.')
      setVideoPreviewUrl(null)
      onFileFieldValueChange(null)
      return
    }

    const videoUrl = URL.createObjectURL(file)
    const videoElement = document.createElement('video')
    videoElement.src = videoUrl

    // Check video dimensions when metadata is loaded
    videoElement.onloadedmetadata = async () => {
      const { videoWidth, videoHeight, duration } = videoElement

      // Validate that video dimensions are exactly 720x1280
      if (videoWidth !== 720 || videoHeight !== 1280) {
        errorToast('Video dimensions must be 720px x 1280px.')
        setVideoPreviewUrl(null)
        onFileFieldValueChange(null)
        URL.revokeObjectURL(videoUrl)
        return
      }

      // Use Web Audio API to check for the presence of an audio track
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)()
      try {
        const fileArrayBuffer = await file.arrayBuffer() // Read the file as an ArrayBuffer
        const audioBuffer = await audioContext.decodeAudioData(fileArrayBuffer) // Decode audio data

        // Check if the audio buffer has at least one channel
        if (audioBuffer.numberOfChannels === 0) {
          errorToast('Video must contain at least one audio track.')
          setVideoPreviewUrl(null)
          onFileFieldValueChange(null)
          URL.revokeObjectURL(videoUrl)
          return
        }
      } catch (error) {
        // Handle cases where decoding fails or no audio track is found
        errorToast('Error decoding audio track or no audio track found.')
        setVideoPreviewUrl(null)
        onFileFieldValueChange(null)
        URL.revokeObjectURL(videoUrl)
        return
      }

      // If all checks pass, update the state and allow video preview
      setVideoPreviewUrl(videoUrl)
      onFileFieldValueChange({
        video_type: file.type,
        filesize: file.size,
        dimensions: [videoWidth, videoHeight],
        file,
        url: videoUrl,
        duration,
      })
      URL.revokeObjectURL(videoUrl)
    }
  }

  // Function to remove the video
  const handleRemoveVideo = () => {
    setVideoPreviewUrl(null)
    // Reset the file input value
    document.getElementById(name).value = ''
    onFileFieldValueChange(null)
  }

  // Function to trigger file input click when placeholder is clicked
  const triggerFileInput = () => {
    document.getElementById(name).click()
  }

  useEffect(() => {
    handleVideoRender(video)
  }, [video])

  return (
    <div className={classes.wrapper}>
      <span className={classes.label}>
        {label}{' '}
        {required ? <span className={classes.label__required}>*</span> : null}
      </span>

      <input
        type="file"
        name={name}
        id={name}
        accept="video/mp4"
        onChange={handleVideoChange}
        style={{ display: 'none' }}
        {...rest}
      />

      {videoPreviewUrl ? (
        <div className={classes.preview}>
          <AdaptiveVideo
            maxWidth={192}
            minWidth={192}
            controls
            autoPlay={true}
            muted={true}
            loop={true}
            src={videoPreviewUrl}
          />
          <button
            className={classes.preview__button}
            onClick={handleRemoveVideo}
          >
            <XIcon />
          </button>
        </div>
      ) : (
        <div className={classes.placeholder} onClick={triggerFileInput}>
          <PlusIcon />
        </div>
      )}
      {helperText ? (
        typeof helperText === 'string' ? (
          <span className={classes.text}>{helperText}</span>
        ) : (
          <div className={classes.helperText}>
            {helperText.map((text, index) => (
              <span key={index} className={classes.text}>
                {text}
              </span>
            ))}
          </div>
        )
      ) : null}
    </div>
  )
}

VideoUpload.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.any,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onFileFieldValueChange: PropTypes.func,
  video: PropTypes.object,
}
