import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Lyrics.css'

export default function Lyrics({ onLyricSelected, lyrics, videoDuration }) {
  const maximumAllowedDurationOfFeelInMilliseconds = 15000
  const showErrorForMs = 2500

  const [errorDisplay, setErrorDisplay] = useState('hidden')
  const [errorTop, setErrorTop] = useState(0)
  const [errorLeft, setErrorLeft] = useState(0)
  const [errorMessage, setErrorMesage] = useState(
    'Music Message should be less than 15 seconds long'
  )
  const [errorTimer, setErrorTimer] = useState(null)

  function handleLyricsSelection(e, line) {
    if (shouldTheLineBeSelected(line)) {
      onLyricSelected(line)
      clearTimeout(errorTimer)
      setErrorDisplay('hidden')
    } else {
      showErrorPrompt(e)
    }
  }

  function showErrorPrompt(e) {
    const errorPromptElement =
      document.getElementsByClassName('div-error-prompt')
    if (errorPromptElement.length > 0) {
      setErrorDisplay('visible')
      setErrorLeft(e.pageX - errorPromptElement[0].offsetWidth / 2)
      setErrorTop(e.pageY - errorPromptElement[0].offsetHeight * 1.2)
      clearTimeout(errorTimer)
      const timer = setTimeout(() => {
        setErrorDisplay('hidden')
      }, showErrorForMs)
      setErrorTimer(timer)
    }
  }

  function shouldTheLineBeSelected(line) {
    const indexOfSelectedLine = lyrics.indexOf(line)
    const firstAlreadySelectedLine = lyrics
      .map(item => item.isSelected)
      .indexOf(true)
    const lastAlreadySelectedLine = lyrics
      .map(item => item.isSelected)
      .lastIndexOf(true)
    if (firstAlreadySelectedLine === -1 && lastAlreadySelectedLine === -1) {
      //If no line is selected yet - then select this line
      return true
    } else if (
      indexOfSelectedLine === firstAlreadySelectedLine ||
      indexOfSelectedLine === lastAlreadySelectedLine
    ) {
      //If user tapped the first or the last line - then deselect it
      return true
    } else if (
      indexOfSelectedLine - 1 === lastAlreadySelectedLine ||
      indexOfSelectedLine + 1 === firstAlreadySelectedLine
    ) {
      //If user tapped the previous line before the first one or the next line after the last one - then let's check if time allows us to select it
      const selectedLyrics = lyrics.filter(item => {
        return item.isSelected
      })
      const firstSelectedLyric = selectedLyrics.reduce((prev, curr) =>
        parseInt(prev.milliseconds) < parseInt(curr.milliseconds) ? prev : curr
      )
      const lastSelectedLyric = selectedLyrics.reduce((prev, curr) =>
        parseInt(prev.milliseconds) > parseInt(curr.milliseconds) ? prev : curr
      )
      let duration = 0
      if (
        parseInt(line.milliseconds) < parseInt(firstSelectedLyric.milliseconds)
      ) {
        //If this line is before the first selected, we need to add the current line to exisitng duraton
        duration =
          parseInt(lastSelectedLyric.milliseconds) -
          parseInt(line.milliseconds) +
          parseInt(lastSelectedLyric.duration)
      } else {
        //If this line is after the last selected, we need to include all the space in between the lines
        duration =
          parseInt(line.milliseconds) -
          parseInt(firstSelectedLyric.milliseconds) +
          parseInt(line.duration)
      }
      if (duration <= maximumAllowedDurationOfFeelInMilliseconds) {
        //If time is still less than the limit - then select the line
        return true
      } else {
        setErrorMesage('Music Messages must be less than 15 seconds long')
        return false
      }
    } else {
      if (line.isSelected) {
        setErrorMesage('Selected lyric lines must be next to each other')
      } else {
        setErrorMesage('Select lyric lines next to each other')
      }
      return false
    }
  }

  return (
    <>
      {lyrics.map((line, index) => {
        return (
          <button
            key={index}
            className={`lyrics-container`}
            type="button"
            disabled={line.t_end > videoDuration}
            onClick={e => {
              handleLyricsSelection(e, line)
            }}
          >
            <span
              className={
                line.isSelected ? 'lyrics-text-selected' : 'lyrics-text'
              }
              title={'' + parseInt(line.duration) / 1000 + 's'}
            >
              {line.line}
            </span>
          </button>
        )
      })}
      <div
        className="div-error-prompt"
        style={{
          visibility: errorDisplay,
          top: errorTop + 'px',
          left: errorLeft + 'px',
        }}
      >
        <p className="p-error-text">{errorMessage}</p>
      </div>
    </>
  )
}

Lyrics.propTypes = {
  onLyricSelected: PropTypes.func.isRequired,
  lyrics: PropTypes.array.isRequired,
  videoDuration: PropTypes.number.isRequired,
}
