import React, { useEffect, useState } from 'react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { useAuth } from '../../../Contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { useSongsStore } from '../../../Storage/songsPage/useSongsStore'
import { isEmailInSongManagementTeam } from '../../../Helpers/RestrictedEmailsArray'
import DebounceSearch from '../../../Components/GeneralComponents/DebounceSearch/DebounceSearch'
import SongGridItem from '../../../Components/DashboardComponents/SongGridItem/SongGridItem'
import Loader from '../../../Components/GeneralComponents/Loader'
import classes from './index.module.css'

export default function AllSongs() {
  const pageLimit = 20
  const { getCurrentUser, logout } = useAuth()
  const { getAllSongs, getSearchAllSongs } = useAPI()
  const navigate = useNavigate()
  const [ref, inView] = useInView()
  const [refSearch, inViewSearch] = useInView()

  const searchTerm = useSongsStore(state => state.searchTerm)
  const setSearchTerm = useSongsStore(state => state.setSearchTerm)

  const inputValue = useSongsStore(state => state.inputValue)
  const setInputValue = useSongsStore(state => state.setInputValue)

  const { data, isError, error, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['AllSongs', searchTerm],
      queryFn: ({ pageParam = 1 }) =>
        searchTerm === ''
          ? getAllSongs(pageParam, pageLimit)
          : getSearchAllSongs(searchTerm, pageParam, pageLimit),
      getNextPageParam: pageData =>
        pageData.p * pageLimit < pageData.songs_count ? pageData.p + 1 : null,
    })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [])

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  function handleClickOnSong(song) {
    let currentUser = getCurrentUser()
    if (currentUser == null || currentUser == undefined) {
      logout()
    } else {
      if (isEmailInSongManagementTeam(currentUser?.email)) {
        navigate('/edit-song/' + song.f_id)
      } else {
        navigate('/song/' + song.f_id)
      }
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <h1 className={classes.title}>Songs</h1>
        <div className={classes.searchWrapper}>
          <DebounceSearch
            inputValue={inputValue}
            setInputValue={setInputValue}
            searchValue={searchTerm}
            setSearchValue={setSearchTerm}
            placeholder={'Search for Songs, Artists...'}
          />
        </div>
      </div>
      <div className={classes.songs}>
        {!isError &&
          data &&
          data.pages.map((page, index) => (
            <React.Fragment key={index}>
              {page.songs.map((song, innerIndex) => (
                <div key={song.f_id} ref={ref}>
                  <SongGridItem
                    key={song.f_id}
                    song={song}
                    onClick={songItem => {
                      handleClickOnSong(songItem)
                    }}
                  />
                </div>
              ))}
            </React.Fragment>
          ))}
      </div>

      <Loader isLoading={isLoading || isFetchingNextPage} />

      {isError && (
        <h1 className={classes.errorText}>{`Failed to fetch data with code: ${
          error?.status ? error.status : 'unknown'
        }`}</h1>
      )}
    </div>
  )
}
