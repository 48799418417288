export const orgRolesAdmin = [
  'Organization Owner',
  'Organization Administrator',
]
export const orgRolesMembers = ['Editor', 'Viewer']
// export const orgRolesMembers = ['Organization Editor', 'Organization Viewer']

export const orgRoles = [...orgRolesAdmin, ...orgRolesMembers]
export const adminRoles = [
  'Super Administrator',
  'Administrator',
  'Editor',
  'Viewer',
]

export const groupRoles = ['Team Administrator', 'Editor', 'Viewer']

// ADMINISTRATOR
// ORGANIZATION_ADMINISTRATOR
// GROUP_ADMINISTRATOR
// ORGANIZATION_OWNER
// SUPER_ADMINISTRATOR
// EDITOR
// VIEWER

export const roles = [
  ...new Set([...orgRoles, ...adminRoles, ...groupRoles]),
].reduce((acc, role) => {
  const roleToUppercase = role.toUpperCase().split(' ').join('_')
  return { ...acc, [roleToUppercase]: role }
}, {})

export const permissions = {
  r: {
    support: 'support:r',
    music_artist: 'music_artist:r',
    music_song: 'music_song:r',
    feels_mm: 'feels_mm:r',
    feels_factory: 'feels_factory:r',
    analytics: 'analytics:r',
    internal_all_content: 'internal_all_content:r',
  },
  ra: {
    support: 'support:ra',
  },
  rwad: {
    support: 'support:*',
    music_artist: 'music_artist:*',
    music_song: 'music_song:*',
    feels_mm: 'feels_mm:*',
    feels_factory: 'feels_factory:*',
    analytics: 'analytics:*',
    internal_all_content: 'internal_all_content:*',
    organization_owner: 'organization_owner:*',
    internal_superadmin: 'internal_superadmin:*',
    organization_admin: 'organization_admin:*',
    internal_admin: 'internal_admin:*',
  },
}
