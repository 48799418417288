import { useMutation } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export const useSaveTeaseEdits = () => {
  const { saveTeaseEdits } = useAPI()

  return useMutation({
    mutationFn: data =>
      saveTeaseEdits({
        ...data,
      }),
  })
}
