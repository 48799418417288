import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth } from '../Contexts/AuthContext'
import NotFound from '../Pages/Helpers/404'
import { orgRoles } from '../constants/roles'
import useAccessLevel from '../Pages/Organizations/hooks/useAccessLevel'

export default function OrganizationRoute({ children }) {
  const { id } = useParams()

  const {
    isInternalAdmin,
    hasOrgAccess,
  } = useAccessLevel({
    o_f_id: id,
  })

  if (isInternalAdmin || hasOrgAccess) {
    return children
  }

  return <NotFound />
}

OrganizationRoute.propTypes = {
  children: PropTypes.node,
}
