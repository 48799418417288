

import { formatStringInNav } from '../../../Helpers/String+Extension'

export const renderBreadcrumbs = data => [
  {
    title: 'All Artists',
    link: `/artists`,
  },

  {
    title: formatStringInNav(data.artist.name),
    link: `/artist/${data.artist.artist_name}`,
  },
  {
    title: 'Teases',
  },
]
