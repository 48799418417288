import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function OptionsButton({ options, disabled = false }) {
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [buttonRef])

  return (
    <>
      <div className={classes.anchor}></div>
      <div className={classes.buttonWrapper} ref={buttonRef}>
        <button
          disabled={disabled}
          className={classes.dotsContainer}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={classes.dot}></div>
        </button>

        <div
          className={
            isOpen
              ? classes.optionsContainerOpen
              : classes.optionsContainerClose
          }
        >
          {options.map((option, index) => (
            <div
              style={{
                opacity: option.disable ? '0.5' : '1',
                cursor: option.disable ? 'not-allowed' : 'pointer',
              }}
              className={classes.option}
              key={index}
              onClick={
                option.disable
                  ? () => {}
                  : () => {
                      option.func()
                      setIsOpen(false)
                    }
              }
            >
              {option.title}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

OptionsButton.propTypes = {
  options: PropTypes.any,
  disabled: PropTypes.bool,
}
