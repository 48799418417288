import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function TextField(props) {
  const {
    label,
    helperText,
    name,
    isRequired,
    variant = 'regular',
    startIcon = undefined,
    inputRef,
    ...rest
  } = props
  return (
    <div className={classes.wrapper}>
      {label ? (
        <label className={classes.label} htmlFor={name}>
          {label}{' '}
          {isRequired ? (
            <span className={classes.label__required}>*</span>
          ) : null}
        </label>
      ) : null}
      <div className={`${classes['input-wrapper']}`.trim()}>
        {startIcon ? (
          <div className={classes['start-icon']}>{startIcon}</div>
        ) : null}
        <input
          ref={inputRef}
          className={`${classes.input} ${classes['input__' + variant]} ${
            startIcon ? classes['input__start-icon'] : ''
          }`.trim()}
          name={name}
          {...rest}
        />
      </div>

      {helperText ? <span className={classes.text}>{helperText}</span> : null}
    </div>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['regular', 'rounded']),
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  startIcon: PropTypes.node,
  inputRef: PropTypes.any,
}
