import React from 'react'
import TextField from '../../TextField'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import CheckboxButton from '../../../../Components/GeneralComponents/CheckboxButton'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function OffsetActions({
  onVideoOffsetValueChange,
  currentlySelectedLine,
  onLoopLinesClick,
  isLoopingLines,
  tease,
  handleSetCurrentVideoPlayerToLineAction,
}) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.input}>
        <TextField
          name={'lyrics_adj'}
          placeholder={'Enter Seconds to adjust video...'}
          onChange={onVideoOffsetValueChange}
          value={tease.lyrics_adj || 0}
          variant={'rounded'}
        />
        <label htmlFor="lyrics_adj" className={classes.input__label}>
          Video Offset (in ms)
        </label>
      </div>
      <div className={classes.actions}>
        <ButtonOutline
          color={'pink'}
          size="extraSmall"
          text={
            currentlySelectedLine !== null
              ? 'ADJUST ALL LINES AHEAD WITH PLAYER TIME'
              : 'SET PLAYER TIME AS VIDEO OFFSET'
          }
          onClick={handleSetCurrentVideoPlayerToLineAction}
        />

        <CheckboxButton
          onClick={onLoopLinesClick}
          condition={isLoopingLines}
          label={'Loop Lines'}
        />
      </div>
    </div>
  )
}

OffsetActions.propTypes = {
  onVideoOffsetValueChange: PropTypes.func,
  handleSetCurrentVideoPlayerToLineAction: PropTypes.func,
  onLoopLinesClick: PropTypes.func,
  currentlySelectedLine: PropTypes.object,
  isLoopingLines: PropTypes.bool,
  tease: PropTypes.object,
}
