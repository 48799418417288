const songManagementTeamEmails = [
  'dedvadik@gmail.com',
  'yegornikit@gmail.com',
  'tennisazvezda@gmail.com',
  'tkravchun@gmail.com',
  'shtybsergey@gmail.com',
  'skripchenko.andre@gmail.com',
  'bogdan.drozdov.ua@gmail.com',
  'lutsenko.evgenij@gmail.com',
  'ihor8404@gmail.com',
  'dariamorozova.idiomas@gmail.com'
]

export function isEmailInSongManagementTeam(email) {
  if (email === null || email === undefined) {
    return false
  }
  return songManagementTeamEmails.includes(email)
}
