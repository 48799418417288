import React, { useState, useRef } from 'react'
import { useCollectionsStore } from '../../../Storage/useCollectionStore'
import { ButtonOutline } from '../../GeneralComponents/ButtonOutline/ButtonOutline'
import PropTypes from 'prop-types'
import calendarIcon from '../../icons/collections_icons/calanderIcon.png'
import songIcon from '../../icons/collections_icons/songIcon.png'
import albumIcon from '../../icons/collections_icons/albumIcon.png'
import artistIcon from '../../icons/collections_icons/artistIcon.png'
import mutedButton from '../../icons/collections_icons/mutedButton.png'
import unmutedButton from '../../icons/collections_icons/unmutedButton.png'
import classes from './index.module.css'
import { usePreventFullscreen } from '../../../Helpers/hooks/usePreventFullscreen'

export default function CollectionFeelsModal({
  feel,
  setToNewCollection,
  reservedFeels,
  closeModal,
}) {
  const videoElement = useRef(null)
  const [shouldShowVideo, setShouldShowVideo] = useState(false)
  const { feelsMuted, setFeelsMuted } = useCollectionsStore()
  usePreventFullscreen(videoElement)

  function getPublishedDate() {
    let publishedDate = new Date(
      Date.parse(feel.date_created)
    ).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
    return publishedDate
  }

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  async function handleVideoPlay() {
    try {
      await videoElement.current.play()
    } catch (error) {
      // solution for error 'The play() request was interrupted by a call to pause()'
    }
  }

  function handleVideoPause() {
    if (!videoElement.current.paused) {
      videoElement.current.pause()
      videoElement.current.currentTime = 0
    }
  }

  return (
    <div
      className={classes.container}
      style={{ opacity: feel?.is_public_visible ? 1 : 0.2 }}
      onMouseOver={() => {
        setShouldShowVideo(true)
        if (videoElement.current) {
          handleVideoPlay()
        }
      }}
      onMouseLeave={() => {
        setShouldShowVideo(false)
        if (videoElement.current) {
          handleVideoPause()
        }
      }}
    >
      <div className={classes.imageWrapper}>
        <img className={classes.image} src={feel?.preview?.image?.image?.url} />

        {shouldShowVideo && (
          <video
            ref={videoElement}
            className={classes.video}
            controls={false}
            autoPlay={false}
            muted={feelsMuted}
            hidden={!shouldShowVideo}
            onTimeUpdate={() => {
              handleOnTimeUpdate()
            }}
            src={feel?.preview?.video?.video?.url}
            controlsList="nofullscreen"
          />
        )}
        <img
          className={classes.muteIcon}
          src={feelsMuted ? mutedButton : unmutedButton}
          hidden={!shouldShowVideo}
          onClick={() => {
            setFeelsMuted()
          }}
        />
      </div>

      <div className={classes.detailsWrapper}>
        <div className={classes.dataWrapper}>
          <span className={classes.songTitle}>{feel.lyrics}</span>
          <div className={classes.infoWrapper}>
            <img className={classes.infoIcon} src={songIcon} />
            <span className={classes.infoText}>{feel.song_name}</span>
          </div>
          <div className={classes.infoWrapper}>
            <img className={classes.infoIcon} src={artistIcon} />
            <span className={classes.infoText}>{feel.artist.name}</span>
          </div>
          <div className={classes.infoWrapper}>
            <img className={classes.infoIcon} src={calendarIcon} />
            <p className={classes.publishedText}>
              {feel.date_published
                ? 'Published on ' + getPublishedDate()
                : 'Not Published yet'}
            </p>
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <ButtonOutline
            onClick={() => {
              setToNewCollection(feel)
              closeModal()
            }}
            background="transparent"
            fontWeight={400}
            size={'extraSmall'}
            text={'Add to collection'}
            disable={reservedFeels.includes(feel.f_id)}
            color={'pinkBlack'}
          />
        </div>
      </div>
    </div>
  )
}

CollectionFeelsModal.propTypes = {
  feel: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  setToNewCollection: PropTypes.func,
  reservedFeels: PropTypes.arrayOf(PropTypes.string),
}
