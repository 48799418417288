import { useMutation } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export const useCreateOrganizationGroup = () => {
  const { createOrganizationGroup } = useAPI()

  return useMutation({
    mutationFn: data =>
      createOrganizationGroup({
        ...data,
      }),
  })
}
