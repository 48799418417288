// link
// title

import { formatStringInNav } from "../../../Helpers/String+Extension"

export const renderBreadcrumbs = data =>
  !data
    ? [
        {
          title: 'All Songs',
          link: `/songs/all`,
        },
      ]
    : [
        {
          title: 'All Songs',
          link: `/songs/all`,
        },

        {
          title: formatStringInNav(data?.artist.name),
          link: `/artist/${data?.artist.artist_name}`,
        },
        {
          title: 'Songs',
          link: `/songs/artist/${data?.artist.artist_name}`,
        },
        {
          title: formatStringInNav(data?.song_name),
          link: `/song/${data?.song_f_id}`,
        },
        {
          title: `Music Messages For ${formatStringInNav(data?.song_name)}`,
          link: `/song/feels/${data?.song_f_id}`,
        },
        {
          title: 'Music Message',
          link: `/feel/${data?.f_id}`,
        },
        {
          title: 'Export',
        },
      ]
