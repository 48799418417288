import { useEffect, useState } from 'react'
import { useArtistSongsStore } from '../../Storage/artistSongsPage/useArtistSongsStore'
import { useArtistFeelsStore } from '../../Storage/artistFeelsPage/useArtistFeelsStore'

export default function useClearArtistSearchTerms({ artistName }) {
  const songArtistName = useArtistSongsStore(state => state.artistName)
  const setSongArtistName = useArtistSongsStore(state => state.setArtistName)
  const setSongArtistSearchTerm = useArtistSongsStore(
    state => state.setSearchTerm
  )
  const setSongArtistInputValue = useArtistSongsStore(
    state => state.setInputValue
  )

  const feelArtistName = useArtistFeelsStore(state => state.artistName)
  const setFeelArtistName = useArtistFeelsStore(state => state.setArtistName)
  const setFeelArtistSearchTerm = useArtistFeelsStore(
    state => state.setSearchTerm
  )
  const setFeelArtistInputValue = useArtistFeelsStore(
    state => state.setInputValue
  )

  const searchSongTerm = useArtistSongsStore(state => state.searchTerm)
  const setSearchSongTerm = useArtistSongsStore(state => state.setSearchTerm)
  const inputSongValue = useArtistSongsStore(state => state.inputValue)
  const setInputSongValue = useArtistSongsStore(state => state.setInputValue)

  const searchFeelTerm = useArtistFeelsStore(state => state.searchTerm)
  const setSearchFeelTerm = useArtistFeelsStore(state => state.setSearchTerm)
  const inputFeelValue = useArtistFeelsStore(state => state.inputValue)
  const setInputFeelValue = useArtistFeelsStore(state => state.setInputValue)

  const preAddedSearchTerm = [searchSongTerm, searchFeelTerm].filter(
    term => !!term
  ).length
    ? [...new Set([searchSongTerm, searchFeelTerm])].toString()
    : undefined
  const preAddedInputValue = [inputSongValue, inputFeelValue].filter(
    value => !!value
  ).length
    ? [...new Set([inputSongValue, inputFeelValue])].toString()
    : undefined

  const [searchTerm, setSearchTerm] = useState(preAddedSearchTerm || '')
  const [inputValue, setInputValue] = useState(preAddedInputValue || '')

  useEffect(() => {
    setSearchSongTerm(searchTerm)
    setInputSongValue(inputValue)
    setSearchFeelTerm(searchTerm)
    setInputFeelValue(inputValue)
  }, [searchTerm, inputValue])

  useEffect(() => {
    if ((songArtistName || feelArtistName) !== artistName) {
      setSongArtistSearchTerm('')
      setSongArtistInputValue('')
      setFeelArtistInputValue('')
      setFeelArtistSearchTerm('')
      setSearchTerm('')
      setInputValue('')
    }
    setSongArtistName(artistName)
    setFeelArtistName(artistName)
  }, [artistName])

  return { searchTerm, setSearchTerm, inputValue, setInputValue }
}
