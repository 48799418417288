import React from 'react'
import classes from './index.module.css'
import PropTypes from 'prop-types'

export default function Button({ className = '', disabled, onClick, label }) {
  return (
    <button
      className={`${classes.button} ${className}`.trim()}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
}
