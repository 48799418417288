import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const Breadcrumbs = ({ items }) => {
  return (
    <div className={classes.wrapper}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className={classes.arrow}>{'>'}</span>}
          {index !== items.length - 1 && (
            <button className={classes.item} onClick={item.onClick}>
              {item.text}
            </button>
          )}
          {index === items.length - 1 && (
            <button className={classes.currentItem} onClick={item.onClick}>
              {item.text}
            </button>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
}
