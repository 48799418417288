import React, { useRef } from 'react'
import mask from '../InstagramPost/instaScreen.svg'
import frame from '../../MobileFrames/frameClean.png'
import unfilledLike from './unfilledLike.png'
import filledLike from './filledLike.png'
import addButton from '../InstagramPost/addButton.svg'
import classes from './index.module.css'
import PropTypes from 'prop-types'
import { usePreventFullscreen } from '../../../../../Helpers/hooks/usePreventFullscreen'

const InstagramPost = ({ getCurrentUser, data }) => {
  const videoElement = useRef()
  usePreventFullscreen(videoElement)

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }
  const videoDimensionWidth = data?.export?.dimensions[0]
  const videoDimensionHeight = data?.export?.dimensions[1]
  let videoClass = classes.videoS

  if (videoDimensionHeight === 640 && videoDimensionWidth === 640) {
    videoClass = classes.videoL
  } else if (videoDimensionHeight === 180) {
    videoClass = classes.videoM
  } else if (videoDimensionWidth === 640 && videoDimensionHeight === 480) {
    videoClass = classes.videoSm
  } else if (videoDimensionWidth === 720 && videoDimensionHeight === 720) {
    videoClass = classes.videoXXL
  } else if (videoDimensionWidth === 1280 && videoDimensionHeight === 720) {
    videoClass = classes.videoXl
  } else if (videoDimensionWidth === 1280 && videoDimensionHeight === 718) {
    videoClass = classes.videoXl
  } else if (videoDimensionWidth === 1280 && videoDimensionHeight === 640) {
    videoClass = classes.videoXlS
  } else if (videoDimensionWidth === 1280 && videoDimensionHeight === 544) {
    videoClass = classes.videoXMS
  } else if (videoDimensionWidth === 726 && videoDimensionHeight === 720) {
    videoClass = classes.videoXXXL
  } else if (videoDimensionWidth === 854 && videoDimensionHeight === 480) {
    videoClass = classes.videoXM
  } else if (videoDimensionWidth === 954 && videoDimensionHeight === 720) {
    videoClass = classes.videoSm
  } else if (videoDimensionWidth === 720 && videoDimensionHeight === 1280) {
    videoClass = classes.verticalVideoXXL
  }

  return (
    <div className={classes.container}>
      <div className={classes.blackScreenCover} />
      <img className={classes.unfilledLike} src={unfilledLike} />
      <img className={classes.filledLike} src={filledLike} />
      <img src={getCurrentUser()?.image_url} className={classes.headerImage} />
      <video
        muted={true}
        autoPlay={true}
        ref={videoElement}
        onTimeUpdate={handleOnTimeUpdate}
        className={`${videoClass} ${classes.video}`}
        src={data?.export?.url}
        controlsList="nofullscreen"
      />
      <img src={addButton} className={classes.addButton} />
      <div className={classes.topPost}>
        <img src={getCurrentUser()?.image_url} className={classes.postImage} />
        <span className={classes.postName}>{getCurrentUser()?.name}</span>
      </div>

      <div className={classes.botPost}>
        <img src={getCurrentUser()?.image_url} className={classes.postImage} />
        <span className={classes.postName}>{getCurrentUser()?.name}</span>
      </div>

      <img src={getCurrentUser()?.image_url} className={classes.footerImage} />
      <span className={classes.userName}>{getCurrentUser()?.name}</span>
      <div className={classes.frameContainer}>
        <img src={frame} className={classes.frame} />
      </div>
      <div className={classes.maskContainer}>
        <img src={mask} className={classes.mask} />
      </div>
    </div>
  )
}

InstagramPost.propTypes = {
  getCurrentUser: PropTypes.func,
  data: PropTypes.object,
}

export default InstagramPost
