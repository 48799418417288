import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { Link } from 'react-router-dom'
import right_circle from '../../../../Components/icons/right_circle.png'

export default function ViewAll({ title = 'View All', count, path }) {
  return (
    <Link to={path} className={classes.wrapper}>
      <span className={classes.title}>
        {title} ({count})
      </span>
      <img className={classes.image} width={17} height={17} src={right_circle} alt={title} />
    </Link>
  )
}

ViewAll.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  count: PropTypes.any,
}
