import React, { useCallback, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Dashboard from './Pages/Feels/Dashboard/Dashboard'
import Data from './Pages/Data'
import NotFound from './Pages/Helpers/404'
import CreateNewFeelStep1 from './Pages/Feels/createNewFeelStep1'
import Nav from './Components/Navbar'
import SideBar from './Components/Sidebar'
import LoginPage from './Pages/Authentication/Login'
import OrgLoginPage from './Pages/Authentication/OrgLoginPage'
import PrivateRoute from './PrivateRoute'
import AllArtists from './Pages/Artists/AllArtists/AllArtists'
import Artist from './Pages/Artists/Artist/Artist'
import Albums from './Pages/Artists/Albums/Albums'
import FeelsForSong from './Pages/Songs/FeelsForSong/FeelsForSong'
import Feel from './Pages/Feels/Feel/Feel'
import EditSong from './Pages/Songs/EditSong/EditSong'
import ArtistSongs from './Pages/Songs/ArtistSongs/ArtistSongs'
import AllSongs from './Pages/Songs/AllSongs/AllSongs'
import AlbumSongs from './Pages/Songs/AlbumSongs/AlbumSongs'
import './index.css'
import SongDetails from './Pages/Songs/SongDetails/SongDetails'
import { isEmailInSongManagementTeam } from './Helpers/RestrictedEmailsArray'
import Trivia from './Pages/Trivia/Trivia'
import { useAuth } from './Contexts/AuthContext'
import trackPathForAnalytics from './firebase-analytics'

import Collections from './Pages/Collections/Collections'
import ExportFeel from './Pages/Feels/ExportFeel/ExportFeel'
import Stickers from './Pages/Stickers/Stickers'
import { Backdrop } from './Pages/Songs/Backdrop/Backdrop'
import ArtistFeels from './Pages/Feels/ArtistFeels/ArtistFeels'
import { Step2 } from './Pages/Feels/CreateFeel/Step2'
import Step1 from './Pages/Feels/CreateFeel/Step1'
import useAdminAccess from './services/hooks/useAdminAccess'
import OrganizationUsers from './Pages/Organization/OrganizationUsers'
import { permissions } from './constants/roles'
import useReadOnly from './services/hooks/useReadOnly'
import CreateTease from './Pages/Teases/CreateTease'
import TeaseDetails from './Pages/Teases/TeaseDetails'
import EditTease from './Pages/Teases/EditTease'
import Organizations from './Pages/Organizations'
import OrganizationDetails from './Pages/Organizations/OrganizationDetails'
import TeamDetails from './Pages/Organizations/TeamDetails'
import OrganizationTeams from './Pages/Organizations/OrganizationTeams'
import OrganizationArtists from './Pages/Organizations/OrganizationArtists'
import TeamDetailsMembers from './Pages/Organizations/TeamDetailsMembers'
import OrganizationMembers from './Pages/Organizations/OrganizationMembers'
import TeamDetailsArtists from './Pages/Organizations/TeamDetailsArtists'
import MyTeams from './Pages/Organizations/MyTeams'
import OnlyInternalRoute from './protectedRoutes/OnlyInternalRoute'
import OrganizationRoute from './protectedRoutes/OrganizationRoute'
import OrganizationGroupRoute from './protectedRoutes/OrganizationGroupRoute'

export default function Router() {
  const { getCurrentUser } = useAuth()

  const { hasAdminAccess } = useAdminAccess()
  const { isReadOnly: isSongReadOnly } = useReadOnly(permissions.r.music_song)
  const { isReadOnly: isFeelsFactoryReadOnly } = useReadOnly(
    permissions.r.feels_factory
  )
  const { isReadOnly: isFeelsReadOnly } = useReadOnly(permissions.r.feels_mm)

  const { pathname, search } = useLocation()

  const analytics = useCallback(() => {
    trackPathForAnalytics({
      path: pathname,
      search: search,
      title: pathname.split('/')[1],
    })
  }, [pathname, search])

  useEffect(() => {
    analytics()
  }, [analytics])

  return (
    <>
      {getCurrentUser() && <SideBar />}
      <div id="main">
        {getCurrentUser() && <Nav />}
        {isEmailInSongManagementTeam(getCurrentUser()?.email) ? (
          <div className="child-wrapper">
            <Routes>
              <Route
                path="/login/admin"
                element={
                  getCurrentUser() ? <Navigate to="/artists" /> : <LoginPage />
                }
              />
              <Route
                path="/login"
                element={
                  getCurrentUser() ? (
                    <Navigate to="/artists" />
                  ) : (
                    <OrgLoginPage />
                  )
                }
              />
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<AllSongs />} />
                <Route
                  path="/songs/all"
                  element={<Navigate to="/dashboard" />}
                />
                {!isSongReadOnly && (
                  <Route path="/edit-song/:id" element={<EditSong />} />
                )}
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        ) : (
          <div className="child-wrapper">
            <Routes>
              <Route
                path="/login/admin"
                element={
                  getCurrentUser() ? <Navigate to="/artists" /> : <LoginPage />
                }
              />
              <Route
                path="/login"
                element={
                  getCurrentUser() ? (
                    <Navigate to="/artists" />
                  ) : (
                    <OrgLoginPage />
                  )
                }
              />
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Navigate to="/artists" />} />
                <Route path="/dashboard" element={<Dashboard />} />

                {hasAdminAccess && (
                  <>
                    <Route path="/collections" element={<Collections />} />
                    <Route path="/stickers" element={<Stickers />} />
                  </>
                )}
                <Route path="/artists" element={<AllArtists />} />
                <Route path="/artist/:artist_name" element={<Artist />} />
                <Route path="/songs/all" element={<AllSongs />} />
                <Route
                  path="/songs/artist/:artist_name"
                  element={<ArtistSongs />}
                />
                <Route
                  path="/teases/artist/:artist_name"
                  element={<ArtistSongs />}
                />
                <Route
                  path="/feels/artist/:artist_name"
                  element={<ArtistFeels />}
                />
                <Route path="/songs/album/:id" element={<AlbumSongs />} />
                <Route path="/song/feels/:id" element={<FeelsForSong />} />
                <Route path="/tease/feels/:id" element={<FeelsForSong />} />
                <Route path="/song/:id" element={<SongDetails />} />

                {!isSongReadOnly && (
                  <>
                    <Route path="/edit-song/:id" element={<EditSong />} />
                    <Route path="/create-tease/:id" element={<CreateTease />} />
                    <Route path="/edit-tease/:id" element={<EditTease />} />
                  </>
                )}
                <Route path="/tease/:id" element={<TeaseDetails />} />
                <Route path="/albums/:artist_name" element={<Albums />} />
                <Route path="/data/:dash" element={<Data />} />
                <Route path="/feel/:id" element={<Feel />} />
                {!isFeelsReadOnly && (
                  <Route path="/feel/:id/export" element={<ExportFeel />} />
                )}
                {!isSongReadOnly && (
                  <Route
                    path="/song/:id/backdrop/:artist_name"
                    element={<Backdrop />}
                  />
                )}

                <Route
                  path="/organization"
                  element={
                    // <OrganizationRoute>
                    <OrganizationUsers />
                    // </OrganizationRoute>
                  }
                />

                <Route
                  path="/organizations"
                  element={
                    <OnlyInternalRoute>
                      <Organizations />
                    </OnlyInternalRoute>
                  }
                />
                <Route
                  path="/organizations/:id"
                  element={
                    <OrganizationRoute>
                      <OrganizationDetails />
                    </OrganizationRoute>
                  }
                />
                <Route path="/organizations/my-teams" element={<MyTeams />} />
                <Route
                  path="/organizations/:id/teams"
                  element={
                    <OrganizationRoute>
                      <OrganizationTeams />
                    </OrganizationRoute>
                  }
                />
                <Route
                  path="/organizations/:id/artists"
                  element={
                    <OrganizationRoute>
                      <OrganizationArtists />
                    </OrganizationRoute>
                  }
                />
                <Route
                  path="/organizations/:id/members"
                  element={
                    <OrganizationRoute>
                      <OrganizationMembers />
                    </OrganizationRoute>
                  }
                />
                <Route
                  path="/organizations/:o_f_id/teams/:g_f_id"
                  element={
                    <OrganizationGroupRoute>
                      <TeamDetails />
                    </OrganizationGroupRoute>
                  }
                />
                <Route
                  path="/organizations/:o_f_id/teams/:g_f_id/members"
                  element={
                    <OrganizationGroupRoute>
                      <TeamDetailsMembers />
                    </OrganizationGroupRoute>
                  }
                />
                <Route
                  path="/organizations/:o_f_id/teams/:g_f_id/artists"
                  element={
                    <OrganizationGroupRoute>
                      <TeamDetailsArtists />
                    </OrganizationGroupRoute>
                  }
                />

                <Route
                  path="/faq"
                  element={<Navigate to="/dashboard" />}
                  render={() => {
                    window
                      .open(
                        'https://musicmakesusfeels.zendesk.com/hc/en-us',
                        '_blank'
                      )
                      .focus()
                  }}
                />
                {!isFeelsFactoryReadOnly && (
                  <>
                    <Route
                      path="/create-new-feel-step-1/"
                      element={<CreateNewFeelStep1 />}
                    />
                    <Route
                      path="/create-new-feel-step-2/:id"
                      element={<Step2 />}
                    />
                    <Route
                      path="/create-new-feel-step-1/:artist_name"
                      element={<Step1 />}
                    />
                  </>
                )}
                <Route path="/trivia" element={<Trivia />} />
                {/* <Prompt
              when={useEditDataContext().isEditing}
              message="Are you sure you want to leave?"
            /> */}
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        )}
      </div>
    </>
  )
}
