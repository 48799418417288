import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Chevron } from '../../../../Components/icons/triangle.svg'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function RolesSelect({
  options,
  defaultOption,
  onOptionSelect = () => console.log('select'),
  userId,
  groupId,
  disabled = false,
  ...rest
}) {
  const roleRef = useRef(defaultOption)
  const [selectedOption, setSelectedOption] = useState(defaultOption || null)
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  // Toggle the dropdown when clicking the select element
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  // Handle option click and notify parent
  const handleOptionClick = option => {
    if (option === roleRef.current) {
      return
    }
    if (onOptionSelect) {
      onOptionSelect({
        role: option,
        organization_users: [userId],
        g_f_id: groupId,
        oldRole: roleRef.current,
      })
      roleRef.current = option
    }
    setSelectedOption(option)
    setIsOpen(false)
  }

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  // Close the dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className={classes.wrapper} {...rest}>
      <div className={classes['custom-select']} ref={selectRef}>
        <button
          type="button"
          disabled={disabled}
          className={classes['select-box']}
          onClick={toggleDropdown}
        >
          {selectedOption}
          {!disabled ? (
            <Chevron
              className={`${classes.chevron} ${
                isOpen ? classes.chevron__active : ''
              }`.trim()}
            />
          ) : null}
        </button>

        {isOpen && (
          <ul className={classes['options-list']}>
            {options.map((option, index) => (
              <li
                key={index}
                className={`${classes['option-item']} ${
                  option === selectedOption ? classes['selected'] : ''
                }`.trim()}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

RolesSelect.propTypes = {
  options: PropTypes.array,
  defaultOption: PropTypes.string,
  onOptionSelect: PropTypes.func,
  disabled: PropTypes.bool,
  userId: PropTypes.any,
  groupId: PropTypes.any,
}
