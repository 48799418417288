import { create } from 'zustand'

export const useArtistFeelsStore = create(set => ({
  artistName: null,
  options: [
    { id: 'alphabet', text: 'A-Z' },
    { id: 'recent', text: 'last updated' },
    { id: 'published', text: 'published date' },
  ],
  selectedOption: {
    id: 'alphabet',
    text: 'A-Z',
  },
  searchTerm: '',
  inputValue: '',
  setArtistName: newName => set({ artistName: newName }),
  setSelectedOption: newOption => set({ selectedOption: newOption }),
  setSearchTerm: newText => set({ searchTerm: newText }),
  setInputValue: newText => set({ inputValue: newText }),
}))