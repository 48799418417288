import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const Header = ({ children, className = '' }) => (
  <header className={`${classes.header} ${className}`.trim()}>
    {children}
  </header>
)

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
