import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { useArtistSelection } from '../../../Helpers/hooks/useArtistSelection'

export default function ArtistAndSongName({ song, badge }) {
  const artist = song.artist
  const { handleArtistSelection } = useArtistSelection()

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <h1 className={classes.songTitle}>{song?.name}</h1>
        {badge ? <div className={classes.badge}>{badge}</div> : null}
      </div>
      <button
        className={classes.artistWrapper}
        onClick={() => {
          handleArtistSelection(artist)
        }}
      >
        {song?.artist?.image?.url ? (
          <img className={classes.avatar} src={song?.artist?.image?.url} />
        ) : (
          <div className={classes.emptyAvatarWrapper}>
            <p className={classes.emptyAvatarLetter}>
              {song?.artist?.name.charAt(0).toUpperCase()}
            </p>
          </div>
        )}
        <p className={classes.artistName}>{song?.artist?.name}</p>
      </button>
    </div>
  )
}

ArtistAndSongName.propTypes = {
  song: PropTypes.object.isRequired,
  badge: PropTypes.node,
}
