import React from 'react'
import PropTypes from 'prop-types'
import RemovePopup from '../RemovePopup'
import classes from './index.module.css'
import usePopup from '../../hooks/usePopup'

export default function MyTeamsTableRow({ group, onDelete, zIndex }) {
  const { openPopup, isOpen, closePopup } = usePopup()
  return (
    <tr className={classes.row} style={{ zIndex, position: 'relative' }}>
      <td>
        <p className={`${classes.text}`}>{group.name}</p>
      </td>
      <td>
        <p className={`${classes.text}`}>{group.orgName}</p>
      </td>
      <td>
        <p className={`${classes.text}`}>{group.artists_count}</p>
      </td>
      <td>
        <p className={`${classes.text}`}>
          {group?.roles[0].role === 'Group Administrator'
            ? 'Team Administrator'
            : group.roles[0].role}
        </p>
      </td>
      <td>
        <button onClick={openPopup} className={`${classes.text}`}>
          ...
          <div className={classes.remove}>
            <RemovePopup
              closePopup={closePopup}
              isOpen={isOpen}
              action={() => {
                onDelete({
                  role:
                    group?.roles[0].role === 'Team Administrator'
                      ? 'Group Administrator'
                      : group.roles[0].role,
                  organization_users: [group.userId],
                  g_f_id: group.f_id,
                  o_f_id: group.orgId,
                })
                closePopup()
              }}
              title={'REMOVE TEAM ACCESS'}
            />
          </div>
        </button>
      </td>
    </tr>
  )
}

MyTeamsTableRow.propTypes = {
  group: PropTypes.object,
  onDelete: PropTypes.func,
  zIndex: PropTypes.any,
}
