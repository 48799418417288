import React, { useState, useRef } from 'react'
import { useCollectionsStore } from '../../../Storage/useCollectionStore'
import songIcon from '../../icons/collections_icons/songIcon.png'
import artistIcon from '../../icons/collections_icons/artistIcon.png'
import mutedButton from '../../icons/collections_icons/mutedButton.png'
import unmutedButton from '../../icons/collections_icons/unmutedButton.png'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { usePreventFullscreen } from '../../../Helpers/hooks/usePreventFullscreen'

export default function FeelItemCard({ feel, onClick, onDelete, isDragging }) {
  const videoElement = useRef(null)
  const [shouldShowVideo, setShouldShowVideo] = useState(false)
  const { feelsMuted, setFeelsMuted } = useCollectionsStore()
  usePreventFullscreen(videoElement)

  const handleDetailsClicked = () => {
    if (onClick) {
      onClick(feel)
    }
  }

  const handleOnDelete = () => {
    if (onDelete) {
      onDelete(feel)
    }
  }

  const handleOnTimeUpdate = () => {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  async function handleVideoPlay() {
    try {
      await videoElement.current.play()
    } catch (error) {
      // solution for error 'The play() request was interrupted by a call to pause()'
    }
  }

  function handleVideoPause() {
    if (!videoElement.current.paused) {
      videoElement.current.pause()
      videoElement.current.currentTime = 0
    }
  }

  return (
    <div
      className={classes.wrapper}
      style={{
        borderLeft: isDragging ? '1px dotted #d9d9d9' : 'none',
        borderBlock: isDragging ? '1px solid #8a8a8a' : 'none',
      }}
    >
      <div style={{ opacity: feel?.is_public_visible ? 1 : 0.2 }}>
        <div
          className={classes.feelImageContainer}
          onMouseOver={() => {
            setShouldShowVideo(true)
            if (videoElement.current) {
              handleVideoPlay()
            }
          }}
          onMouseLeave={() => {
            setShouldShowVideo(false)
            if (videoElement.current) {
              handleVideoPause()
            }
          }}
        >
          <img
            className={classes.feelImage}
            src={feel?.preview?.image?.image?.url}
            alt="image-preview"
          />
          {shouldShowVideo && (
            <video
              ref={videoElement}
              className={classes.feelVideo}
              controls={false}
              autoPlay={false}
              muted={feelsMuted}
              hidden={!shouldShowVideo}
              onTimeUpdate={() => {
                handleOnTimeUpdate()
              }}
              onClick={() => {
                handleDetailsClicked()
              }}
              src={feel?.preview?.video?.video?.url}
              controlsList="nofullscreen"
            />
          )}

          <img
            className={classes.playIcon}
            src={feelsMuted ? mutedButton : unmutedButton}
            hidden={!shouldShowVideo}
            onClick={() => {
              setFeelsMuted()
            }}
            alt="play-icon"
          />
        </div>

        <div
          className={classes.feelDetailsContainer}
          onClick={() => {
            handleDetailsClicked()
          }}
        >
          <span className={classes.lyricsTitle}>{feel.lyrics}</span>
          <div className={classes.itemInfoContainer}>
            <img className={classes.infoIcon} src={songIcon} alt="song-icon" />
            <span className={classes.itemInfoText}>{feel.song_name}</span>
          </div>
          <div className={classes.itemInfoContainer}>
            <img
              className={classes.infoIcon}
              src={artistIcon}
              alt="info-icon"
            />
            <span className={classes.itemInfoText}>{feel.artist.name}</span>
          </div>
        </div>
      </div>

      <div className={classes.actionsContainer}>
        <div
          className={classes.actionButton}
          onClick={() => handleDetailsClicked()}
        >
          <p className={classes.actionButtonText}>View Music Message</p>
        </div>
        <div className={classes.actionButton} onClick={() => handleOnDelete()}>
          <p className={classes.actionButtonTextRemove}>
            Remove from Collection
          </p>
        </div>
      </div>
    </div>
  )
}

FeelItemCard.propTypes = {
  feel: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  isDragging: PropTypes.bool,
}
