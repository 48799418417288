import React from 'react'
import PropTypes from 'prop-types'
import WaveForm from "./WaveForm"
import classes from './index.module.css'

export default function TeaseVideo({ videoUrl, videoElement }) {

  return (
    <div className={classes.wrapper}>
      <div className={classes.player}>
        <video ref={videoElement} id="video" src={videoUrl} />
      </div>
      <WaveForm videoElement={videoElement} videoUrl={videoUrl} />
    </div>
  )
}

TeaseVideo.propTypes = {
  videoUrl: PropTypes.string,
  videoElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}
