import { randomString } from '../../../Helpers/String+Extension'

export const processNewLyricsText = (textValue, lyrics = []) => {
  const newLyricsRaw = textValue.split('\n')
  const currentLyricsForTimings = [...lyrics].filter(item => item.line !== '')

  return newLyricsRaw.map(line => {
    if (line === '') {
      return { line: '', identifier: randomString(10) }
    }

    const timing = currentLyricsForTimings.length
      ? currentLyricsForTimings.shift()
      : {
          duration: 0,
          end_adj: 0,
          milliseconds: 0,
          start_adj: 0,
          t_end: 0,
          t_start: 0,
        }

    return {
      ...timing,
      line,
      identifier: randomString(10),
    }
  })
}
