import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useGetTeamDetails({ o_f_id, g_f_id }) {
  const { getTeamDetails } = useAPI()

  return useQuery({
    queryKey: ['organization', 'team', 'details', o_f_id, g_f_id],
    queryFn: () => getTeamDetails({ o_f_id, g_f_id }),
  })
}
