import { create } from 'zustand'

export const useCreateTeaseStore = create(set => ({

  defaultTease: {
    name: '',
    promotional_url: '',
    promotional_url_display_text: '',
    lyrics: '',
    video: null,
  },
  setSongData: songData => set({ songData }),
}))

