export function prepareHeaders() {
  let headers = new Headers()

  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json')

  headers.append('X-MMUF-API-Version', '1.0.0')
  headers.append('X-MMUF-Client', 'MMUF/1.0.0')

  return headers
}
