import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import errorToast from '../../Toasts/ErrorToast'
import uploadIcon from '../../icons/uploadIcon.svg'
import classes from './index.module.css'

export default function StickerUploader({
  setShowUploader,
  file,
  setFile,
  setPreviewSticker,
}) {
  const inputRef = useRef()

  const handleDragOver = event => {
    event.preventDefault()
  }

  const handleSaveCoverClick = () => {
    setFile(file)
    handleCloseUploader()
    setPreviewSticker(file)
  }

  const handleDrop = event => {
    event.preventDefault()
    event.stopPropagation()
    const droppedFiles = event.dataTransfer.files[0]
    const droppedFile = event.dataTransfer.files
    if (droppedFiles.type !== 'image/gif') {
      errorToast('Error: Sticker format must be a GIF')
      return
    }
    const reader = new FileReader()
    reader.onload = function (e) {
      const img = new Image()
      img.onload = function () {
        if (img.width !== 200 || img.height !== 200) {
          errorToast('Error: Sticker must be 200x200 pixels')
        } else {
          setFile(droppedFile)
        }
      }
      img.src = e.target.result
    }
    reader.readAsDataURL(droppedFiles)
  }

  const handleCloseUploader = () => {
    setShowUploader(false)
  }

  const handleInnerClick = e => {
    e.stopPropagation()
  }

  const validateAndSetFile = file => {
    if (file[0].type !== 'image/gif') {
      errorToast('Error: Sticker format must be a GIF')
      return
    }
    const reader = new FileReader()
    reader.onload = function (e) {
      const img = new Image()
      img.onload = function () {
        if (img.width !== 200 || img.height !== 200) {
          errorToast('Error: Sticker must be 200x200 pixels')
        } else {
          setFile(file)
        }
      }
      img.src = e.target.result
    }
    reader.readAsDataURL(file[0])
  }

  return (
    <>
      {!file && (
        <div
          className={classes.uploaderBgContainer}
          onClick={handleCloseUploader}
        >
          <div onClick={handleInnerClick} className={classes.uploaderContainer}>
            <div className={classes.uploaderHeader}>
              <span className={classes.uploaderTitle}>SMART STICKER</span>
              <span className={classes.uploaderSubtitle}>
                Each sticker must be 200 x 200 GIF, transparent background
              </span>
            </div>
            <div
              className={classes.uploaderWrapper}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                accept="image/gif"
                type="file"
                onChange={event => validateAndSetFile(event.target.files)}
                hidden
                ref={inputRef}
              />
              <div className={classes.uploaderImageContainer}>
                <img
                  className={classes.uploaderImage}
                  src={uploadIcon}
                  alt="upload_icon"
                />
              </div>
              <div className={classes.uploadTextSection}>
                <button
                  onClick={() => inputRef.current.click()}
                  className={classes.uploaderButton}
                >
                  Click to Upload
                </button>
                <span className={classes.uploaderText}> or Drag and Drop</span>
              </div>
            </div>
            <div className={classes.uploaderBtnGroup}>
              <button
                className={classes.uploaderCancel}
                onClick={handleCloseUploader}
              >
                Cancel
              </button>
              <button
                className={classes.uploaderSave}
                disabled={!file}
                onClick={handleSaveCoverClick}
              >
                Save Sticker
              </button>
            </div>
          </div>
        </div>
      )}
      {file && (
        <div
          className={classes.uploaderBgContainer}
          onClick={handleCloseUploader}
        >
          <div onClick={handleInnerClick} className={classes.uploaderContainer}>
            <div className={classes.uploaderHeader}>
              <span className={classes.uploaderTitle}>SMART STICKER</span>
              <span className={classes.uploaderSubtitle}>
                Each sticker must be 200 x 200 GIF, transparent background
              </span>
            </div>
            <div
              className={classes.uploaderWrapper}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {file && file.length > 0 && (
                <img
                  className={classes.uploadedImage}
                  src={URL.createObjectURL(file[0])}
                />
              )}
              <input
                accept="image/gif"
                type="file"
                onChange={event => setFile(event.target.files)}
                hidden
                ref={inputRef}
              />
              <div className={classes.uploaderImageContainer}>
                <img
                  className={classes.uploaderImage}
                  src={uploadIcon}
                  alt="upload_icon"
                />
              </div>
              <div className={classes.uploadTextSection}>
                <button
                  onClick={() => inputRef.current.click()}
                  className={classes.uploaderButton}
                >
                  Click to Upload
                </button>
                <span className={classes.uploaderText}> or Drag and Drop</span>
              </div>
            </div>
            <div className={classes.uploaderBtnGroup}>
              <button
                className={classes.uploaderCancel}
                onClick={handleCloseUploader}
              >
                Cancel
              </button>
              <button
                className={classes.uploaderSave}
                disabled={!file}
                onClick={handleSaveCoverClick}
              >
                Save Sticker
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

StickerUploader.propTypes = {
  setShowUploader: PropTypes.func,
  file: PropTypes.object,
  setFile: PropTypes.func,
  setPreviewSticker: PropTypes.func,
}
