import React from "react";
// import { useState } from 'react'
import "./Trivia.css";
import {
  getFirestore,
  doc,
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  setDoc,
  getDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import app from "../../firebase";
import { useState } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { useEffect } from "react";
import Loader from "../../Components/GeneralComponents/Loader";
import { useAPI } from "../../Contexts/APIContext";
import { useRef } from "react";

export default function Trivia() {
  const { getCurrentUser } = useAuth();
  const { fetchFeelsByStatus, fetchAllSongs, cancelRequest } = useAPI();

  const videoElement = useRef();

  // Initialize Cloud Firestore and get a reference to the service
  const db = getFirestore(app);

  const [participants, setParticipants] = useState([]);
  const [isUserInTheRoom, setIsUserInTheRoom] = useState(false);
  const [ownerId, setOwnerId] = useState(null);

  const [isPreparingGame, setIsPreparingGame] = useState(false);

  const [pageLimit] = useState(50);
  const [controller, setController] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [activeGameId, setActiveGameId] = useState(null);

  const [questions, setQuestions] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const currentQuestionRef = useRef(currentQuestion);
  currentQuestionRef.current = currentQuestion;

  const [submittedAnswer, setSubmittedAnswer] = useState(null);

  useEffect(() => {
    const unsubWaitroom = observeWaitroom();
    return () => {
      leaveWaitroom();
      unsubWaitroom();
    };
  }, []);

  //   useEffect(() => {
  //     let unsubGame
  //     if (activeGameId !== null) {
  //       unsubGame = observeGame(activeGameId)
  //     }
  //     return () => {
  //       if (unsubGame !== null) {
  //         unsubGame()
  //       }
  //     }
  //   }, [activeGameId])

  //////// ROOM MANAGEMENT - START ///////////

  function observeWaitroom() {
    return onSnapshot(doc(db, "trivia_waitroom", "waitroom"), (doc) => {
      const participants = doc?.data()?.participants;
      if (
        !doc.exists() ||
        participants === null ||
        participants === undefined
      ) {
        createWaitroom();
      } else {
        setOwnerId(doc.data().owner);
        setIsPreparingGame(doc.data().preparing_game);
        if (doc.data().active_game_id && activeGameId === null) {
          setActiveGameId(doc.data().active_game_id);
          getDataForGame(doc.data().active_game_id);
          observeGame(doc.data().active_game_id);
        } else {
          if (participants.length > 0) {
            setParticipants(participants);
          }
        }
        if (
          !checkIfThereIsPlaceInWaitroom(participants) &&
          isUserAnOwner(getCurrentUser.uid)
        ) {
          prepareGame();
        }
      }
    });
  }

  function checkIfThereIsPlaceInWaitroom(participants) {
    return participants.length < 5;
  }

  async function createWaitroom() {
    const waitroomRef = doc(db, "trivia_waitroom", "waitroom");

    const participants = [];
    await setDoc(waitroomRef, {
      participants,
    });
  }

  async function joinWaitroom(isUserOwner) {
    const waitroomRef = doc(db, "trivia_waitroom", "waitroom");

    const participants = arrayUnion({
      name: getCurrentUser.email,
      uid: getCurrentUser.uid,
    });
    await updateDoc(waitroomRef, {
      participants,
    }).then(() => {
      setIsUserInTheRoom(true);
      if (isUserOwner) {
        assignOwnershipOfTheRoomToUser(getCurrentUser.uid);
      }
    });
  }

  async function leaveWaitroom() {
    const waitroomRef = doc(db, "trivia_waitroom", "waitroom");

    if (
      participants !== undefined &&
      participants !== null &&
      participants.length > 1
    ) {
      if (isUserAnOwner(getCurrentUser.uid)) {
        assignOwnershipOfTheRoomToAnotherParticipant();
      }

      const participants = arrayRemove({
        name: getCurrentUser.email,
        uid: getCurrentUser.uid,
      });
      await updateDoc(waitroomRef, {
        participants,
      }).then(() => {
        setIsUserInTheRoom(false);
      });
    } else {
      deleteWaitroom();
    }
  }

  async function deleteWaitroom() {
    const waitroomRef = doc(db, "trivia_waitroom", "waitroom");
    await deleteDoc(waitroomRef).then(() => {
      setIsUserInTheRoom(false);
    });
  }

  function assignOwnershipOfTheRoomToAnotherParticipant() {
    const nextParticipantIndex = participants.findIndex((participant) => {
      return participant.uid !== getCurrentUser.uid;
    });
    const nextParticipant = participants[nextParticipantIndex];
    assignOwnershipOfTheRoomToUser(nextParticipant.uid);
  }

  async function assignOwnershipOfTheRoomToUser(id) {
    const waitroomRef = doc(db, "trivia_waitroom", "waitroom");

    await updateDoc(waitroomRef, {
      owner: id,
    });
  }

  function isUserAnOwner(id) {
    return ownerId === id;
  }

  function handleOnJoin() {
    if (
      participants.findIndex((item) => {
        item.uid === getCurrentUser.uid;
      }) === -1
    ) {
      joinWaitroom(participants.length === 0);
    }
  }

  function handleOnLeave() {
    if (isUserInTheRoom) {
      leaveWaitroom();
    }
  }

  //////// ROOM MANAGEMENT - END ///////////

  //////// GAME SETUP - START ///////////

  function handleOnStart() {
    prepareGame();
  }

  function prepareGame() {
    startGamePreparation();
    fetchFeels(
      (feels) => {
        fetchSongs(
          (songs) => {
            const questions = generateQuestions(feels, songs);
            startGameWithQuestions(questions);
          },
          () => {
            console.log("error");
          }
        );
      },
      () => {
        console.log("error");
      }
    );
  }

  async function startGamePreparation() {
    const waitroomRef = doc(db, "trivia_waitroom", "waitroom");

    await updateDoc(waitroomRef, {
      preparing_game: true,
    });
  }

  function fetchFeels(success, failure) {
    setErrorMessage(null);
    cancelRequest(controller);

    const newController = fetchFeelsByStatus(
      "",
      Math.floor(Math.random() * 150),
      pageLimit,
      (data) => {
        setController(null);
        data
          .json()
          .then((json) => {
            success(json.feels);
          })
          .catch((e) => {
            setErrorMessage(e.message);
            failure();
          });
      },
      (error) => {
        setController(null);
        setErrorMessage(error);
        failure();
      }
    );
    setController(newController);
  }

  function fetchSongs(success, failure) {
    setErrorMessage(null);
    cancelRequest(controller);

    const newController = fetchAllSongs(
      Math.floor(Math.random() * 50),
      pageLimit,
      (data) => {
        setController(null);
        data
          .json()
          .then((json) => {
            success(json.songs);
          })
          .catch((e) => {
            setErrorMessage(e.message);
            failure();
          });
      },
      (error) => {
        setController(null);
        setErrorMessage(error);
        failure();
      }
    );
    setController(newController);
  }

  function generateQuestions(feels, songs) {
    var questions = [];
    for (let i = 0; i < 6; i++) {
      const randomFeelIndex = Math.floor(Math.random() * (feels.length - 1));
      const randomFeel = feels[randomFeelIndex];

      var randomSongs = [
        {
          song_name: randomFeel?.song_name,
          artist_name: randomFeel?.song_artist_name,
        },
      ];
      for (let j = 0; j < 3; j++) {
        const randomSongIndex = Math.floor(Math.random() * (songs.length - 1));
        const randomSong = songs[randomSongIndex];
        randomSongs.push({
          song_name: randomSong?.name,
          artist_name: randomSong?.song_artist_name,
        });
      }
      shuffle(randomSongs);

      questions.push({
        question_number: i,
        answer_data: {
          song_name: randomFeel?.song_name,
          artist_name: randomFeel?.song_artist_name,
          preview: randomFeel?.preview,
        },
        question_data: randomSongs,
      });
    }
    return questions;
  }

  async function startGameWithQuestions(questions) {
    const participantsWithScores = [...participants];
    for (let i = 0; i < participantsWithScores.length; i++) {
      participantsWithScores[i].score = 0;
    }

    const docRef = await addDoc(collection(db, "trivia_active"), {
      questions: questions,
      participants: participantsWithScores,
    });

    const waitroomRef = doc(db, "trivia_waitroom", "waitroom");
    await updateDoc(waitroomRef, {
      preparing_game: false,
      active_game_id: docRef.id,
    });

    //Add timer
    setTimeout(() => {
      deleteWaitroom();
    }, 500);
  }
  //   const [timer, setTimer] = useState(null)
  //   clearInterval(timer)
  //   const newTimer = setInterval(() => {
  //     handleOnTimeUpdate()
  //   }, 30)
  //   setTimer(newTimer)

  //////// GAME SETUP - END ///////////

  //////// GAME - START ///////////

  async function getDataForGame(id) {
    await getDoc(doc(db, "trivia_active", id)).then((doc) => {
      const participants = doc?.data()?.participants;
      setParticipants(participants);
      const questions = doc?.data()?.questions;
      setQuestions(questions);
    });
  }

  useEffect(() => {
    if (questions !== null) {
      presentNextRoundAndStartTimer();
    }
  }, [questions]);

  function observeGame(id) {
    return onSnapshot(doc(db, "trivia_active", id), (doc) => {
      setParticipants(doc?.data()?.participants);
      if (doc?.data()?.is_game_ended) {
        setCurrentQuestion(null);
        setTheGameIsEnded(true);
      }
    });
  }

  const [roundTimer, setRoundTimer] = useState(null);
  const [timerTimer, setTimerTimer] = useState(null);

  const [startTime, setStarTime] = useState(null);
  const startTimeRef = useRef(startTime);
  startTimeRef.current = startTime;

  function presentNextRoundAndStartTimer() {
    //Get the next question
    if (
      currentQuestionRef.current === null ||
      currentQuestionRef.current === undefined
    ) {
      setCurrentQuestion(questions[0]);
      console.log(questions[0]);
    } else {
      let index = questions.findIndex((item) => {
        return (
          item.question_number === currentQuestionRef.current.question_number
        );
      });
      if (index >= questions.length - 1) {
        if (isUserAnOwner(getCurrentUser.uid)) {
          endTheGame();
        }
        return;
      } else {
        console.log(questions[index + 1]);
        setCurrentQuestion(questions[index + 1]);
      }
    }

    setShouldShowTheRightAnswer(false);

    //Start timer
    const startTime = new Date();
    setStarTime(startTime);
    //Reset the answer
    setSubmittedAnswer(null);
    //Start timer
    clearTimeout(roundTimer);
    const newTimer = setTimeout(() => {
      presentAnswersAndStartTimer();
    }, 10 * 1000);
    setRoundTimer(newTimer);

    clearTimeout(timerTimer);
    setTimerTime(10);
    updateTimer();
  }

  const [timerTime, setTimerTime] = useState(10);
  const timerTimeRef = useRef(timerTime);
  timerTimeRef.current = timerTime;

  function updateTimer() {
    if (timerTimeRef.current > 0) {
      setTimerTime(timerTimeRef.current - 1);

      clearTimeout(timerTimer);
      const newTimerTimer = setTimeout(() => {
        updateTimer();
      }, 1 * 1000);
      setTimerTimer(newTimerTimer);
    }
  }

  const [answerTimer, setAnswerTimer] = useState(null);

  function presentAnswersAndStartTimer() {
    if (isUserAnOwner(getCurrentUser.uid)) {
      scoreTheAnswers();
    }
    setShouldShowTheRightAnswer(true);
    clearInterval(answerTimer);
    const newTimer = setTimeout(() => {
      presentNextRoundAndStartTimer();
    }, 4 * 1000);
    setAnswerTimer(newTimer);
  }

  function getTimeToAnswerQuestion() {
    const endTime = new Date();
    var timeDiff = endTime - startTimeRef.current; //in ms
    // strip the ms
    // timeDiff /= 1000

    // // get seconds
    // var seconds = Math.round(timeDiff)
    return timeDiff;
  }

  async function handleOnAnswerSubmit(answer) {
    setSubmittedAnswer(answer);
    const triviaRef = doc(db, "trivia_active", activeGameId);

    const answers = arrayUnion({
      name: getCurrentUser.email,
      uid: getCurrentUser.uid,
      answer: answer,
      time: getTimeToAnswerQuestion(),
      question_number: currentQuestionRef.current.question_number,
    });
    await updateDoc(triviaRef, {
      answers,
    });
  }

  async function scoreTheAnswers() {
    const triviaRef = doc(db, "trivia_active", activeGameId);
    await getDoc(triviaRef).then(async (doc) => {
      const rightAnswer =
        doc?.data()?.questions[currentQuestionRef.current.question_number]
          ?.answer_data?.song_name;
      const answers = doc?.data()?.answers;
      const participants = doc?.data()?.participants;
      for (let i = 0; i < participants.length; i++) {
        if (answers === undefined || answers === null) {
          //No one answered it
          return;
        }
        let answerIndex = answers.findIndex((answer) => {
          return answer.uid === participants[i].uid;
        });
        if (answerIndex === -1) {
          //This participant didn't answer the question
          console.log("Get no points");
        } else {
          //Calculate the score based on the answer, and time
          if (rightAnswer === answers[answerIndex].answer) {
            participants[i].score += 50;
          }
        }
      }
      await updateDoc(triviaRef, {
        participants,
        answers: [],
      });
    });
  }

  const [showTheRightAnswer, setShouldShowTheRightAnswer] = useState(false);

  function shuffle(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  const [theGameIsEnded, setTheGameIsEnded] = useState(false);

  async function endTheGame() {
    setCurrentQuestion(null);
    setTheGameIsEnded(true);

    const triviaRef = doc(db, "trivia_active", activeGameId);

    await updateDoc(triviaRef, {
      is_game_ended: true,
    });
  }

  //////// GAME - END ///////////

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0;
      videoElement.current.play();
    }
  }

  return (
    <div className="trivia-wrapper">
      {!isPreparingGame && !activeGameId && (
        <>
          <div className="join-btn-container">
            {participants?.length > 0 ? (
              <span className="join-title-text">
                {"Participants in the room: " + participants.length}
              </span>
            ) : (
              <span className="join-title-text">No one in the room yet</span>
            )}
            {!isUserInTheRoom && (
              <button
                className="trivia-btn"
                onClick={() => {
                  handleOnJoin();
                }}>
                JOIN TRIVIA
              </button>
            )}
            {isUserInTheRoom && (
              <div className="owner-actions">
                {isUserAnOwner(getCurrentUser.uid) && (
                  <button
                    className="trivia-btn"
                    onClick={() => {
                      handleOnStart();
                    }}>
                    START GAME
                  </button>
                )}

                <button
                  className="trivia-btn"
                  onClick={() => {
                    handleOnLeave();
                  }}>
                  LEAVE
                </button>
              </div>
            )}
          </div>
          {isUserInTheRoom && participants?.length > 0 && (
            <div className="participants-waitroom-list">
              {participants.map((item, index) => (
                <span className="join-title-text" key={index}>
                  {item.name + (isUserAnOwner(item.uid) ? " - 😎" : "")}
                </span>
              ))}
            </div>
          )}
        </>
      )}
      {isPreparingGame && (
        <>
          {isUserInTheRoom ? (
            <div>
              {!errorMessage ? (
                <>
                  {<Loader isLoading={isPreparingGame} />}
                  <span className="join-title-text">
                    Game is about to start...
                  </span>
                </>
              ) : (
                <h1 style={{ color: 'white' }}>{errorMessage ? errorMessage : "No Results"}</h1>
              )}
            </div>
          ) : (
            <span className="join-title-text">
              The game is in progress. Try joining later.
            </span>
          )}
        </>
      )}
      {/* {activeGameId && questions?.questions?.length > 1 && (
        <>
          <div>
            {questions?.questions?.map((item, index) => (
              <span className="join-title-text" key={index}>
                {item.question_number}
              </span>
            ))}
          </div>
        </>
      )} */}
      {activeGameId && currentQuestionRef.current && (
        <>
          <div className="participants-container">
            <span className="join-title-text">{timerTime}</span>
            {participants.map((participant, index) => (
              <span className="join-title-text" key={index}>
                {participant.name + " - " + participant.score}
              </span>
            ))}
          </div>
          <div className="question-container">
            {/* <span className="join-title-text">
              {currentQuestionRef.current.question_number}
            </span> */}
            <video
              ref={videoElement}
              className="question-video-player"
              controls={false}
              autoPlay={true}
              muted={false}
              //   onLoadedMetadata={() => setLoadedMetadata(true)}
              onTimeUpdate={() => {
                handleOnTimeUpdate();
              }}
              src={
                currentQuestionRef.current.answer_data?.preview?.video?.video
                  ?.url
              }
            />
            <span className="join-title-text">What is the song name?</span>
            <div className="answer-buttons-container">
              {currentQuestionRef.current.question_data.map((item, index) => (
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  key={index}>
                  <button
                    className={
                      showTheRightAnswer
                        ? currentQuestionRef.current.answer_data.song_name ===
                          item.song_name
                          ? "correct-answer-btn"
                          : submittedAnswer === item.song_name
                            ? "wrong-answer-btn"
                            : "disabled-btn"
                        : submittedAnswer
                          ? submittedAnswer === item.song_name
                            ? "submitted-answer-btn"
                            : "disabled-btn"
                          : "trivia-btn answer-button"
                    }
                    onClick={() => {
                      if (submittedAnswer === null && !showTheRightAnswer) {
                        handleOnAnswerSubmit(item.song_name);
                      }
                    }}>
                    {item.song_name}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {theGameIsEnded && (
        <div className="participants-container">
          {participants.map((participant, index) => (
            <span className="join-title-text" key={index}>
              {participant.name + " - " + participant.score}
            </span>
          ))}
          <button
            className="trivia-btn"
            onClick={() => {
              window.location.reload(true);
            }}>
            START NEW GAME
          </button>
        </div>
      )}
    </div>
  );
}
