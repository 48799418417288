import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import Step from './Step'

export default function ManageOrganizationArtistsPopupFormStep({
  setData,
  data,
  handleManageGroupArtists,
  group,
}) {
  return (
    <div className={classes.wrapper}>
      <Step
        setData={setData}
        data={data}
        handleManageGroupArtists={handleManageGroupArtists}
        group={group}
      />
    </div>
  )
}

ManageOrganizationArtistsPopupFormStep.propTypes = {
  setData: PropTypes.func,
  handleManageGroupArtists: PropTypes.func,
  data: PropTypes.any,
  group: PropTypes.any,
}
