import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CollectionNameInput({
  name,
  setName,
  isCreate = false,
  initialValue,
  setInitialValue = () => {},
  setInitialFlag = () => {},
  setIsEdit = () => {},
}) {
  const [toggle, setToggle] = useState(true)
  const [text, setText] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    if (name !== null && !initialValue) {
      setInitialValue(name)
    }
  }, [name, initialValue])

  useEffect(() => {
    if (name === null) {
      setText('')
    } else if (name) {
      setText(name)
    }
  }, [name])

  useEffect(() => {
    isCreate && !text.length && setToggle(false)
  }, [isCreate, text])

  useEffect(() => {
    const handleClickOutside = event => {
      if (!text) {
        setIsEdit(true)
      }
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (text.length > 0) {
          setName(text)
          if (text !== initialValue) {
            setInitialFlag(true)
          } else {
            setInitialFlag(false)
          }
        } else {
          setName(null)
          setIsEdit(false)
        }
        setToggle(text.length > 0)
        setIsEdit(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [text, setName, initialValue])

  const toggleInput = () => {
    setToggle(false)
    setIsEdit(true)
  }

  const handleChange = event => {
    setText(event.target.value)
    setIsEdit(true)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (text.length > 0) {
        setName(text)
        setIsEdit(false)
        if (text !== initialValue) {
          setInitialFlag(true)
        } else {
          setInitialFlag(false)
        }
      }
      setToggle(text.length > 0)
    }
  }

  return (
    <div ref={inputRef}>
      {toggle ? (
        <span className={classes.spanName} onDoubleClick={toggleInput}>
          {name}
        </span>
      ) : (
        <input
          className={classes.inputName}
          type="text"
          placeholder="Collection name"
          value={text}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      )}
    </div>
  )
}

CollectionNameInput.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  setInitialValue: PropTypes.func,
  setName: PropTypes.func.isRequired,
  isCreate: PropTypes.bool,
  setIsEdit: PropTypes.func,
  setInitialFlag: PropTypes.func,
}
