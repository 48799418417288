import { useEffect, useState } from 'react'
import { useAuth } from '../../Contexts/AuthContext'
import { isEmailInSongManagementTeam } from '../../Helpers/RestrictedEmailsArray'
import useAdminAccess from '../../services/hooks/useAdminAccess'

const songManagementTeamLinks = [
  {
    name: 'Songs',
    iconSelected: './icons/feels-selected.png',
    iconUnselected: './icons/feels-unselected.png',
    alt: 'songs',
    route: '/dashboard',
    selectedIfMatchesRoutes: ['/dashboard', '/all', '/edit-song', '/song'],
  },
]

const adminLinks = [
  {
    name: 'Artists',
    iconSelected: './icons/artists-selected.png',
    iconUnselected: './icons/artists-unselected.png',
    alt: 'artists',
    route: '/artists',
    selectedIfMatchesRoutes: [
      '/artists',
      '/artist',
      '/albums',
      '/album',
      '/feels',
    ],
  },
  {
    name: 'Songs',
    iconSelected: './icons/feels-selected.png',
    iconUnselected: './icons/feels-unselected.png',
    alt: 'songs',
    route: '/songs/all',
    selectedIfMatchesRoutes: ['/all', '/edit-song', '/song'],
  },
  // {
  //   name: 'Music Messages',
  //   iconSelected: './icons/dashboard-selected.png',
  //   iconUnselected: './icons/dashboard-unselected.png',
  //   alt: 'music messages',
  //   route: '/dashboard',
  //   selectedIfMatchesRoutes: [
  //     '/dashboard',
  //     '/create-new-feel-step-1',
  //     '/create-new-feel-step-2',
  //     '/feel',
  //     '/feels',
  //   ],
  // },
  {
    name: 'Collections',
    iconSelected: './icons/collections-selected.svg',
    iconUnselected: './icons/collections-unselected.svg',
    alt: 'collections',
    route: '/collections',
    selectedIfMatchesRoutes: ['/collections'],
  },
  {
    name: 'Smart Stickers',
    iconSelected: './icons/stickers-selected.png',
    iconUnselected: './icons/stickers-unselected.png',
    alt: 'stickers',
    route: '/stickers',
    selectedIfMatchesRoutes: ['/stickers'],
  },
  {
    name: 'Data',
    iconSelected: './icons/data-selected.png',
    iconUnselected: './icons/data-unselected.png',
    alt: 'data',
    route: '/data/superset-dash',
    selectedIfMatchesRoutes: ['/data'],
  },
  {
    name: 'FAQs',
    iconSelected: './icons/faq-selected.png',
    iconUnselected: './icons/faq-unselected.png',
    alt: 'faq',
    route: '/faq',
    selectedIfMatchesRoutes: ['/faq'],
  },
]

const defaultLinks = [
  {
    name: 'Artists',
    iconSelected: './icons/artists-selected.png',
    iconUnselected: './icons/artists-unselected.png',
    alt: 'artists',
    route: '/artists',
    selectedIfMatchesRoutes: [
      '/artists',
      '/artist',
      '/albums',
      '/album',
      '/feels',
    ],
  },
  {
    name: 'Songs',
    iconSelected: './icons/feels-selected.png',
    iconUnselected: './icons/feels-unselected.png',
    alt: 'songs',
    route: '/songs/all',
    selectedIfMatchesRoutes: ['/all', '/edit-song', '/song'],
  },
  // {
  //   name: 'Music Messages',
  //   iconSelected: './icons/dashboard-selected.png',
  //   iconUnselected: './icons/dashboard-unselected.png',
  //   alt: 'music messages',
  //   route: '/dashboard',
  //   selectedIfMatchesRoutes: [
  //     '/dashboard',
  //     '/create-new-feel-step-1',
  //     '/create-new-feel-step-2',
  //     '/feel',
  //     '/feels',
  //   ],
  // },
  {
    name: 'Data',
    iconSelected: './icons/data-selected.png',
    iconUnselected: './icons/data-unselected.png',
    alt: 'data',
    route: '/data/superset-dash',
    selectedIfMatchesRoutes: ['/data'],
  },
  {
    name: 'FAQs',
    iconSelected: './icons/faq-selected.png',
    iconUnselected: './icons/faq-unselected.png',
    alt: 'faq',
    route: '/faq',
    selectedIfMatchesRoutes: ['/faq'],
  },
]
export default function useSidebar() {
  const { hasAdminAccess } = useAdminAccess()
  const { getCurrentUser } = useAuth()
  const isSongManagementTeam = isEmailInSongManagementTeam(
    getCurrentUser()?.email
  )

  const [sidebarLinks, setSidebarLinks] = useState([])

  const songManagementTeamLinksWithIndex = songManagementTeamLinks.map(
    (link, index) => ({ ...link, index })
  )
  const adminLinksWithIndex = adminLinks.map((link, index) => ({
    ...link,
    index,
  }))
  const defaultLinksWithIndex = defaultLinks.map((link, index) => ({
    ...link,
    index,
  }))

  useEffect(() => {
    if (isSongManagementTeam) {
      setSidebarLinks(songManagementTeamLinksWithIndex)
    } else if (hasAdminAccess && getCurrentUser().is_admin) {
      setSidebarLinks(adminLinksWithIndex)
    } else {
      setSidebarLinks(defaultLinksWithIndex)
    }

    return () => {
      setSidebarLinks([])
    }
  }, [hasAdminAccess, isSongManagementTeam])

  return { sidebarLinks }
}
