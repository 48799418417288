import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export function ModalWindow({ open, onClose, children }) {
  if (!open) return null
  return (
    <div
      className={classes.backgroundContainer}
      onClick={() => {
        onClose()
      }}
    >
      <div className={classes.container} onClick={e => e.stopPropagation()}>
        <button
          className={classes.closeButton}
          onClick={() => {
            onClose()
          }}
        />
        {children}
      </div>
    </div>
  )
}

ModalWindow.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}
