import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function TeaseFormTitle({ title }) {
  return (
    <div>
      <h2 className={classes.title}>{title}</h2>
      <p className={classes.text}>
        All uploaded content will be visible to users once published. Only
        upload content you want users to see. Display as a “Sneak Peak”.
      </p>
    </div>
  )
}

TeaseFormTitle.propTypes = {
  title: PropTypes.string,
}
