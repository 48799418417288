import { useEffect, useState } from 'react'

export default function useVisibleItems({ flag, ref, gap, maxRows = 2 }) {
  const [visibleItemsCount, setVisibleItemsCount] = useState(0)

  const calculateItemsPerRow = () => {
    if (ref.current && flag) {
      const containerWidth = ref.current.offsetWidth
      const items = Array.from(ref.current.children)

      if (items.length === 0) {
        setVisibleItemsCount(0)
        return
      }

      let totalItems = 0
      for (let i = 0; i < maxRows; i++) {
        let allItems = [...items]
        const totalRowItems = allItems.reduce(
          (acc, item, index) => {
            if (index === 0 && item.offsetWidth <= containerWidth) {
              acc.total = index + 1
              acc.width = item.offsetWidth
            } else if (item.offsetWidth + gap <= containerWidth - acc.width) {
              acc.total++
              acc.width += item.offsetWidth + gap
            }
            return acc
          },
          { total: 0, width: 0 }
        )

        allItems.splice(0, totalRowItems.total)
        totalItems = totalItems + totalRowItems.total
      }
      setVisibleItemsCount(totalItems)
    }
  }

  useEffect(() => {
    calculateItemsPerRow()
    window.addEventListener('resize', calculateItemsPerRow)

    return () => {
      window.removeEventListener('resize', calculateItemsPerRow)
    }
  }, [calculateItemsPerRow])

  return { visibleItemsCount }
}
