import React from 'react'
import PropTypes from 'prop-types'
import rightCircleIcon from '../../icons/right_circle.png'
import classes from './index.module.css'

export default function ButtonArrow({ text, onClick, disabled }) {
  const hasText = !!text
  return (
    <button disabled={disabled} className={classes.button} onClick={onClick}>
      {hasText && <p className={classes.buttonText}>{text}</p>}
      <img
        className={`${classes.buttonIcon} ${!hasText ? classes.noText : ''}`}
        src={rightCircleIcon}
        alt="Right Circle"
      />
    </button>
  )
}

ButtonArrow.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}
