/* eslint-disable react/prop-types */
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import EditLyrics from '../../../Components/DashboardComponents/Lyrics/EditLyrics'
import ArtistAndSongName from '../../../Components/GeneralComponents/ArtistAndSongName/ArtistAndSongName'
import Loader from '../../../Components/GeneralComponents/Loader'
import { randomString } from '../../../Helpers/String+Extension'
import { useAuth } from '../../../Contexts/AuthContext'
import errorToast from '../../../Components/Toasts/ErrorToast'
import successToast from '../../../Components/Toasts/SuccessToast'
import TeaseVideo from './TeaseVideo'
import useKeyboardEvents from '../hooks/useKeyboardEvents'
import { useFetchTease } from '../hooks/useFetchTease'
import deepEqual from 'deep-equal'
import diff from 'deep-diff'
import usePopup from '../hooks/usePopup'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { Navigation } from '../../../Components/page/Navigation'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import PublicationBadge from '../../../Components/GeneralComponents/PublicationBadge'
import CheckboxButton from '../../../Components/GeneralComponents/CheckboxButton'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import { renderBreadcrumbs } from './breadcrumbs'
import { Actions } from '../../../Components/page/Actions'
import OffsetActions from './OffsetActions'
import TextFields from './TextFields'
import classes from './index.module.css'
import { useSaveTeaseEdits } from '../hooks/useSaveTeaseEdits'
import { useQueryClient } from '@tanstack/react-query'
import { useCreateTease } from '../hooks/useCreateTease'
import { useEditTeaseLyrics } from '../hooks/useEditTeaseLyrics'
import CreateTeasePopupContent from './CreateTeasePopupContent'
import TeasePopup from '../TeasePopup'
import { useCreateTeaseVideo } from '../hooks/useCreateTeaseVideo'
import { useUploadTeaseVideo } from '../hooks/useUploadTeaseVideo'
import { isValidUrl } from '../helpers/isValidUrl'
import { useAPI } from '../../../Contexts/APIContext'
import PromptWithText from '../../../Components/DashboardComponents/Prompt/PromptWithText'
import useDeviceAccessControl from '../../../Helpers/hooks/useDeviceAccessControl'
import DevicesRestrictionsNotification from '../../../Components/common/DevicesRestrictionsNotification'

export default function EditTease() {
  const { getCurrentUser } = useAuth()
  const hasDeviceAccess = useDeviceAccessControl()

  const { id } = useParams()
  const videoElement = useRef()
  const initialTeaseRef = useRef()
  const [videoDuration, setVideoDuration] = useState(0)

  const { openPopup, closePopup, isOpen } = usePopup()
  const {
    openPopup: openFeedbackPrompt,
    closePopup: closeFeedbackPrompt,
    isOpen: isFeedbackPrompt,
  } = usePopup()
  const { reportSong } = useAPI()
  const queryClient = useQueryClient()
  const {
    mutate: saveTeaseEdits,
    isPending: isLoadingSaveTeaseEdits,
    isError: isErrorSaveTeaseEdits,
  } = useSaveTeaseEdits()
  const {
    mutate: createTease,
    isPending: isLoadingCreateTease,
    isError: isErrorCreateTease,
  } = useCreateTease()
  const {
    mutate: editTeaseLyrics,
    isPending: isLoadingEditTeaseLyrics,
    isError: isErrorEditTeaseLyrics,
  } = useEditTeaseLyrics()
  const {
    mutate: createTeaseVideo,
    isPending: isLoadingCreateTeaseVideo,
    isError: isErrorCreateTeaseVideo,
  } = useCreateTeaseVideo()
  const {
    mutate: uploadTeaseVideo,
    isPending: isLoadingUploadTeaseVideo,
    isError: isErrorUploadTeaseVideo,
  } = useUploadTeaseVideo()
  const [songData, setSongData] = useState(null)
  const [isLoopingLines, setIsLoopingLines] = useState(true)
  const [currentlyHighlightedLine, setCurrentlyHighlightedLine] = useState(null)
  const [currentlySelectedLine, setCurrentlySelectedLine] = useState(null)
  const [timer, setTimer] = useState(null)
  const [isTeaseChanged, setIsTeaseChanged] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [difference, setDifference] = useState(undefined)

  const isSyncVerified = songData?.is_sync_verified
  const currentVideoOffset = Number(songData?.lyrics_adj) || 0
  const userEmail = getCurrentUser()?.email
  const songFID = songData?.f_id
  const isFeelsLocked = songData?.feels_locked || false
  const promotional_url = songData?.promoting?.url
  const promotional_url_display_text = songData?.promoting?.url_display_text
  const artist_f_id = songData?.artist.f_id
  const name = songData?.name
  const artist = songData?.artist
  const lyrics = songData?.lyrics_sync

  const saveTeaseSyncArgs = {
    songFID,
    video_offset: currentVideoOffset,
    lyrics_sync: lyrics,
    is_sync_verified: isSyncVerified,
    sync_user_id: userEmail,
    feels_locked: isFeelsLocked,
  }

  const promotionalTeaseData = {
    name,
    name_full: name,
    promotional_url: promotional_url || null,
    promotional_url_display_text: promotional_url_display_text || null,
    artist_f_id,
    songFID,
  }

  const {
    data: teaseData,
    isError: teaseError,
    isLoading: teaseIsLoading,
    isFetching: isTeaseFetching,
  } = useFetchTease({
    tease_id: id,
  })

  const isTeaseUpdating =
    isLoadingSaveTeaseEdits ||
    isLoadingCreateTease ||
    isLoadingEditTeaseLyrics ||
    isLoadingCreateTeaseVideo ||
    isLoadingUploadTeaseVideo ||
    isTeaseFetching

  const setLyrics = lyrics_sync =>
    setSongData(prev => ({ ...prev, lyrics_sync }))

  const handleChanges = (...props) =>
    difference && [...new Set(difference)].some(item => props.includes(item))

  const onSyncVerifiedClick = () => {
    songData &&
      setSongData({ ...songData, is_sync_verified: !songData.is_sync_verified })
  }

  const handlePopupOpen = () => {
    openPopup()
    setIsEditing(true)
    videoElement?.current.pause()
  }
  const handlePopupClose = () => {
    closePopup()
    setIsEditing(false)
    videoElement?.current.play()
  }

  const onEditClick = () => {
    handlePopupOpen()
  }
  const onReportClick = () => {
    setIsEditing(true)
    openFeedbackPrompt()
  }

  const onSaveClick = () => {
    if (songData?.promoting?.url && !isValidUrl(songData?.promoting?.url)) {
      errorToast(
        'The URL you entered is not valid. Please provide a valid URL starting with http:// or https://'
      )
      return
    }
    if (handleChanges('lyrics')) {
      handleEditTeaseLyrics({
        payload: {
          lyrics_sync: JSON.parse(
            JSON.stringify(prepareLyricsToSave(lyrics))
          ).map(lyric => {
            lyric.t_start = lyric.t_start - currentVideoOffset
            lyric.t_end = lyric.t_end - currentVideoOffset
            lyric.milliseconds = lyric.milliseconds - currentVideoOffset
            lyric.lrc_timestamp = tToTimestamp(lyric.t_start)
            return lyric
          }),
          edit_user_id: userEmail,
        },
        tease_id: songFID,
      })
    }

    if (
      !handleChanges('lyrics') &&
      handleChanges('lyrics_sync', 'lyrics_adj', 'is_sync_verified')
    ) {
      handleSyncTease(saveTeaseSyncArgs)
    }

    if (handleChanges('name', 'name_full', 'promoting')) {
      handleUpdateTease(promotionalTeaseData)
    }

    if (handleChanges('video')) {
      songData.video.file &&
        handleCreateTeaseVideo({
          payload: {
            video_type: songData.video.video.video_type,
            filesize: songData.video.video.filesize,
            dimensions: songData.video.video.dimensions,
            orientation: 'vertical',
          },
          tease_id: songData.f_id,
        })
    }
  }

  // const onDoneClick = () => {
  //   setIsDone(true)
  //   onSaveClick()
  // }

  // useEffect(() => {
  //   if (!isTeaseUpdating && !isTeaseUpdateError && !isTeaseChanged && isDone) {
  //     navigate(`/tease/${songFID}`)
  //   }
  // }, [isTeaseUpdating, isTeaseUpdateError, isTeaseChanged, isDone])

  const onLoopLinesClick = () => {
    setIsLoopingLines(!isLoopingLines)
  }
  const onVideoOffsetValueChange = e => {
    let numericValue = e.target.value.replace(/\D/g, '')
    numericValue = numericValue.replace(/^0+(?=\d)/, '')

    setSongData({ ...songData, [e.target.name]: numericValue })
    setCurrentlyHighlightedLine(null)
  }

  const handleUploadTeaseVideo = videoData => {
    uploadTeaseVideo(videoData, {
      onSuccess: () => {
        successToast('Video uploaded successfully')

        queryClient.invalidateQueries(['tease', songFID])
      },
      onError: error => {
        errorToast('Error uploading video', error)
      },
    })
  }
  const handleCreateTeaseVideo = videoData => {
    createTeaseVideo(videoData, {
      onSuccess: video => {
        successToast('Video created successfully')
        handleUploadTeaseVideo({
          uploadURL: video.upload_url[0],
          file: songData.video.file,
        })
      },
      onError: error => {
        errorToast('Error creating video', error)
      },
    })
  }

  const handleSyncTease = lyricsData => {
    saveTeaseEdits(lyricsData, {
      onSuccess: async data => {
        successToast('Lyrics synchronized successfully')
        await queryClient.invalidateQueries(['tease', songFID])
      },
      onError: error => {
        errorToast('Error synchronizing lyrics', error)
      },
    })
  }

  const handleUpdateTease = teaseData => {
    createTease(teaseData, {
      onSuccess: () => {
        successToast('Tease updated successfully')

        queryClient.invalidateQueries(['tease', songFID])
      },
      onError: error => {
        errorToast('Error updating tease', error)
      },
    })
  }

  const handleEditTeaseLyrics = lyricsData => {
    editTeaseLyrics(lyricsData, {
      onSuccess: async () => {
        successToast('Lyrics updated successfully')
        await queryClient.invalidateQueries(['tease', songFID])
      },
      onError: error => {
        errorToast('Error updating lyrics', error)
      },
    })
  }

  useEffect(() => {
    if (!!songData) {
      deepEqual(initialTeaseRef.current, songData)
        ? setIsTeaseChanged(false)
        : setIsTeaseChanged(true)
    }
  }, [songData])

  useEffect(() => {
    return () => {
      clearInterval(timer)
      setTimer(null)
    }
  }, [])

  useEffect(() => {
    if (!!teaseData) {
      const newTease = JSON.parse(JSON.stringify(teaseData))
      const lyricsSync = [...newTease.lyrics_sync].map(item => {
        item.identifier = randomString(10)
        return item
      })
      const newTeaseWithLyricsIdentifier = {
        ...newTease,
        lyrics_sync: lyricsSync,
      }

      if (
        handleChanges('lyrics') &&
        handleChanges('lyrics_sync', 'lyrics_adj', 'is_sync_verified')
      ) {
        const saveTeaseSyncArgs = {
          songFID,
          video_offset: currentVideoOffset,
          lyrics_sync: lyricsSync,
          is_sync_verified: isSyncVerified,
          sync_user_id: userEmail,
          feels_locked: isFeelsLocked,
        }
        handleSyncTease(saveTeaseSyncArgs)
      }

      setSongData(JSON.parse(JSON.stringify(newTeaseWithLyricsIdentifier)))
      initialTeaseRef.current = JSON.parse(
        JSON.stringify(newTeaseWithLyricsIdentifier)
      )
    }
  }, [teaseData])

  useEffect(() => {
    if (songData && initialTeaseRef.current) {
      const diffEdits = diff(songData, initialTeaseRef.current)?.reduce(
        (acc, item) => item.path && [...acc, item?.path[0]],
        []
      )
      setDifference(diffEdits)
    }
  }, [songData, initialTeaseRef.current])

  useEffect(() => {
    return () => {
      clearInterval(timer)
      setTimer(null)
    }
  }, [])

  //Hot keys setup

  const { isShiftDown, isAltDown, isXDown } = useKeyboardEvents({
    lyrics,
    currentlySelectedLine,
    handleLyricSelected,
    onSaveClick,
    videoElement,
    isTeaseChanged,
    isLoopingLines,
    setIsLoopingLines,
    handleSetCurrentVideoPlayerToLineAction,
    handleIncrementDecrementButton,
    handleIncrementDecrementToLineAndFollowingLines,
    isEditing,
    songData,
    isOpen,
  })

  useEffect(() => {
    if (currentlySelectedLine !== null) {
      setVideoPlayerAtLine(currentlySelectedLine)
    }
  }, [currentVideoOffset])

  useEffect(() => {
    clearInterval(timer)
    const newTimer = setInterval(() => {
      handleOnTimeUpdate()
    }, 5)
    setTimer(newTimer)

    return () => {
      clearInterval(timer)
      setTimer(null)
    }
  }, [isLoopingLines, lyrics, currentlySelectedLine])

  function prepareLyricsToSave(newLyrics) {
    var lyricsToSave = []
    for (var i = 0; i < newLyrics.length; i++) {
      if (newLyrics[i].line === '') {
        lyricsToSave.push({ line: '' })
      } else {
        const startTime =
          currentVideoOffset +
          newLyrics[i].t_start +
          (newLyrics[i].start_adj !== undefined ? newLyrics[i].start_adj : 0)
        const endTime =
          currentVideoOffset +
          newLyrics[i].t_end +
          (newLyrics[i].end_adj !== undefined ? newLyrics[i].end_adj : 0)

        lyricsToSave.push({
          line: newLyrics[i].line,
          t_end: endTime,
          t_start: startTime,
          duration: newLyrics[i].duration,
          milliseconds: startTime,
          lrc_timestamp: tToTimestamp(startTime),
        })
      }
    }
    return lyricsToSave
  }

  function zeroPad(num, places) {
    return String(num).padStart(places, '0')
  }

  function tToTimestamp(t) {
    if (!t) {
      return '[00:00.00]'
    }

    let s_raw = Math.floor(t / 1000.0)

    let m_ts = Math.floor(s_raw / 60)
    let s_ts = s_raw % 60
    let ss_ts = Math.round(t - s_raw * 1000) / 10.0

    return `[${zeroPad(m_ts, 2)}:${zeroPad(s_ts, 2)}.${zeroPad(ss_ts, 2)}]`
  }

  function handleLyricSelected(line) {
    if (
      currentlySelectedLine !== null &&
      currentlySelectedLine.identifier === line.identifier
    ) {
      setCurrentlySelectedLine(null)
    } else {
      setCurrentlySelectedLine(line)
      setVideoPlayerAtLine(line)
    }
  }

  function setVideoPlayerAtLine(line) {
    const currentStartTime =
      currentVideoOffset +
      line.t_start +
      (line.start_adj !== undefined ? line.start_adj : 0)
    videoElement.current.currentTime = currentStartTime / 1000
  }

  function setVideoPlayerAtEndOfALine(line) {
    const currentEndTime =
      currentVideoOffset +
      line.t_end +
      (line.end_adj !== undefined ? line.end_adj : 0) -
      1000
    videoElement.current.currentTime = currentEndTime / 1000
  }

  function handleOnTimeUpdate() {
    if (videoElement.current === undefined || videoElement.current === null) {
      return
    }
    const line = lyrics.find(item => {
      const currentStartTime =
        currentVideoOffset +
        item.t_start +
        (item.start_adj !== undefined ? item.start_adj : 0)
      const currentEndTime =
        currentVideoOffset +
        item.t_end +
        (item.end_adj !== undefined ? item.end_adj : 0)
      return (
        videoElement.current.currentTime * 1000 > currentStartTime &&
        videoElement.current.currentTime * 1000 < currentEndTime
      )
    })
    if (line !== undefined) {
      setCurrentlyHighlightedLine(line)
    } else {
      setCurrentlyHighlightedLine(null)
    }

    if (currentlySelectedLine !== null && isLoopingLines) {
      const currentStartTime = getCurrentSelectedLineStartTime()
      const currentEndTime = getCurrentSelectedLineEndTime()
      // This is very handy for debugging of the line looping
      // console.log("START: " + currentStartTime)
      // console.log("END: " + currentEndTime)
      // console.log("CURRENT: " + Math.round((videoElement.current.currentTime + Number.EPSILON) * 1000) / 1000)
      if (
        Math.round((videoElement.current.currentTime + Number.EPSILON) * 1000) /
          1000 <
          currentStartTime / 1000 ||
        Math.round((videoElement.current.currentTime + Number.EPSILON) * 1000) /
          1000 +
          0.05 >
          currentEndTime / 1000
      ) {
        setVideoPlayerAtLine(currentlySelectedLine)
      }
    } else if (
      videoElement.current.currentTime >= videoElement.current.duration
    ) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  function getCurrentSelectedLineStartTime() {
    if (currentlySelectedLine === null || currentlySelectedLine === undefined) {
      return null
    }
    return (
      currentVideoOffset +
      currentlySelectedLine.t_start +
      (currentlySelectedLine.start_adj !== undefined
        ? currentlySelectedLine.start_adj
        : 0)
    )
  }

  function getCurrentSelectedLineEndTime() {
    if (currentlySelectedLine === null || currentlySelectedLine === undefined) {
      return null
    }
    return (
      currentVideoOffset +
      currentlySelectedLine.t_end +
      (currentlySelectedLine.end_adj !== undefined
        ? currentlySelectedLine.end_adj
        : 0)
    )
  }

  function handleSetCurrentVideoPlayerToLineAction() {
    const newVideoOffset = Math.floor(videoElement.current.currentTime * 1000)

    if (currentlySelectedLine === null) {
      setSongData({ ...songData, lyrics_adj: newVideoOffset })
      setCurrentlyHighlightedLine(null)
    } else {
      const indexOfSelectedLine = lyrics.indexOf(currentlySelectedLine)
      const newLineOffset =
        newVideoOffset -
        lyrics[indexOfSelectedLine].t_start -
        currentVideoOffset -
        (lyrics[indexOfSelectedLine].start_adj !== undefined
          ? lyrics[indexOfSelectedLine].start_adj
          : 0)
      var newLyrics = [...lyrics]
      newLyrics.splice(0, indexOfSelectedLine)
      for (var i = 0; i < newLyrics.length; i++) {
        let line = newLyrics[i]
        handleIncrementDecrementButton(line, true, false, newLineOffset, false)
        handleIncrementDecrementButton(line, false, false, newLineOffset, false)
      }
      setVideoPlayerAtLine(newLyrics[0])
    }
  }

  function handleIncrementDecrementToLineAndFollowingLines(
    line,
    isStart,
    isDecrement,
    value,
    setPlayer
  ) {
    if (currentlySelectedLine !== null) {
      const indexOfSelectedLine = lyrics.indexOf(currentlySelectedLine)
      var newLyrics = [...lyrics]
      newLyrics.splice(0, indexOfSelectedLine)
      for (var i = 0; i < newLyrics.length; i++) {
        let line = newLyrics[i]
        if (line.line !== '') {
          handleIncrementDecrementButton(
            line,
            isStart,
            isDecrement,
            value,
            setPlayer
          )
        }
      }
      setVideoPlayerAtLine(newLyrics[0])
    }
  }

  function handleIncrementDecrementButton(
    line,
    isStart,
    isDecrement,
    value,
    setPlayer
  ) {
    const indexOfSelectedLine = lyrics.indexOf(line)
    const newLyrics = [...lyrics]

    if (
      newLyrics[indexOfSelectedLine].start_adj === undefined ||
      newLyrics[indexOfSelectedLine].start_adj === null
    ) {
      newLyrics[indexOfSelectedLine].start_adj = 0
    }
    if (
      newLyrics[indexOfSelectedLine].end_adj === undefined ||
      newLyrics[indexOfSelectedLine].end_adj === null
    ) {
      newLyrics[indexOfSelectedLine].end_adj = 0
    }

    if (isStart) {
      if (isDecrement) {
        newLyrics[indexOfSelectedLine].start_adj -= value //isShiftDown ? 1000 : 100
      } else {
        newLyrics[indexOfSelectedLine].start_adj += value //isShiftDown ? 1000 : 100
      }
      if (setPlayer) {
        setVideoPlayerAtLine(newLyrics[indexOfSelectedLine])
      }
    } else {
      if (isDecrement) {
        newLyrics[indexOfSelectedLine].end_adj -= value //isShiftDown ? 1000 : 100
      } else {
        const newValue = newLyrics[indexOfSelectedLine].end_adj + value
        newValue + newLyrics[indexOfSelectedLine].t_end > videoDuration
          ? (newLyrics[indexOfSelectedLine].end_adj =
              videoDuration - newLyrics[indexOfSelectedLine].t_end)
          : (newLyrics[indexOfSelectedLine].end_adj = newValue)
      }
      if (setPlayer) {
        setVideoPlayerAtEndOfALine(newLyrics[indexOfSelectedLine])
      }
    }
    setLyrics(newLyrics)
  }

  function handleStartEndInputValueChange(line, value, isStartTime) {
    const indexOfSelectedLine = lyrics.indexOf(line)
    const newLyrics = [...lyrics]

    if (
      newLyrics[indexOfSelectedLine].start_adj === undefined ||
      newLyrics[indexOfSelectedLine].start_adj === null
    ) {
      newLyrics[indexOfSelectedLine].start_adj = 0
    }
    if (
      newLyrics[indexOfSelectedLine].end_adj === undefined ||
      newLyrics[indexOfSelectedLine].end_adj === null
    ) {
      newLyrics[indexOfSelectedLine].end_adj = 0
    }

    if (isStartTime) {
      const adj =
        value -
        newLyrics[indexOfSelectedLine].t_start -
        newLyrics[indexOfSelectedLine].start_adj -
        currentVideoOffset
      newLyrics[indexOfSelectedLine].start_adj += adj
    } else {
      const duration = value > videoDuration ? videoDuration : value
      const adj =
        duration -
        newLyrics[indexOfSelectedLine].t_end -
        newLyrics[indexOfSelectedLine].end_adj -
        currentVideoOffset
      newLyrics[indexOfSelectedLine].end_adj += adj
    }
    setLyrics(newLyrics)
  }

  function handleOnReport(comment) {
    closeFeedbackPrompt()
    setIsEditing(false)
    errorToast('Submitting...')

    reportSong(
      id,
      comment,
      getCurrentUser()?.email,
      () => {
        successToast('Successfully reported this Tease - thank you!')
      },
      error => {
        errorToast(error)
      }
    )
  }

  useEffect(() => {
    const videoEl = videoElement.current

    const handleLoadedMetadata = () => {
      if (videoEl?.duration) {
        setVideoDuration(Math.floor(videoEl.duration) * 1000 - 1)
      }
    }

    if (videoEl) {
      videoEl.load()
      videoEl.addEventListener('loadedmetadata', handleLoadedMetadata)
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener('loadedmetadata', handleLoadedMetadata)
      }
    }
  }, [videoElement.current])

  return (
    <Wrapper>
      {isFeedbackPrompt && (
        <PromptWithText
          title={'Describe the issue with this tease'}
          subtitle={''}
          primaryAction={'CANCEL'}
          onPrimaryClick={() => {
            closeFeedbackPrompt()
            setIsEditing(false)
          }}
          secondaryAction={'SEND'}
          onSecondaryClick={comment => {
            handleOnReport(comment)
          }}
          isSecondaryActionDestructive={false}
          onCloseClick={() => {
            closeFeedbackPrompt()
            setIsEditing(false)
          }}
        />
      )}
      <TeasePopup closePopup={handlePopupClose} isOpen={isOpen}>
        <CreateTeasePopupContent
          closePopup={handlePopupClose}
          artist={artist}
          songData={songData}
          setSongData={setSongData}
        />
      </TeasePopup>
      {!teaseIsLoading && songData && (
        <Header>
          <Navigation breadcrumbs={renderBreadcrumbs(songData)} />
          <HeaderInfo className={classes.HeaderInfo}>
            <ArtistAndSongName
              song={songData}
              badge={
                <PublicationBadge size="md" status={'tease'} text={'Tease'} />
              }
            />
            <Actions>
              {isTeaseUpdating ? (
                <Loader isLoading={isTeaseUpdating} />
              ) : (
                <>
                  <CheckboxButton
                    onClick={onSyncVerifiedClick}
                    condition={songData.is_sync_verified}
                    label={'Sync Verified'}
                  />
                  <ButtonOutline
                    color={'pink'}
                    size="small"
                    text={'EDIT CONTENT'}
                    onClick={onEditClick}
                  />
                  <ButtonOutline
                    color={'red'}
                    size="small"
                    text={'REPORT'}
                    onClick={onReportClick}
                  />
                  <ButtonOutline
                    color={'pink'}
                    size="small"
                    text={'SAVE'}
                    onClick={onSaveClick}
                    disable={!isTeaseChanged}
                  />
                </>
              )}
            </Actions>
          </HeaderInfo>
        </Header>
      )}

      {!teaseIsLoading && songData && songData?.video?.video?.url ? (
        <section className={classes.content}>
          {hasDeviceAccess ? (
            <div className={classes.content__data}>
              <div className={classes.actions}>
                <OffsetActions
                  onVideoOffsetValueChange={onVideoOffsetValueChange}
                  currentlySelectedLine={currentlySelectedLine}
                  onLoopLinesClick={onLoopLinesClick}
                  isLoopingLines={isLoopingLines}
                  tease={songData}
                  handleSetCurrentVideoPlayerToLineAction={
                    handleSetCurrentVideoPlayerToLineAction
                  }
                />
              </div>
              <div className={classes.lyrics}>
                <div className={classes.lyrics__content}>
                  <EditLyrics
                    onLyricSelected={line => {
                      handleLyricSelected(line)
                    }}
                    videOffset={currentVideoOffset}
                    lyrics={lyrics}
                    currentlySelectedLine={currentlySelectedLine}
                    currentlyHighlightedLine={currentlyHighlightedLine}
                    isShiftDown={isShiftDown}
                    isAltDown={isAltDown}
                    isXDown={isXDown}
                    isEnabled={true}
                    handleIncrementDecrementButton={(
                      line,
                      isStart,
                      isDecerement,
                      isShiftDown,
                      isAltDown,
                      isXDown
                    ) => {
                      handleIncrementDecrementButton(
                        line,
                        isStart,
                        isDecerement,
                        isShiftDown
                          ? 1000
                          : isAltDown
                          ? 20
                          : isXDown
                          ? 300
                          : 100,
                        true
                      )
                    }}
                    handleStartEndInputValueChange={(
                      line,
                      value,
                      isStartTime
                    ) => {
                      handleStartEndInputValueChange(line, value, isStartTime)
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <DevicesRestrictionsNotification />
          )}

          {hasDeviceAccess ? (
            <div className={classes.content__video}>
              <TeaseVideo
                videoElement={videoElement}
                videoUrl={songData.video.video.url}
              />
            </div>
          ) : null}
        </section>
      ) : (
        <>
          {!teaseIsLoading && !teaseData && (
            <h1 style={{ color: 'white' }}>
              Something went wrong. Please try reloading the page.
            </h1>
          )}
          {teaseIsLoading && <Loader isLoading={teaseIsLoading} />}
        </>
      )}
    </Wrapper>
  )
}
