import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function PageTitle({ title }) {
  return <h1 className={classes.title}>{title}</h1>
}

PageTitle.propTypes = {
  title: PropTypes.string,
}
