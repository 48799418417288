import React from 'react'
import classes from './index.module.css'
import Loader from '../../../Components/GeneralComponents/Loader'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import { Actions } from '../../../Components/page/Actions'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'

import PageTitle from '../components/PageTitle'
import { useParams } from 'react-router-dom'
import { useGetOrgDetails } from '../hooks/useGetOrgDetails'
import { useGetOrganizationGroups } from '../hooks/useGetOrganizationGroups'
import { useGetOrgUsers } from '../hooks/useGetOrgUsers'
import UsersTable from '../components/UsersTable'
import useAdminAccess from '../../../services/hooks/useAdminAccess'
import { getUserRole } from '../../../services/helpers/getUserRole.'
import { groupRoles, orgRoles } from '../../../constants/roles'
import { useFetchAccount } from '../../Organization/hooks'
import OrganizationsPopup from '../components/OrganizationsPopup'
import usePopup from '../hooks/usePopup'
import AddAdminOrganizationPopupForm from '../components/AddAdminOrganizationPopupForm'
import { useDeleteOrganizationUser } from '../hooks/useDeleteOrganizationUser'
import successToast from '../../../Components/Toasts/SuccessToast'
import { useQueryClient } from '@tanstack/react-query'
import { useChangeOrganizationUsersRole } from '../hooks/useChangeOrganizationUsersRole'
import { Navigation } from '../../../Components/page/Navigation'
import { renderBreadcrumbs } from './breadcrumbs'
import useAccessLevel from '../hooks/useAccessLevel'

export default function OrganizationMembers() {
  const { id } = useParams()

  const { isInternalAdmin, hasOrgAdminAccess } = useAccessLevel({
    o_f_id: id,
  })

  const {
    openPopup: addAdminOpenPopup,
    closePopup: addAdminClosePopup,
    isOpen: addAdminIsOpen,
  } = usePopup()

  const queryClient = useQueryClient()

  const {
    data: userData,
    isError: userDataError,
    isLoading: userDataIsLoading,
    isFetching: userDataIsFetching,
  } = useFetchAccount()

  const currentUser = userData?.user
  const currentUserRole = getUserRole(currentUser) || ''
  const { hasAdminAccess } = useAdminAccess(currentUser)

  const {
    data: orgDetails,
    isError: orgDetailsError,
    isLoading: orgDetailsIsLoading,
    isFetching: orgDetailsFetching,
  } = useGetOrgDetails({
    id,
  })

  const {
    data: organizationGroups,
    isError: organizationGroupsError,
    isLoading: organizationGroupsIsLoading,
    isFetching: isOrganizationGroupsFetching,
  } = useGetOrganizationGroups({
    o_f_id: id,
  })

  const {
    data: orgUsers,
    isError: orgUsersError,
    isLoading: orgUsersIsLoading,
    isFetching: orgUsersIsFetching,
  } = useGetOrgUsers({
    o_f_id: id,
  })

  const groups = organizationGroups?.organization_groups

  const users = orgUsers?.organization_users

  const { mutate: deleteOrganizationUser } = useDeleteOrganizationUser()

  const handleDeleteOrganizationUser = data => {
    deleteOrganizationUser(data, {
      onSuccess: () => {
        successToast('User removed')
        queryClient.invalidateQueries(['organization'])
      },
      onError: error => {
        errorToast('Error removing User', error)
      },
    })
  }

  const { mutate: changeOrganizationUsersRole } =
    useChangeOrganizationUsersRole()

  const handleDeleteGroupUser = data => {
    const removeData = {
      ...data,
      o_f_id: id,
      _action: 'delete',
      role:
        data.role === 'Team Administrator' ? 'Group Administrator' : data.role,
    }
    changeOrganizationUsersRole(removeData, {
      onSuccess: () => {
        queryClient.invalidateQueries(['organization'])
        successToast('User updated')
      },
      onError: error => {
        errorToast('Error updating User', error)
      },
    })
  }
  const handleChangeOrganizationUsersRole = data => {
    const { oldRole, ...rest } = data
    const newData = {
      ...rest,
      o_f_id: id,
      _action: 'add',
      role:
        rest.role === 'Team Administrator' ? 'Group Administrator' : rest.role,
    }
    changeOrganizationUsersRole(newData, {
      onSuccess: () => {
        handleDeleteGroupUser({
          role: oldRole,
          organization_users: newData.organization_users,
          g_f_id: newData.g_f_id,
        })
      },
      onError: error => {
        errorToast('Error updating User', error)
      },
    })
  }

  if (
    userDataIsLoading ||
    orgDetailsIsLoading ||
    organizationGroupsIsLoading ||
    orgUsersIsLoading
  )
    return (
      <Loader
        isLoading={
          userDataIsLoading ||
          orgDetailsIsLoading ||
          organizationGroupsIsLoading ||
          orgUsersIsLoading
        }
      />
    )

  if (
    orgDetailsError ||
    organizationGroupsError ||
    orgUsersError ||
    userDataError
  ) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
            <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={classes.wrapper}>
      <OrganizationsPopup
        isOpen={addAdminIsOpen}
        closePopup={addAdminClosePopup}
      >
        <AddAdminOrganizationPopupForm
          closePopup={addAdminClosePopup}
          organization={orgDetails}
          groups={groups}
        />
      </OrganizationsPopup>
      <Header>
        {orgDetails ? (
          <Navigation breadcrumbs={renderBreadcrumbs(orgDetails)} />
        ) : null}
        <HeaderInfo>
          <PageTitle title={orgDetails?.name} />
          <Actions>
            <ButtonOutline
              disable={!isInternalAdmin && !hasOrgAdminAccess}
              color={'pink'}
              size="medium"
              text={'ADD ADMIN'}
              onClick={addAdminOpenPopup}
            />
          </Actions>
        </HeaderInfo>
      </Header>
      <main className={classes.main}>
        {users?.length ? (
          <div className={classes.section}>
            <div className={classes.section__header}>
              <h2 className={classes.section__title}>Members</h2>
            </div>
            <div className={classes.users}>
              <UsersTable
                isCurrentUserAnAdmin={hasAdminAccess}
                currentUserRole={currentUserRole}
                users={users}
                getUserRole={getUserRole}
                optionsOrg={orgRoles}
                optionsGroup={groupRoles}
                onChangeRole={handleChangeOrganizationUsersRole}
                onTeamUserDelete={handleDeleteGroupUser}
                onOrgUserDelete={handleDeleteOrganizationUser}
              />
            </div>
          </div>
        ) : null}
      </main>
    </Wrapper>
  )
}
