/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react'
import classes from './index.module.css'
import Loader from '../../../Components/GeneralComponents/Loader'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import { Actions } from '../../../Components/page/Actions'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import OptionsButton from '../../../Components/GeneralComponents/OptionsButton/OptionsButton'
import PageTitle from '../components/PageTitle'
import { useParams } from 'react-router-dom'
import { useGetOrgDetails } from '../hooks/useGetOrgDetails'
import { useGetOrganizationGroups } from '../hooks/useGetOrganizationGroups'
import { useGetOrgUsers } from '../hooks/useGetOrgUsers'
import { useGetOrgContent } from '../hooks/useGetOrgContent'
import GroupPreview from '../components/GroupPreview'
import ViewAll from '../components/ViewAll'
import ArtistPreview from '../components/ArtistPreview'
import useVisibleItems from '../hooks/useVisibleItems'
import UsersTable from '../components/UsersTable'
import useAdminAccess from '../../../services/hooks/useAdminAccess'
import { getUserRole } from '../../../services/helpers/getUserRole.'
import { groupRoles, orgRoles } from '../../../constants/roles'
import { useInView } from 'react-intersection-observer'
import { useFetchAccount } from '../../Organization/hooks'
import OrganizationsPopup from '../components/OrganizationsPopup'
import EditOrganizationPopupForm from '../components/EditOrganizationPopupForm'
import usePopup from '../hooks/usePopup'
import ManageOrganizationArtistsPopupForm from '../components/ManageOrganizationArtistsPopupForm'
import AddAdminOrganizationPopupForm from '../components/AddAdminOrganizationPopupForm'
import { useDeleteOrganizationUser } from '../hooks/useDeleteOrganizationUser'
import successToast from '../../../Components/Toasts/SuccessToast'
import { useQueryClient } from '@tanstack/react-query'
import CreateTeamPopupForm from '../components/CreateTeamPopupForm'
import { useChangeOrganizationUsersRole } from '../hooks/useChangeOrganizationUsersRole'
import useAccessLevel from '../hooks/useAccessLevel'

export default function OrganizationDetails() {
  const { id } = useParams()
  const artistsRef = useRef()
  const groupsRef = useRef()
  const [artistRef, artistInView] = useInView()

  const { isInternalAdmin, hasOrgAdminAccess, hasOrgEditAccess } =
    useAccessLevel({
      o_f_id: id,
    })

  const {
    openPopup: editOrgOpenPopup,
    closePopup: editOrgClosePopup,
    isOpen: editOrgIsOpen,
  } = usePopup()
  const {
    openPopup: manageArtistsOpenPopup,
    closePopup: manageArtistsClosePopup,
    isOpen: manageArtistsIsOpen,
  } = usePopup()
  const {
    openPopup: addAdminOpenPopup,
    closePopup: addAdminClosePopup,
    isOpen: addAdminIsOpen,
  } = usePopup()
  const {
    openPopup: addTeamOpenPopup,
    closePopup: addTeamClosePopup,
    isOpen: addTeamIsOpen,
  } = usePopup()
  const queryClient = useQueryClient()

  const {
    data: userData,
    isError: userDataError,
    isLoading: userDataIsLoading,
    isFetching: userDataIsFetching,
  } = useFetchAccount()

  const currentUser = userData?.user
  const currentUserRole = getUserRole(currentUser) || ''
  const { hasAdminAccess } = useAdminAccess(currentUser)

  const {
    data: orgDetails,
    isError: orgDetailsError,
    isLoading: orgDetailsIsLoading,
    isFetching: orgDetailsFetching,
  } = useGetOrgDetails({
    id,
  })

  const {
    data: organizationGroups,
    isError: organizationGroupsError,
    isLoading: organizationGroupsIsLoading,
    isFetching: isOrganizationGroupsFetching,
  } = useGetOrganizationGroups({
    o_f_id: id,
  })

  const {
    data: orgUsers,
    isError: orgUsersError,
    isLoading: orgUsersIsLoading,
    isFetching: orgUsersIsFetching,
  } = useGetOrgUsers({
    o_f_id: id,
  })
  const {
    data: orgContent,
    isError: orgContentError,
    isLoading: orgContentIsLoading,
    isFetching: orgContentIsFetching,
    fetchNextPage: orgContentFetchNextPage,
    hasNextPage: orgContentHasNextPage,
  } = useGetOrgContent({
    o_f_id: id,
    content_object: 'artist',
    n: 15,
  })

  const artists = orgContent?.pages.reduce((acc, page) => {
    return [...acc, ...page.artist]
  }, [])
  const allArtists = orgContent?.pages[0]?.artist_count || 0
  const groups = organizationGroups?.organization_groups
  const users = orgUsers?.organization_users
  const allUsers = orgUsers?.organization_users_count
  const { visibleItemsCount: artistsCount } = useVisibleItems({
    ref: artistsRef,
    gap: 15,
    flag: artists,
  })
  const { visibleItemsCount: groupsCount } = useVisibleItems({
    ref: groupsRef,
    gap: 15,
    flag: groups,
    maxRows: 1,
  })
  const { mutate: deleteOrganizationUser } = useDeleteOrganizationUser()

  const handleDeleteOrganizationUser = data => {
    deleteOrganizationUser(data, {
      onSuccess: () => {
        successToast('User removed')
        queryClient.invalidateQueries(['organization'])
      },
      onError: error => {
        errorToast('Error removing User', error)
      },
    })
  }

  const { mutate: changeOrganizationUsersRole } =
    useChangeOrganizationUsersRole()

  const handleDeleteGroupUser = data => {
    const removeData = {
      ...data,
      o_f_id: id,
      _action: 'delete',
      role:
        data.role === 'Team Administrator' ? 'Group Administrator' : data.role,
    }
    changeOrganizationUsersRole(removeData, {
      onSuccess: () => {
        queryClient.invalidateQueries(['organization'])
        successToast('User updated')
      },
      onError: error => {
        errorToast('Error updating User', error)
      },
    })
  }
  const handleChangeOrganizationUsersRole = data => {
    const { oldRole, ...rest } = data
    const newData = {
      ...rest,
      o_f_id: id,
      _action: 'add',
      role:
        rest.role === 'Team Administrator' ? 'Group Administrator' : rest.role,
    }
    changeOrganizationUsersRole(newData, {
      onSuccess: () => {
        handleDeleteGroupUser({
          role: oldRole,
          organization_users: newData.organization_users,
          g_f_id: newData.g_f_id,
        })
      },
      onError: error => {
        errorToast('Error updating User', error)
      },
    })
  }

  useEffect(() => {
    if (artistInView && orgContentHasNextPage) {
      orgContentFetchNextPage()
    }
  }, [artistInView, orgContentHasNextPage])

  if (
    orgDetailsIsLoading ||
    organizationGroupsIsLoading ||
    orgUsersIsLoading ||
    orgContentIsLoading
    // userDataIsLoading
  ) {
    return (
      <Loader
        isLoading={
          orgDetailsIsLoading ||
          organizationGroupsIsLoading ||
          orgUsersIsLoading ||
          orgContentIsLoading
          // userDataIsLoading
        }
      />
    )
  }

  if (
    userDataError ||
    organizationGroupsError ||
    orgUsersError ||
    orgContentError ||
    orgDetailsError
  ) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
            <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }
  return (
    <Wrapper className={classes.wrapper}>
      <OrganizationsPopup isOpen={addTeamIsOpen} closePopup={addTeamClosePopup}>
        <CreateTeamPopupForm organization={orgDetails} x />
      </OrganizationsPopup>
      <OrganizationsPopup isOpen={editOrgIsOpen} closePopup={editOrgClosePopup}>
        <EditOrganizationPopupForm
          closePopup={editOrgClosePopup}
          organization={orgDetails}
        />
      </OrganizationsPopup>
      <OrganizationsPopup
        isOpen={manageArtistsIsOpen}
        closePopup={manageArtistsClosePopup}
      >
        <ManageOrganizationArtistsPopupForm
          closePopup={manageArtistsClosePopup}
          organization={orgDetails}
          artists={artists}
        />
      </OrganizationsPopup>
      <OrganizationsPopup
        isOpen={addAdminIsOpen}
        closePopup={addAdminClosePopup}
      >
        <AddAdminOrganizationPopupForm
          closePopup={addAdminClosePopup}
          organization={orgDetails}
          groups={groups}
        />
      </OrganizationsPopup>
      <Header>
        <HeaderInfo>
          <PageTitle title={orgDetails?.name} />
          <Actions>
            <ButtonOutline
              disable={!hasOrgAdminAccess && !isInternalAdmin}
              color={'pink'}
              size="medium"
              text={'CREATE TEAM'}
              onClick={addTeamOpenPopup}
            />

            <ButtonOutline
              disable={!hasOrgAdminAccess && !isInternalAdmin}
              color={'pink'}
              size="medium"
              text={'ADD ADMIN'}
              onClick={addAdminOpenPopup}
            />
            <div style={{ marginTop: '6px', position: 'relative' }}>
              <OptionsButton
                disabled={false}
                options={[
                  {
                    title: 'EDIT ORGANIZATION',
                    func: editOrgOpenPopup,
                    disable:
                      !isInternalAdmin &&
                      !hasOrgAdminAccess &&
                      !hasOrgEditAccess,
                  },
                  {
                    title: 'MANAGE ARTISTS',
                    func: manageArtistsOpenPopup,
                    disable: !isInternalAdmin,
                  },
                ]}
              />
            </div>
          </Actions>
        </HeaderInfo>
      </Header>
      <main className={classes.main}>
        {groups?.length ? (
          <div className={classes.section}>
            <div className={classes.section__header}>
              <h2 className={classes.section__title}>Teams</h2>
              {groups.filter(group => group.name !== 'Organization').length >
              groupsCount ? (
                <ViewAll
                  path={`/organizations/${id}/teams`}
                  count={groups.length}
                />
              ) : null}
            </div>
            <ul className={classes.groups} ref={groupsRef}>
              {groups
                .filter(group => group.name !== 'Organization')
                .map((group, index) => (
                  <li
                    key={group.f_id}
                    style={{
                      visibility:
                        index + 1 > groupsCount ? 'hidden' : 'visible',
                      position: index + 1 > groupsCount ? 'absolute' : 'static',
                      top: index + 1 > groupsCount ? '-1000px' : 'unset',
                    }}
                  >
                    <GroupPreview
                      group={group}
                      disabled={!hasOrgAdminAccess && !isInternalAdmin}
                    />
                  </li>
                ))}
            </ul>
          </div>
        ) : null}
        {artists?.length ? (
          <div className={classes.section}>
            <div className={classes.section__header}>
              <h2 className={classes.section__title}>Associated Artists</h2>
              {allArtists > artistsCount ? (
                <ViewAll
                  path={`/organizations/${id}/artists`}
                  count={allArtists}
                />
              ) : null}
            </div>
            <ul className={classes.artists} ref={artistsRef}>
              {artists.map((artist, index) => {
                return (
                  <li
                    ref={artistRef}
                    key={artist.f_id}
                    style={{
                      visibility:
                        index + 1 > artistsCount ? 'hidden' : 'visible',
                      position:
                        index + 1 > artistsCount ? 'absolute' : 'static',
                      top: index + 1 > artistsCount ? '-1000px' : 'unset',
                    }}
                  >
                    <ArtistPreview artist={artist} />
                  </li>
                )
              })}
            </ul>
          </div>
        ) : null}
        {users?.length ? (
          <div className={classes.section}>
            <div className={classes.section__header}>
              <h2 className={classes.section__title}>Members</h2>
              <ViewAll path={`/organizations/${id}/members`} count={allUsers} />
            </div>
            <div className={classes.users}>
              <UsersTable
                isCurrentUserAnAdmin={hasAdminAccess}
                currentUserRole={currentUserRole}
                users={users}
                getUserRole={getUserRole}
                optionsOrg={orgRoles}
                optionsGroup={groupRoles}
                onChangeRole={handleChangeOrganizationUsersRole}
                onTeamUserDelete={handleDeleteGroupUser}
                onOrgUserDelete={handleDeleteOrganizationUser}
              />
            </div>
          </div>
        ) : null}
      </main>
    </Wrapper>
  )
}
