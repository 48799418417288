
export const renderBreadcrumbs = data => [
  {
    title: data?.name,
    link: `/organizations/${data?.f_id}`,
  },

  {
    title: 'Teams',
  },
]
