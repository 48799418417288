import React from 'react'
import PropTypes from 'prop-types'
import './FeelSectionButton.css'

export default function FeelSectionButton({
  onSelected,
  isSelected,
  title,
  amount,
}) {
  function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n
  }
  return (
    <div
      className={isSelected ? 'item item-selected' : 'item'}
      onClick={() => {
        onSelected(title)
      }}
    >
      <span className="text-number">{minTwoDigits(amount)} </span>
      <span className="text">{title}</span>
    </div>
  )
}

FeelSectionButton.propTypes = {
  onSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
}
