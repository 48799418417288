import React from 'react'
import PropTypes from 'prop-types'
import './Prompt.css'
import note from './note.png'
import { useState } from 'react'

export default function PromptWithText({
  title,
  subtitle,
  placeholder,
  primaryAction,
  onPrimaryClick,
  secondaryAction,
  onSecondaryClick,
  isSecondaryActionDestructive,
  onCloseClick,
}) {
  const [comment, setComment] = useState('')

  return (
    <div
      className="prompt-bg-container"
      onClick={(e) => {
        const dataView = document.getElementsByClassName('prompt-container')
        const dataViewRect = dataView[0].getBoundingClientRect()
        const contains =
          e.clientX >= dataViewRect.x &&
          e.clientX <= dataViewRect.x + dataViewRect.width &&
          e.clientY >= dataViewRect.y &&
          e.clientY <= dataViewRect.y + dataViewRect.height

        if (!contains && onCloseClick) {
          onCloseClick()
        }
      }}
    >
      <div className="prompt-container">
        <div className="prompt-title-subtitle-container">
          <div className="prompt-title-container">
            <img className="icon-image" src={note} />
            <p className="prompt-title-text">{title}</p>
          </div>
          {subtitle && <p className="prompt-subtitle-text">{subtitle}</p>}
          <input
            className="prompt-input-field"
            type="text"
            id="name"
            name="name"
            value={comment}
            placeholder={placeholder !== null && placeholder !== undefined ? placeholder : "Enter your comment here"}
            required
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="prompt-actions">
          <button
            className="prompt-action-button"
            onClick={() => {
              if (onPrimaryClick) {
                onPrimaryClick()
              }
            }}
          >
            {primaryAction}
          </button>
          {secondaryAction && (
            <button
              className={
                'prompt-action-button' +
                (isSecondaryActionDestructive
                  ? ' prompt-destructive-action-button'
                  : '')
              }
              onClick={() => {
                if (onSecondaryClick) {
                  onSecondaryClick(comment)
                }
              }}
            >
              {secondaryAction}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

PromptWithText.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  placeholder: PropTypes.string,
  primaryAction: PropTypes.string.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  secondaryAction: PropTypes.string,
  onSecondaryClick: PropTypes.func,
  isSecondaryActionDestructive: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
}
