import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { Breadcrumbs } from '../../../Components/GeneralComponents/Breadcrumbs/Breadcrumbs'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import { formatStringInNav } from '../../../Helpers/String+Extension'
import Loader from '../../../Components/GeneralComponents/Loader'
// import TagBubble from '../../Components/DashboardComponents/TagInputSection/TagBubble'
import TagInputSection from '../../../Components/DashboardComponents/TagInputSection'
import Prompt from '../../../Components/DashboardComponents/Prompt/Prompt'
import classes from './index.module.css'
import { StickyHeader } from '../../../Components/StickyHeader'
import {
  convertMillisecondsToSeconds,
  isPlural,
} from '../../../services/functions'
import { permissions } from '../../../constants/roles'
import useReadOnly from '../../../services/hooks/useReadOnly'
import { useGenerateTags } from '../CreateFeel/Step2/useGenerateTags'
import errorToast from '../../../Components/Toasts/ErrorToast'
import successToast from '../../../Components/Toasts/SuccessToast'
import { usePreventFullscreen } from '../../../Helpers/hooks/usePreventFullscreen'

export default function Feel() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const {
    getFeelData,
    publishFeel,
    unpublishFeel,
    deleteFeel,
    updateTagsOnFeel,
    getFeelTags,
  } = useAPI()
  const { id } = useParams()
  const videoElement = useRef()
  usePreventFullscreen(videoElement)

  const { isReadOnly: isFeelsReadOnly } = useReadOnly(permissions.r.feels_mm)

  const [isLoadinAction, setIsLoadingAction] = useState(false)
  const [isPromptDelete, setIsPromptDelete] = useState(false)
  const [suggestedTags, setSuggestedTags] = useState(null)

  // data feel
  const { data, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['FeelData', id],
    queryFn: () => getFeelData(id),
  })

  const { data: officialTags, isLoading: isFetchingOfficialTags } = useQuery({
    queryKey: ['officialTags', id],
    queryFn: () => getFeelTags({ feelFID: id }),
    enabled: !!data && !!id,
  })

  const { data: userTags, isLoading: isFetchingUserTags } = useQuery({
    queryKey: ['userTags', id],
    queryFn: () => getFeelTags({ feelFID: id, isUserTags: true }),
    enabled: !!data && !!id,
  })

  const { data: suggestedTagsList, isLoading: isFetchingSuggestedTags } =
    useQuery({
      queryKey: ['suggestedTags', id],
      queryFn: () => getFeelTags({ feelFID: id, isSuggestedTags: true }),
      enabled: !!data && !!id,
    })

  // unpublish feel
  const unpublishMutation = useMutation({
    mutationFn: () => unpublishFeel(id),
    onSuccess: data => {
      queryClient.clear()
      setIsLoadingAction(false)
    },
    onError: () => {
      setIsLoadingAction(false)
    },
  })

  const handleOnUnpublish = () => {
    setIsLoadingAction(true)
    unpublishMutation.mutate()
  }

  // publish feel
  const publishMutation = useMutation({
    mutationFn: () => publishFeel(id),
    onSuccess: data => {
      queryClient.clear()
      setIsLoadingAction(false)
    },
    onError: () => {
      setIsLoadingAction(false)
    },
  })

  const handleOnPublish = () => {
    setIsLoadingAction(true)
    publishMutation.mutate()
  }

  // delete feel
  const deleteMutation = useMutation({
    mutationFn: () => deleteFeel(id),
    onSuccess: data => {
      queryClient.clear()
      navigate(
        data?.is_promotional
          ? '/tease/feels/' + data?.song_f_id
          : '/song/feels/' + data?.song_f_id
      )
      setIsLoadingAction(false)
    },
    onError: () => {
      setIsPromptDelete(false)
      setIsLoadingAction(false)
    },
  })

  const handleOnDelete = () => {
    setIsLoadingAction(true)
    deleteMutation.mutate()
  }

  // update tags
  const updateTagsMutation = useMutation({
    mutationFn: newTags => updateTagsOnFeel(id, newTags),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['FeelData', id],
      })
      queryClient.invalidateQueries({
        queryKey: ['officialTags', id],
      })
      queryClient.invalidateQueries({
        queryKey: ['userTags', id],
      })
    },
  })

  const handleUpdateTags = newTags => {
    updateTagsMutation.mutate(newTags)
  }

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  const {
    mutate: generateSuggestedTags,
    isPending: isLoadingGenerateSuggestedTags,
    isError: isErrorGenerateSuggestedTags,
  } = useGenerateTags()

  const handleGenerateTags = data => {
    generateSuggestedTags(data, {
      onSuccess: data => {
        setSuggestedTags(data)
        successToast('Suggested tags generated successfully')
      },
      onError: error => {
        errorToast('Error generating Suggested tags', error)
      },
    })
  }

  useEffect(() => {
    suggestedTagsList && setSuggestedTags(suggestedTagsList)
  }, [suggestedTagsList])

  return (
    <div className={classes.wrapper}>
      {isPromptDelete && (
        <Prompt
          title={'Delete Music Message'}
          subtitle={
            'Are you sure you want to delete this Music Message? This action is permanent.'
          }
          primaryAction={'CANCEL'}
          onPrimaryClick={() => {
            setIsPromptDelete(false)
          }}
          secondaryAction={'DELETE'}
          onSecondaryClick={() => {
            handleOnDelete()
          }}
          isSecondaryActionDestructive={true}
          onCloseClick={() => {
            setIsPromptDelete(false)
          }}
        />
      )}
      {!isLoading && data && (
        <StickyHeader>
          <Breadcrumbs
            items={
              !data?.artist?.name
                ? [
                    {
                      text: data.is_promotional ? 'All Artists' : 'All Songs',
                      onClick: () => {
                        navigate(
                          data.is_promotional ? '/artists' : '/songs/all'
                        )
                      },
                    },
                  ]
                : [
                    {
                      text: data.is_promotional ? 'All Artists' : 'All Songs',
                      onClick: () => {
                        navigate(
                          data.is_promotional ? '/artists' : '/songs/all'
                        )
                      },
                    },
                    {
                      text: formatStringInNav(data?.artist?.name),
                      onClick: () => {
                        navigate('/artist/' + data.artist.artist_name)
                      },
                    },
                    {
                      text: data.is_promotional ? 'Teases' : 'Songs',
                      onClick: () => {
                        navigate(
                          data.is_promotional
                            ? '/teases/artist/' + data.artist.artist_name
                            : '/songs/artist/' + data.artist.artist_name
                        )
                      },
                    },
                    {
                      text: formatStringInNav(data?.song_name),
                      onClick: () => {
                        navigate(
                          data.is_promotional
                            ? '/tease/' + data.song_f_id
                            : '/song/' + data.song_f_id
                        )
                      },
                    },
                    {
                      text: `Music Messages For ${formatStringInNav(
                        data?.song_name
                      )}`,
                      onClick: () => {
                        navigate(
                          data.is_promotional
                            ? '/tease/feels/' + data?.song_f_id
                            : '/song/feels/' + data?.song_f_id
                        )
                      },
                    },
                    {
                      text: 'Music Message',
                      onClick: () => {},
                    },
                  ]
            }
          />
          <div className={classes.titleContainer}>
            <h1 className={classes.title}>View Music Message</h1>

            <div className={classes.buttonsWrapper}>
              {isLoadinAction ? (
                <div className={classes.buttonLoadingWrapper}>
                  <Loader isLoading={isLoadinAction} />
                </div>
              ) : (
                <>
                  <ButtonOutline
                    disable={isFeelsReadOnly}
                    color={'pink'}
                    size="small"
                    text={'EXPORT'}
                    onClick={() => {
                      navigate('/feel/' + data?.f_id + '/export')
                    }}
                  />

                  {data && !data?.is_published && (
                    <ButtonOutline
                      disable={isFeelsReadOnly}
                      color={'green'}
                      size="small"
                      text={'PUBLISH'}
                      onClick={() => {
                        handleOnPublish()
                      }}
                    />
                  )}
                  {data?.is_published && (
                    <ButtonOutline
                      disable={isFeelsReadOnly}
                      color={'red'}
                      size="small"
                      text={'UNPUBLISH'}
                      onClick={() => {
                        handleOnUnpublish()
                      }}
                    />
                  )}
                  <ButtonOutline
                    disable={isFeelsReadOnly}
                    color={'red'}
                    size="small"
                    text={'DELETE'}
                    onClick={() => {
                      setIsPromptDelete(true)
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </StickyHeader>
      )}

      {!isLoading && data ? (
        <>
          <div className={classes.contentWrapper}>
            <div className={classes.videoContainer}>
              <video
                ref={videoElement}
                className={classes.videoPlayer}
                controls
                autoPlay={true}
                muted={true}
                onTimeUpdate={() => {
                  handleOnTimeUpdate()
                }}
                src={data?.preview?.video?.video?.url}
                controlsList="nofullscreen"
              />
            </div>
            <div className={classes.tagsColumnWrapper}>
              <div className={classes.tagsWrapper}>
                <div className={classes.lyrics}>
                  <ul className={classes.lyrics__text}>
                    {data.lyrics_sync.map(({ line }, index) => {
                      return <li key={line + index}>{line}</li>
                    })}
                  </ul>
                  {
                    <p
                      className={classes.lyrics__duration}
                    >{`${convertMillisecondsToSeconds(
                      data.video_duration
                    )} second${isPlural(
                      convertMillisecondsToSeconds(data.video_duration)
                    )}`}</p>
                  }
                </div>
                <TagInputSection
                  disabled={
                    isFeelsReadOnly ||
                    updateTagsMutation.isPending ||
                    isFetchingOfficialTags ||
                    isFetchingUserTags ||
                    isFetchingSuggestedTags
                  }
                  officialTags={officialTags?.tags}
                  userTags={userTags?.tags_user}
                  suggestedTags={suggestedTags?.tags || []}
                  handleTagsUpdated={newTags => {
                    handleUpdateTags(newTags)
                  }}
                  isFetchingOfficialTags={isFetchingOfficialTags}
                  isFetchingUserTags={isFetchingUserTags}
                  isFetchingSuggestedTags={
                    isFetchingSuggestedTags || isLoadingGenerateSuggestedTags
                  }
                  handleSuggestionTagClick={updateTagsMutation}
                  retchSuggestedTags={handleGenerateTags}
                  song={data}
                  isTagsUpdatingSuccess={updateTagsMutation.isSuccess}
                />
              </div>
              {/* )} */}
            </div>
          </div>
        </>
      ) : (
        <>
          {isError && (
            <h1 className={classes.errorText}>
              {'Oops, something went wrong'}
            </h1>
          )}
          {isLoading && <Loader isLoading={isLoading} />}
        </>
      )}
    </div>
  )
}
