import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import CreateOrganizationArtistsList from '../CreateOrganizationArtistsList'
import TextField from '../../../../Components/GeneralComponents/TextField'
import { ReactComponent as SearchIcon } from '../../../../Components/icons/carbon_search.svg'

export default function CreateOrganizationPopupArtistsSearch({
  handleSetSearchString,
  artists,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  handleAddArtists,
  handleRemoveArtist,
  stepData,
  showOptions,
  hideOptions,
  isOptionsOpen,
}) {
  const inputRef = useRef(null)
  const listRef = useRef(null)

  const handleEsc = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      event.preventDefault()
      inputRef.current.blur()
      hideOptions()
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        hideOptions()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [listRef, inputRef])

  return (
    <div className={classes.search}>
      <div className={classes.search__input}>
        <TextField
          onChange={handleSetSearchString}
          isRequired
          variant={'rounded'}
          placeholder={'Search Artists'}
          type="text"
          startIcon={<SearchIcon />}
          onFocus={showOptions}
          inputRef={inputRef}
        />
      </div>
      {isOptionsOpen ? (
          <div className={classes.list} ref={listRef}>
            <CreateOrganizationArtistsList
              artists={artists}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
              handleAddArtists={handleAddArtists}
              handleRemoveArtist={handleRemoveArtist}
              stepData={stepData}
            />
          </div>
      ) : null}
    </div>
  )
}

CreateOrganizationPopupArtistsSearch.propTypes = {
  handleSetSearchString: PropTypes.func,
  artists: PropTypes.array,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleAddArtists: PropTypes.func,
  handleRemoveArtist: PropTypes.func,
  stepData: PropTypes.array,
  showOptions: PropTypes.func,
  hideOptions: PropTypes.func,
  isOptionsOpen: PropTypes.bool,
}
