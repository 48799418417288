import React from 'react'
import PropTypes from 'prop-types'
import TeaseFormTitle from '../../TeaseFormTitle'
import classes from './index.module.css'
import CreateTeaseForm from '../CreateTeaseForm'

export default function CreateTeasePopupContent({
  closePopup,
  artist,
  songData,
  setSongData,
}) {
  return (
    <div className={classes.wrapper}>
      <TeaseFormTitle title={'Create Tease Content'} />
      <CreateTeaseForm
        closePopup={closePopup}
        artist={artist}
        songData={songData}
        setSongData={setSongData}
      />
    </div>
  )
}

CreateTeasePopupContent.propTypes = {
  closePopup: PropTypes.func,
  setSongData: PropTypes.func,
  songData: PropTypes.object,
  artist: PropTypes.shape({
    artist_name: PropTypes.string,
    f_id: PropTypes.string,
  }),
}
