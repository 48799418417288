import { create } from 'zustand'

export const useFeelsStore = create(set => ({
  currentlySelectedSection: {
    status: '',
    title: 'Total',
    count: 0,
  },
  searchTerm: '',
  inputValue: '',
  setSearchTerm: newText => set({ searchTerm: newText }),
  setInputValue: newText => set({ inputValue: newText }),
  setCurrentlySelectedSection: newSection => set({ currentlySelectedSection: newSection }),
}))