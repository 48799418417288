import { create } from 'zustand'

export const useStickersAddStore = create(set => ({
  packName: null,
  packDescription: null,

  setPackName: newName => set({ packName: newName }),
  setPackDescription: newDesc => set({ packDescription: newDesc }),

}))
