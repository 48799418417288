import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../../../Contexts/AuthContext'
import { prepareHeaders } from '../../../Helpers/prepareHeaders'
import useAdminAccess from '../../../services/hooks/useAdminAccess'

const baseURL = process.env.REACT_APP_BACKEND_BASE_URL
const INCLUDE = 'include'

export function useFetchAccount() {
  const { currentAuthUser } = useAuth()
  const isAdmin = currentAuthUser?.is_admin
  const { hasAminAccess } = useAdminAccess()

  return useQuery({
    queryKey: isAdmin ? ['adminAccount'] : ['orgAccount'],
    queryFn: isAdmin ? fetchAdminAccount : fetchOrgAccount,
    refetchOnWindowFocus: false, // Optional
    select: data => {
      return data
    },
    enabled: !!currentAuthUser && hasAminAccess,
  })
}

export function useFetchUsers(ofID, user) {
  const isAdmin = user?.is_admin
  const { hasAminAccess } = useAdminAccess(user)

  return useQuery({
    queryKey: isAdmin ? ['adminUsers'] : ['orgUsers', ofID],
    queryFn: () => (isAdmin ? fetchAdminUserList() : fetchOrgUserList(ofID)),
    refetchOnWindowFocus: false, // Optional
    select: data => {
      return isAdmin ? data.admin_users : data.organization_users
    },
    enabled: (isAdmin || !!ofID) && hasAminAccess,
  })
}

async function fetchData(url, method, headers, body, credentials) {
  try {
    const newController = new AbortController()
    const signal = newController.signal
    const response = await fetch(url, {
      method,
      headers,
      body,
      signal,
      credentials,
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    throw error
  }
}

export async function fetchOrgAccount() {
  const url = `${baseURL}/organization/account`
  return fetchData(url, 'GET', prepareHeaders(), null, INCLUDE)
}

export async function fetchOrgUserList(orgFId) {
  const url = `${baseURL}/organization/${orgFId}/user`
  return fetchData(url, 'GET', prepareHeaders(), null, INCLUDE)
}

export async function fetchAdminAccount() {
  const url = `${baseURL}/admin/account`
  return fetchData(url, 'GET', prepareHeaders(), null, INCLUDE)
}

export async function fetchAdminUserList() {
  const url = `${baseURL}/admin/user`
  return fetchData(url, 'GET', prepareHeaders(), null, INCLUDE)
}
