const LENGTH_OF_STRING_IN_NAV_TITLE = 20

export function formatStringInNav(string) {
  return string.length > LENGTH_OF_STRING_IN_NAV_TITLE
    ? string.substring(0, LENGTH_OF_STRING_IN_NAV_TITLE - 3) + '...'
    : string
}

export function randomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
