import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { usePreventFullscreen } from '../../../../Helpers/hooks/usePreventFullscreen'

export default function AdaptiveVideo({
  videoRef = null,
  src,
  maxWidth,
  minWidth,
  aspectRatio = 0.56,
  ...rest
}) {
    usePreventFullscreen(videoRef)

  return (
    <div
      className={classes.video}
      style={{
        maxWidth: maxWidth + 'px',
        minWidth: minWidth + 'px',
        minHeight: minWidth / aspectRatio + 'px',
        aspectRatio,
      }}
    >
      <div className={classes.video__wrapper} style={{ aspectRatio }}>
        <video
          ref={videoRef}
          className={classes.video__player}
          src={src}
          {...rest}
          controlsList="nofullscreen"
        />
      </div>
    </div>
  )
}

AdaptiveVideo.propTypes = {
  videoRef: PropTypes.object,
  src: PropTypes.string.isRequired,
  maxWidth: PropTypes.number.isRequired,
  minWidth: PropTypes.number.isRequired,
  aspectRatio: PropTypes.number,
}
