import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { Title } from './Title'
import { SubTitle } from './SubTitle'


export const Heading = ({ title, subtitle }) => (
  <div className={classes.heading}>
    {title ? <Title title={title} /> : null}
    {subtitle ? <SubTitle subtitle={subtitle} /> : null}
  </div>
)

Heading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}
