import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

const typeToClass = {
  success: classes.greenButton,
  warning: classes.yellowButton,
  primary: classes.pinkButton,
}

export default function StatusBadge({ text, type }) {
  const buttonClass = typeToClass[type] || typeToClass['primary']
  return <div className={buttonClass}>{text}</div>
}

StatusBadge.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'primary']).isRequired,
  text: PropTypes.string.isRequired,
}
