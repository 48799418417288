import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './Contexts/AuthContext'
import { StorageProvider } from './Contexts/StorageContext'
import { EditDataProvider } from './Contexts/EditDataContext'
import { APIProvider } from './Contexts/APIContext'
import Router from './Router'
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from '@tanstack/react-query'
import './index.css'
import { ToastContainer } from 'react-toastify'
import errorToast from './Components/Toasts/ErrorToast'
import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
  // Unique errorToasts: ArtistEditData, CoverUploader, Backdrop, StickerPack
  queryCache: new QueryCache({
    onError: e => {
      errorToast(`Query error: ${e.status ?? ''} ${e.message ?? ''}`)
    },
  }),
  mutationCache: new MutationCache({
    onError: e => {
      errorToast(`Query mutation error: ${e.status ?? ''} ${e.message ?? ''}`)
    },
  }),
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthProvider>
        <APIProvider>
          <StorageProvider>
            <EditDataProvider>
              <Router />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
            </EditDataProvider>
          </StorageProvider>
        </APIProvider>
      </AuthProvider>
    </BrowserRouter>
  </QueryClientProvider>
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
