import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'


export const HeaderInfo = ({ children, className = '' }) => (
  <div className={`${classes.info} ${className}`.trim()}>{children}</div>
)

HeaderInfo.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
