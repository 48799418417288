import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { useSearchArtists } from '../../../hooks/useSearchArtists'
import { useGetArtists } from '../../../hooks/useGetArtists'
import CreateOrganizationSortedArtistsList from '../../CreateOrganizationSortedArtistsList'
import CreateOrganizationPopupArtistsCount from '../../CreateOrganizationPopupArtistsCount'
import ManageOrganizationPopupArtistsSearch from '../../CreateOrganizationPopupArtistsSearch'
import usePopup from '../../../hooks/usePopup'
import { useParams } from 'react-router-dom'

function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
export default function Step({
  setData,
  data,
  handleManageOrganizationArtists,
  organization,
}) {
  const [searchString, setSearchString] = useState('')
const {id} = useParams()
  const {
    openPopup: showOptions,
    closePopup: hideOptions,
    isOpen: isOptionsOpen,
  } = usePopup()

  const handleSetSearchString = debounce(
    e => setSearchString(e.target.value),
    500
  )

  const handleRemoveAllArtists = () => {
    setData([])
    const content_object_f_ids = data.map(({ f_id }) => f_id)
    handleManageOrganizationArtists({
      o_f_id: id,
      content_object: 'artist',
      is_cascade: true,
      content_object_f_ids,
      _action: 'delete',
    })
  }

  const handleAddArtists = artists => {
    const newArtists = Array.isArray(artists) ? artists : [artists]

    setData(prev => [
      ...new Map(
        [...prev, ...newArtists].map(item => [item.f_id, item])
      ).values(),
    ])

    const content_object_f_ids = [
      ...new Map(
        [...data, ...newArtists].map(item => [item.f_id, item])
      ).values(),
    ].map(({ f_id }) => f_id)

    handleManageOrganizationArtists({
      o_f_id: id,
      content_object: 'artist',
      is_cascade: true,
      content_object_f_ids,
      _action: 'add',
    })
  }

  const handleRemoveArtist = artist => {
    setData(prev => prev.filter(item => item.f_id !== artist.f_id))
    const content_object_f_ids = data
      .filter(item => item.f_id === artist.f_id)
      .map(({ f_id }) => f_id)
    handleManageOrganizationArtists({
      o_f_id: id,
      content_object: 'artist',
      is_cascade: true,
      content_object_f_ids,
      _action: 'delete',
    })
  }

  const {
    data: artistsList,
    isLoading: isArtistsListLoading,
    fetchNextPage: fetchNextArtists,
    hasNextPage: hasNextPageArtists,
    isFetchingNextPage: isFetchingNextPageArtists,
  } = useGetArtists()

  const {
    data: artistsSearchList,
    isLoading: isArtistsSearchListLoading,
    fetchNextPage: fetchNextSearchArtists,
    hasNextPage: hasNextPageSearchArtists,
    isFetchingNextPage: isFetchingNextPageSearchArtists,
  } = useSearchArtists(searchString.trim())

  const artists = searchString
    ? artistsSearchList?.pages.map(page => page.artists).flat()
    : artistsList?.pages.map(page => page.artists).flat()
  const fetchNextPage = searchString ? fetchNextSearchArtists : fetchNextArtists
  const hasNextPage = searchString
    ? hasNextPageSearchArtists
    : hasNextPageArtists

  const isFetchingNextPage = searchString
    ? isFetchingNextPageSearchArtists
    : isFetchingNextPageArtists

  const isLoading = searchString
    ? isArtistsSearchListLoading
    : isArtistsListLoading

  return (
    <div className={classes.wrapper}>
      <div className={classes.search}>
        <ManageOrganizationPopupArtistsSearch
          handleSetSearchString={handleSetSearchString}
          artists={artists}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          handleAddArtists={handleAddArtists}
          handleRemoveArtist={handleRemoveArtist}
          stepData={data}
          showOptions={showOptions}
          hideOptions={hideOptions}
          isOptionsOpen={isOptionsOpen}
        />
      </div>

      <div className={classes.count}>
        <CreateOrganizationPopupArtistsCount
          count={data.length}
          action={handleRemoveAllArtists}
        />
      </div>
      <div className={classes.sorted}>
        <CreateOrganizationSortedArtistsList
          artists={data}
          handleRemoveArtist={handleRemoveArtist}
        />
      </div>
    </div>
  )
}

Step.propTypes = {
  setData: PropTypes.func,
  handleManageOrganizationArtists: PropTypes.func,
  data: PropTypes.any,
  organization: PropTypes.any,
}
