import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useFetchTease({ tease_id, ...rest }) {
  const { fetchTease } = useAPI()

  return useQuery({
    queryKey: ['tease', tease_id],
    queryFn: () => fetchTease(tease_id),
    ...rest
  })
}
