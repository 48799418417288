import React from 'react'
import PropTypes from 'prop-types'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import classes from './index.module.css'

export default function TeaseFormActions({
  isNextButtonEnabled,
  onCancelClick,
  isLoading,
}) {
  return (
    <div className={classes.actions}>
      <ButtonOutline
        disable={!isNextButtonEnabled || isLoading}
        color={'pink'}
        size="small"
        text={'NEXT'}
        type="submit"
      />
      <ButtonOutline
        disable={isLoading}
        color={'red'}
        size="small"
        text={'CANCEL'}
        onClick={onCancelClick}
      />
    </div>
  )
}

TeaseFormActions.propTypes = {
  onCancelClick: PropTypes.func,
  isNextButtonEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}
