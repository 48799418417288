import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import { Breadcrumbs } from '../../../Components/GeneralComponents/Breadcrumbs/Breadcrumbs'
import EditLyrics from '../../../Components/DashboardComponents/Lyrics/EditLyrics'
import ArtistAndSongName from '../../../Components/GeneralComponents/ArtistAndSongName/ArtistAndSongName'
import Loader from '../../../Components/GeneralComponents/Loader'
import WaveSurfer from 'wavesurfer.js'
import checkmark_empty from '../../../Components/icons/checkmark_empty.png'
import checkmark_full from '../../../Components/icons/checkmark_full.png'
import classes from './index.module.css'
// import { Player, Controls } from '@lottiefiles/react-lottie-player'

import './EditSong.css'
import { useAPI } from '../../../Contexts/APIContext'
import {
  formatStringInNav,
  randomString,
} from '../../../Helpers/String+Extension'
import { useAuth } from '../../../Contexts/AuthContext'
import { isEmailInSongManagementTeam } from '../../../Helpers/RestrictedEmailsArray'
import PromptWithText from '../../../Components/DashboardComponents/Prompt/PromptWithText'
import errorToast from '../../../Components/Toasts/ErrorToast'
import successToast from '../../../Components/Toasts/SuccessToast'
import { StickyHeader } from '../../../Components/StickyHeader'
import { usePreventFullscreen } from '../../../Helpers/hooks/usePreventFullscreen'
import useDeviceAccessControl from '../../../Helpers/hooks/useDeviceAccessControl'
import DevicesRestrictionsNotification from '../../../Components/common/DevicesRestrictionsNotification'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { Actions } from '../../../Components/page/Actions'

export default function EditSong() {
  const { getCurrentUser } = useAuth()
  const navigate = useNavigate()
  const {
    fetchLyricsToEdit,
    saveSongEdits,
    saveSongLyricsEdits,
    reportSong,
    cancelRequest,
  } = useAPI()

  const { id } = useParams()

  const hasDeviceAccess = useDeviceAccessControl()

  const videoElement = useRef()
  const waveformElement = useRef()
  const videoColumnElement = useRef()
  const topOfLyricsColumnElement = useRef()
  usePreventFullscreen(videoElement)

  const [songData, setSongData] = useState(null)
  const [isSyncVerified, setIsSyncVerified] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [controller, setController] = useState(null)
  const [waveform, setWaveform] = useState(null)

  const [hasEdited, setHasEdited] = useState(false)
  const [isSavingSong, setIsSavingSong] = useState(false)
  const [isLoopingLines, setIsLoopingLines] = useState(true)

  const [isShiftDown, setIsShiftDown] = useState(false)
  const [isAltDown, setIsAltDown] = useState(false)
  const [isXDown, setIsXDown] = useState(false)

  const [currentlyHighlightedLine, setCurrentlyHighlightedLine] = useState(null)

  const [currentlySelectedLine, setCurrentlySelectedLine] = useState(null)
  const [videoOffset, setVideoOffset] = useState(0)
  const [originalLyrics, setOriginalLyrics] = useState([])
  const [lyrics, setLyrics] = useState([])

  let didAddEventListeners = false
  const currentVideoOffset = Number(videoOffset) || 0

  const [errorSavingMessage, setErrorSavingMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isFeedbackPrompt, setIsFeedbackPrompt] = useState(false)

  const [showLyricsEditText, setShowLyricsEditText] = useState(false)

  const [timer, setTimer] = useState(null)
  const [videoDuration, setVideoDuration] = useState(0)

  // console.log({ videoDuration })

  useEffect(() => {
    fetchData()
    return () => {
      clearInterval(timer)
      setTimer(null)
    }
  }, [])

  //Hot keys setup
  useEffect(() => {
    if (!didAddEventListeners && !isFeedbackPrompt) {
      document.onkeydown = e => {
        if (!showLyricsEditText) {
          e = e || window.event
          if (e.shiftKey) {
            setIsShiftDown(true)
          }
          if (e.key.toLowerCase() === 'z') {
            setIsAltDown(true)
          }
          if (e.key.toLowerCase() === 'x') {
            setIsXDown(true)
          }
          if (!showLyricsEditText && e.code === 'Space') {
            e.preventDefault()
          }
        }
      }

      document.onkeyup = e => {
        if (!showLyricsEditText && !isFeedbackPrompt) {
          e = e || window.event
          const indexOfSelectedLine = lyrics.indexOf(currentlySelectedLine)
          if (e.key === 'ArrowDown') {
            if (indexOfSelectedLine === -1 && lyrics.length > 0) {
              handleLyricSelected(lyrics[0])
            } else if (lyrics.length - 1 > indexOfSelectedLine) {
              for (var i = indexOfSelectedLine + 1; i < lyrics.length; i++) {
                if (lyrics[i].line !== '') {
                  handleLyricSelected(lyrics[i])
                  break
                }
              }
            }
          } else if (e.key === 'ArrowUp') {
            if (indexOfSelectedLine >= 1) {
              for (var j = indexOfSelectedLine - 1; j >= 0; j--) {
                if (lyrics[j].line !== '') {
                  handleLyricSelected(lyrics[j])
                  break
                }
              }
            } else if (indexOfSelectedLine === 0) {
              handleLyricSelected(lyrics[indexOfSelectedLine])
            }
          } else if (e.key.toLowerCase() === 's' && hasEdited) {
            saveEdits()
          } else if (e.key.toLowerCase() === 'm') {
            videoElement.current.muted = !videoElement.current.muted
          } else if (e.key.toLowerCase() === 'p') {
            if (videoElement.current.paused) {
              videoElement.current.play()
            } else {
              videoElement.current.pause()
            }
            videoElement.current.muted = !videoElement.current.muted
          } else if (e.key.toLowerCase() === 'y') {
            setIsLoopingLines(!isLoopingLines)
          } else if (e.key.toLowerCase() === 'u') {
            handleSetCurrentVideoPlayerToLineAction()
          } else if (
            (e.key.toLowerCase() === 'q' ||
              e.key.toLowerCase() === 'w' ||
              e.key.toLowerCase() === 'e' ||
              e.key.toLowerCase() === 'r') &&
            indexOfSelectedLine !== -1
          ) {
            handleIncrementDecrementButton(
              lyrics[indexOfSelectedLine],
              e.key.toLowerCase() === 'q' || e.key.toLowerCase() === 'w',
              e.key.toLowerCase() === 'q' || e.key.toLowerCase() === 'e',
              isShiftDown ? 1000 : isAltDown ? 20 : isXDown ? 300 : 100,
              true
            )
          } else if (
            (e.key.toLowerCase() === 'h' ||
              e.key.toLowerCase() === 'j' ||
              e.key.toLowerCase() === 'k' ||
              e.key.toLowerCase() === 'l') &&
            indexOfSelectedLine !== -1
          ) {
            handleIncrementDecrementToLineAndFollowingLines(
              lyrics[indexOfSelectedLine],
              e.key.toLowerCase() === 'h' || e.key.toLowerCase() === 'j',
              e.key.toLowerCase() === 'h' || e.key.toLowerCase() === 'k',
              isShiftDown ? 1000 : isAltDown ? 20 : isXDown ? 300 : 100,
              true
            )
          } else if (e.code === 'Space') {
            if (videoElement.current.paused) {
              videoElement.current.play()
            } else {
              videoElement.current.pause()
            }
            videoElement.current.muted = !videoElement.current.muted
          }
          if (e.key === 'Shift') {
            setIsShiftDown(false)
          }
          if (e.key.toLowerCase() === 'z') {
            setIsAltDown(false)
          }
          if (e.key.toLowerCase() === 'x') {
            setIsXDown(false)
          }
        }
      }
      didAddEventListeners = true
    }
    return () => {
      document.onkeydown = null
      document.onkeyup = null
    }
  }, [
    lyrics,
    songData,
    currentlySelectedLine,
    hasEdited,
    isLoopingLines,
    isShiftDown,
    isAltDown,
    isXDown,
    showLyricsEditText,
    isFeedbackPrompt,
  ])

  useEffect(() => {
    if (currentlySelectedLine !== null) {
      setVideoPlayerAtLine(currentlySelectedLine)
    }
  }, [videoOffset])

  useEffect(() => {
    setupWaveform()
  }, [songData])

  useEffect(() => {
    setupWaveform()
  }, [waveformElement])

  useEffect(() => {
    clearInterval(timer)
    const newTimer = setInterval(() => {
      handleOnTimeUpdate()
    }, 5)
    setTimer(newTimer)

    return () => {
      clearInterval(timer)
      setTimer(null)
    }
  }, [isLoopingLines, lyrics, currentlySelectedLine])

  function fetchData() {
    setErrorMessage(null)
    setErrorSavingMessage(null)
    cancelRequest(controller)
    setIsLoading(true)

    const newController = fetchLyricsToEdit(
      id,
      data => {
        setIsLoading(false)
        setController(null)
        data
          .json()
          .then(json => {
            setSongData(json)
            let lyrics = json.lyrics_sync.map(item => {
              const newItem = item
              newItem.identifier = randomString(10)
              return newItem
            })
            setLyrics(lyrics)
            setOriginalLyrics(lyrics)
            if (
              json.is_sync_verified !== undefined ||
              json.is_sync_verified !== null
            ) {
              setIsSyncVerified(json.is_sync_verified)
            }
            if (json.lyrics_adj) {
              setVideoOffset(json.lyrics_adj)
            }
          })
          .catch(e => {
            setErrorMessage(e.message)
          })
      },
      error => {
        setController(null)
        setIsLoading(false)
        setErrorMessage(error)
      }
    )
    setController(newController)
  }

  function saveEdits() {
    setVideoOffset(currentVideoOffset)
    setErrorSavingMessage(null)
    setErrorMessage(null)
    setIsSavingSong(true)
    cancelRequest(controller)

    const newController = saveSongEdits(
      id,
      currentVideoOffset,
      lyrics,
      isSyncVerified,
      songData?.feels_locked ?? false,
      getCurrentUser()?.email,
      data => {
        setIsSavingSong(false)
        setController(null)
        data
          .json()
          .then(json => {
            setHasEdited(false)
            setSongData(json)
            let lyrics = json.lyrics_sync.map(item => {
              const newItem = item
              newItem.identifier = randomString(10)
              return newItem
            })
            setLyrics(lyrics)
            setOriginalLyrics(lyrics)
            if (json.lyrics_adj) {
              setVideoOffset(json.lyrics_adj)
            }
          })
          .catch(e => {
            setErrorSavingMessage(e.message)
          })
      },
      error => {
        setErrorSavingMessage(error)
        setIsSavingSong(false)
        setController(null)
      }
    )
    setController(newController)
  }

  function saveLyricsEdits(newLyrics) {
    setErrorSavingMessage(null)
    setErrorMessage(null)
    setIsSavingSong(true)
    cancelRequest(controller)

    const lyricsToSave = prepareLyricsToSave(newLyrics)

    const newController = saveSongLyricsEdits(
      id,
      lyricsToSave,
      getCurrentUser()?.email,
      data => {
        setIsSavingSong(false)
        setController(null)
        data
          .json()
          .then(json => {
            setHasEdited(false)
            let lyrics = json.lyrics_sync.map(item => {
              const newItem = item
              newItem.identifier = randomString(10)
              return newItem
            })
            setLyrics(lyrics)
            setOriginalLyrics(lyrics)
            // Backend resets the video offset after the lyrics are changed. That's why in prepareLyricsToSave we added the video offset into the timings before sending it out.
            setVideoOffset(0)
            handleChangeOfEditMode(false)
          })
          .catch(e => {
            setErrorSavingMessage(e.message)
          })
      },
      error => {
        setErrorSavingMessage(error)
        setIsSavingSong(false)
        setController(null)
      }
    )
    setController(newController)
  }

  function prepareLyricsToSave(newLyrics) {
    var lyricsToSave = []
    for (var i = 0; i < newLyrics.length; i++) {
      if (newLyrics[i].line === '') {
        lyricsToSave.push({ line: '' })
      } else {
        const startTime =
          currentVideoOffset +
          newLyrics[i].t_start +
          (newLyrics[i].start_adj !== undefined ? newLyrics[i].start_adj : 0)
        const endTime =
          currentVideoOffset +
          newLyrics[i].t_end +
          (newLyrics[i].end_adj !== undefined ? newLyrics[i].end_adj : 0)

        lyricsToSave.push({
          line: newLyrics[i].line,
          t_end: endTime,
          t_start: startTime,
          duration: newLyrics[i].duration,
          milliseconds: startTime,
          lrc_timestamp: tToTimestamp(startTime),
        })
      }
    }
    return lyricsToSave
  }

  function zeroPad(num, places) {
    return String(num).padStart(places, '0')
  }

  function tToTimestamp(t) {
    if (!t) {
      return '[00:00.00]'
    }

    let s_raw = Math.floor(t / 1000.0)

    let m_ts = Math.floor(s_raw / 60)
    let s_ts = s_raw % 60
    let ss_ts = Math.round(t - s_raw * 1000) / 10.0

    return `[${zeroPad(m_ts, 2)}:${zeroPad(s_ts, 2)}.${zeroPad(ss_ts, 2)}]`
  }

  function setupWaveform() {
    if (
      waveformElement.current !== undefined &&
      videoElement !== undefined &&
      songData !== undefined &&
      waveform === null
    ) {
      const newWaveform = WaveSurfer.create({
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        barMinHeight: 1,
        cursorWidth: 2,
        container: waveformElement.current,
        backend: 'MediaElement',
        height: 80,
        responsive: true,
        interact: true,
        waveColor: '#C4C4C4',
        progressColor: '#FF00FF',
        cursorColor: 'white',
        hideScrollbar: true,
      })
      var mediaElt = videoElement.current
      newWaveform.load(mediaElt)
      videoElement.current.controls = 'controls'
      videoElement.current.muted = true
      videoElement.current.play()
      setWaveform(newWaveform)
    }
  }

  function handleLyricSelected(line) {
    if (
      currentlySelectedLine !== null &&
      currentlySelectedLine.identifier === line.identifier
    ) {
      setCurrentlySelectedLine(null)
    } else {
      setCurrentlySelectedLine(line)
      setVideoPlayerAtLine(line)
    }
  }

  function setVideoPlayerAtLine(line) {
    const currentStartTime =
      currentVideoOffset +
      line.t_start +
      (line.start_adj !== undefined ? line.start_adj : 0)
    videoElement.current.currentTime = currentStartTime / 1000
  }

  function setVideoPlayerAtEndOfALine(line) {
    const currentEndTime =
      currentVideoOffset +
      line.t_end +
      (line.end_adj !== undefined ? line.end_adj : 0) -
      1000
    videoElement.current.currentTime = currentEndTime / 1000
  }

  function handleOnTimeUpdate() {
    if (videoElement.current === undefined || videoElement.current === null) {
      return
    }
    const line = lyrics.find(item => {
      const currentStartTime =
        currentVideoOffset +
        item.t_start +
        (item.start_adj !== undefined ? item.start_adj : 0)
      const currentEndTime =
        currentVideoOffset +
        item.t_end +
        (item.end_adj !== undefined ? item.end_adj : 0)
      return (
        videoElement.current.currentTime * 1000 > currentStartTime &&
        videoElement.current.currentTime * 1000 < currentEndTime
      )
    })
    if (line !== undefined) {
      setCurrentlyHighlightedLine(line)
    } else {
      setCurrentlyHighlightedLine(null)
    }

    if (currentlySelectedLine !== null && isLoopingLines) {
      const currentStartTime = getCurrentSelectedLineStartTime()
      const currentEndTime = getCurrentSelectedLineEndTime()
      // This is very handy for debugging of the line looping
      // console.log("START: " + currentStartTime)
      // console.log("END: " + currentEndTime)
      // console.log("CURRENT: " + Math.round((videoElement.current.currentTime + Number.EPSILON) * 1000) / 1000)
      if (
        Math.round((videoElement.current.currentTime + Number.EPSILON) * 1000) /
          1000 <
          currentStartTime / 1000 ||
        Math.round((videoElement.current.currentTime + Number.EPSILON) * 1000) /
          1000 +
          0.05 >
          currentEndTime / 1000
      ) {
        setVideoPlayerAtLine(currentlySelectedLine)
        // videoElement.current.play()
      }
    } else if (
      videoElement.current.currentTime >= videoElement.current.duration
    ) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }

    // requestAnimationFrame(handleOnTimeUpdate)
  }

  function getCurrentSelectedLineStartTime() {
    if (currentlySelectedLine === null || currentlySelectedLine === undefined) {
      return null
    }
    return (
      currentVideoOffset +
      currentlySelectedLine.t_start +
      (currentlySelectedLine.start_adj !== undefined
        ? currentlySelectedLine.start_adj
        : 0)
    )
  }

  function getCurrentSelectedLineEndTime() {
    if (currentlySelectedLine === null || currentlySelectedLine === undefined) {
      return null
    }
    return (
      currentVideoOffset +
      currentlySelectedLine.t_end +
      (currentlySelectedLine.end_adj !== undefined
        ? currentlySelectedLine.end_adj
        : 0)
    )
  }

  function handleSetCurrentVideoPlayerToLineAction() {
    const newVideoOffset = Math.floor(videoElement.current.currentTime * 1000)

    if (currentlySelectedLine === null) {
      setVideoOffset(newVideoOffset)
      setHasEdited(true)
      setCurrentlyHighlightedLine(null)
    } else {
      const indexOfSelectedLine = lyrics.indexOf(currentlySelectedLine)
      const newLineOffset =
        newVideoOffset -
        lyrics[indexOfSelectedLine].t_start -
        currentVideoOffset -
        (lyrics[indexOfSelectedLine].start_adj !== undefined
          ? lyrics[indexOfSelectedLine].start_adj
          : 0)
      var newLyrics = [...lyrics]
      newLyrics.splice(0, indexOfSelectedLine)
      for (var i = 0; i < newLyrics.length; i++) {
        let line = newLyrics[i]
        handleIncrementDecrementButton(line, true, false, newLineOffset, false)
        handleIncrementDecrementButton(line, false, false, newLineOffset, false)
      }
      setVideoPlayerAtLine(newLyrics[0])
    }
  }

  function handleIncrementDecrementToLineAndFollowingLines(
    line,
    isStart,
    isDecrement,
    value,
    setPlayer
  ) {
    if (currentlySelectedLine !== null) {
      const indexOfSelectedLine = lyrics.indexOf(currentlySelectedLine)
      var newLyrics = [...lyrics]
      newLyrics.splice(0, indexOfSelectedLine)
      for (var i = 0; i < newLyrics.length; i++) {
        let line = newLyrics[i]
        if (line.line !== '') {
          handleIncrementDecrementButton(
            line,
            isStart,
            isDecrement,
            value,
            setPlayer
          )
        }
      }
      setVideoPlayerAtLine(newLyrics[0])
    }
  }

  function handleOnVideoOffsetChange(e) {
    let inputValue = e.target.value
    const testInputValue = /[^\d-]/.test(inputValue)
    if (
      videoOffset.toString() === '0' &&
      videoOffset.toString().length === 1 &&
      (inputValue === '-' || !testInputValue)
    ) {
      inputValue = inputValue.substring(1)
    }

    inputValue = inputValue.replace(/[^0-9-]/g, '')

    if (inputValue.lastIndexOf('-') > 0) {
      const index = inputValue.lastIndexOf('-')
      inputValue =
        inputValue.substring(0, index) + inputValue.substring(index + 1)
    }

    if (inputValue.length > 1) {
      inputValue = inputValue.replace(/^(-)?0+(?=\d)/, '$1')
    }

    setVideoOffset(inputValue)
    setHasEdited(true)
    setCurrentlyHighlightedLine(null)
  }

  function handleIncrementDecrementButton(
    line,
    isStart,
    isDecrement,
    value,
    setPlayer
  ) {
    const indexOfSelectedLine = lyrics.indexOf(line)
    const newLyrics = [...lyrics]

    if (
      newLyrics[indexOfSelectedLine].start_adj === undefined ||
      newLyrics[indexOfSelectedLine].start_adj === null
    ) {
      newLyrics[indexOfSelectedLine].start_adj = 0
    }
    if (
      newLyrics[indexOfSelectedLine].end_adj === undefined ||
      newLyrics[indexOfSelectedLine].end_adj === null
    ) {
      newLyrics[indexOfSelectedLine].end_adj = 0
    }

    if (isStart) {
      if (isDecrement) {
        newLyrics[indexOfSelectedLine].start_adj -= value //isShiftDown ? 1000 : 100
      } else {
        newLyrics[indexOfSelectedLine].start_adj += value //isShiftDown ? 1000 : 100
      }
      if (setPlayer) {
        setVideoPlayerAtLine(newLyrics[indexOfSelectedLine])
      }
    } else {
      if (isDecrement) {
        newLyrics[indexOfSelectedLine].end_adj -= value //isShiftDown ? 1000 : 100
      } else {
        const newValue = newLyrics[indexOfSelectedLine].end_adj + value
        newValue + newLyrics[indexOfSelectedLine].t_end > videoDuration
          ? (newLyrics[indexOfSelectedLine].end_adj =
              videoDuration - newLyrics[indexOfSelectedLine].t_end)
          : (newLyrics[indexOfSelectedLine].end_adj = newValue)
      }
      if (setPlayer) {
        setVideoPlayerAtEndOfALine(newLyrics[indexOfSelectedLine])
      }
    }
    setLyrics(newLyrics)
    setOriginalLyrics(newLyrics)
    setHasEdited(true)
  }

  function handleStartEndInputValueChange(line, value, isStartTime) {
    const indexOfSelectedLine = lyrics.indexOf(line)
    const newLyrics = [...lyrics]

    if (
      newLyrics[indexOfSelectedLine].start_adj === undefined ||
      newLyrics[indexOfSelectedLine].start_adj === null
    ) {
      newLyrics[indexOfSelectedLine].start_adj = 0
    }
    if (
      newLyrics[indexOfSelectedLine].end_adj === undefined ||
      newLyrics[indexOfSelectedLine].end_adj === null
    ) {
      newLyrics[indexOfSelectedLine].end_adj = 0
    }

    if (isStartTime) {
      const adj =
        value -
        newLyrics[indexOfSelectedLine].t_start -
        newLyrics[indexOfSelectedLine].start_adj -
        currentVideoOffset
      newLyrics[indexOfSelectedLine].start_adj += adj
    } else {
      const duration = value > videoDuration ? videoDuration : value
      const adj =
        duration -
        newLyrics[indexOfSelectedLine].t_end -
        newLyrics[indexOfSelectedLine].end_adj -
        currentVideoOffset
      newLyrics[indexOfSelectedLine].end_adj += adj
    }
    setLyrics(newLyrics)
    setOriginalLyrics(newLyrics)
    setHasEdited(true)
  }

  function handleOnNavigationToAllSongs() {
    cancelRequest(controller)
    navigate('/songs/all')
  }

  function handleOnNavigationToSpecificArtistClick() {
    cancelRequest(controller)
    navigate('/artist/' + songData.artist.artist_name)
  }

  function handleOnNavigationToArtistSongsClick() {
    cancelRequest(controller)
    navigate('/songs/artist/' + songData.artist.artist_name)
  }

  function handleOnNavigationToSongClick() {
    navigate('/song/' + songData.f_id)
  }

  function handleOnShowReport() {
    setIsFeedbackPrompt(true)
  }

  function handleOnReport(comment) {
    setIsFeedbackPrompt(false)
    errorToast('Submitting...')

    reportSong(
      id,
      comment,
      getCurrentUser()?.email,
      () => {
        successToast('Successfully reported this Song - thank you!')
      },
      error => {
        errorToast(error)
      }
    )
  }

  function handleChangeOfEditMode(isCancel) {
    if (isCancel) {
      setLyrics(originalLyrics)
    }
    const textInputField = document.getElementsByClassName(
      'lyrics-freeform-textarea'
    )[0]
    textInputField.value = getLyricsInOneTextBlock()
    setShowLyricsEditText(!showLyricsEditText)
    videoElement.current.pause()
  }

  function getLyricsInOneTextBlock() {
    var lyricsText = ''
    for (var i = 0; i < lyrics.length; i++) {
      lyricsText += lyrics[i].line
      if (i < lyrics.length - 1) {
        lyricsText += '\n'
      }
    }
    return lyricsText
  }

  function lyricsTextIsChanging() {
    setHasEdited(true)
    const textInputField = document.getElementsByClassName(
      'lyrics-freeform-textarea'
    )[0]
    if (textInputField !== undefined && textInputField !== null) {
      processNewLyricsText(textInputField.value)
    }
  }

  function handleOnNewLyricsSave() {
    const textValue = document.getElementsByClassName(
      'lyrics-freeform-textarea'
    )[0].value
    const newLyrics = processNewLyricsText(textValue)
    saveLyricsEdits(newLyrics)
  }

  function processNewLyricsText(textValue) {
    const newLyricsRaw = textValue.split('\n')
    const copyOfCurrentLyricsForTimings = [...lyrics].filter(item => {
      return item.line !== ''
    })
    let newLyrics = []
    for (let i = 0; i < newLyricsRaw.length; i++) {
      if (newLyricsRaw[i] == '') {
        newLyrics.push({
          line: '',
          identifier: randomString(10),
        })
        continue
      } else {
        var timing = {
          duration: 0,
          end_adj: 0,
          milliseconds: 0,
          start_adj: 0,
          t_end: 0,
          t_start: 0,
          identifier: randomString(10),
        }
        if (copyOfCurrentLyricsForTimings.length > 0) {
          timing = copyOfCurrentLyricsForTimings[0]
          copyOfCurrentLyricsForTimings.splice(0, 1)
        }
        newLyrics.push({
          duration: timing.duration,
          end_adj: timing.end_adj,
          line: newLyricsRaw[i],
          milliseconds: timing.milliseconds,
          start_adj: timing.start_adj,
          t_end: timing.t_end,
          t_start: timing.t_start,
          identifier: randomString(10),
        })
      }
    }
    setLyrics(newLyrics)
    return newLyrics
  }

  useEffect(() => {
    const videoEl = videoElement.current

    const handleLoadedMetadata = () => {
      if (videoEl?.duration) {
        setVideoDuration(Math.floor(videoEl.duration) * 1000 - 1)
      }
    }

    if (videoEl) {
      videoEl.load()
      videoEl.addEventListener('loadedmetadata', handleLoadedMetadata)
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener('loadedmetadata', handleLoadedMetadata)
      }
    }
  }, [videoElement.current])

  return (
    <Wrapper>
      {isFeedbackPrompt && (
        <PromptWithText
          title={'Describe the issue with this song'}
          subtitle={''}
          primaryAction={'CANCEL'}
          onPrimaryClick={() => {
            setIsFeedbackPrompt(false)
          }}
          secondaryAction={'SEND'}
          onSecondaryClick={comment => {
            handleOnReport(comment)
          }}
          isSecondaryActionDestructive={false}
          onCloseClick={() => {
            setIsFeedbackPrompt(false)
          }}
        />
      )}
      {!isLoading && (
        <Header>
          {isEmailInSongManagementTeam(getCurrentUser()?.email) ? (
            <Breadcrumbs
              items={[
                {
                  text: 'All Songs',
                  onClick: () => {
                    handleOnNavigationToAllSongs()
                  },
                },
                {
                  text: 'Edit Text',
                  onClick: () => {},
                },
              ]}
            />
          ) : (
            <Breadcrumbs
              items={
                !songData?.artist?.name
                  ? [
                      {
                        text: 'All Songs',
                        onClick: () => {
                          handleOnNavigationToAllSongs()
                        },
                      },
                    ]
                  : [
                      {
                        text: 'All Songs',
                        onClick: () => {
                          handleOnNavigationToAllSongs()
                        },
                      },
                      {
                        text: formatStringInNav(songData?.artist?.name),
                        onClick: () => {
                          handleOnNavigationToSpecificArtistClick()
                        },
                      },
                      {
                        text: 'Songs',
                        onClick: () => {
                          handleOnNavigationToArtistSongsClick()
                        },
                      },
                      {
                        text: formatStringInNav(songData?.name),
                        onClick: () => {
                          handleOnNavigationToSongClick()
                        },
                      },
                      {
                        text: 'Edit Lyrics',
                        onClick: () => {},
                      },
                    ]
              }
            />
          )}
          {songData && (
            <div className="edit-song-artist-container-holder">
              <ArtistAndSongName song={songData} />
              {isSavingSong ? (
                <ScaleLoader color="#FF00FF" loading={isSavingSong} />
              ) : (
                <Actions>
                  <button
                    className={
                      !showLyricsEditText
                        ? 'edit-song-save-button'
                        : 'edit-song-report-btn'
                    }
                    onClick={() => {
                      handleChangeOfEditMode(showLyricsEditText ? true : false)
                    }}
                  >
                    {!showLyricsEditText ? 'EDIT LYRICS' : 'CANCEL'}
                  </button>
                  {!showLyricsEditText && (
                    <button
                      className="edit-song-report-btn"
                      onClick={() => {
                        handleOnShowReport()
                      }}
                    >
                      REPORT
                    </button>
                  )}
                  {!showLyricsEditText && (
                    <div className="sync-verified-btn">
                      <img
                        width="24"
                        height="24"
                        style={{ cursor: 'pointer' }}
                        src={isSyncVerified ? checkmark_full : checkmark_empty}
                        onClick={() => {
                          setHasEdited(true)
                          setIsSyncVerified(!isSyncVerified)
                        }}
                      />
                      <span className="span-sync-verified-btn">
                        Sync Verified
                      </span>
                    </div>
                  )}
                  <button
                    className={
                      hasEdited
                        ? 'edit-song-save-button'
                        : 'edit-song-save-button-disabled'
                    }
                    onClick={() => {
                      if (hasEdited) {
                        if (showLyricsEditText) {
                          handleOnNewLyricsSave()
                        } else {
                          saveEdits()
                        }
                      }
                    }}
                  >
                    {showLyricsEditText ? 'SAVE LYRICS' : 'SAVE'}
                  </button>
                  {errorSavingMessage && (
                    <p className="error-text error-text-wrap">
                      {errorSavingMessage}
                    </p>
                  )}
                </Actions>
              )}
            </div>
          )}
        </Header>
      )}

      {!isLoading && songData && songData?.video?.video?.url ? (
        hasDeviceAccess ? (
          <div className={`edit-song-lyrics-video-container ${classes.main}`}>
            <div className="edit-lyrics-column">
              {!showLyricsEditText && (
                <div
                  className="top-of-lyrics-column"
                  ref={topOfLyricsColumnElement}
                >
                  <div className="video-offset-input-holder">
                    <input
                      className="video-offset-input-field"
                      placeholder={'Enter Seconds to adjust video...'}
                      onChange={e => {
                        handleOnVideoOffsetChange(e)
                      }}
                      value={videoOffset}
                    />
                  </div>
                  <p className="video-offset-label">Video Offset (in ms)</p>
                  <div className="edit-song-action-buttons-container">
                    <button
                      className="edit-song-btn"
                      onClick={() => {
                        handleSetCurrentVideoPlayerToLineAction()
                      }}
                    >
                      {currentlySelectedLine !== null
                        ? 'ADJUST ALL LINES AHEAD WITH PLAYER TIME'
                        : 'SET PLAYER TIME AS VIDEO OFFSET'}
                    </button>

                    <div className="sync-verified-btn">
                      <img
                        width="24"
                        height="24"
                        style={{ cursor: 'pointer' }}
                        src={isLoopingLines ? checkmark_full : checkmark_empty}
                        onClick={() => {
                          setIsLoopingLines(!isLoopingLines)
                        }}
                      />
                      <span className="span-sync-verified-btn">Loop Lines</span>
                    </div>
                  </div>
                </div>
              )}
              <div className={classes.lyricsColumn}>
                <EditLyrics
                  onLyricSelected={line => {
                    handleLyricSelected(line)
                  }}
                  videOffset={currentVideoOffset}
                  lyrics={lyrics}
                  currentlySelectedLine={currentlySelectedLine}
                  currentlyHighlightedLine={currentlyHighlightedLine}
                  isShiftDown={isShiftDown}
                  isAltDown={isAltDown}
                  isXDown={isXDown}
                  isEnabled={!showLyricsEditText}
                  handleIncrementDecrementButton={(
                    line,
                    isStart,
                    isDecerement,
                    isShiftDown,
                    isAltDown,
                    isXDown
                  ) => {
                    handleIncrementDecrementButton(
                      line,
                      isStart,
                      isDecerement,
                      isShiftDown ? 1000 : isAltDown ? 20 : isXDown ? 300 : 100,
                      true
                    )
                  }}
                  handleStartEndInputValueChange={(
                    line,
                    value,
                    isStartTime
                  ) => {
                    handleStartEndInputValueChange(line, value, isStartTime)
                  }}
                />
              </div>
            </div>
            <div className="edit-song-video-column-wrapper">
              <div className="edit-song-video-column" ref={videoColumnElement}>
                <div
                  className={
                    showLyricsEditText
                      ? 'edit-song-text'
                      : 'edit-song-text do-not-show'
                  }
                >
                  <textarea
                    className="lyrics-freeform-textarea"
                    name="freeform"
                    cols="55"
                    defaultValue={getLyricsInOneTextBlock()}
                    onInput={() => {
                      lyricsTextIsChanging()
                    }}
                  ></textarea>
                </div>
                <div
                  className={
                    showLyricsEditText
                      ? 'edit-song-video-and-buttons-container do-not-show'
                      : 'edit-song-video-and-buttons-container'
                  }
                >
                  <div className="edit-song-video-player-container">
                    <video
                      ref={videoElement}
                      className="edit-song-video-player"
                      controls
                      autoPlay={true}
                      muted={true}
                      loop={false}
                      src={songData?.video?.video?.url}
                      controlsList="nofullscreen"
                    />
                  </div>
                  <div className="waveform-container">
                    <div className="wave" ref={waveformElement} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <DevicesRestrictionsNotification />
        )
      ) : (
        <>
          {!isLoading && (
            <h1 style={{ color: 'white' }}>
              {errorMessage ? errorMessage : ''}
            </h1>
          )}
          {isLoading && <Loader isLoading={isLoading} />}
        </>
      )}
    </Wrapper>
  )
}
