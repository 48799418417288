import React from 'react'
import PropTypes from 'prop-types'
import ButtonArrow from '../../GeneralComponents/ButtonArrow'
import classes from './index.module.css'

export default function SongFeelsHeader({
  totalFeels,
  onClick,
  title = 'Song Music Messages',
}) {
  return (
    <div className={classes.container}>
      <p className={classes.text}>{title}</p>
      <ButtonArrow
        text={'View All' + (totalFeels ? ' (' + totalFeels + ')' : '')}
        onClick={() => onClick()}
      />
    </div>
  )
}

SongFeelsHeader.propTypes = {
  totalFeels: PropTypes.number.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}
