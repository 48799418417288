import React, { useRef } from 'react'
import frame from '../../MobileFrames/frame.png'
import headerMask from './headerMask.svg'
import footerMask from './footerMask.svg'
import classes from './index.module.css'
import PropTypes from 'prop-types'
import useUserInfo from '../../../../../services/hooks/useUserInfo'
import { usePreventFullscreen } from '../../../../../Helpers/hooks/usePreventFullscreen'

const InstagramStories = ({ data }) => {
  const videoElement = useRef()
  const { icon, name } = useUserInfo()
  usePreventFullscreen(videoElement)

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  const videoDimensionWidth = data?.export?.dimensions[0]
  const videoDimensionHeight = data?.export?.dimensions[1]
  let videoClass = classes.videoM

  // TO DO: other resolutions
  if (videoDimensionHeight === 720 && videoDimensionWidth === 1280) {
    videoClass = classes.videoM
  }

  return (
    <div className={classes.container}>
      <div className={classes.frameContainer}>
        <img src={frame} className={classes.frame} alt={'phone frame'} />
      </div>
      <video
        muted={true}
        autoPlay={true}
        ref={videoElement}
        onTimeUpdate={handleOnTimeUpdate}
        className={`${videoClass} ${classes.video}`}
        src={data?.export?.url}
        controlsList="nofullscreen"
      />
      <img
        src={headerMask}
        className={classes.headerMask}
        alt={'header mask'}
      />
      <img src={icon} className={classes.avatar} alt={'avatar'} />
      <div className={classes.headerTitle}>
        <span className={classes.name}>{name}</span>
        <span className={classes.time}> 1h</span>
      </div>
      <img
        src={footerMask}
        className={classes.footerMask}
        alt={'footer mask'}
      />
    </div>
  )
}

InstagramStories.propTypes = {
  getCurrentUser: PropTypes.func,
  data: PropTypes.object,
}

export default InstagramStories
