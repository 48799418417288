import React, { useRef, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import StickerSidebarButton from '../StickerSidebarButton/StickerSidebarButton'
import Loader from '../../GeneralComponents/Loader'
import classes from './index.module.css'

export default function StickersSidebar({
  stickersList,
  setStickersOrder,
  currentSticker,
  setCurrentSticker,
  currentStickerAdd,
  setCurrentStickerAdd,
  editStickersList,
  disabled,
  fetchNextPage,
  isFetchingNextPage,
}) {
  const [ref, inView] = useInView()
  const firstStickerRef = useRef(null);

  useEffect(() => {
    if (inView && stickersList) {
      fetchNextPage()
    }
    if (firstStickerRef.current) {
      firstStickerRef.current.click();
    }
  }, [inView])

  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }

    const items = Array.from(stickersList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setStickersOrder(items)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="stickers">
        {provided => (
          <div
            className={classes.wrapper}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div>
              <button
                type="button" 
                className={classes.NewStickerSet}
                onClick={() => {
                  setCurrentSticker(null);
                  setCurrentStickerAdd(true);
                }}
              >
                + ADD NEW SET
              </button>

              {/* <div className={classes.delimiter}></div> */}
            </div>
            {stickersList.map((sticker, index) => (
              <Draggable
                key={sticker.f_id}
                draggableId={sticker.f_id.toString()}
                index={index}
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div ref={index === 0 ? firstStickerRef : null}>
                      <StickerSidebarButton
                        isEdit={editStickersList.includes(sticker.f_id)}
                        isCurrent={sticker.f_id === currentSticker?.f_id}
                        isPublished={sticker.is_published}
                        text={sticker.name}
                        onClick={() => {
                          setCurrentSticker(sticker),
                            setCurrentStickerAdd(false)
                        }}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <Loader isLoading={isFetchingNextPage} />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

StickersSidebar.propTypes = {
  setStickersOrder: PropTypes.func.isRequired,
  stickersList: PropTypes.array.isRequired,
  currentSticker: PropTypes.object,
  setCurrentSticker: PropTypes.func.isRequired,
  currentStickerAdd: PropTypes.bool,
  setCurrentStickerAdd: PropTypes.func.isRequired,
  editStickersList: PropTypes.array,
  disabled: PropTypes.bool,
  fetchNextPage: PropTypes.func,
  isFetchingNextPage: PropTypes.bool,
}
