import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TeaseFormActions from '../../TeaseFormActions'
import classes from './index.module.css'
import TextField from '../../TextField'
import LyricsField from '../../LyricsField'
import VideoUpload from '../../VideoUpload'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import { processNewLyricsText } from '../../helpers/processNewLyricsText'
import { CustomSelect } from '../../CustomSelect'
import { URL_TEXT_OPTIONS } from '../../helpers/constants'
import { isValidUrl } from '../../helpers/isValidUrl'

export default function CreateTeaseForm({ closePopup, songData, setSongData }) {
  const [values, setValues] = useState({})

  const onTextFieldValueChange = e => {
    setValues(values => ({ ...values, [e.target.name]: e.target.value }))
  }

  const onTextLyricsValueChange = e => {
    const lyricsSync = processNewLyricsText(e.target.value, values.lyrics_sync)
    setValues(values => ({
      ...values,
      lyrics: e.target.value,
      lyrics_sync: lyricsSync,
    }))
  }

  const onPromotingFieldValueChange = e => {
    setValues({
      ...values,
      promoting: { ...values.promoting, [e.target.name]: e.target.value },
    })
  }
  const handleOptionSelect = option => {
    setValues({
      ...values,
      promoting: { ...values.promoting, url_display_text: option },
    })
  }
  const onFileFieldValueChange = props => {
    setValues(values => ({
      ...values,
      video: props
        ? {
            file: props.file,
            video: {
              video_type: props.video_type,
              dimensions: props.dimensions,
              url: props.url,
              filesize: props.filesize,
            },
          }
        : null,
    }))
  }

  const onCancelClick = () => {
    closePopup()
  }
  const isNextButtonEnabled = !!values.name && !!values.lyrics && !!values.video

  const onSubmit = e => {
    e.preventDefault()
    const { name, lyrics, video } = values
    if (!name) {
      errorToast('Title is required')
      return
    }
    if (!lyrics) {
      errorToast('Lyrics is required')
      return
    }
    if (!video) {
      errorToast('Video is required')
      return
    }

    if (
      values?.promoting?.url &&
      !isValidUrl(values?.promoting?.url)
    ) {
      errorToast(
        'The URL you entered is not valid. Please provide a valid URL starting with http:// or https://'
      )
      return
    }

    setSongData(prev => ({ ...prev, ...values }))
    closePopup()
  }

  useEffect(() => {
    !!songData && setValues(JSON.parse(JSON.stringify(songData)))
  }, [songData])

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.form__fields}>
        <div className={`${classes.form__column} ${classes.form__column_16}`}>
          <TextField
            label={'Tease Title'}
            name={'name'}
            onChange={onTextFieldValueChange}
            isRequired
            value={values.name || ''}
          />
          <LyricsField
            label={'Lyrics'}
            name={'lyrics'}
            onChange={onTextLyricsValueChange}
            isRequired
            value={values.lyrics || ''}
            helperText={'Note: Each lyric line should be on a separate line.'}
            placeholder={'Enter the tease lyrics here...'}
          />
        </div>
        <div className={`${classes.form__column} ${classes.form__column_24}`}>
          <TextField
            label={'URL'}
            name={'url'}
            helperText={
              'Viewers can tap the Display Text on your Tease Music Message to open this link.'
            }
            placeholder={'pre-save/promo.com'}
            onChange={onPromotingFieldValueChange}
            value={values.promoting?.url || ''}
          />

          <CustomSelect
            options={URL_TEXT_OPTIONS}
            onOptionSelect={handleOptionSelect}
            label={'URL Display Text'}
            helperText={
              'This text will appear with the Music Message instead of the full URL.'
            }
            defaultOption={values?.promoting?.url_display_text}
            disabled={!values.promoting?.url}
          />
          <VideoUpload
            label={'Upload Video'}
            name={'video'}
            helperText={['Dimensions: 720px x 1280px', 'Video must have audio']}
            required
            onFileFieldValueChange={onFileFieldValueChange}
            video={values.video}
          />
        </div>
      </div>
      <TeaseFormActions
        isNextButtonEnabled={isNextButtonEnabled}
        onCancelClick={onCancelClick}
        isLoading={false}
      />
    </form>
  )
}

CreateTeaseForm.propTypes = {
  closePopup: PropTypes.func,
  artist: PropTypes.object,
  songData: PropTypes.object,
  file: PropTypes.any,
  video_type: PropTypes.any,
  filesize: PropTypes.any,
  dimensions: PropTypes.any,
  url: PropTypes.any,
  setSongData: PropTypes.func,
}
