import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import TextField from '../../../../Components/GeneralComponents/TextField'
import { isValidEmail } from '../../../../Helpers/isValidEmail'
import Input from './Input'

export default function CreateOrganizationDynamicInputsTextFields({
  onChange = () => false,
  required = true,
  label,
  buttonText = 'Add More Owners',
  inputType = 'rounded',
  placeholder,
}) {
  const [owners, setOwners] = useState([''])

  const addOwner = () => {
    setOwners([...owners, '']) // Adds a new empty email field
  }

  const updateOwner = (index, value) => {
    const newOwners = [...owners]
    newOwners[index] = value
    setOwners(newOwners)
  }

  useEffect(() => {
    onChange(owners)
  }, [owners])

  return (
    <div>
      {label ? (
        <p className={classes.label}>
          {label} {required ? <span>*</span> : null}
        </p>
      ) : null}
      <div className={classes.inputs}>
        {owners.map((_, index) => {
          return (
            <Input
              key={index}
              index={index}
              updateOwner={updateOwner}
              inputType={inputType}
              placeholder={placeholder}
            />
          )
        })}
      </div>
      {owners.length < 5 ? (
        <button onClick={addOwner} type="button" className={classes.button}>
          + {buttonText}
        </button>
      ) : null}
    </div>
  )
}

CreateOrganizationDynamicInputsTextFields.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  inputType: PropTypes.string,
  placeholder: PropTypes.string,
}
