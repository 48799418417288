import React from 'react'
import PropTypes from 'prop-types'
import OrganizationUserTableRow from '../OrganizationUserTableRow'
import InviteButton from '../InviteButton'
import classes from './index.module.css'

export default function OrganizationUsersTable({
  isCurrentUserAnAdmin,
  currentUserRole,
  users,
  handleInvite,
  getUserRole,
  options,
  handleChangeRole,
  setIsPromptDelete,
  setUserToDeleteIfConfirmed,
  showDelete,
}) {
  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.row}>
          <th>
            <p className={classes.text}>Name</p>
          </th>
          <th>
            <p className={classes.text}>Last Active</p>
          </th>
          <th>
            <p className={classes.text}>Permissions</p>
          </th>
          {isCurrentUserAnAdmin ? (
            <th className={classes.actions}>
              <InviteButton handleInvite={handleInvite} />
            </th>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {users.map(user => {
          return (
            <OrganizationUserTableRow
              key={user.f_id}
              user={user}
              currentRole={getUserRole(user)}
              currentUserRole={currentUserRole}
              isCurrentUserAdmin={isCurrentUserAnAdmin}
              options={options}
              showDelete={showDelete}
              onRoleSelected={(user, newRole) => {
                handleChangeRole(user, newRole, 'add')
              }}
              onDelete={user => {
                setIsPromptDelete(true)
                window.scrollTo(0, 0)
                setUserToDeleteIfConfirmed(user)
              }}
            />
          )
        })}
      </tbody>
    </table>
  )
}

OrganizationUsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  isCurrentUserAnAdmin: PropTypes.bool.isRequired,
  showDelete: PropTypes.bool.isRequired,
  handleInvite: PropTypes.func.isRequired,
  getUserRole: PropTypes.func.isRequired,
  handleChangeRole: PropTypes.func.isRequired,
  setIsPromptDelete: PropTypes.func.isRequired,
  setUserToDeleteIfConfirmed: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  currentUserRole: PropTypes.string.isRequired,
}
