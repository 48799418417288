import { create } from 'zustand'

export const useStickersStore = create(set => ({
  stickersList: [],
  currentStickerAdd: true,
  currentSticker: null,
  editStickersList: [],
  setStickersList: newStickers => set({ stickersList: newStickers }),
  setCurrentStickerAdd: newState => set({ currentStickerAdd: newState }),
  setCurrentSticker: newSticker => set({ currentSticker: newSticker }),
  setEditStickersList: newStickers => set({ editStickersList: newStickers }),
}))
