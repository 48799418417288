import { create } from 'zustand'

export const useAddCollectionStore = create(set => ({
  feels: [],
  collectionName: null,
  collectionLocation: null,
  denySaving: true,
  setFeels: newFeels => set({ feels: newFeels }),
  setCollectionName: newName => set({ collectionName: newName }),
  setCollectionLocation: newLocation =>
    set({ collectionLocation: newLocation }),
  setDenySaving: newState => set({ denySaving: newState }),
}))