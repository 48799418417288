import React from 'react'
import PropTypes from 'prop-types'
import OrganizationDropdown from '../../../Components/OrganizationComponents/OrganizationDropdown/OrganizationDropdown'
import classes from './index.module.css'
import RemoveButton from '../RemoveButton'
import { handleActivity } from '../../../services/functions'
import { roles } from '../../../constants/roles'

export default function OrganizationUserTableRow({
  user,
  currentRole,
  currentUserRole,
  isCurrentUserAdmin,
  options,
  showDelete,
  onDelete,
  onRoleSelected,
}) {
  const isFieldEditable = (currentRole, currentUserRole) => {
    return (
      (isCurrentUserAdmin &&
        ((currentRole !== roles.SUPER_ADMINISTRATOR &&
          currentUserRole === roles.ADMINISTRATOR) ||
          (currentRole !== roles.ORGANIZATION_OWNER &&
            currentUserRole === roles.ORGANIZATION_ADMINISTRATOR))) ||
      currentUserRole === roles.SUPER_ADMINISTRATOR ||
      currentUserRole === roles.ORGANIZATION_OWNER
    )
  }

  return (
    <tr className={classes.row}>
      <td>
        <p
          className={`${classes.text} ${classes.text_primary} ${classes.text_bold}`}
        >
          {user.name ? user.name : '-'}
        </p>
        <p className={`${classes.text} ${classes.text_primary}`}>
          {user.email}
        </p>
      </td>
      <td>
        <p className={`${classes.text} ${classes.text_secondary}`}>
          {user.date_last_active
            ? handleActivity({ dateTo: user.date_last_active })
            : '-'}
        </p>
      </td>
      <td>
        {isCurrentUserAdmin ? (
          <OrganizationDropdown
            options={options}
            selectedOption={currentRole}
            setSelectedOption={newRole => onRoleSelected(user, newRole)}
            disabled={!isFieldEditable(currentRole, currentUserRole)}
          />
        ) : (
          <p className={`${classes.text} ${classes.text_primary}`}>
            {currentRole}
          </p>
        )}
      </td>
      {isCurrentUserAdmin && showDelete ? (
        <td className={classes.actions}>
          <RemoveButton
            user={user}
            onDelete={onDelete}
            disabled={!isFieldEditable(currentRole, currentUserRole)}
          />
        </td>
      ) : null}
    </tr>
  )
}

OrganizationUserTableRow.propTypes = {
  user: PropTypes.object.isRequired,
  currentRole: PropTypes.string.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  isCurrentUserAdmin: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  showDelete: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRoleSelected: PropTypes.func.isRequired,
}
