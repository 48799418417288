export const getUserPermissions = user => {
  if (!user) {
    return false
  }

  let userPermissions

  if (user.is_admin) {
    userPermissions = user?.permissions
  }

  if (user.roles) {
    userPermissions = user?.permissions
  }

  if (user.is_organization) {
    userPermissions =
      user?.organizations[0]?.organization_user?.organization_groups[0]?.permissions
  }

  if (user.organization_groups) {
    userPermissions = user?.organization_groups[0]?.permissions
  }

  return userPermissions
}

export const isPermissionAllowed = ({ user, permission }) => {
  if (!user || !permission) {
    return false
  }
  const userPermissions = getUserPermissions(user)
  return userPermissions && userPermissions.includes(permission)
}
