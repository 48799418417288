import { useMutation } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export const useDeleteOrganizationUser = () => {
  const { deleteOrganizationUser } = useAPI()

  return useMutation({
    mutationFn: data =>
      deleteOrganizationUser({
        ...data,
      }),
  })
}
