import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import TextField from '../../../../../Components/GeneralComponents/TextField'
import CreateOrganizationDynamicInputsTextFields from '../../CreateOrganizationDynamicInputsTextFields'

export default function CreateOrganizationPopupFormStep1({ setStepData }) {
  const onTextChange = e =>
    setStepData(prev => ({ ...prev, [e.target.name]: e.target.value }))

  const onArrayChange = newOwners =>
    setStepData(prev => {
      return {
        ...prev,
        owners: newOwners,
      }
    })

  return (
    <div className={classes.wrapper}>
      <TextField
        label={'Organization Name'}
        name={'name'}
        onChange={onTextChange}
        isRequired
        variant={'rounded'}
        placeholder={'Enter organization name'}
      />

      <CreateOrganizationDynamicInputsTextFields
        placeholder={'Enter owner email'}
        label={'Assign Owner(s)'}
        onChange={onArrayChange}
      />
      {/* <TextField
        label={'Organization Logo'}
        name={'image_url'}
        onChange={onTextChange}
        variant={'rounded'}
        placeholder={'Enter organization logo link'}
        helperText={'Dimensions: 250px x 100px'}
      /> */}
    </div>
  )
}

CreateOrganizationPopupFormStep1.propTypes = {
  setStepData: PropTypes.func,
}
