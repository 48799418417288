import React from 'react'
import { ScaleLoader } from 'react-spinners'
import PropTypes from 'prop-types'

export default function Loader({ isLoading }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
      }}
    >
      <ScaleLoader color="#FF00FF" loading={isLoading} />
    </div>
  )
}

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}
