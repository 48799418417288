import React, { useState, useEffect } from 'react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useAPI } from '../../Contexts/APIContext'
import { useStickersStore } from '../../Storage/stickersPage/useStickersStore'
import StickersSidebar from '../../Components/StickersComponents/StickersSidebar/StickersSidebar'
import StickerPack from '../../Components/StickersComponents/StickerPack/StickerPack'
import StickerPackAdd from '../../Components/StickersComponents/StickerPackAdd/StickerPackAdd'
import SearchInput from '../../Components/DashboardComponents/SearchInput/SearchInput'
import Loader from '../../Components/GeneralComponents/Loader'
import classes from './index.module.css'
import useUserInfo from '../../services/hooks/useUserInfo'

export default function Stickers() {
  const pageLimit = 12
  const { getStickers } = useAPI()
  const { name, email } = useUserInfo()

  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [isLoadingSaveAdd, setIsLoadingSaveAdd] = useState(false)
  const [newStickerPack, setNewStickerPack] = useState(null)
  const [forcedFetch, setForcedFetch] = useState(false)

  // zustand storage
  const stickersList = useStickersStore(state => state.stickersList)
  const setStickersList = useStickersStore(state => state.setStickersList)

  const currentSticker = useStickersStore(state => state.currentSticker)
  const setCurrentSticker = useStickersStore(state => state.setCurrentSticker)

  const currentStickerAdd = useStickersStore(state => state.currentStickerAdd)
  const setCurrentStickerAdd = useStickersStore(
    state => state.setCurrentStickerAdd
  )

  const editStickersList = useStickersStore(state => state.editStickersList)
  const setEditStickersList = useStickersStore(
    state => state.setEditStickersList
  )

  const {
    data,
    isError,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['stickers'],
    queryFn: ({ pageParam = 1 }) => getStickers(pageParam, pageLimit),
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.sticker_sets_count
          ? nextPage
          : undefined
      return newPage
    },
    onError: e => {
      setCurrentSticker(null),
        setCurrentStickerAdd(true),
        setIsLoadingSaveAdd(false)
    },
  })

  useEffect(() => {
    if (data) {
      const paginatedStickers = data.pages.flatMap(page => page.sticker_sets)
      setStickersList(paginatedStickers)
    }
  }, [data])

  useEffect(() => {
    if (forcedFetch && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, forcedFetch, isFetchingNextPage])

  useEffect(() => {
    if (newStickerPack && !hasNextPage) {
      const matchedStickerPack = stickersList.find(
        item => item.f_id === newStickerPack
      )
      if (matchedStickerPack) {
        setCurrentSticker(matchedStickerPack)
        setCurrentStickerAdd(false)
        setNewStickerPack(null)
        setIsLoadingSaveAdd(false)
      }
    }
  }, [newStickerPack, stickersList, hasNextPage])

  const setIsEditStickerPack = (f_id, state) => {
    if (state) {
      setEditStickersList([...editStickersList, f_id])
    } else {
      setEditStickersList(editStickersList.filter(itemId => itemId !== f_id))
    }
  }

  const setNameStickerPack = (f_id, state) => {
    const updatedStickers = stickersList.map(sticker => ({
      ...sticker,
      name: sticker.f_id === f_id ? state : sticker.name,
    }))
    setStickersList(updatedStickers)
  }

  const setCurrentStickerAddHandler = () => {
    setCurrentSticker(null), setCurrentStickerAdd(true)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <h1 className={classes.title}>Stickers</h1>
      </div>

      {(isLoading || isLoadingSaveAdd) && (
        <Loader isLoading={isLoading || isLoadingSaveAdd} />
      )}

      {!isError && !isLoading && !isLoadingSaveAdd && stickersList.length && (
        <div className={classes.contentWrapper}>
          <StickersSidebar
            editStickersList={editStickersList}
            currentSticker={currentSticker}
            setCurrentSticker={setCurrentSticker}
            currentStickerAdd={currentStickerAdd}
            setCurrentStickerAdd={setCurrentStickerAdd}
            stickersList={stickersList}
            setStickersOrder={setStickersList}
            disabled={isLoadingSave}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />

          <div className={classes.delimiter}></div>

          {currentStickerAdd && (
            <StickerPackAdd
              setIsLoadingSave={setIsLoadingSaveAdd}
              setCurrentStickerPack={f_id => setNewStickerPack(f_id)}
              setCurrentStickerOnError={setCurrentStickerAddHandler}
              setForcedFetch={setForcedFetch}
            />
          )}

          {currentSticker && (
            <StickerPack
              sticker={currentSticker}
              isEdit={editStickersList.includes(currentSticker?.f_id)}
              setIsEdit={setIsEditStickerPack}
              setName={setNameStickerPack}
              setCurrentStickerPack={setCurrentStickerAddHandler}
              isLoadingSave={isLoadingSave}
              setIsLoadingSave={setIsLoadingSave}
            />
          )}
        </div>
      )}
    </div>
  )
}
