import React, { useState, useEffect } from 'react'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useAPI } from '../../../Contexts/APIContext'
import { useInView } from 'react-intersection-observer'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import { BackdropModal } from '../../../Components/SongsComponents/BackdropModal/BackdropModule'
import { Breadcrumbs } from '../../../Components/GeneralComponents/Breadcrumbs/Breadcrumbs'
import { formatStringInNav } from '../../../Helpers/String+Extension'
import errorToast from '../../../Components/Toasts/ErrorToast'
import Loader from '../../../Components/GeneralComponents/Loader'
import Prompt from '../../../Components/DashboardComponents/Prompt/Prompt'
import trash from '../../../Components/icons/backdrop_icons/trash_icon.svg'
import classes from './index.module.css'
import { StickyHeader } from '../../../Components/StickyHeader'

export function Backdrop() {
  const pageLimit = 10
  const navigate = useNavigate()
  const {
    getBackdropList,
    getUrlNewBackdrop,
    uploadBackdropMedia,
    updateBackdrop,
    deleteBackdrop,
    getBackdrop,
    getSongData,
  } = useAPI()
  const queryClient = useQueryClient()
  const { id, artist_name } = useParams()
  const [ref, inView] = useInView()

  const [isLoading, setIsLoading] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const [uploadFile, setUploadFile] = useState(null)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [newBackdrop, setNewBackdrop] = useState(null) // new backdrop f_id and upload url
  const [newBackdropStatus, setNewBackdropStatus] = useState(null)
  const [newMediaStatus, setNewMediaStatus] = useState(false)
  const [selectedBackdrop, setSelectedBackdrop] = useState(null)
  const [videoKey, setVideoKey] = useState(0)
  const [isBackdropUpdated, setIsBackdropUpdated] = useState(false)
  const [song, setSong] = useState(undefined)
  const {
    data,
    isError,
    error,
    isLoading: isLoadingData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['backdropList', artist_name, id],
    queryFn: ({ pageParam = 1 }) =>
      getBackdropList(artist_name, pageParam, pageLimit, id),
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.backdrops_count ? nextPage : undefined
      return newPage
    },
  })

  const {
    data: songData,
    isLoading: isSongDataLoading,
    isError: isSongDataError,
  } = useQuery({
    queryKey: ['songData', id],
    queryFn: () => getSongData(id),
    enabled: !!data,
    refetchInterval:
      isBackdropUpdated && selectedBackdrop.f_id !== song?.video.backdrop_f_id
        ? 500
        : false,
  })

  const { isError: isErrorDataNewBackdrop } = useQuery({
    queryKey: ['dataNewBackdropStatus'],
    queryFn: async () => {
      const data = await getBackdrop(newBackdrop.f_id)
      setNewBackdropStatus(data?.status)
      return data
    },
    enabled: newMediaStatus && !!newBackdrop?.f_id,
    staleTime: 0,
    refetchInterval: newBackdropStatus !== 'ready' ? 500 : false,
  })

  const uploadNewBackdrop = async () => {
    try {
      await queryClient.invalidateQueries({
        queryKey: ['backdropList'],
      })
    } catch (e) {
      errorToast(`Error: ${e.status ?? ''} ${e.message ?? ''}`)
    } finally {
      setUploadFile(null)
      setNewBackdrop(null)
      setIsFileUploading(false)
      setNewMediaStatus(false)
    }
  }

  useEffect(() => {
    if (newBackdropStatus === 'ready') {
      uploadNewBackdrop()
    }
  }, [newBackdropStatus])

  useEffect(() => {
    setSong(songData)
  }, [songData])

  const invalidateHandler = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['songData', id],
    })
    await queryClient.invalidateQueries({
      queryKey: ['backdropList'],
    })
    navigate('/song/' + id)
    setIsBackdropUpdated(false)
    setNewBackdrop(null)
    setSelectedBackdrop(null)
    setIsLoading(false)
  }

  useEffect(() => {
    if (
      song &&
      selectedBackdrop &&
      selectedBackdrop.f_id === song.video.backdrop_f_id
    ) {
      invalidateHandler()
    }
  }, [song])

  useEffect(() => {
    if (isErrorDataNewBackdrop) {
      errorToast('Something went wrong')
    }
  }, [isErrorDataNewBackdrop])

  const getUrlNewBackdropMutation = useMutation({
    mutationFn: ({ backdropType, mediaType }) =>
      getUrlNewBackdrop(data?.pages[0]?.artist?.f_id, backdropType, mediaType),
    onSuccess: async data => {
      setNewBackdrop(data)
      try {
        await uploadBackdropMedia(
          data.upload_url[0],
          uploadFile,
          uploadFile?.type
        )
        setNewMediaStatus(true)
      } catch (e) {
        errorToast(`Error: ${e.status ?? ''} ${e.message ?? ''}`)
        setIsFileUploading(false)
      }
    },
    onError: e => {
      setIsFileUploading(false)
    },
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage])

  const handleFileChange = event => {
    setIsFileUploading(true)
    const file = event.target.files[0]
    if (file.type === 'video/mp4') {
      // validation video resolution
      try {
        const video = document.createElement('video')
        video.src = URL.createObjectURL(file)
        video.onloadedmetadata = function () {
          const { videoWidth, duration } = video
          if (videoWidth < 720) {
            errorToast('Video width must be at least 720px')
            setIsFileUploading(false)
            return
          }
          if (duration < 3) {
            errorToast('Video must be longer than 3 seconds')
            setIsFileUploading(false)
            return
          }
          if (duration > 15) {
            errorToast('Video must be shorter than 15 seconds')
            setIsFileUploading(false)
            return
          }
          setUploadFile(file)
          getUrlNewBackdropMutation.mutate({
            backdropType: 'video',
            mediaType: 'video/mp4',
          })
        }
        video.onerror = function () {
          setIsFileUploading(false)
          errorToast('Error loading video')
        }
        video.load()
      } catch (e) {
        setIsFileUploading(false)
        errorToast('Error:', e)
      }
    } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
      // validation image resolution
      try {
        const image = new Image()
        image.src = URL.createObjectURL(file)
        image.onload = function () {
          const { width, height } = image
          if (width < 720 || height < 1280) {
            errorToast('Image needs to be 720px x 1280px resolution')
            setIsLoading(false)
            return
          }
          setUploadFile(file)
          getUrlNewBackdropMutation.mutate({
            backdropType: 'image',
            mediaType: file.type,
          })
        }

        image.onerror = function () {
          setIsLoading(false)
          errorToast('Error loading image')
        }
        image.decode()
      } catch (e) {
        setIsLoading(false)
        errorToast('Error:', e)
      }
    } else {
      setIsLoading(false)
      errorToast('Only MP4, JPEG, PNG files are allowed')
    }
  }

  const saveHandler = async () => {
    setIsLoading(true)
    setIsOpenModal(false)
    try {
      await updateBackdrop(id, selectedBackdrop.f_id)

      if (selectedBackdrop.f_id === song?.video.backdrop_f_id) {
        navigate('/song/' + id)
        setIsBackdropUpdated(false)
        setNewBackdrop(null)
        setSelectedBackdrop(null)
        setIsLoading(false)
      } else {
        setIsBackdropUpdated(true)
      }
    } catch (e) {
      setIsLoading(false)
      errorToast(`Error ${e.status ?? ''} ${e.message ?? ''}`)
    }
  }

  const deleteHandler = async deleteItem => {
    setIsLoading(true)
    setDeleteItem(null)
    try {
      await deleteBackdrop(deleteItem.f_id)
      await queryClient.invalidateQueries({
        queryKey: ['backdropList'],
      })
    } catch (e) {
      errorToast(`Error: ${e.status ?? ''} ${e.message ?? ''}`)
    }
    setIsLoading(false)
  }

  const handleVideoChange = backdrop => {
    setSelectedBackdrop(backdrop)
    setVideoKey(prevKey => prevKey + 1)
  }
  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries({
        queryKey: ['backdropList'],
      })
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      {deleteItem && (
        <Prompt
          title={'Confirm Deletion of Backdrop'}
          subtitle={
            'Are you sure you want to delete this backdrop video? This action is permanent and cannot be undone.'
          }
          primaryAction={'CANCEL'}
          onPrimaryClick={() => {
            setDeleteItem(null)
          }}
          secondaryAction={'DELETE'}
          onSecondaryClick={() => {
            deleteHandler(deleteItem)
          }}
          isSecondaryActionDestructive={true}
          onCloseClick={() => {
            setDeleteItem(null)
          }}
        />
      )}

      <BackdropModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        onSubmit={saveHandler}
      />
      <div className={classes.wrapper}>
        {!isLoadingData && (
          <StickyHeader className={classes.stickyHeader}>
            <Breadcrumbs
              items={
                !data
                  ? [
                      {
                        text: 'All Songs',
                        onClick: () => {
                          navigate('/songs/all')
                        },
                      },
                    ]
                  : [
                      {
                        text: 'All Songs',
                        onClick: () => {
                          navigate('/songs/all')
                        },
                      },
                      {
                        text: formatStringInNav(data?.pages[0]?.artist?.name),
                        onClick: () => {
                          navigate(
                            '/artist/' + data?.pages[0]?.artist?.artist_name
                          )
                        },
                      },
                      {
                        text: 'Songs',
                        onClick: () => {
                          navigate(
                            '/songs/artist/' +
                              data?.pages[0]?.artist?.artist_name
                          )
                        },
                      },
                      {
                        text: formatStringInNav(data?.pages[0]?.song?.name),
                        onClick: () => {
                          navigate('/song/' + data?.pages[0]?.song_f_id)
                        },
                      },
                      {
                        text: 'Modify Backdrop',
                        onClick: () => {},
                      },
                    ]
              }
            />
            <div className={classes.header}>
              <span className={classes.headerTitle}>Modify Backdrop</span>
              <div className={classes.headerButtons}>
                <ButtonOutline
                  disable={!selectedBackdrop || isLoading}
                  onClick={() => {
                    setIsOpenModal(true)
                  }}
                  fontFamily="Noto Sans"
                  fontWeight={700}
                  text={'UPDATE BACKDROP'}
                  color={'pink'}
                />
              </div>
            </div>
            <div className={classes.artistContainer}>
              {data?.pages[0]?.artist && (
                <>
                  <img
                    src={data?.pages[0]?.artist?.image?.url}
                    className={classes.artistAvatar}
                    alt="avatar"
                  />
                  <span className={classes.artistName}>
                    {data?.pages[0]?.artist?.name}
                  </span>
                </>
              )}
            </div>
          </StickyHeader>
        )}
        {data &&
          songData &&
          !isError &&
          !isLoadingData &&
          !isSongDataLoading &&
          !isLoading && (
            <>
              <div className={classes.contentWrapper}>
                <div className={classes.uploadContainer}>
                  <span className={classes.contentTitle}>
                    {selectedBackdrop &&
                    selectedBackdrop.f_id !== songData?.video.backdrop_f_id
                      ? 'Selected Backdrop'
                      : 'Live Backdrop'}
                  </span>

                  {(!selectedBackdrop && songData?.video?.video?.url) ||
                  selectedBackdrop?.video?.url ? (
                    <video
                      className={classes.uploadVideo}
                      controls
                      autoPlay
                      loop
                      muted={false}
                      style={{ objectFit: 'cover' }}
                      key={videoKey}
                    >
                      <source
                        src={
                          selectedBackdrop?.video?.url ||
                          songData?.video?.video?.url
                        }
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                  {selectedBackdrop?.media_type === 'image/jpeg' ||
                  selectedBackdrop?.media_type === 'image/png' ? (
                    <img
                      src={selectedBackdrop?.image?.url}
                      alt="backdrop"
                      className={classes.uploadVideo}
                    />
                  ) : null}
                  <div className={classes.descriptionWrapper}>
                    <p className={classes.description}>
                      Dimensions: 720px x 1280px
                    </p>
                    <p className={classes.description}>
                      Length: 3-15 seconds (can trim)
                    </p>
                  </div>
                </div>
                <div className={classes.yourContentContainer}>
                  <div className={classes.contentHeader}>
                    <span className={classes.contentTitle}>
                      Uploaded Content
                    </span>
                    <label
                      htmlFor="fileInput"
                      className={classes.uploadVideoButton}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M5.25 9V2.8875L3.3 4.8375L2.25 3.75L6 0L9.75 3.75L8.7 4.8375L6.75 2.8875V9H5.25ZM1.5 12C1.0875 12 0.7345 11.8533 0.441 11.5597C0.1475 11.2662 0.0005 10.913 0 10.5V8.25H1.5V10.5H10.5V8.25H12V10.5C12 10.9125 11.8533 11.2657 11.5597 11.5597C11.2662 11.8538 10.913 12.0005 10.5 12H1.5Z"
                          fill="#FF00FF"
                        />
                      </svg>
                      <span>UPLOAD VIDEO</span>
                      <input
                        type="file"
                        id="fileInput"
                        accept=".jpg, .jpeg, .png, .mp4"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </label>
                  </div>
                  <div className={classes.yourContentWrapper}>
                    <div className={classes.originalTrack}>
                      <div className={classes.yourContentItemWrapper}>
                        <video
                          className={classes.yourContentVideo}
                          autoPlay={false}
                          muted={true}
                        >
                          <source
                            src={
                              songData.video.backdrop_f_id
                                ? songData.video.source_video.url
                                : songData.video.video.url
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                      <span className={classes.originalTrackText}>
                        Original
                      </span>
                    </div>

                    {data.pages.map((page, index) => (
                      <React.Fragment key={index}>
                        {page.backdrops.map(backdrop => {
                          return (
                            <div key={backdrop?.f_id} ref={ref}>
                              {backdrop.backdrop_type === 'image' && (
                                <div className={classes.yourContentItemWrapper}>
                                  <img
                                    src={backdrop.image.url}
                                    className={classes.yourContentImage}
                                    alt="content image"
                                    onClick={() => {
                                      handleVideoChange(backdrop)
                                    }}
                                  />

                                  {backdrop.f_id !==
                                    songData?.video.backdrop_f_id &&
                                  backdrop.f_id !== selectedBackdrop?.f_id ? (
                                    <img
                                      src={trash}
                                      className={classes.trashIcon}
                                      onClick={() => {
                                        setDeleteItem(backdrop)
                                      }}
                                      alt="trash icon"
                                    />
                                  ) : null}
                                  {backdrop.f_id === selectedBackdrop?.f_id ? (
                                    <div className={classes.selectedBackdrop}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="56"
                                        height="56"
                                        viewBox="0 0 56 56"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M46.0303 13.8581C47.3972 15.225 47.3972 17.441 46.0303 18.8079L24.6414 40.1968C23.9851 40.8532 23.0948 41.2219 22.1666 41.2219C21.2383 41.2219 20.3481 40.8532 19.6917 40.1968L9.96946 30.4746C8.60263 29.1077 8.60263 26.8916 9.96946 25.5248C11.3363 24.158 13.5524 24.158 14.9192 25.5248L22.1666 32.7722L41.0806 13.8581C42.4474 12.4913 44.6635 12.4913 46.0303 13.8581Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  ) : null}
                                </div>
                              )}
                              {backdrop.backdrop_type === 'video' && (
                                <div className={classes.yourContentItemWrapper}>
                                  <video
                                    className={classes.yourContentVideo}
                                    autoPlay={false}
                                    muted={true}
                                    onClick={() => {
                                      handleVideoChange(backdrop)
                                    }}
                                  >
                                    <source
                                      src={backdrop.video.url}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                  {backdrop.f_id !==
                                    songData?.video.backdrop_f_id &&
                                  backdrop.f_id !== selectedBackdrop?.f_id ? (
                                    <img
                                      src={trash}
                                      className={classes.trashIcon}
                                      onClick={() => {
                                        setDeleteItem(backdrop)
                                      }}
                                      alt="trash icon"
                                    />
                                  ) : null}
                                  {backdrop.f_id === selectedBackdrop?.f_id ? (
                                    <div className={classes.selectedBackdrop}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="56"
                                        height="56"
                                        viewBox="0 0 56 56"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M46.0303 13.8581C47.3972 15.225 47.3972 17.441 46.0303 18.8079L24.6414 40.1968C23.9851 40.8532 23.0948 41.2219 22.1666 41.2219C21.2383 41.2219 20.3481 40.8532 19.6917 40.1968L9.96946 30.4746C8.60263 29.1077 8.60263 26.8916 9.96946 25.5248C11.3363 24.158 13.5524 24.158 14.9192 25.5248L22.1666 32.7722L41.0806 13.8581C42.4474 12.4913 44.6635 12.4913 46.0303 13.8581Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </React.Fragment>
                    ))}
                    {isFileUploading ? (
                      <div className={classes.yourContentItemLoader}>
                        <Loader isLoading={isFileUploading} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        <div className={classes.loader}>
          <Loader isLoading={isLoadingData || isLoading || isSongDataLoading} />
        </div>
      </div>

      {isError && (
        <h1
          style={{ color: 'white', margin: 0 }}
        >{`Failed to fetch data with code: ${
          error?.status ? error.status : 'unknown'
        }`}</h1>
      )}
    </>
  )
}
