import { useCallback, useState } from 'react'

export default function usePopup() {
  const [isOpen, setIsOpen] = useState(false)

  const openPopup = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closePopup = useCallback(() => {
    setIsOpen(false)
  }, [])

  const togglePopup = () => setIsOpen(!isOpen)

  return { togglePopup, openPopup, closePopup, isOpen }
}
