import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

const optionsList = [
  { id: 0, name: 'Position 1' },
  { id: 1, name: 'Position 2' },
  { id: 2, name: 'Position 3' },
  { id: 3, name: 'Position 4' },
  { id: 4, name: 'Position 5' },
  { id: 5, name: 'Position 6' },
  { id: 6, name: 'Position 7' },
  { id: 7, name: 'Position 8' },
  { id: 8, name: 'Position 9' },
  { id: 9, name: 'Position 10' },
  { id: 10, name: 'Position 11' },
  { id: 11, name: 'Position 12' },
  { id: 12, name: 'Position 13' },
  { id: 13, name: 'Position 14' },
  { id: 14, name: 'Position 15' },
]

export default function CollectionDropdownList({
  selectedOption,
  setSelectedOption,
  collectionLength,
  initialValue,
  setInitialValue = () => {},
  setIsEdit = () => {},
  setInitialFlag = () => {},
}) {
  const [showOptionList, setShowOptionList] = useState(false)
  const selectContainerRef = useRef(null)
  const currentOptionList = optionsList.slice(0, collectionLength)

  useEffect(() => {
    if (selectedOption !== null && !initialValue) {
      setInitialValue(selectedOption)
    }
  }, [selectedOption, initialValue])

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(e.target)
      ) {
        setShowOptionList(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedOption, showOptionList])

  const handleListDisplay = () => {
    setShowOptionList(prevState => !prevState)
    setIsEdit(true)
  }

  const handleOptionClick = option => {
    setSelectedOption(option.id)
    setShowOptionList(false)
    if (option.id !== initialValue) {
      setInitialFlag(true)
    } else {
      setInitialFlag(false)
    }
  }

  return (
    <div className={classes.container} ref={selectContainerRef}>
      <div
        className={`${classes.selectedText} ${
          showOptionList ? classes.active : ''
        }`}
        onClick={handleListDisplay}
      >
        {selectedOption !== null
          ? optionsList[selectedOption].name
          : 'Select Location in App'}
      </div>
      {showOptionList && (
        <ul className={classes.selectOptions}>
          {currentOptionList.map(option => (
            <li
              key={option.id}
              onClick={() => handleOptionClick(option)}
              className={selectedOption === option.id ? classes.active : ''}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

CollectionDropdownList.propTypes = {
  selectedOption: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  setInitialValue: PropTypes.func,
  setSelectedOption: PropTypes.func.isRequired,
  setIsEdit: PropTypes.func,
  collectionLength: PropTypes.number,
  setInitialFlag: PropTypes.func,
}
