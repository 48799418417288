import { useAuth } from '../../Contexts/AuthContext'

export default function useUserInfo() {
  const { getCurrentUser } = useAuth()
  const user = getCurrentUser()

  const { icon, name, email } = handleUserInfo(user)
  return { icon, name, email }
}

const handleUserInfo = user => {
  if (!user) {
    return {}
  }
  const currentUser = user.is_organization
    ? user.organizations[0].organization_user
    : user

  const icon = currentUser.image_url
  const name = currentUser.name
  const email = currentUser.email

  return { icon, name, email }
}
