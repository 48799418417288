import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ButtonArrow from '../../GeneralComponents/ButtonArrow'
import albumSocialIcon from '../../icons/social_icons/album_social_icon.png'
import instaSocialIcon from '../../icons/social_icons/insta_social_icon.png'
import merchSocialIcon from '../../icons/social_icons/merch_social_icon.png'
import ticketsSocialIcon from '../../icons/social_icons/tickets_social_icon.png'
import tiktokSocialIcon from '../../icons/social_icons/tiktok_social_icon.png'
import xSocialIcon from '../../icons/social_icons/x-logo.png'
import youtubeSocialIcon from '../../icons/social_icons/youtube_social_icon.png'
import classes from './index.module.css'
import { permissions } from '../../../constants/roles'
import useReadOnly from '../../../services/hooks/useReadOnly'

export default function ArtistProfileHeader({ artist, onEditClick }) {
  const { isReadOnly: isArtistReadOnly } = useReadOnly(
    permissions.r.music_artist
  )

  const [socialMediaUrls, setSocialMediaUrls] = useState({
    youtube: '',
    instagram: '',
    twitter: '',
    tiktok: '',
  })
  const [additionalUrls, setAdditionalUrls] = useState({
    merchandise: '',
    tour: '',
    impact: '',
  })

  useEffect(() => {
    if (artist) {
      const socialMedia = artist.social_media || []
      const links = artist.links || []

      const socialMediaMap = {}
      socialMedia.forEach(item => {
        socialMediaMap[item.f_id] = item.url
      })

      const additionalUrlsMap = {}
      links.forEach(item => {
        additionalUrlsMap[item.f_id] = item.url
      })
      setSocialMediaUrls(socialMediaMap)
      setAdditionalUrls(additionalUrlsMap)
    }
  }, [artist])

  const renderSocialMediaIcon = (url, icon) => {
    return url ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img
          className={classes.socialIconActive}
          src={icon}
          alt="Active Social Icon"
        />
      </a>
    ) : (
      <img
        className={classes.socialIconInactive}
        src={icon}
        alt="Inactive Social Icon"
      />
    )
  }

  return (
    <div className={classes.wrapper}>
      {artist.image.url ? (
        <img
          className={classes.image}
          src={artist.image.url}
          alt="Artist Profile"
        />
      ) : (
        <div className={classes.emptyImage}>
          <p className={classes.emptyImageText}>
            {artist.name.charAt(0).toUpperCase()}
          </p>
        </div>
      )}
      <div className={classes.dataWrapper}>
        <div className={classes.nameWrapper}>
          <p className={classes.name}>{artist.name}</p>
          <ButtonArrow
            disabled={isArtistReadOnly}
            text="Edit"
            onClick={onEditClick}
          />
        </div>
        <div className={classes.socialElementsWrapper}>
          <div className={classes.socialElementsFirstRow}>
            {renderSocialMediaIcon(socialMediaUrls.youtube, youtubeSocialIcon)}
            {renderSocialMediaIcon(socialMediaUrls.instagram, instaSocialIcon)}
            {renderSocialMediaIcon(socialMediaUrls.twitter, xSocialIcon)}
            {renderSocialMediaIcon(socialMediaUrls.tiktok, tiktokSocialIcon)}
          </div>
          <div className={classes.socialElementsSecondRow}>
            {renderSocialMediaIcon(additionalUrls.merchandise, merchSocialIcon)}
            {renderSocialMediaIcon(additionalUrls.tour, ticketsSocialIcon)}
            {renderSocialMediaIcon(additionalUrls.impact, albumSocialIcon)}
          </div>
        </div>
      </div>
    </div>
  )
}

ArtistProfileHeader.propTypes = {
  artist: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
}
