import React from 'react'
import PropTypes from 'prop-types'
import WaveForm from "./WaveForm"
import classes from './index.module.css'
import { usePreventFullscreen } from '../../../../Helpers/hooks/usePreventFullscreen'

export default function TeaseVideo({ videoUrl, videoElement }) {
  usePreventFullscreen(videoElement)

  return (
    <div className={classes.wrapper}>
      <div className={classes.player}>
        <video
          ref={videoElement}
          id="video"
          src={videoUrl}
          controlsList="nofullscreen"
        />
      </div>
      <WaveForm videoElement={videoElement} videoUrl={videoUrl} />
    </div>
  )
}

TeaseVideo.propTypes = {
  videoUrl: PropTypes.string,
  videoElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}
