import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

// export function useGetOrgGroupContent({
//   o_f_id,
//   g_f_id,
//   content_object,
//   n = 8,
//   ...rest
// }) {
//   const { getOrganizationGroupContent } = useAPI()

//   return useQuery({
//     queryKey: [
//       'organization',
//       'group',
//       'content',
//       o_f_id,
//       g_f_id,
//       content_object,
//       n,
//     ],
//     queryFn: () =>
//       getOrganizationGroupContent({
//         n,
//         o_f_id,
//         g_f_id,
//         content_object,
//       }),
//     ...rest,
//   })
// }

export function useGetOrgGroupContent({
  o_f_id,
  content_object,
  n = undefined,
  g_f_id,
}) {
  const { getOrganizationGroupContent } = useAPI()

  const CONTENT_PER_PAGE = n || 50

  return useInfiniteQuery({
    queryKey: [
      'organization',
      'content',
      'group',
      'content',
      o_f_id,
      g_f_id,
      content_object,
      CONTENT_PER_PAGE,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getOrganizationGroupContent({
        pageParam,
        perPage: CONTENT_PER_PAGE,
        o_f_id,
        g_f_id,
        content_object,
      }),
    initialPageParam: 1,

    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * CONTENT_PER_PAGE < pageData[`${content_object}_count`]
          ? nextPage
          : undefined
      return newPage
    },
  })
}
