import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CreateOrganizationArtistsBlock({ children, title }) {
  return (
    <div className={classes.block}>
      <h3 className={classes.block__title}>{title}</h3>
      {children}
    </div>
  )
}

CreateOrganizationArtistsBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}
