import { useInfiniteQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useSearchArtists(searchString) {
  const { searchArtists } = useAPI()
  const ARTISTS_PER_PAGE = 50

  return useInfiniteQuery({
    queryKey: ['organizations', 'artists', 'search', searchString],
    queryFn: ({ pageParam = 1 }) =>
      searchArtists(searchString, pageParam, ARTISTS_PER_PAGE),
    initialPageParam: 1,
    getNextPageParam: pageData => {

      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * ARTISTS_PER_PAGE < pageData.artists_count
          ? nextPage
          : undefined
      return newPage
    },
    enabled: !!searchString,
  })
}
