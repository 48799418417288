import { create } from 'zustand'

const collectionStores = {}

export const createStoreForCollection = (collectionId, name, location) => {
  if (!collectionStores[collectionId]) {
    collectionStores[collectionId] = create(set => ({
      feels: [],
      isChangedFeels: false,
      removedFeels: [],
      collectionName: name,
      collectionNameInfo: {
        isChanged: false,
        initialValue: null,
      },
      collectionLocation: location,
      collectionLocationInfo: {
        isSelected: false,
        initialValue: null,
      },
      showSave: false,

      setFeels: newFeels => set({ feels: newFeels }),
      setIsChangedFeels: newState => set({ isChangedFeels: newState }),
      setRemovedFeels: newFeels => set({ removedFeels: newFeels }),
      setCollectionName: newName => set({ collectionName: newName }),
      setCollectionNameInfo: newValue => set({ collectionNameInfo: newValue }),
      setCollectionLocation: newLocation =>
        set({ collectionLocation: newLocation }),
      setCollectionLocationInfo: newValue =>
        set({ collectionLocationInfo: newValue }),
      setShowSave: newState => set({ showSave: newState }),
    }))
  }

  return collectionStores[collectionId]
}