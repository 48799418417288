import { useMutation } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export const useUploadTeaseVideo = () => {
  const { uploadTeaseVideo } = useAPI()

  return useMutation({
    mutationFn: data =>
      uploadTeaseVideo({
        ...data,
      }),
  })
}
