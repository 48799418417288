import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CreateOrganizationArtistBadge({
  artist,
  onClick = () => false,
}) {
  return (
    <div className={classes.artist}>
      {artist?.image.url ? (
        <div className={classes.artist__image}>
          <img
            src={artist.image.url}
            alt={artist.name}
            width={29}
            height={29}
          />
        </div>
      ) : (
        <div
          className={`${classes.artist__image} ${classes.artist__image_empty}`}
        >
          <span>{artist.artist_name.charAt(0).toUpperCase()}</span>
        </div>
      )}
      <h4 className={classes.artist__title}>{artist.name}</h4>
      <span
        onClick={() => onClick(artist)}
        className={classes.artist__button}
        role="button"
      >
        x
      </span>
    </div>
  )
}

CreateOrganizationArtistBadge.propTypes = {
  artist: PropTypes.object,
  onClick: PropTypes.func,
}
