import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import Step from './Step'


export default function AddAdminGroupPopupFormStep({ setData, data }) {
  return (
    <div className={classes.wrapper}>
      <Step setData={setData} data={data} />
    </div>
  )
}

AddAdminGroupPopupFormStep.propTypes = {
  setData: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
