import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const EditDataContext = createContext()

export function useEditDataContext() {
  return useContext(EditDataContext)
}

export function EditDataProvider({ children }) {
  const [isEditing, setIsEditing] = useState(false)

  function changeIsEditing(bool) {
    setIsEditing(bool)
  }

  const value = {
    isEditing,
    changeIsEditing,
  }
  return (
    <EditDataContext.Provider value={value}>
      {children}
    </EditDataContext.Provider>
  )
}

EditDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
