import React, { useState, useRef } from 'react'
import classes from './index.module.css'
import uploadIcon from '../../icons/uploadIcon.svg'
import PropTypes from 'prop-types'
import errorToast from '../../Toasts/ErrorToast'
import successToast from '../../Toasts/SuccessToast'
import { useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import Loader from '../../GeneralComponents/Loader'

const CoverUploader = ({ collectionId, coverImage, isPublic }) => {
  const inputRef = useRef()
  const queryClient = useQueryClient()
  const { getCollectionImageUrl, uploadCollectionImage } = useAPI()
  const [isUploaderOpen, setIsUploaderOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)

  const handleSaveCoverClick = async () => {
    if (!file || file.length === 0) return
    const uploadedImage = file[0]
    try {
      const image = new Image()
      image.src = URL.createObjectURL(file[0])
      await image.decode()
      const { width, height } = image
      if (width < 720) {
        errorToast('The image must be at least 720px wide.')
        return
      }
      setIsLoading(true)
      const uploadLink = await getCollectionImageUrl(
        collectionId,
        uploadedImage.type,
        uploadedImage.size
      )
      await uploadCollectionImage(
        [uploadLink.upload_url[0]],
        uploadedImage,
        uploadedImage.type
      )
    } catch (e) {
      errorToast(`Error: ${e.status ?? ''} ${e.message ?? ''}`)
    } finally {
      queryClient.invalidateQueries({
        queryKey: ['collection', collectionId, isPublic],
      })
      setFile(null)
      setIsLoading(false)
      setIsUploaderOpen(false)
    }
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  const handleDrop = event => {
    event.preventDefault()
    event.stopPropagation()
    setFile(event.dataTransfer.files)
  }

  const handleContainerClick = event => {
    event.stopPropagation()
  }

  function onOpen() {
    setIsUploaderOpen(true)
  }

  function onClose() {
    setIsUploaderOpen(false)
    setFile(null)
  }

  return (
    <>
      <button
        className={
          coverImage ? classes.coverButtonChange : classes.coverButtonUpload
        }
        type="button"
        onClick={onOpen}
      >
        {coverImage ? 'View or Change Cover' : 'Add Cover to Collection'}
      </button>

      {isUploaderOpen && !file && (
        <div className={classes.uploaderBgContainer} onClick={onClose}>
          <div
            className={classes.uploaderContainer}
            onClick={handleContainerClick}
          >
            <div className={classes.uploaderHeader}>
              <span className={classes.uploaderTitle}>Collection Cover</span>
              <span className={classes.uploaderSubtitle}>
                Upload and attach an image file to be the cover for this
                collection. The image must be at least 720px wide.
              </span>
            </div>
            <div
              className={classes.uploaderWrapper}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {coverImage && (
                <img className={classes.uploadedImage} src={coverImage} />
              )}
              <input
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                onChange={event => setFile(event.target.files)}
                hidden
                ref={inputRef}
              />
              <div className={classes.uploaderImageContainer}>
                <img
                  className={classes.uploaderImage}
                  src={uploadIcon}
                  alt="upload_icon"
                />
              </div>
              <div className={classes.uploadTextSection}>
                <button
                  onClick={() => inputRef.current.click()}
                  className={classes.uploaderButton}
                >
                  Click to Upload
                </button>
                <span className={classes.uploaderText}> or Drag and Drop</span>
              </div>
            </div>
            <div className={classes.uploaderBtnGroup}>
              <button className={classes.uploaderCancel} onClick={onClose}>
                Cancel
              </button>
              <button
                className={classes.uploaderSave}
                disabled={!file}
                onClick={handleSaveCoverClick}
              >
                Save Cover
              </button>
            </div>
          </div>
        </div>
      )}

      {isUploaderOpen && file && (
        <div className={classes.uploaderBgContainer} onClick={onClose}>
          <div
            className={classes.uploaderContainer}
            onClick={handleContainerClick}
          >
            <div className={classes.uploaderHeader}>
              <span className={classes.uploaderTitle}>Collection Cover</span>
              <span className={classes.uploaderSubtitle}>
                Upload and attach image file to be the cover for this
                collection. The image must be 1280 wide by 720 high.
              </span>
            </div>
            <div
              className={classes.uploaderWrapper}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {file && file.length > 0 && (
                <img
                  className={classes.uploadedImage}
                  src={URL.createObjectURL(file[0])}
                />
              )}
              <input
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                onChange={event => setFile(event.target.files)}
                hidden
                ref={inputRef}
              />
              <div className={classes.uploaderImageContainer}>
                <img
                  className={classes.uploaderImage}
                  src={uploadIcon}
                  alt="upload_icon"
                />
              </div>
              <div className={classes.uploadTextSection}>
                <button
                  onClick={() => inputRef.current.click()}
                  className={classes.uploaderButton}
                >
                  Click to Upload
                </button>
                <span className={classes.uploaderText}> or Drag and Drop</span>
              </div>
            </div>
            {!isLoading && (
              <div className={classes.uploaderBtnGroup}>
                <button className={classes.uploaderCancel} onClick={onClose}>
                  Cancel
                </button>
                <button
                  className={classes.uploaderSave}
                  onClick={handleSaveCoverClick}
                >
                  Save Cover
                </button>
              </div>
            )}
            <Loader isLoading={isLoading} />
          </div>
        </div>
      )}
    </>
  )
}

CoverUploader.propTypes = {
  collectionId: PropTypes.string,
  coverImage: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  isPublic: PropTypes.bool,
}

export default CoverUploader
