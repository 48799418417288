import { useAuth } from '../../Contexts/AuthContext'
import { roles } from '../../constants/roles'
import { getUserRole } from '../helpers/getUserRole.'

export default function useAdminAccess(user = undefined) {
  const { getCurrentUser } = useAuth()
  const currentUser = user || getCurrentUser()

  const hasAdminAccess = handleAdminAccess(currentUser)
  return { hasAdminAccess }
}

const handleAdminAccess = currentUser => {
  if (!currentUser) {
    return false
  }

  const {
    SUPER_ADMINISTRATOR,
    ADMINISTRATOR,
    ORGANIZATION_OWNER,
    ORGANIZATION_ADMINISTRATOR,
  } = roles

  const userRole = getUserRole(currentUser)

  switch (userRole) {
    case SUPER_ADMINISTRATOR:
    case ADMINISTRATOR:
    case ORGANIZATION_OWNER:
    case ORGANIZATION_ADMINISTRATOR:
      return true

    default:
      return false
  }
}
