export const getUserRole = user => {
  if (!user) {
    return false
  }

  let userRole

  if (user.is_admin) {
    userRole = user?.roles[0]?.role
  }

  if (user.roles) {
    userRole = user?.roles[0]?.role
  }

  if (user.is_organization) {
    userRole =
      user.organizations[0]?.organization_user.organization_groups[0]?.roles[0]
        .role
  }

  if (user.organization_groups) {
    userRole = user?.organization_groups[0]?.roles[0]?.role
  }

  return userRole
}
