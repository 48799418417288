import React, { useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useAPI } from '../../../Contexts/APIContext'
import { formatStringInNav } from '../../../Helpers/String+Extension'
import { useInView } from 'react-intersection-observer'
import { Breadcrumbs } from '../../../Components/GeneralComponents/Breadcrumbs/Breadcrumbs'
import AlbumGridItem from '../../../Components/ArtistProfileComponents/AlbumGridItem/AlbumGridItem'
import Loader from '../../../Components/GeneralComponents/Loader'
import classes from './index.module.css'
import { StickyHeader } from '../../../Components/StickyHeader'

export default function Albums() {
  const pageLimit = 20
  const navigate = useNavigate()
  const { artist_name } = useParams()
  const { getAlbums } = useAPI()

  // Update the `useInView` hook with customized options
  const [ref, inView] = useInView({
    triggerOnce: false,   // Keeps triggering until it reaches the end
    threshold: 0.5,       // Triggers when 50% of the element is visible
  })

  const {
    data,
    isError,
    error,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['albumsList', artist_name],
    queryFn: ({ pageParam = 1 }) =>
      getAlbums(artist_name, pageParam, pageLimit),
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.albums_count ? nextPage : undefined
      return newPage
    },
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage]) 

  useEffect(() => {
    const handleScroll = () => {
      const scrollBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100; // 100px before bottom
      if (scrollBottom && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  function handleClickOnAlbum(album) {
    if (album !== undefined && album !== null) {
      navigate('/songs/album/' + album.f_id)
    }
  }

  return (
    <div className={classes.wrapper}>
      {!isLoading && (
        <StickyHeader>
          <Breadcrumbs
            items={[
              {
                text: 'Artists',
                onClick: () => {
                  navigate('/artists')
                },
              },
              {
                text: formatStringInNav(
                  data ? data.pages[0]?.albums[0]?.artist.name : artist_name
                ),
                onClick: () => {
                  navigate('/artist/' + artist_name)
                },
              },
              {
                text: 'Albums',
                onClick: () => {},
              },
            ]}
          />
          <h1 className={classes.title}>
            {'Albums by ' +
              (data ? data.pages[0]?.albums[0]?.artist.name : artist_name)}
          </h1>
        </StickyHeader>
      )}
      <div className={classes.albums}>
        {!isError &&
          data &&
          data.pages.map((page, index) => (
            <React.Fragment key={index}>
              {page.albums.length > 0 ? (
                page.albums.map((album, innerIndex) => (
                  <div key={album.f_id} ref={ref}>
                    <AlbumGridItem
                      album={album}
                      hidden={
                        !album?.is_public_visible || !album.has_visible_songs
                      }
                      onClick={album => {
                        handleClickOnAlbum(album)
                      }}
                    />
                  </div>
                ))
              ) : (
                <h1 className={classes.emptyResultText}>No Results</h1>
              )}
            </React.Fragment>
          ))}
      </div>

      {!isFetching && (
        <h1 className={classes.errorText}>
          {isError ? `Error ${error.status}` : ''}
        </h1>
      )}
      <Loader isLoading={isLoading || isFetchingNextPage} />
    </div>
  )
}
