import React from 'react'
import { ModalWindow } from '../../GeneralComponents/ModalWindow/ModalWindow'
import { ButtonOutline } from '../../GeneralComponents/ButtonOutline/ButtonOutline'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export function BackdropModal({ isOpenModal, setIsOpenModal, onSubmit }) {
  return (
    <ModalWindow
      open={isOpenModal}
      onClose={() => {
        setIsOpenModal(false)
      }}
    >
      <div className={classes.wrapper}>
        <div>
          <h2 className={classes.title}>Ready to post your Backdrop?</h2>
          <h6 className={classes.subTitle}>
            First let’s agree to a few things.
          </h6>
          <p className={classes.text}>
            We want to make sure we’re respecting creators’ rights. Once you
            post your Backdrop it will be considered User Content as defined in
            the Feels for Artists Terms. When you agreed to those terms you
            stated that you hae the right to post User Content.
          </p>
          <p className={classes.text}>
            By posting a Backdrop, you represent and warrant that you have all
            the necessary rights to do so, including the right to stream, allow
            offline downloads, and display your Backdrop to Feels users, all in
            connection with other content you selected (for example, lyrics and
            audio). It’s essential that you only .....
          </p>
        </div>
        <div className={classes.buttonWrapper}>
          <ButtonOutline
            disable={false}
            onClick={() => {
              onSubmit()
            }}
            fontFamily="Noto Sans"
            fontWeight={700}
            text={'AGREE & POST BACKDROP'}
            color={'pink'}
          />
        </div>
      </div>
    </ModalWindow>
  )
}

BackdropModal.propTypes = {
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
  onSubmit: PropTypes.func,
}
