import React, { useEffect, useState } from 'react'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useAPI } from '../../../Contexts/APIContext'
import { useInView } from 'react-intersection-observer'
import { formatStringInNav } from '../../../Helpers/String+Extension'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import { Breadcrumbs } from '../../../Components/GeneralComponents/Breadcrumbs/Breadcrumbs'
import FeelGridItem from '../../../Components/DashboardComponents/FeelGridItem/FeelGridItem'
// import SearchInput from '../../Components/DashboardComponents/SearchInput/SearchInput'
import Loader from '../../../Components/GeneralComponents/Loader'
import classes from './index.module.css'

export default function FeelsForSong() {
  const pageLimit = 20
  const navigate = useNavigate()
  const { id } = useParams()
  const [ref, inView] = useInView()
  const { getSongData, getFeelsForSong } = useAPI()

  //     set search query to empty string
  // const [searchTerm, setSearchTerm] = useState('')
  //     set search parameters we only what to search songs by song title and artist name
  // const [searchParam] = useState(['title', 'artist', 'tags', 'status'])
  const [feelsMuted, setFeelsMuted] = useState(true)

  const {
    data: dataSong,
    isLoading: isLoadingSong,
    isError: isErrorSong,
    isFetching: isFetchingSong,
    error: errorSong,
  } = useQuery({
    queryKey: ['songData', id],
    queryFn: () => getSongData(id),
    refetchInterval: 2000,
  })

  const {
    data: feelsData,
    isError: isErrorFeels,
    error: errorFeels,
    isLoading: isLoadingFeels,
    isFetching: isFetchingFeels,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['songFeelsList', id],
    queryFn: ({ pageParam = 1 }) => getFeelsForSong(id, pageParam, pageLimit),
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.feels_count ? nextPage : undefined
      return newPage
    },
    refetchInterval: 2000,
  })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  // function handleSearchInput(e) {
  //   setSearchTerm(e.target.value)
  // }

  function handleClickOnFeel(feel) {
    if (feel !== undefined && feel !== null) {
      navigate('/feel/' + feel.f_id)
    }
  }

  const allFeels = feelsData?.pages?.flatMap(page => page.feels) || []

  return (
    <div className={classes.wrapper}>
      {!isLoadingSong && !isLoadingFeels && (
        <Breadcrumbs
          items={
            !dataSong?.artist?.name
              ? [
                  {
                    text: dataSong?.is_promotional
                      ? 'All Artists'
                      : 'All songs',
                    onClick: () => {
                      navigate(
                        dataSong?.is_promotional ? '/artists' : '/songs/all'
                      )
                    },
                  },
                ]
              : [
                  {
                    text: dataSong?.is_promotional
                      ? 'All Artists'
                      : 'All songs',
                    onClick: () => {
                      navigate(
                        dataSong?.is_promotional ? '/artists' : '/songs/all'
                      )
                    },
                  },
                  {
                    text: formatStringInNav(dataSong?.artist?.name),
                    onClick: () => {
                      navigate('/artist/' + dataSong?.artist?.artist_name)
                    },
                  },
                  {
                    text: dataSong?.is_promotional ? 'Teases' : 'Songs',
                    onClick: () => {
                      navigate(
                        dataSong?.is_promotional
                          ? '/teases/artist/' + dataSong?.artist?.artist_name
                          : '/songs/artist/' + dataSong?.artist?.artist_name
                      )
                    },
                  },
                  {
                    text: formatStringInNav(dataSong?.name),
                    onClick: () => {
                      navigate(
                        dataSong?.is_promotional
                          ? '/tease/' + id
                          : '/song/' + id
                      )
                    },
                  },
                  {
                    text: 'Music Messages',
                    onClick: () => {},
                  },
                ]
          }
        />
      )}
      {!isLoadingSong && dataSong && !isErrorFeels && feelsData && (
        <>
          {allFeels.length > 0 && (
            <>
              <h1 className={classes.title}>
                Music Messages for &quot;{dataSong?.name}&quot;
              </h1>
              <p className={classes.subtitle}>
                {'Total Music Messages ' + allFeels.length}
              </p>
            </>
          )}

          <div className={classes.feels}>
            {allFeels.map((feel) => (
              <div key={feel.f_id} ref={ref}>
                <FeelGridItem
                  feel={feel}
                  isMutedOnHover={feelsMuted}
                  onClick={() => handleClickOnFeel(feel)}
                  onMuteUnmuteClick={() => setFeelsMuted(!feelsMuted)}
                />
              </div>
            ))}
          </div>

          {/* Show empty state if no feels are available */}
          {allFeels.length === 0 && (
            <>
              <h1 className={classes.emptyFeelsText}>
                This song has no Music Messages. Let&apos;s create one?
              </h1>
              <ButtonOutline
                text="Create Music Message"
                onClick={() => {
                  navigate('/create-new-feel-step-2/' + dataSong?.f_id)
                }}
                color="pink"
                size="small"
              />
            </>
          )}
        </>
      )}
      {/* <SearchInput
        onSearchInput={handleSearchInput}
        placeholder={'Search for Music Messages...'}
      /> */}

      {(isErrorSong || isErrorFeels) && (
        <h1 className={classes.errorText}>
          Error {errorSong ? errorSong.status : errorFeels.status}
        </h1>
      )}
      <Loader
        isLoading={isLoadingSong || isLoadingFeels || isFetchingNextPage}
      />
    </div>
  )
}
