import { useInfiniteQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useGetOrgList() {
  const { getOrganizationsList } = useAPI()
  const ORGANIZATIONS_PER_PAGE = 19
  return useInfiniteQuery({
    queryKey: ['organizations', 'list', 'All'],
    queryFn: ({ pageParam = 1 }) =>
      getOrganizationsList({ p: pageParam, n: ORGANIZATIONS_PER_PAGE }),
    initialPageParam: 1,
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * ORGANIZATIONS_PER_PAGE < pageData.organizations_count
          ? nextPage
          : undefined
      return newPage
    },
  })
}
