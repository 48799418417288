import React from 'react'
import PropTypes from 'prop-types'
import checkmark_empty from '../../icons/checkmark_empty.png'
import checkmark_full from '../../icons/checkmark_full.png'
import classes from './index.module.css'

export default function SongGridItem({ song, onClick }) {
  const hasLyrics = song.has_lyrics
  const feelsCount = song.feels_count
  const isPublished = song.is_published
  return (
    <div
      className={classes.container}
      onClick={() => {
        onClick(song)
      }}
    >
      <div className={classes.imageWrapper}>
        <img className={classes.image} src={song?.image?.url} />
      </div>
      <div className={classes.songDetailsWrapper}>
        <p className={classes.songTitle}>{song?.name}</p>
        <div className={classes.artistAvatarNameWrapper}>
          {song.artist.image.url ? (
            <img className={classes.avatar} src={song?.artist?.image?.url} />
          ) : (
            <div className={classes.emptyAvatarWrapper}>
              <p className={classes.emptyAvatarLetter}>
                {song?.artist?.name.charAt(0).toUpperCase()}
              </p>
            </div>
          )}
          <p className={classes.artistName}>{song?.artist?.name}</p>
        </div>
        {song?.track && (
          <span className={classes.statusText}>Track {song?.track}</span>
        )}

        <p className={classes.feelsCount}>
          {!feelsCount || feelsCount === 0 ? 0 : feelsCount} Music Message
          {feelsCount === 1 ? '' : 's'}
        </p>

        {hasLyrics ? (
          <div className={classes.verifiedWrapper}>
            <img
              width="24"
              height="24"
              src={song.is_sync_verified ? checkmark_full : checkmark_empty}
            />
            <span className={classes.statusText}>Sync Verified</span>
          </div>
        ) : (
          <div className={classes.statusPlateWrapper}>
            <span className={classes.statusPlateText}>Needs Lyrics</span>
          </div>
        )}
        {!isPublished ? (
          <div className={classes.unpublished}>Unpublished</div>
        ) : null}
      </div>
    </div>
  )
}

SongGridItem.propTypes = {
  song: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}
