import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { ScaleLoader } from 'react-spinners'


export const GeneratingLoader = ({ isGenerating, generatingMessage }) => (
  <div className={classes.wrapper}>
    <div className={classes.preview}>
      <ScaleLoader color="#FF00FF" loading={isGenerating} />
    </div>
    {generatingMessage && (
      <p
        className={classes.message}
      >
        {generatingMessage}
      </p>
    )}
  </div>
)

GeneratingLoader.propTypes = {
  isGenerating: PropTypes.bool,
  generatingMessage: PropTypes.string,
}
