import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { formatStringInNav } from '../../../Helpers/String+Extension'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import { Breadcrumbs } from '../../../Components/GeneralComponents/Breadcrumbs/Breadcrumbs'
import AlbumGridItem from '../../../Components/ArtistProfileComponents/AlbumGridItem/AlbumGridItem'
import ArtistAlbumsHeader from '../../../Components/ArtistProfileComponents/AlbumGridItem/ArtistAlbumsHeader'
import ArtistEditData from '../../../Components/ArtistProfileComponents/ArtistEditData/ArtistEditData'
import ArtistProfileHeader from '../../../Components/ArtistProfileComponents/ArtistHeaderComponent/ArtistProfileHeader'
import ArtistTopSongGridItem from '../../../Components/ArtistProfileComponents/ArtistTopSongsComponent/ArtistTopSongGridItem'
import ArtistTopSongsHeader from '../../../Components/ArtistProfileComponents/ArtistTopSongsComponent/ArtistTopSongsHeader'
import Loader from '../../../Components/GeneralComponents/Loader'
import classes from './index.module.css'
import { StickyHeader } from '../../../Components/StickyHeader'
import useGetArtistData from '../../../Helpers/hooks/useGetArtistData'
import SongFeelsHeader from '../../../Components/SongsComponents/SongFeelsHeader/SongFeelsHeader'
import FeelGridItem from '../../../Components/DashboardComponents/FeelGridItem/FeelGridItem'
import DebounceSearch from '../../../Components/GeneralComponents/DebounceSearch/DebounceSearch'
import { useArtistSongsStore } from '../../../Storage/artistSongsPage/useArtistSongsStore'
import { useArtistFeelsStore } from '../../../Storage/artistFeelsPage/useArtistFeelsStore'
import useClearArtistSearchTerms from '../../../Helpers/hooks/useClearArtistSearchTerms'
import { permissions } from '../../../constants/roles'
import useReadOnly from '../../../services/hooks/useReadOnly'
import usePopup from '../../Teases/hooks/usePopup'
import TeasePopup from '../../Teases/TeasePopup'
import TeasePopupContent from '../../Teases/TeasePopupContent'
import ArtistTopTeaseGridItem from '../../../Components/ArtistProfileComponents/ArtistTopTeaseGridItem'

export default function Artist() {
  const albumsPageLimit = 20
  const songsPageLimit = 3
  const pageNumber = 1
  const {
    getArtistSongs,
    getAlbums,
    getArtistFeels,
    getSearchAllSongs,
    searchFeels,
    getArtistTeases,
  } = useAPI()
  const { artist_name } = useParams()
  const navigate = useNavigate()

  const [itemsToShowFeels, setItemsToShowFeels] = useState(6)
  const [itemsToShowSongs, setItemsToShowSongs] = useState(6)
  const [itemsToShowAlbums, setItemsToShowAlbums] = useState(14)
  const [itemsToShowTeases, setItemsToShowTeases] = useState(4)

  const containerRefFeels = useRef(null)
  const containerRefSongs = useRef(null)
  const containerRefAlbums = useRef(null)
  const containerRefTeases = useRef(null)

  const [isOpenModal, setIsOpenModal] = useState(false)

  const { isReadOnly: isSongReadOnly } = useReadOnly(permissions.r.music_song)
  const { isReadOnly: isFeelsFactoryReadOnly } = useReadOnly(
    permissions.r.feels_factory
  )

  const { popupRef, openPopup, closePopup, isOpen } = usePopup()

  const { searchTerm, setSearchTerm, inputValue, setInputValue } =
    useClearArtistSearchTerms({ artistName: artist_name })

  const selectedSongOption = useArtistSongsStore(state => state.selectedOption)
  const selectedFeelOption = useArtistFeelsStore(state => state.selectedOption)

  const {
    data: dataArtist,
    isLoading: isLoadingArtist,
    isFetching: isFetchingArtist,
    isError: isErrorArtist,
    error: errorArtist,
  } = useGetArtistData({ artistName: artist_name })

  const {
    data: dataFeels,
    isLoading: isLoadingFeels,
    isFetching: isFetchingFeels,
    isError: isErrorFeels,
    error: errorFeels,
  } = useQuery({
    queryFn: ({ pageParam = 1 }) =>
      searchTerm === ''
        ? getArtistFeels(artist_name, pageNumber, songsPageLimit)
        : searchFeels(
            searchTerm,
            pageParam,
            songsPageLimit,
            undefined,
            dataArtist.f_id
          ),

    queryKey: ['artistFeels', artist_name, searchTerm, selectedFeelOption.id],
    enabled: !isLoadingArtist && !isFetchingArtist && !isErrorArtist,
  })

  const {
    data: dataSongs,
    isLoading: isLoadingSongs,
    isFetching: isFetchingSongs,
    isError: isErrorSongs,
    error: errorSongs,
  } = useQuery({
    queryFn: ({ pageParam = 1 }) =>
      searchTerm === ''
        ? getArtistSongs(artist_name, pageNumber, songsPageLimit)
        : getSearchAllSongs(
            searchTerm,
            pageParam,
            songsPageLimit,
            dataArtist.f_id
          ),
    queryKey: ['artistSongs', artist_name, searchTerm, selectedSongOption.id],
    enabled: !isLoadingArtist && !isFetchingArtist && !isErrorArtist,
  })

  const {
    data: dataTeases,
    isLoading: isLoadingTeases,
    isFetching: isFetchingTeases,
    isError: isErrorTeases,
    error: errorTeases,
  } = useQuery({
    queryFn: ({ pageParam = 1 }) =>
      searchTerm === ''
        ? getArtistTeases(artist_name, pageNumber, songsPageLimit)
        : getSearchAllSongs(
            searchTerm,
            pageParam,
            songsPageLimit,
            dataArtist.f_id
          ),
    queryKey: ['artistTeases', artist_name, searchTerm, selectedSongOption.id],
    enabled: !isLoadingArtist && !isFetchingArtist && !isErrorArtist,
  })

  const {
    data: dataAlbums,
    isLoading: isLoadingAlbums,
    isFetching: isFetchingAlbums,
    isError: isErrorAlbums,
    error: errorAlbums,
  } = useQuery({
    queryKey: ['artistAlbums', artist_name],
    queryFn: () => getAlbums(artist_name, pageNumber, albumsPageLimit),
    enabled: !isLoadingArtist && !isFetchingArtist && !isErrorArtist,
  })

  const calculateItemsPerRow = (ref, itemWidth, gap, setItemsToShow) => {
    if (ref.current) {
      const containerWidth = ref.current.offsetWidth
      const itemsPerRow = Math.floor((containerWidth + gap) / (itemWidth + gap))
      const newItemsToShow = itemsPerRow * 2 // To show items that fill 2 rows
      setItemsToShow(newItemsToShow)
    }
  }

  const handleResize = () => {
    calculateItemsPerRow(containerRefFeels, 325, 40, setItemsToShowFeels)
    calculateItemsPerRow(containerRefSongs, 273, 40, setItemsToShowSongs)
    calculateItemsPerRow(containerRefAlbums, 130, 25, setItemsToShowAlbums)
    calculateItemsPerRow(containerRefTeases, 317, 65, setItemsToShowTeases)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [!!dataFeels, !!dataSongs, !!dataAlbums, !!dataTeases])

  // TO DO:
  function handleOnCreateTease() {
    openPopup()
  }

  if (!isFetchingArtist && isErrorArtist) {
    return (
      <h1 className={classes.errorText}>
        {isErrorArtist ? `Error ${errorArtist.status}` : 'Artist not found'}
      </h1>
    )
  }

  return (
    <>
      <TeasePopup popupRef={popupRef} closePopup={closePopup} isOpen={isOpen}>
        <TeasePopupContent closePopup={closePopup} artist={dataArtist} />
      </TeasePopup>
      {dataArtist ? (
        <ArtistEditData
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          artist={dataArtist}
        />
      ) : null}

      <div className={classes.wrapper}>
        {isLoadingArtist ? (
          <Loader isLoading={isLoadingArtist} />
        ) : (
          <StickyHeader>
            <Breadcrumbs
              items={
                !dataArtist?.name
                  ? [
                      {
                        text: 'All Artists',
                        onClick: () => {
                          navigate('/artists')
                        },
                      },
                    ]
                  : [
                      {
                        text: 'All Artists',
                        onClick: () => {
                          navigate('/artists')
                        },
                      },
                      {
                        text: formatStringInNav(dataArtist.name),
                        onClick: () => {},
                      },
                    ]
              }
            />
            <div className={classes.info}>
              <div className={classes.info__artist}>
                {dataArtist ? (
                  <ArtistProfileHeader
                    artist={dataArtist}
                    onEditClick={() => {
                      setIsOpenModal(true)
                    }}
                  />
                ) : null}
              </div>
              <div className={classes.info__actions}>
                <ButtonOutline
                  disable={isFeelsFactoryReadOnly}
                  color={'pink'}
                  size="small"
                  icon="createIcon"
                  text={'CREATE MUSIC MESSAGE'}
                  onClick={() => {
                    navigate(
                      '/create-new-feel-step-1/' + dataArtist.artist_name
                    )
                  }}
                />
                <ButtonOutline
                  disable={isSongReadOnly}
                  color={'pink'}
                  size="small"
                  icon="createIcon"
                  text={'CREATE TEASE'}
                  onClick={() => {
                    handleOnCreateTease()
                  }}
                />
                <div className={classes.search}>
                  <DebounceSearch
                    searchValue={searchTerm}
                    inputValue={inputValue}
                    setSearchValue={setSearchTerm}
                    setInputValue={setInputValue}
                    placeholder={'Search for Music Messages, Songs...'}
                  />
                </div>
              </div>
            </div>
          </StickyHeader>
        )}
        <div className={classes.content}>
          {isLoadingFeels ? (
            <Loader isLoading={isLoadingFeels} />
          ) : (
            <div>
              {dataFeels?.feels?.length > 0 ? (
                <div className={classes.content__block}>
                  <SongFeelsHeader
                    title="Music Messages"
                    totalFeels={dataFeels?.feels_count ?? 0}
                    onClick={() => {
                      navigate('/feels/artist/' + dataArtist?.artist_name)
                    }}
                  />
                  <div
                    className={classes.topSongsItemsWrapper}
                    ref={containerRefFeels}
                  >
                    {[...dataFeels?.feels]
                      .slice(0, itemsToShowFeels)
                      .map(feel => (
                        <FeelGridItem
                          key={feel.f_id}
                          feel={feel}
                          onMuteUnmuteClick={() => false}
                          onClick={feel => {
                            navigate('/feel/' + feel.f_id)
                          }}
                        />
                      ))}
                  </div>
                </div>
              ) : !isLoadingArtist ? (
                <div className={classes.emptyResultContainer}>
                  <p className={classes.emptyResultText}>
                    {!isFetchingFeels &&
                      (isErrorFeels
                        ? `Error ${errorFeels.status}`
                        : 'No Music Messages')}
                  </p>
                </div>
              ) : null}
            </div>
          )}
          {isLoadingSongs ? (
            <Loader isLoading={isLoadingSongs} />
          ) : (
            <div>
              {dataSongs?.songs?.length > 0 ? (
                <div className={classes.content__block}>
                  <ArtistTopSongsHeader
                    title="Songs"
                    totalSongs={dataSongs?.songs_count ?? 0}
                    onClick={() => {
                      navigate('/songs/artist/' + dataArtist?.artist_name)
                    }}
                  />
                  <div
                    className={classes.topSongsItemsWrapper}
                    ref={containerRefSongs}
                  >
                    {[...dataSongs.songs]
                      .slice(0, itemsToShowSongs)
                      .map(song => (
                        <ArtistTopSongGridItem
                          key={song.f_id}
                          song={song}
                          onClick={song => {
                            navigate('/song/' + song.f_id)
                          }}
                        />
                      ))}
                  </div>
                </div>
              ) : !isLoadingArtist ? (
                <div className={classes.emptyResultContainer}>
                  <p className={classes.emptyResultText}>
                    {!isFetchingSongs &&
                      (isErrorSongs
                        ? `Error ${errorSongs.status}`
                        : 'No Songs')}
                  </p>
                </div>
              ) : null}
            </div>
          )}
          {isLoadingAlbums ? (
            <Loader isLoading={isLoadingAlbums} />
          ) : (
            <div>
              {dataAlbums?.albums?.length > 0 ? (
                <div className={classes.albums}>
                  <ArtistAlbumsHeader
                    totalAlbums={dataAlbums?.albums_count ?? 0}
                    onClick={() => {
                      navigate('/albums/' + dataArtist?.artist_name)
                    }}
                  />
                  <div
                    className={classes.albumsWrapper}
                    ref={containerRefAlbums}
                  >
                    {[...dataAlbums.albums]
                      .slice(0, itemsToShowAlbums)
                      .map(album => (
                        <AlbumGridItem
                          key={album.f_id}
                          hidden={
                            !album?.is_public_visible ||
                            !album.has_visible_songs
                          }
                          album={album}
                          onClick={album => {
                            navigate('/songs/album/' + album.f_id)
                          }}
                        />
                      ))}
                  </div>
                </div>
              ) : !isLoadingArtist ? (
                <div className={classes.emptyResultContainer}>
                  <p className={classes.emptyResultText}>
                    {!isFetchingAlbums &&
                      (isErrorAlbums
                        ? `Error ${errorAlbums.content__block}`
                        : 'No Albums')}
                  </p>
                </div>
              ) : null}
            </div>
          )}
          {isLoadingTeases ? (
            <Loader isLoading={isLoadingTeases} />
          ) : (
            <div>
              {dataTeases?.songs?.length > 0 ? (
                <div className={classes.content__block}>
                  <ArtistTopSongsHeader
                    title="Tease Content"
                    totalSongs={dataTeases?.songs_count ?? 0}
                    onClick={() => {
                      navigate('/teases/artist/' + dataArtist?.artist_name)
                    }}
                  />
                  <div
                    className={classes.topTeasesItemsWrapper}
                    ref={containerRefTeases}
                  >
                    {[...dataTeases.songs]
                      .slice(0, itemsToShowTeases)
                      .map(song => (
                        <ArtistTopTeaseGridItem
                          key={song.f_id}
                          song={song}
                          onClick={song => {
                            navigate('/tease/' + song.f_id)
                          }}
                        />
                      ))}
                  </div>
                </div>
              ) : !isLoadingArtist ? (
                <div className={classes.emptyResultContainer}>
                  <p className={classes.emptyResultText}>
                    {!isFetchingTeases &&
                      (isErrorTeases
                        ? `Error ${errorTeases.status}`
                        : 'No Teases')}
                  </p>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
