import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlusIcon } from '../../../../icons/plus.svg'
import classes from './index.module.css'

export default function SuggestedTag({ tag, disabled, onClick }) {
  return (
    <button
      onClick={() => onClick(tag)}
      disabled={disabled}
      className={classes.button}
    >
      <span>#{tag}</span>
      <PlusIcon />
    </button>
  )
}

SuggestedTag.propTypes = {
  tag: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
