/* eslint-disable react/prop-types */
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { useAuth } from '../../../Contexts/AuthContext'
import successToast from '../../../Components/Toasts/SuccessToast'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import ArtistAndSongName from '../../../Components/GeneralComponents/ArtistAndSongName/ArtistAndSongName'
import Loader from '../../../Components/GeneralComponents/Loader'
import FeelGridItem from '../../../Components/DashboardComponents/FeelGridItem/FeelGridItem'
import SongFeelsHeader from '../../../Components/SongsComponents/SongFeelsHeader/SongFeelsHeader'
import OptionsButton from '../../../Components/GeneralComponents/OptionsButton/OptionsButton'
import checkmark_full from '../../../Components/icons/checkmark_full.png'
import checkmark_empty from '../../../Components/icons/checkmark_empty.png'
import diff from 'deep-diff'

import classes from './index.module.css'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { Navigation } from '../../../Components/page/Navigation'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import { Actions } from '../../../Components/page/Actions'

import { permissions } from '../../../constants/roles'
import useReadOnly from '../../../services/hooks/useReadOnly'
import { renderBreadcrumbs } from './beadcrumbs'
import PublicationBadge from '../../../Components/GeneralComponents/PublicationBadge'
import { useCreateTease } from '../hooks/useCreateTease'
import errorToast from '../../../Components/Toasts/ErrorToast'
import TextFields from './TextFields'
import { isValidUrl } from '../helpers/isValidUrl'
import { usePreventFullscreen } from '../../../Helpers/hooks/usePreventFullscreen'

export default function TeaseDetails() {
  const pageParam = 1
  const pageLimit = 3
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { getCurrentUser } = useAuth()
  const { saveSongLockState, getSongData, getFeelsForSong } = useAPI()
  const initialTeaseRef = useRef()

  const [songData, setSongData] = useState(null)
  const [difference, setDifference] = useState(undefined)

  const {
    mutate: createTease,
    isPending: isLoadingCreateTease,
    isError: isErrorCreateTease,
  } = useCreateTease()

  const handleUpdateTease = teaseData => {
    if (!songData?.is_sync_verified) {
      errorToast(
        'A Tease Song can not publish until Tease Song is not Sync Verified'
      )
      return
    }
    if (!songData?.feels_count) {
      errorToast(
        'A Tease Song can not publish until at least ONE Music Message is made from the Tease Song'
      )
      return
    }
    if (songData?.promoting?.url && !isValidUrl(songData?.promoting?.url)) {
      errorToast(
        'The URL you entered is not valid. Please provide a valid URL starting with http:// or https://'
      )
      return
    }
    createTease(teaseData, {
      onSuccess: () => {
        handleChanges('promoting')
          ? successToast('Tease updated successfully')
          : successToast('Tease published successfully')

        queryClient.invalidateQueries(['tease', id])
      },
      onError: error => {
        handleChanges('promoting')
          ? errorToast('Error updating tease', error)
          : errorToast('Error publishing tease', error)
      },
    })
  }

  const { id } = useParams()

  const videoElement = useRef()
  usePreventFullscreen(videoElement)

  const [feelsMuted, setFeelsMuted] = useState(true)
  const [isSavingSong, setIsSavingSong] = useState(false)

  const { isReadOnly: isSongReadOnly } = useReadOnly(permissions.r.music_song)

  const {
    data: dataSong,
    isLoading: isLoadingSong,
    isFetching: isFetchingSong,
    isError: isErrorSong,
    error: errorSong,
  } = useQuery({
    queryKey: ['tease', id],
    queryFn: () => getSongData(id),
  })

  const promotionalTeaseData = {
    artist_f_id: songData?.artist.f_id,
    songFID: songData?.f_id,
    is_published: !songData?.is_published,
    is_public_visible: !songData?.is_published,
  }

  const urlTeaseData = {
    promotional_url: songData?.promoting?.url || null,
    promotional_url_display_text: songData?.promoting?.url_display_text || null,
    artist_f_id: songData?.artist.f_id,
    songFID: songData?.f_id,
  }
  const urlTextTeaseData = {
    promotional_url_display_text: songData?.promoting?.url_display_text || null,
    artist_f_id: songData?.artist.f_id,
    songFID: songData?.f_id,
  }

  const {
    data: dataFeels,
    isLoading: isLoadingFeels,
    isFetching: isFetchingFeels,
    isError: isErrorFeels,
    error: errorFeels,
  } = useQuery({
    queryKey: ['songFeels', id],
    queryFn: () => getFeelsForSong(id, pageParam, pageLimit),
  })

  const lockSongMutation = useMutation({
    mutationFn: () =>
      saveSongLockState(id, !songData?.feels_locked, getCurrentUser()?.email),
    onSuccess: () => {
      queryClient.invalidateQueries(['tease', id])
      setIsSavingSong(false)
    },
    onError: e => {
      setIsSavingSong(false)
    },
  })

  const handleOnLock = () => {
    setIsSavingSong(true)
    lockSongMutation.mutate()
  }

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  const handleUrlSave = () => {
    if (handleChanges('promoting')) {
      handleUpdateTease(urlTeaseData)
    }
  }

  const handleUrlTextSave = () => {
    handleUpdateTease(urlTextTeaseData)
  }

  const onTextFieldValueChange = e => {
    setSongData({
      ...songData,
      promoting: { ...songData.promoting, [e.target.name]: e.target.value },
    })
  }

  const handleOptionSelect = option => {
    setSongData({
      ...songData,
      promoting: { ...songData.promoting, url_display_text: option },
    })
  }

  const handleChanges = (...props) =>
    difference &&
    [...new Set(difference)].some(item =>
      item.some(item => props.includes(item))
    )

  useEffect(() => {
    if (dataSong) {
      const newTease = JSON.parse(JSON.stringify(dataSong))
      setSongData(JSON.parse(JSON.stringify(newTease)))
      initialTeaseRef.current = JSON.parse(JSON.stringify(newTease))
    }
  }, [dataSong])

  useEffect(() => {
    if (
      difference &&
      handleChanges('url_display_text') &&
      !handleChanges('url')
    ) {
      handleUrlTextSave()
    }
  }, [!!difference])

  useEffect(() => {
    if (songData && initialTeaseRef.current) {
      const diffEdits = diff(songData, initialTeaseRef.current)?.reduce(
        (acc, item) => item.path && [...acc, item.path],
        []
      )
      setDifference(diffEdits)
    }
  }, [songData, initialTeaseRef.current])
  return (
    <Wrapper className={classes.wrapper}>
      {!isLoadingSong && (
        <Header>
          <Navigation breadcrumbs={renderBreadcrumbs(songData)} />
          {songData && (
            <HeaderInfo>
              <ArtistAndSongName
                song={songData}
                badge={
                  <>
                    <PublicationBadge
                      size="md"
                      status={'tease'}
                      text={'Tease'}
                    />
                    <PublicationBadge
                      size="md"
                      status={
                        songData.is_published ? 'published' : 'unpublished'
                      }
                      text={songData.is_published ? 'Published' : 'Unpublished'}
                    />
                  </>
                }
              />

              {isLoadingCreateTease || isFetchingSong ? (
                <div className={classes.loader}>
                  <Loader isLoading={isLoadingCreateTease || isFetchingSong} />
                </div>
              ) : (
                <Actions>
                  {songData.is_published ? (
                    <ButtonOutline
                      color={'red'}
                      size="small"
                      text={'UNPUBLISH'}
                      disable={isSongReadOnly}
                      onClick={() => handleUpdateTease(promotionalTeaseData)}
                    />
                  ) : (
                    <ButtonOutline
                      color={'pink'}
                      size="small"
                      text={'PUBLISH'}
                      disable={isSongReadOnly}
                      onClick={() => handleUpdateTease(promotionalTeaseData)}
                    />
                  )}
                  <ButtonOutline
                    disable={isSongReadOnly}
                    color={'pink'}
                    size="small"
                    icon="createIcon"
                    text={'CREATE NEW MUSIC MESSAGES'}
                    onClick={() => {
                      navigate('/create-new-feel-step-2/' + songData?.f_id)
                    }}
                  />

                  <ButtonOutline
                    disable={isSongReadOnly}
                    color={'pink'}
                    size="small"
                    text={'EDIT TIMING'}
                    onClick={() => {
                      queryClient.clear()
                      navigate('/edit-tease/' + songData?.f_id)
                    }}
                  />
                  <div style={{ marginTop: '3px', position: 'relative' }}>
                    <OptionsButton
                      disabled={isSongReadOnly}
                      options={[
                        {
                          title: songData?.feels_locked
                            ? 'UNLOCK SONG'
                            : 'LOCK SONG',
                          func: handleOnLock,
                          disable: isSavingSong,
                        },
                      ]}
                    />
                  </div>
                </Actions>
              )}
            </HeaderInfo>
          )}
        </Header>
      )}
      {songData && (
        <>
          <main className={classes.main}>
            <div className={classes.video}>
              <div className={classes.video__verified}>
                <div className={classes.video__verified_image}>
                  <img
                    width="24"
                    height="24"
                    src={
                      songData?.is_sync_verified
                        ? checkmark_full
                        : checkmark_empty
                    }
                  />
                </div>
                <div className={classes.video__verified_text}>
                  Sync Verified
                </div>
              </div>
              <div className={classes.videoPlayer}>
                <video
                  ref={videoElement}
                  controls
                  autoPlay={true}
                  muted={true}
                  onTimeUpdate={() => {
                    handleOnTimeUpdate()
                  }}
                  src={songData?.video?.video?.url}
                  width={267}
                  height={465}
                  controlsList="nofullscreen"
                />
              </div>
            </div>

            {!isLoadingFeels && dataFeels?.feels?.length > 0 ? (
              <>
                <div className={classes.musicMessageWrapper}>
                  <TextFields
                    tease={songData}
                    onChange={onTextFieldValueChange}
                    handleOptionSelect={handleOptionSelect}
                    handleSave={handleUrlSave}
                    disabled={isLoadingCreateTease || isFetchingSong}
                  />
                  <SongFeelsHeader
                    title="Tease Music Messages"
                    totalFeels={dataFeels?.feels_count}
                    onClick={() => {
                      navigate('/tease/feels/' + songData?.f_id)
                    }}
                  />
                  <div className={classes.feels}>
                    {dataFeels.feels.map(item => (
                      <FeelGridItem
                        key={item.f_id}
                        feel={item}
                        isMutedOnHover={feelsMuted}
                        onClick={feel => {
                          navigate('/feel/' + feel.f_id)
                        }}
                        onMuteUnmuteClick={() => {
                          setFeelsMuted(!feelsMuted)
                        }}
                      />
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                {isLoadingFeels && <Loader isLoading={isLoadingFeels} />}
                <p className={classes.emptyFeelErrorText}>
                  {!isLoadingFeels &&
                    (isErrorFeels
                      ? `Error ${errorFeels.status}`
                      : 'No Music Messages created yet')}
                </p>
              </>
            )}
          </main>
        </>
      )}

      {isErrorSong && (
        <h1 className={classes.errorText}>Error {errorSong.status}</h1>
      )}
      {isLoadingSong ? <Loader isLoading={isLoadingSong} /> : null}
    </Wrapper>
  )
}
