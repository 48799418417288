const isPlural = (entity, result = 's') => (entity === 1 ? '' : result)

const handleActivity = ({ dateTo, dateFrom = Date.now() }) => {
  const date1 = new Date(dateTo)
  const date2 = new Date(dateFrom)
  const diff = {}

  diff.years = date2.getFullYear() - date1.getFullYear()
  diff.months = date2.getMonth() - date1.getMonth()
  diff.days = date2.getDate() - date1.getDate()

  if (diff.days < 0) {
    diff.months -= 1
    diff.days += new Date(date2.getFullYear(), date2.getMonth(), 0).getDate()
  }

  if (diff.months < 0) {
    diff.years -= 1
    diff.months += 12
  }

  if (!!diff.years) {
    return `${diff.years} year${isPlural(diff.years)} ago`
  }
  if (!!diff.months) {
    return `${diff.months} month${isPlural(diff.months)} ago`
  }
  if (!!diff.days) {
    return `${diff.days} day${isPlural(diff.days)} ago`
  }

  return 'Today'
}

const convertMillisecondsToSeconds = milliseconds => {
  const seconds = milliseconds / 1000
  return Number(seconds.toFixed(1))
}

export { isPlural, handleActivity, convertMillisecondsToSeconds }
