import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export default function useTags({ officialTags, handleTagsUpdated }) {
  const [tags, setTags] = useState([])
  const [currentlyEnteredTag, setCurrentlyEnteredTag] = useState('')

  useEffect(() => {
    if (
      officialTags !== undefined &&
      officialTags !== null &&
      officialTags?.length
    ) {
      setTags(officialTags)
    }
  }, [officialTags])

  function handleOnEnterPressed(tag) {
    if (
      tag ||
      (currentlyEnteredTag !== undefined &&
        currentlyEnteredTag !== null &&
        currentlyEnteredTag.length > 0)
    ) {
      const newTags = [...tags]
      newTags.push(tag || currentlyEnteredTag)
      setTags(newTags)
      handleTagsUpdated(newTags)
      setCurrentlyEnteredTag('')
    }
  }

  function handleTagInput(e) {
    setCurrentlyEnteredTag(e.target.value)
  }

  function handleDeleteTag(tagToDelete) {
    const newTags = tags.filter(tagInArray => {
      return tagInArray !== tagToDelete
    })
    setTags(newTags)
    handleTagsUpdated(newTags)
  }
  return {
    tags,
    setTags,
    currentlyEnteredTag,
    setCurrentlyEnteredTag,
    handleOnEnterPressed,
    handleTagInput,
    handleDeleteTag,
  }
}

useTags.propTypes = {
  officialTags: PropTypes.array,
  handleTagsUpdated: PropTypes.func,
}
