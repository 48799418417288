import React, { useState, useEffect } from 'react'
import { useAPI } from '../../../Contexts/APIContext'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createStoreForStickers } from '../../../Storage/stickersPage/createStoreForStickers'
import Sticker from '../Sticker/Sticker'
import PropTypes from 'prop-types'
import StickerNameInput from '../StickerNameInput/StickerNameInput'
import StickerTextArea from '../StickerTextArea/StickerTextArea'
import StatusButton from '../../GeneralComponents/StatusButton/StatusButton'
import Loader from '../../GeneralComponents/Loader'
import Prompt from '../../DashboardComponents/Prompt/Prompt'
import errorToast from '../../Toasts/ErrorToast'
import classes from './index.module.css'
export default function StickerPack({
  sticker,
  isEdit,
  setIsEdit,
  setName,
  setCurrentStickerPack,
  isLoadingSave,
  setIsLoadingSave,
}) {
  const {
    getStickersPack,
    deleteStickersPack,
    updatePackDetails,
    getStickersUrls,
    uploadStickerItem,
    updateStickersStatus,
  } = useAPI()
  const queryClient = useQueryClient()
  const [isPromptDelete, setIsPromptDelete] = useState(false)
  const [isRequestUrls, setIsRequestUrls] = useState(false)

  // zustand storage
  const useStickerPackStore = createStoreForStickers(sticker.f_id, sticker.name)

  const packName = useStickerPackStore(state => state.packName)
  const setPackName = useStickerPackStore(state => state.setPackName)

  const packInitialName = useStickerPackStore(state => state.packInitialName)
  const setPackInitialName = useStickerPackStore(
    state => state.setPackInitialName
  )

  const packDescription = useStickerPackStore(state => state.packDescription)
  const setPackDescription = useStickerPackStore(
    state => state.setPackDescription
  )
  const packInitialDescription = useStickerPackStore(
    state => state.packInitialDescription
  )
  const setPackInitialDescription = useStickerPackStore(
    state => state.setPackInitialDescription
  )

  const isFullStickers = useStickerPackStore(state => state.isFullStickers)
  const setIsFullStickers = useStickerPackStore(
    state => state.setIsFullStickers
  )

  // -- isEdit --
  const isEditDetails = useStickerPackStore(state => state.isEditDetails)
  const setIsEditName = useStickerPackStore(state => state.setIsEditName)
  const setIsEditDescription = useStickerPackStore(
    state => state.setIsEditDescription
  )
  const isEditStickers = useStickerPackStore(state => state.isEditStickers)
  const setIsEditStickers = useStickerPackStore(
    state => state.setIsEditStickers
  )
  // -- stickers grid --
  const stickersGrid = useStickerPackStore(state => state.stickersGrid)
  const setStickersGrid = useStickerPackStore(state => state.setStickersGrid)
  const stickersGridInitial = useStickerPackStore(
    state => state.stickersGridInitial
  )
  const setStickersGridInitial = useStickerPackStore(
    state => state.setStickersGridInitial
  )
  const setStickersUploadUrls = useStickerPackStore(
    state => state.setStickersUploadUrls
  )
  // -----------

  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ['stickerPack', sticker.f_id],
    queryFn: () => getStickersPack(sticker.f_id),
  })

  const { data: stickersUrlsData } = useQuery({
    queryKey: ['stickersUrls'],
    queryFn: () => getStickersUrls(sticker.f_id),
    enabled: isRequestUrls,
  })

  const updatePackDetailsMutation = useMutation({
    mutationFn: data =>
      updatePackDetails(sticker.f_id, packName, packDescription),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stickers'] })
      queryClient.invalidateQueries({ queryKey: ['stickerPack', sticker.f_id] })
      setIsEditName(false)
      setIsEditDescription(false)
      setPackInitialName(packName)
      setPackInitialDescription(packDescription)
    },
  })

  const updateStatusMutation = useMutation({
    mutationFn: ({ status }) => updateStickersStatus(sticker.f_id, status),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stickers'] })
      queryClient.invalidateQueries({ queryKey: ['stickerPack', sticker.f_id] })
    },
    onSettled: () => {
      setIsLoadingSave(false)
    },
  })

  const deletePackMutation = useMutation({
    mutationFn: data => deleteStickersPack(sticker.f_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stickers'] })
      setCurrentStickerPack()
      setIsPromptDelete(false)
    },
    onSettled: () => {
      setIsLoadingSave(false)
    },
    onError: e => {
      setIsPromptDelete(false)
    },
  })

  const stickers = Array.from({ length: 16 }, (_, index) => index)
  const stickerRows = stickers.reduce((acc, _, index) => {
    if (index % 4 === 0) {
      acc.push(stickers.slice(index, index + 4))
    }
    return acc
  }, [])

  useEffect(() => {
    if (isEditDetails.name || isEditDetails.description || isEditStickers) {
      setIsEdit(sticker.f_id, true)
    } else setIsEdit(sticker.f_id, false)
    if (stickersGrid) {
      const isAllStickers = Object.values(stickersGrid).every(
        sticker => sticker.previewSticker !== null
      )
      setIsFullStickers(isAllStickers)
    }
  }, [data, isEditDetails, isEditStickers, stickersGrid])

  useEffect(() => {
    setName(sticker.f_id, packName)
  }, [packName])

  useEffect(() => {
    data &&
      !isEditDetails.description &&
      (setPackDescription(data?.description),
      setPackInitialDescription(data?.description))

    if (data && !stickersGridInitial) {
      const newStickersGrid = data.stickers.reduce((acc, sticker) => {
        acc[sticker.tag] = {
          name: sticker.name,
          tag: sticker.tag,
          previewSticker: sticker.url ? sticker.url : null,
          uploadSticker: null,
          uploadUrl: null,
        }
        return acc
      }, {})
      setStickersGrid(newStickersGrid)
      setStickersGridInitial(newStickersGrid)
    }
    if (stickersUrlsData) {
      const stickersUrls = stickersUrlsData?.uploads?.stickers
      setStickersUploadUrls(stickersUrls)
    }
  }, [data, isEditDetails, stickersGridInitial, stickersUrlsData])

  // UPDATE DETAILS

  const saveHandler = async () => {
    setIsLoadingSave(true)
    try {
      const uploadPromises = []
      if (isEditDetails.name || isEditDetails.description) {
        await updatePackDetailsMutation.mutate()
      }
      if (isEditStickers) {
        for (const key in stickersGrid) {
          if (stickersGrid[key].uploadSticker !== null) {
            const promise = uploadStickerItem(
              stickersGrid[key].uploadUrl,
              stickersGrid[key].uploadSticker,
              'image/gif'
            )
            uploadPromises.push(promise)
          }
        }
        await Promise.all(uploadPromises)
        setStickersGridInitial(null)
        setIsEditStickers(false)
        queryClient.invalidateQueries({
          queryKey: ['stickerPack', sticker.f_id],
        })
      }
    } catch (e) {
      errorToast(`Error: ${e.status ?? ''} ${e.message ?? ''}`)
    }
    setIsLoadingSave(false)
  }

  // UPLOAD STICKERS

  const setPreviewHandler = (file, tag) => {
    const currentStickerGrid = stickersGrid[tag]
    const newStickerGrid = { ...currentStickerGrid, previewSticker: file }
    setStickersGrid({ ...stickersGrid, [tag]: newStickerGrid })
    setIsEditStickers(true)
  }

  const setFileHandler = (file, tag) => {
    const currentStickerGrid = stickersGrid[tag]
    const newStickerGrid = { ...currentStickerGrid, uploadSticker: file }
    setStickersGrid({ ...stickersGrid, [tag]: newStickerGrid })
    setIsRequestUrls(true)
  }

  // PUBLISH/UNPUBLISH STICKERS

  const unpublishHandler = () => {
    setIsLoadingSave(true)
    updateStatusMutation.mutate({ status: false })
  }

  const publishHandler = () => {
    if (isEditDetails.name || isEditDetails.description || isEditStickers) {
      errorToast('Before publishing, please save your changes')
    } else {
      setIsLoadingSave(true), updateStatusMutation.mutate({ status: true })
    }
  }

  if (isLoading || isFetching || isLoadingSave) {
    return (
      <div className={classes.loadingWrapper}>
        <Loader isLoading={isLoading || isFetching || isLoadingSave} />
      </div>
    )
  }

  return (
    <div>
      {isPromptDelete && (
        <Prompt
          title={'Are you sure?'}
          subtitle={'This will delete sticker set'}
          primaryAction={'CANCEL'}
          onPrimaryClick={() => {
            setIsPromptDelete(false)
          }}
          secondaryAction={'DELETE'}
          onSecondaryClick={() => {
            setIsLoadingSave(true), deletePackMutation.mutate()
          }}
          isSecondaryActionDestructive={true}
          onCloseClick={() => {
            setIsPromptDelete(false)
          }}
        />
      )}
      <StickerNameInput
        name={packName}
        setName={setPackName}
        initialValue={packInitialName}
        setInitialValue={setPackInitialName}
        setIsEdit={state => setIsEditName(state)}
      />
      <StickerTextArea
        description={packDescription}
        setDescription={setPackDescription}
        initialValue={packInitialDescription}
        setInitialValue={setPackInitialDescription}
        setIsEdit={state => setIsEditDescription(state)}
      />
      <p className={classes.creationDate}>Created on 00/00/0000</p>
      <div className={classes.statusGroup}>
        <div className={classes.statusTags}>
          {isEdit && <span className={classes.tagEditting}>Editing</span>}
          {data?.is_published && <span className={classes.tagPublished}>Published</span>}
        </div>
        <div className={classes.buttonGroup}>
          {(isEditDetails.name || isEditDetails.description || isEditStickers) &&
            packName &&
            packName?.trim() !== '' &&
            packDescription &&
            packDescription?.trim() !== '' && (
              <StatusButton
                color={'yellow'}
                text={'save'}
                disabled={false}
                onClick={saveHandler}
              />
            )}

          {isFullStickers && packName && packDescription && (
            <StatusButton
              color={'green'}
              text={'publish'}
              disabled={false}
              onClick={() => publishHandler()}
            />
          )}
          {data?.is_published && (
            <StatusButton
              color={'red'}
              text={'unpublish'}
              disabled={false}
              onClick={() => unpublishHandler()}
            />
          )}
          <StatusButton
            color={'red'}
            text={'delete'}
            disabled={false}
            onClick={() => setIsPromptDelete(true)}
          />
        </div>
      </div>

      <div className={classes.gridWrapper}>
        {stickersGrid &&
          Object.keys(stickersGrid).map((key, index) => (
            <Sticker
              key={index}
              stickerTitles={stickersGrid[key].name}
              previewSticker={stickersGrid[key].previewSticker}
              setPreviewSticker={file =>
                setPreviewHandler(file, stickersGrid[key].tag)
              }
              file={stickersGrid[key].uploadSticker}
              setFile={file => setFileHandler(file, stickersGrid[key].tag)}
            />
          ))}
      </div>
      
      
      <ol className={classes.listRequirements}>
        <li>Each sticker must be 200 x 200 GIF, transparent background</li>
        <li>
          All cells must be filled to total 16 Stickers in order to qualify as a
          complete set
        </li>
        <li>Sticker Titles are not editable</li>
      </ol>
    </div>
  )
}

StickerPack.propTypes = {
  sticker: PropTypes.any,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.func,
  setName: PropTypes.func,
  setCurrentStickerPack: PropTypes.func,
  isLoadingSave: PropTypes.bool,
  setIsLoadingSave: PropTypes.func,
}
