import React from 'react'
import checkmark_empty from '../../icons/checkmark_empty.png'
import checkmark_full from '../../icons/checkmark_full.png'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CheckboxButton({ label, condition, onClick }) {
  return (
    <button className={classes.button} onClick={onClick}>
      <img
        width="24"
        height="24"
        src={condition ? checkmark_full : checkmark_empty}
        className={classes.icon}
      />
      <span className={classes.label}>{label}</span>
    </button>
  )
}

CheckboxButton.propTypes = {
  label: PropTypes.string,
  condition: PropTypes.bool,
  onClick: PropTypes.func,
}
