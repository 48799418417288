import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SearchInput from '../../Components/DashboardComponents/SearchInput/SearchInput'
import SongGridItem from '../../Components/DashboardComponents/SongGridItem/SongGridItem'
import Loader from '../../Components/GeneralComponents/Loader'
import { useAPI } from '../../Contexts/APIContext'
import './createNewFeelStep1.css'
import { StickyHeader } from '../../Components/StickyHeader'
import useGetArtistData from '../../Helpers/hooks/useGetArtistData'

export default function CreateNewFeelStep1() {
  const { fetchAllSongs, searchAllSongs, cancelRequest } = useAPI()
  const navigate = useNavigate()
  const { q } = useParams()

  const [pageLimit] = useState(20)
  const [pageNumber, setPageNumber] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [searchTimer, setSearchTimer] = useState(null)
  const [controller, setController] = useState(null)

  const [searchTerm, setSearchTerm] = useState('')

  const [songs, setSongs] = useState([])

  const observer = useRef()
  const lastSongRef = useCallback(
    node => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore]
  )

  const {
    data: dataArtist,
    isLoading: isLoadingArtist,
    isFetching: isFetchingArtist,
    isError: isErrorArtist,
    error: errorArtist,
  } = useGetArtistData({ artistName: q, enabled: !!q })

  useEffect(() => {
    if (dataArtist !== undefined && dataArtist !== null) {
      setSearchTerm(dataArtist.name)
      const field = document.getElementsByClassName('search-box-input')[0]
      field.value = dataArtist.name || ''
    }
  }, [dataArtist])

  useEffect(() => {
    if (!isLoadingArtist && !isFetchingArtist) {
      searchTerm === '' ? fetchData() : fetchSearch()
    }
  }, [pageNumber, searchTerm, isLoadingArtist, isFetchingArtist])
  useEffect(() => {
    isErrorArtist && setErrorMessage(errorArtist)
  }, [isErrorArtist, errorArtist])

  function fetchData() {
    setErrorMessage(null)
    cancelRequest(controller)
    setIsLoading(true)

    const newController = fetchAllSongs(
      pageNumber,
      pageLimit,
      data => {
        setIsLoading(false)
        setController(null)
        data
          .json()
          .then(json => {
            setSongs(prevSongs => {
              return [...prevSongs, ...json.songs]
            })
            setHasMore(json.songs_count > pageNumber * pageLimit)
          })
          .catch(e => {
            setErrorMessage(e.message)
          })
      },
      error => {
        setController(null)
        setIsLoading(false)
        setErrorMessage(error)
      }
    )
    setController(newController)
  }

  function fetchSearch() {
    setErrorMessage(null)
    cancelRequest(controller)
    setIsLoading(true)

    const newController = searchAllSongs(
      searchTerm,
      pageNumber,
      pageLimit,
      data => {
        setIsLoading(false)
        setController(null)
        data
          .json()
          .then(json => {
            setSongs(prevSongs => {
              return [...prevSongs, ...json.songs]
            })
            setHasMore(json.songs_count > pageNumber * pageLimit)
          })
          .catch(e => {
            setErrorMessage(e.message)
          })
      },
      error => {
        setController(null)
        if (!(error instanceof DOMException)) {
          setIsLoading(false)
          setErrorMessage(error.message)
        }
      }
    )
    setController(newController)
  }

  function handleSearchInput(e) {
    if (searchTimer !== null) {
      clearTimeout(searchTimer)
      setSearchTimer(null)
    }
    setSongs([])
    setPageNumber(1)
    setIsLoading(true)

    setSearchTimer(
      setTimeout(() => {
        setSearchTerm(e.target.value)
        setSearchTimer(null)
      }, 250)
    )
  }

  function handleClickOnSong(song) {
    if (song !== undefined && song !== null) {
      cancelRequest(controller)
      navigate('/create-new-feel-step-2/' + song.f_id)
    }
  }

  function handleOnNavigationToDashboardClick() {
    cancelRequest(controller)
    navigate('/dashboard')
  }

  return (
    <div className="new-feel-page-wrapper">
      <StickyHeader>
        <div className="internal-navigation-container">
          <button
            className="navigation-container-past-item"
            onClick={() => {
              handleOnNavigationToDashboardClick()
            }}
          >
            Music Messages
          </button>
          <button className="navigation-container-icon-item">{'>'}</button>
          <button className="navigation-container-current-item">
            Create Music Message
          </button>
        </div>
        <h1 className="page-title" style={{ color: 'white' }}>
          Create Music Message
        </h1>
        <p className="page-subtitle">
          Create a Music Message from the list of songs bellow
        </p>
      </StickyHeader>

      {!isLoadingArtist && !isFetchingArtist ? (
        <SearchInput
          onSearchInput={handleSearchInput}
          placeholder={'Search for Songs, Artists...'}
        />
      ) : null}
      <div className="songs">
        {songs.length > 0 ? (
          songs.map((item, index) => (
            <div
              key={item.f_id}
              ref={songs.length === index + 1 ? lastSongRef : null}
            >
              <SongGridItem
                key={item.f_id}
                song={item}
                onClick={song => {
                  handleClickOnSong(song)
                }}
              />
            </div>
          ))
        ) : (
          <>
            {!isLoading && !isLoadingArtist && !isFetchingArtist ? (
              <h1 style={{ color: 'white' }}>
                {errorMessage ? errorMessage : 'No Results'}
              </h1>
            ) : null}
          </>
        )}
      </div>
      {(isLoading || isLoadingArtist || isFetchingArtist) && (
        <Loader isLoading={isLoading || isLoadingArtist || isFetchingArtist} />
      )}
    </div>
  )
}
