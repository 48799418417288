import { useEffect } from 'react'

export const usePreventFullscreen = videoRef => {
  useEffect(() => {
    if (videoRef && videoRef.current) {
      // Add the controlsList attribute to disable fullscreen in supported browsers
      videoRef.current.setAttribute('controlsList', 'nofullscreen')
    }

    // Handle double-click to prevent fullscreen
    const handleDoubleClick = e => {
      e.preventDefault() // Prevent default double-click behavior
    }

    // Handle fullscreenchange event to exit if fullscreen is triggered
    const handleFullscreenChange = () => {
      if (document.fullscreenElement === videoRef.current) {
        document.exitFullscreen()
      }
    }

    // Block fullscreen shortcut keys
    const preventFullscreenShortcut = e => {
      if (e.key === 'F11' || (e.ctrlKey && e.key === 'f')) {
        e.preventDefault()
      }
    }

    // Attach event listeners
    videoRef?.current?.addEventListener('dblclick', handleDoubleClick)
    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('keydown', preventFullscreenShortcut)

    // Clean up event listeners on unmount
    return () => {
      videoRef?.current?.removeEventListener('dblclick', handleDoubleClick)
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener('keydown', preventFullscreenShortcut)
    }
  }, [videoRef])
}


