import { React, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useAPI } from '../Contexts/APIContext'

export default function Data() {

  const { dash } = useParams()
  const { fetchGuestToken } = useAPI()
  const superset_url = process.env.REACT_APP_SUPERSET_URL;

  function fetchGuestTokenFromBackend() {
    return new Promise((resolve) => {
      fetchGuestToken(
        (response) => {
          response.json().then((json) => {
            resolve(json["token"]);
          })
        },
        (error) => {
          console.log("error", error)
        })
    });
  }

  useEffect(() => {
    const dash_id = process.env.REACT_APP_SUPERSET_EMBED_UUID;
    embedDashboard({
      id: dash_id,
      supersetDomain: superset_url,
      mountPoint: document.getElementById("superset-container"),
      fetchGuestToken: () => fetchGuestTokenFromBackend(),
      dashboardUiConfig: { hideTitle: true },
      debug: true
    });
  }, []);
  return (
    <>
      <div id="superset-container" className="IFrameLoader" style={{ "height": "calc(100vh - 75px)", "width": "100%", "border": "0", "overflow": "hidden"}}></div >
    </>
  )
}

