import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import CreateOrganizationPopupFormStep1 from './CreateOrganizationPopupFormStep1'
import CreateOrganizationPopupFormStep2 from './CreateOrganizationPopupFormStep2'

export default function CreateTeamPopupFormSteps({
  step,
  setStepData,
  stepData,
  organization,
}) {
  const Step = [
    CreateOrganizationPopupFormStep1,
    CreateOrganizationPopupFormStep2,
  ].find((_, index) => index + 1 === step)

  return (
    <div className={classes.wrapper}>
      <Step
        setStepData={setStepData}
        stepData={stepData}
        organization={organization}
      />
    </div>
  )
}

CreateTeamPopupFormSteps.propTypes = {
  step: PropTypes.number,
  setStepData: PropTypes.func,
  organization: PropTypes.object,
  stepData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
