import React, { useEffect, useRef, useState } from 'react'
import classes from './index.module.css'
import Loader from '../../../Components/GeneralComponents/Loader'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import { Actions } from '../../../Components/page/Actions'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import { Navigation } from '../../../Components/page/Navigation'

import PageTitle from '../components/PageTitle'
import { useParams } from 'react-router-dom'
import { useGetTeamDetails } from '../hooks/useGetTeamDetails'
import useAdminAccess from '../../../services/hooks/useAdminAccess'
import { getUserRole } from '../../../services/helpers/getUserRole.'
import { groupRoles } from '../../../constants/roles'
import { useFetchAccount } from '../../Organization/hooks'
import OrganizationsPopup from '../components/OrganizationsPopup'
import usePopup from '../hooks/usePopup'
import successToast from '../../../Components/Toasts/SuccessToast'
import { useQueryClient } from '@tanstack/react-query'
import { useGetOrgGroupUsers } from '../hooks/useGetOrgGroupUsers'
import { useGetOrgDetails } from '../hooks/useGetOrgDetails'
import AddAdminGroupPopupForm from '../components/AddAdminGroupPopupForm'
import TeamUsersTable from '../components/TeamUsersTable'
import { useChangeOrganizationUsersRole } from '../hooks/useChangeOrganizationUsersRole'
import { renderBreadcrumbs } from './breadcrumbs'
import useAccessLevel from '../hooks/useAccessLevel'
import { renderBreadcrumbsTeam } from './breadcrumbsTeam'

export default function TeamDetailsMembers() {
  const { o_f_id, g_f_id } = useParams()
  const [users, setUsers] = useState({
    admin: [],
    viewer: [],
    editor: [],
  })
  const { isInternalAdmin, hasOrgAdminAccess } = useAccessLevel({
    o_f_id,
    g_f_id,
  })
  const {
    openPopup: addAdminOpenPopup,
    closePopup: addAdminClosePopup,
    isOpen: addAdminIsOpen,
  } = usePopup()

  const queryClient = useQueryClient()

  const {
    data: userData,
    isError: userDataError,
    isLoading: userDataIsLoading,
    isFetching: userDataIsFetching,
  } = useFetchAccount()

  const currentUser = userData?.user
  const currentUserRole = getUserRole(currentUser) || ''
  const { hasAdminAccess } = useAdminAccess(currentUser)

  const {
    data: orgDetails,
    isError: orgDetailsError,
    isLoading: orgDetailsIsLoading,
    isFetching: orgDetailsFetching,
  } = useGetOrgDetails({
    id: o_f_id,
  })

  const {
    data: organizationGroup,
    isError: organizationGroupError,
    isLoading: organizationGroupIsLoading,
    isFetching: isOrganizationGroupFetching,
  } = useGetTeamDetails({
    o_f_id,
    g_f_id,
  })

  const {
    data: orgAdmins,
    isError: orgAdminError,
    isLoading: orgAdminIsLoading,
    isFetching: orgAdminIsFetching,
  } = useGetOrgGroupUsers({
    o_f_id,
    g_f_id,
    role: 'Group Administrator',
  })
  const {
    data: orgEditors,
    isError: orgEditorsError,
    isLoading: orgEditorsIsLoading,
    isFetching: orgEditorsIsFetching,
  } = useGetOrgGroupUsers({
    o_f_id,
    g_f_id,
    role: 'Editor',
  })
  const {
    data: orgViewers,
    isError: orgViewersError,
    isLoading: orgViewersIsLoading,
    isFetching: orgViewersIsFetching,
  } = useGetOrgGroupUsers({
    o_f_id,
    g_f_id,
    role: 'Viewer',
  })

  const { mutateAsync: changeOrganizationUsersRole } =
    useChangeOrganizationUsersRole()

  const handleDeleteGroupUser = async data => {
    const newData = {
      ...data,
      o_f_id,
      g_f_id: data.g_f_id || g_f_id,
      _action: 'delete',
    }
    await changeOrganizationUsersRole(newData, {
      onSuccess: async () => {
        successToast('User updated')
        queryClient.invalidateQueries(['organization'])
      },
      onError: error => {
        errorToast('Error updating User', error)
      },
    })
  }
  const handleChangeOrganizationUsersRole = data => {
    const { oldRole, ...rest } = data
    const newData = {
      ...rest,
      o_f_id,
      g_f_id: data.g_f_id || g_f_id,
      role:
        rest.role === 'Team Administrator' ? 'Group Administrator' : rest.role,
    }
    changeOrganizationUsersRole(newData, {
      onSuccess: () => {
        handleDeleteGroupUser({
          role: oldRole,
          organization_users: newData.organization_users,
          g_f_id: newData.g_f_id,
        })
      },
      onError: error => {
        errorToast('Error updating User', error)
      },
    })
  }

  useEffect(() => {
    if (orgAdmins) {
      const users = orgAdmins.organization_users.reduce((acc, user) => {
        const newUser = { ...user, role: orgAdmins.role }
        return [...acc, newUser]
      }, [])
      setUsers(prev => ({ ...prev, admin: users }))
    }
  }, [orgAdmins])
  useEffect(() => {
    if (orgEditors) {
      const users = orgEditors.organization_users.reduce((acc, user) => {
        const newUser = { ...user, role: orgEditors.role }
        return [...acc, newUser]
      }, [])

      setUsers(prev => ({ ...prev, editor: users }))
    }
  }, [orgEditors])
  useEffect(() => {
    if (orgViewers) {
      const users = orgViewers.organization_users.reduce((acc, user) => {
        const newUser = { ...user, role: orgViewers.role }
        return [...acc, newUser]
      }, [])
      setUsers(prev => ({ ...prev, viewer: users }))
    }
  }, [orgViewers])

  if (
    userDataIsLoading ||
    orgDetailsIsLoading ||
    organizationGroupIsLoading ||
    orgAdminIsLoading ||
    orgEditorsIsLoading ||
    orgViewersIsLoading
  )
    return (
      <Loader
        isLoading={
          userDataIsLoading ||
          orgDetailsIsLoading ||
          organizationGroupIsLoading ||
          orgAdminIsLoading ||
          orgEditorsIsLoading ||
          orgViewersIsLoading
        }
      />
    )

  if (
    userDataError ||
    orgDetailsError ||
    organizationGroupError ||
    orgAdminError ||
    orgEditorsError ||
    orgViewersError
  ) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
            <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }
  return (
    <Wrapper className={classes.wrapper}>
      <OrganizationsPopup
        isOpen={addAdminIsOpen}
        closePopup={addAdminClosePopup}
      >
        <AddAdminGroupPopupForm
          closePopup={addAdminClosePopup}
          group={organizationGroup}
        />
      </OrganizationsPopup>
      <Header>
        {orgDetails && (isInternalAdmin || hasOrgAdminAccess) ? (
          <Navigation
            breadcrumbs={renderBreadcrumbs({
              orgName: orgDetails.name,
              teamName: organizationGroup.name,
              o_f_id,
              g_f_id,
            })}
          />
        ) : (
          <Navigation
            breadcrumbs={renderBreadcrumbsTeam({
              orgName: orgDetails.name,
              teamName: organizationGroup.name,
              o_f_id,
              g_f_id,
            })}
          />
        )}
        <HeaderInfo>
          <div className={classes.header}>
            <PageTitle title={organizationGroup?.name} />
            {orgDetails ? (
              <p className={classes.subtitle}>
                A team within the {orgDetails?.name} organization.
              </p>
            ) : null}
          </div>
          {isInternalAdmin || hasOrgAdminAccess ? (
            <Actions>
              <ButtonOutline
                color={'pink'}
                size="medium"
                text={'ADD MEMBERS'}
                onClick={addAdminOpenPopup}
                disable={!isInternalAdmin && !hasOrgAdminAccess}
              />
            </Actions>
          ) : null}
        </HeaderInfo>
      </Header>
      <main className={classes.main}>
        {users ? (
          <div className={classes.section}>
            <div className={classes.section__header}>
              <h2 className={classes.section__title}>Members</h2>
            </div>
            <div className={classes.users}>
              <TeamUsersTable
                isCurrentUserAnAdmin={hasAdminAccess}
                currentUserRole={currentUserRole}
                users={users}
                getUserRole={getUserRole}
                options={groupRoles}
                onChangeRole={handleChangeOrganizationUsersRole}
                onDelete={handleDeleteGroupUser}
              />
            </div>
          </div>
        ) : null}
      </main>
    </Wrapper>
  )
}
