import React from 'react'
import PropTypes from 'prop-types'
import ButtonArrow from '../../GeneralComponents/ButtonArrow'
import classes from './index.module.css'
import PublicationBadge from '../../GeneralComponents/PublicationBadge'

export default function ArtistTopSongGridItem({ song, onClick }) {
  return (
    <div className={classes.itemWrapper} onClick={() => onClick(song)}>
      <div className={classes.imageWrapper}>
        <img
          src={song?.image?.url}
          alt="sound image"
          className={classes.image}
        />
      </div>
      <div className={classes.textWrapper}>
        <p className={classes.itemTitle}>{song?.name}</p>
        <p className={classes.itemSubtitle}>{song?.artist?.name}</p>
        <p className={classes.itemMessageCount}>{`${
          song?.feels_count
        } Music Message${song?.feels_count == 1 ? '' : 's'}`}</p>
        {song?.is_promotional ? (
          <PublicationBadge
            size="sm"
            status={song.is_published ? 'published' : 'unpublished'}
            text={song.is_published ? 'Published' : 'Unpublished'}
          />
        ) : null}
      </div>
    </div>
  )
}

ArtistTopSongGridItem.propTypes = {
  song: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}
