import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function ArtistDataCell({ title, amount }) {
  function styleNumber(number) {
    if (number !== undefined && number !== null) {
      return new Intl.NumberFormat().format(number)
    } else {
      return 0
    }
  }

  return (
    <div>
      <p className={classes.titleText}>{title}</p>
      <p className={classes.numberText}>{styleNumber(amount)}</p>
    </div>
  )
}

ArtistDataCell.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number,
}
