import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

const typeToClass = {
  green: classes.buttonGreen,
  yellow: classes.buttonYellow,
  red: classes.buttonRed,
}

export default function StatusButton({
  color,
  text,
  onClick,
  disabled = false,
}) {
  const buttonClass = typeToClass[color] || typeToClass['red']
  return (
    <button
      className={buttonClass}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  )
}

StatusButton.propTypes = {
  color: PropTypes.oneOf(['green', 'yellow', 'red']).isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
