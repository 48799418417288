import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function ArtistGridItem({ artist, onClick }) {
  const textRef = useRef(null)
  const minFontSize = 12
  const initialFontSize = 16

  useEffect(() => {
    const adjustFontSize = () => {
      const element = textRef.current
      const parentWidth = element.parentElement.offsetWidth

      let currentFontSize = initialFontSize
      element.style.fontSize = `${currentFontSize}px`
      element.style.whiteSpace = 'normal'
      element.style.overflow = 'visible'
      element.style.textOverflow = 'clip'

      while (
        element.scrollWidth > parentWidth &&
        currentFontSize > minFontSize
      ) {
        currentFontSize--
        element.style.fontSize = `${currentFontSize}px`
      }

      if (
        element.scrollWidth > parentWidth &&
        currentFontSize === minFontSize
      ) {
        element.style.fontSize = `${initialFontSize}px`
        element.style.whiteSpace = 'nowrap'
        element.style.overflow = 'hidden'
        element.style.textOverflow = 'ellipsis'
      }
    }

    adjustFontSize()

    window.addEventListener('resize', adjustFontSize)
    return () => window.removeEventListener('resize', adjustFontSize)
  }, [artist.name])
  return (
    <>
      <div
        className={classes.wrapper}
        onClick={() => {
          onClick(artist)
        }}
      >
        {artist.image.url ? (
          <img className={classes.image} src={artist.image.url} />
        ) : (
          <div className={classes.emptyImage}>
            <p className={classes.emptyImageText}>
              {artist.name.charAt(0).toUpperCase()}
            </p>
          </div>
        )}
        <p ref={textRef} className={classes.title}>
          {artist.name}
        </p>
      </div>
    </>
  )
}

ArtistGridItem.propTypes = {
  artist: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}
