import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function FeelDropdownList({
  selectedOption,
  setSelectedOption,
  disabled,
  options,
}) {
  const [showOptionList, setShowOptionList] = useState(false)

  const selectContainerRef = useRef(null)

  const currentOption = options.find(item => item.value === selectedOption)

  const renderSelectedOption = currentOption => {
    if (currentOption) {
      return (
        <>
          {<currentOption.icon />} <span>{currentOption.label}</span>
        </>
      )
    }
    return <span>Select Export Format</span>
  }

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(e.target)
      ) {
        setShowOptionList(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    if (disabled) {
      setShowOptionList(false)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedOption, showOptionList, disabled])

  const handleListDisplay = () => {
    if (!disabled) {
      setShowOptionList(prevState => !prevState)
    }
  }

  const handleOptionClick = option => {
    setSelectedOption(option.value)
    setShowOptionList(false)
  }

  return (
    <div
      className={classes.container}
      ref={selectContainerRef}
      style={{
        opacity: disabled ? '0.7' : '1',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <div
        className={
          currentOption
            ? `${classes.selectedText}  ${classes.selectedText__white}`
            : classes.selectedText
        }
        onClick={handleListDisplay}
      >
        {renderSelectedOption(currentOption)}
      </div>
      {showOptionList && (
        <ul className={classes.selectOptions}>
          {options ? (
            options.map(option => (
              <li
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={
                  selectedOption === option.value ? classes.active : ''
                }
              >
                <option.icon />
                <span>{option.label}</span>
              </li>
            ))
          ) : (
            <li key={'no options'}>no options</li>
          )}
        </ul>
      )}
    </div>
  )
}

FeelDropdownList.propTypes = {
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func,
  collectionLength: PropTypes.number,
  options: PropTypes.array,
  disabled: PropTypes.bool,
}
