import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import TextField from '../../../../../Components/GeneralComponents/TextField'
import { isValidEmail } from '../../../../../Helpers/isValidEmail'

export default function Input({ index, updateOwner, inputType, placeholder }) {
  const [isError, setIsError] = useState(false)
  return (
    <div className={classes.input}>
      <TextField
        name={index.toString()}
        onChange={e => updateOwner(index, e.target.value)}
        isRequired
        variant={inputType}
        placeholder={placeholder}
        autoComplete="new-email"
        type="text"
        onBlur={e =>
          e.target.value && !isValidEmail(e.target.value) && setIsError(true)
        }
        onFocus={() => setIsError(false)}
      />
      {isError ? <p className={classes.error}>Invalid email</p> : null}
    </div>
  )
}

Input.propTypes = {
  index: PropTypes.number,
  updateOwner: PropTypes.func,
  inputType: PropTypes.string,
  placeholder: PropTypes.string,
}
