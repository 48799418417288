import { useMutation } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export const useCreateTeaseVideo = () => {
  const { createTeaseVideo } = useAPI()

  return useMutation({
    mutationFn: data =>
      createTeaseVideo({
        ...data,
      }),
  })
}
