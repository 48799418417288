import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function PublicationBadge({
  text,
  status,
  size = 'sm',
  className = '',
}) {
  return (
    <div
      className={`${classes.badge} ${classes['badge__' + status]} ${
        classes['badge__' + size]
      } ${className}`.trim()}
    >
      {text}
    </div>
  )
}

PublicationBadge.propTypes = {
  status: PropTypes.oneOf(['published', 'unpublished', 'tease']).isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}
