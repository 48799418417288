import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export const ErrorGeneratingMessage = ({ message }) => (
  <p className={classes.message}>{message}</p>
)

ErrorGeneratingMessage.propTypes = {
  message: PropTypes.string,
}
