import React, { useRef } from 'react'
import frame from '../MobileFrames/frame.png'
import mask from './mask.svg'
import classes from './index.module.css'
import PropTypes from 'prop-types'
import useUserInfo from '../../../../services/hooks/useUserInfo'
import { usePreventFullscreen } from '../../../../Helpers/hooks/usePreventFullscreen'

const YoutubeShorts = ({ data }) => {
  const videoElement = useRef()
  const { icon, name } = useUserInfo()
  usePreventFullscreen(videoElement)

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  const videoDimensionWidth = data?.export?.dimensions[0]
  const videoDimensionHeight = data?.export?.dimensions[1]
  let videoClass = classes.videoM

  // TO DO: other resolutions
  if (videoDimensionHeight === 720 && videoDimensionWidth === 1280) {
    videoClass = classes.videoM
  }

  return (
    <div className={classes.container}>
      <div className={classes.frameContainer}>
        <img src={frame} className={classes.frame} alt={'phone frame'} />
      </div>
      <video
        muted={true}
        autoPlay={true}
        ref={videoElement}
        onTimeUpdate={handleOnTimeUpdate}
        className={`${videoClass} ${classes.video}`}
        src={data?.export?.url}
        controlsList="nofullscreen"
      />
      <img src={mask} className={classes.mask} alt={'mask'} />

      <img src={icon} className={classes.avatar} alt={'avatar'} />
      <span className={classes.name}>@{name}</span>
      <span className={classes.tags}>{data?.tags?.join(', ')}</span>
      <span className={classes.songData}>
        {data?.song_name} • {data?.artist?.name}
      </span>
      <img src={icon} className={classes.albumAvatar} alt={'avatar album'} />
      <img src={icon} className={classes.footerAvatar} alt={'avatar footer'} />
    </div>
  )
}

YoutubeShorts.propTypes = {
  getCurrentUser: PropTypes.func,
  data: PropTypes.object,
}

export default YoutubeShorts
