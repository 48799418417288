
export const renderBreadcrumbs = data => [
  {
    title: data?.orgName,
    link: `/organizations/${data?.o_f_id}`,
  },
  {
    title: data?.teamName,
    link: `/organizations/${data?.o_f_id}/teams/${data?.g_f_id}`,
  },
  {
    title: 'Members',
  },
]
