import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function StickerTextArea({
  description,
  setDescription,
  initialValue,
  setInitialValue = () => {},
  setInitialFlag = () => {},
  setIsEdit = () => {},
}) {
  const [toggle, setToggle] = useState(true)
  const [text, setText] = useState('')
  const inputRef = useRef(null)

  useEffect(() => {
    if (description !== null && !initialValue) {
      setInitialValue(description)
    }
  }, [description, initialValue])

  useEffect(() => {
    if (description === null || description.trim() === '') {
      setText('')
      setToggle(false)
    } else if (description) {
      setText(description)
      setToggle(true)
    }
  }, [description])

  useEffect(() => {
    const handleClickOutsideTextArea = event => {
      text !== initialValue ? setIsEdit(true) : setIsEdit(false)

      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (text.length > 0) {
          setDescription(text)
          if (text !== initialValue) {
            setInitialFlag(true)
          } else {
            setInitialFlag(false)
          }
        } else {
          setDescription(null)
        }
        setToggle(text.length > 0 && text.trim() !== '')
      }
    }

    document.addEventListener('mousedown', handleClickOutsideTextArea)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideTextArea)
    }
  }, [text, setDescription, initialValue])

  const handleChange = event => {
    setText(event.target.value)
  }

  return (
    <div ref={inputRef}>
      {toggle ? (
        <span
          className={classes.descriptionText}
          onDoubleClick={() => setToggle(false)}
        >
          {description}
        </span>
      ) : (
        <textarea
          className={classes.textareaDescription}
          type="text"
          placeholder="Smart Stickers Set Description"
          value={text}
          onChange={handleChange}
          rows={4}
          maxLength={360}
        />
      )}
    </div>
  )
}

StickerTextArea.propTypes = {
  description: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  setInitialValue: PropTypes.func,
  setDescription: PropTypes.func.isRequired,
  setIsEdit: PropTypes.func,
  setInitialFlag: PropTypes.func,
}
