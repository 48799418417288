import { useMutation } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export const useCreateOrganizationUser = () => {
  const { createOrganizationUser } = useAPI()

  return useMutation({
    mutationFn: userData =>
      createOrganizationUser({
        ...userData,
      }),

    onError: error => {
      console.error('Error creating user:', error)
    },
  })
}
