import React from 'react'
import classes from './index.module.css'

import PropTypes from 'prop-types'

export default function TagBubble({
  onTagSelected,
  tag,
  editable,
  submissions,
}) {
  return (
    <div className={classes.tag}>
      <p className={editable ? classes.tag__text_editable : classes.tag__text}>
        #{tag}
      </p>
      {editable ? (
        <p
          className={classes.tag__delete}
          onMouseUp={() => {
            onTagSelected(tag)
          }}
        >
          x
        </p>
      ) : null}
      {submissions ? (
        <p className={classes.tag__submissions}>{submissions}</p>
      ) : null}
    </div>
  )
}

TagBubble.propTypes = {
  onTagSelected: PropTypes.func,
  tag: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  submissions: PropTypes.number,
}
