import React, { useState, useEffect } from 'react'
import {
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { useNavigate } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { useFeelsStore } from '../../../Storage/feelsPage/useFeelsStore'
import FeelGridItem from '../../../Components/DashboardComponents/FeelGridItem/FeelGridItem'
import Loader from '../../../Components/GeneralComponents/Loader'
import PropTypes from 'prop-types'
import DebounceSearch from '../../../Components/GeneralComponents/DebounceSearch/DebounceSearch'
import classes from './index.module.css'

export default function FeelsOnDashboard({ section }) {
  // section: "published", "pending_review"
  const pageLimit = 20
  const pageLimitSearch = 20
  const { getFeels, searchFeels } = useAPI()
  const navigate = useNavigate()
  const [ref, inView] = useInView()

  const [feelsMuted, setFeelsMuted] = useState(true)

  // ZUSTAND STORAGE
  const searchTerm = useFeelsStore(state => state.searchTerm)
  const setSearchTerm = useFeelsStore(state => state.setSearchTerm)

  const inputValue = useFeelsStore(state => state.inputValue)
  const setInputValue = useFeelsStore(state => state.setInputValue)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [section])

  const { data, isError, error, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ['feelsDashboard', section.status, searchTerm],
      queryFn: ({ pageParam = 1 }) =>
        searchTerm === ''
          ? getFeels(section.status, pageParam, pageLimit)
          : searchFeels(searchTerm, pageParam, pageLimitSearch, section.status),
      getNextPageParam: pageData =>
        pageData.p * pageLimit < pageData.feels_count ? pageData.p + 1 : null,
    })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  function handleClickOnFeel(feel) {
    navigate('/feel/' + feel.f_id)
  }

  return (
    <>
      <div className={classes.container}>
        <h1 className={classes.title}>{section.title}</h1>
        <div className={classes.searchWrapper}>
          <DebounceSearch
            inputValue={inputValue}
            setInputValue={setInputValue}
            searchValue={searchTerm}
            setSearchValue={setSearchTerm}
            placeholder={'Search for Song Names, Artists, Feel Status...'}
          />
        </div>
      </div>

      {!isError && data && (
        <div className={classes.feels}>
          {data.pages.map((page, index) => (
            <React.Fragment key={index}>
              {page.feels.length > 0 ? (
                page.feels.map((feel, innerIndex) => (
                  <div key={feel.f_id} ref={ref}>
                    <FeelGridItem
                      feel={feel}
                      isMutedOnHover={feelsMuted}
                      onClick={() => {
                        handleClickOnFeel(feel)
                      }}
                      onMuteUnmuteClick={() => {
                        setFeelsMuted(!feelsMuted)
                      }}
                    />
                  </div>
                ))
              ) : (
                <h1 className={classes.errorText}>No Results</h1>
              )}
            </React.Fragment>
          ))}
        </div>
      )}

      <Loader isLoading={isLoading || isFetchingNextPage} />

      {isError && (
        <h1 className={classes.errorText}>{`Failed to fetch data with code: ${
          error?.status ? error.status : 'unknown'
        }`}</h1>
      )}
    </>
  )
}

FeelsOnDashboard.propTypes = {
  section: PropTypes.object.isRequired,
}
