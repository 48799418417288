import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useGetOrgGroupUsers({ o_f_id, role, g_f_id, enabled = true }) {
  const { getOrganizationGroupUsers } = useAPI()

  return useQuery({
    queryKey: ['organization', 'users', role, g_f_id, o_f_id],
    queryFn: () => getOrganizationGroupUsers({ o_f_id, role, g_f_id }),
    enabled,
  })
}
