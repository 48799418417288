import React, { createContext, useContext } from 'react'
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import PropTypes from 'prop-types'

const StorageContext = createContext()

export function useStorage() {
  return useContext(StorageContext)
}

export function StorageProvider({ children }) {
  const storage = getStorage()

  async function getDownloadLinkForFile(file, success, failure) {
    const gsRef = ref(storage, file)
    await getDownloadURL(gsRef)
      .then((url) => {
        success(url)
      })
      .catch((error) => {
        failure(error)
      })
  }

  async function uploadFile(file, success, failure) {
    const randomId =
      Math.random().toString(36).substring(2, 12) +
      Math.random().toString(36).substring(2, 12)
    const storageRef = ref(storage, randomId + '.mp4')

    const metadata = {
      contentType: 'video/mp4',
    }

    uploadBytes(storageRef, file, metadata)
      .then(async () => {
        const url = await getDownloadURL(storageRef)
        success(url)
      })
      .catch((error) => {
        failure(error)
      })
  }

  const value = {
    getDownloadLinkForFile,
    uploadFile,
  }
  return (
    <StorageContext.Provider value={value}>{children}</StorageContext.Provider>
  )
}

StorageProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
