import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import CreateTeamDynamicInputsTextFields from '../../CreateTeamDynamicInputsTextFields'

export default function Step({ setData }) {

  const onArrayChange = newOwners =>
    setData(prev => {
      return {
        ...prev,
        owners: newOwners,
      }
    })

  return (
    <div className={classes.wrapper}>
      <CreateTeamDynamicInputsTextFields
        placeholder={'Enter email'}
        label={'Team Member'}
        onChange={onArrayChange}
      />
    </div>
  )
}

Step.propTypes = {
  setData: PropTypes.func,
}
