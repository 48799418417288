import React, { useEffect, useRef, useState } from 'react'
import classes from './index.module.css'
import Loader from '../../../Components/GeneralComponents/Loader'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import { Actions } from '../../../Components/page/Actions'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import { Navigation } from '../../../Components/page/Navigation'

import PageTitle from '../components/PageTitle'
import { useParams } from 'react-router-dom'
import { useGetTeamDetails } from '../hooks/useGetTeamDetails'
import ViewAll from '../components/ViewAll'
import ArtistPreview from '../components/ArtistPreview'
import useVisibleItems from '../hooks/useVisibleItems'
import useAdminAccess from '../../../services/hooks/useAdminAccess'
import { getUserRole } from '../../../services/helpers/getUserRole.'
import { useAuth } from '../../../Contexts/AuthContext'
import { groupRoles } from '../../../constants/roles'
import { useInView } from 'react-intersection-observer'
import { useFetchAccount } from '../../Organization/hooks'
import OrganizationsPopup from '../components/OrganizationsPopup'
import usePopup from '../hooks/usePopup'
import successToast from '../../../Components/Toasts/SuccessToast'
import { useQueryClient } from '@tanstack/react-query'
import { useGetOrgGroupContent } from '../hooks/useGetOrgGroupContent'
import { useGetOrgGroupUsers } from '../hooks/useGetOrgGroupUsers'
import { useGetOrgDetails } from '../hooks/useGetOrgDetails'
import ManageGroupArtistsPopupForm from '../components/ManageGroupArtistsPopupForm'
import AddAdminGroupPopupForm from '../components/AddAdminGroupPopupForm'
import TeamUsersTable from '../components/TeamUsersTable'
import { useChangeOrganizationUsersRole } from '../hooks/useChangeOrganizationUsersRole'
import { renderBreadcrumbs } from './breadcrumbs'
import useAccessLevel from '../hooks/useAccessLevel'

export default function TeamDetails() {
  const { o_f_id, g_f_id } = useParams()
  const artistsRef = useRef()
  const [artistRef, artistInView] = useInView()
  const { isInternalAdmin, hasOrgAdminAccess } = useAccessLevel({
    o_f_id,
    g_f_id,
  })
  const [users, setUsers] = useState({
    owners: [],
    admin: [],
    viewer: [],
    editor: [],
  })

  const getUsersCount = users => {
    let count = 0

    for (const key in users) {
      if (Object.prototype.hasOwnProperty.call(users, key)) {
        const element = users[key]
        element.length && (count = count + element.length)
      }
    }

    return count
  }

  const {
    openPopup: manageArtistsOpenPopup,
    closePopup: manageArtistsClosePopup,
    isOpen: manageArtistsIsOpen,
  } = usePopup()
  const {
    openPopup: addAdminOpenPopup,
    closePopup: addAdminClosePopup,
    isOpen: addAdminIsOpen,
  } = usePopup()

  const queryClient = useQueryClient()

  const {
    data: userData,
    isError: userDataError,
    isLoading: userDataIsLoading,
    isFetching: userDataIsFetching,
  } = useFetchAccount()

  const currentUser = userData?.user
  const organization = userData?.organization
  const currentUserRole = getUserRole(currentUser) || ''
  const { hasAdminAccess } = useAdminAccess(currentUser)
  const {
    data: orgDetails,
    isError: orgDetailsError,
    isLoading: orgDetailsIsLoading,
    isFetching: orgDetailsFetching,
  } = useGetOrgDetails({
    id: o_f_id,
  })

  const {
    data: organizationGroup,
    isError: organizationGroupError,
    isLoading: organizationGroupIsLoading,
    isFetching: isOrganizationGroupFetching,
  } = useGetTeamDetails({
    o_f_id,
    g_f_id,
  })

  const {
    data: orgOwners,
    isError: orgOwnersError,
    isLoading: orgOwnersIsLoading,
    isFetching: orgOwnersIsFetching,
  } = useGetOrgGroupUsers({
    o_f_id,
    g_f_id,
    role: 'Organization Owner',
    enabled: !!organizationGroup && organizationGroup?.name === 'Organization',
  })
  const {
    data: orgAdmins,
    isError: orgAdminError,
    isLoading: orgAdminIsLoading,
    isFetching: orgAdminIsFetching,
  } = useGetOrgGroupUsers({
    o_f_id,
    g_f_id,
    role:
      organizationGroup?.name === 'Organization'
        ? 'Organization Administrator'
        : 'Group Administrator',
    enabled: !!organizationGroup,
  })
  const {
    data: orgEditors,
    isError: orgEditorsError,
    isLoading: orgEditorsIsLoading,
    isFetching: orgEditorsIsFetching,
  } = useGetOrgGroupUsers({
    o_f_id,
    g_f_id,
    role: 'Editor',
  })
  const {
    data: orgViewers,
    isError: orgViewersError,
    isLoading: orgViewersIsLoading,
    isFetching: orgViewersIsFetching,
  } = useGetOrgGroupUsers({
    o_f_id,
    g_f_id,
    role: 'Viewer',
  })

  const {
    data: orgContent,
    isError: orgContentError,
    isLoading: orgContentIsLoading,
    isFetching: orgContentIsFetching,
    fetchNextPage: orgContentFetchNextPage,
    hasNextPage: orgContentHasNextPage,
  } = useGetOrgGroupContent({
    o_f_id,
    g_f_id,
    content_object: 'artist',
    n: 15,
  })

  const artists = orgContent?.pages.reduce((acc, page) => {
    return [...acc, ...page.artist].filter(artist => artist.f_id)
  }, [])
  const allArtists = orgContent?.pages[0]?.artist_count || 0

  const { visibleItemsCount: artistsCount } = useVisibleItems({
    ref: artistsRef,
    gap: 15,
    flag: artists,
  })

  const { mutateAsync: changeOrganizationUsersRole } =
    useChangeOrganizationUsersRole()

  const handleDeleteGroupUser = async data => {
    const newData = {
      ...data,
      o_f_id,
      g_f_id: data.g_f_id || g_f_id,
      _action: 'delete',
      role:
        data.role === 'Team Administrator' ? 'Group Administrator' : data.role,
    }
    await changeOrganizationUsersRole(newData, {
      onSuccess: () => {
        queryClient.invalidateQueries(['organization'])
        successToast('User updated')
      },
      onError: error => {
        errorToast('Error updating User', error)
      },
    })
  }
  const handleChangeOrganizationUsersRole = data => {
    const { oldRole, ...rest } = data
    const newData = {
      ...rest,
      o_f_id,
      g_f_id: data.g_f_id || g_f_id,
      role:
        rest.role === 'Team Administrator' ? 'Group Administrator' : rest.role,
    }
    changeOrganizationUsersRole(newData, {
      onSuccess: () => {
        handleDeleteGroupUser({
          role: oldRole,
          organization_users: newData.organization_users,
          g_f_id: newData.g_f_id,
        })
      },
      onError: error => {
        errorToast('Error updating User', error)
      },
    })
  }

  useEffect(() => {
    if (artistInView && orgContentHasNextPage) {
      orgContentFetchNextPage()
    }
  }, [artistInView, orgContentHasNextPage])

  useEffect(() => {
    if (orgOwners) {
      const users = orgOwners.organization_users.reduce((acc, user) => {
        const newUser = { ...user, role: orgOwners.role }
        return [...acc, newUser]
      }, [])
      setUsers(prev => ({ ...prev, owners: users }))
    }
  }, [orgOwners])

  useEffect(() => {
    if (orgAdmins) {
      const users = orgAdmins.organization_users.reduce((acc, user) => {
        const newUser = { ...user, role: orgAdmins.role }
        return [...acc, newUser]
      }, [])
      setUsers(prev => ({ ...prev, admin: users }))
    }
  }, [orgAdmins])
  useEffect(() => {
    if (orgEditors) {
      const users = orgEditors.organization_users.reduce((acc, user) => {
        const newUser = { ...user, role: orgEditors.role }
        return [...acc, newUser]
      }, [])

      setUsers(prev => ({ ...prev, editor: users }))
    }
  }, [orgEditors])
  useEffect(() => {
    if (orgViewers) {
      const users = orgViewers.organization_users.reduce((acc, user) => {
        const newUser = { ...user, role: orgViewers.role }
        return [...acc, newUser]
      }, [])
      setUsers(prev => ({ ...prev, viewer: users }))
    }
  }, [orgViewers])

  if (
    userDataIsLoading ||
    orgDetailsIsLoading ||
    organizationGroupIsLoading ||
    orgAdminIsLoading ||
    orgEditorsIsLoading ||
    orgViewersIsLoading ||
    orgContentIsLoading ||
    orgOwnersIsLoading
  ) {
    return (
      <Loader
        isLoading={
          userDataIsLoading ||
          orgDetailsIsLoading ||
          organizationGroupIsLoading ||
          orgAdminIsLoading ||
          orgEditorsIsLoading ||
          orgViewersIsLoading ||
          orgContentIsLoading ||
          orgOwnersIsLoading
        }
      />
    )
  }

  if (
    userDataError ||
    orgDetailsError ||
    organizationGroupError ||
    orgAdminError ||
    orgEditorsError ||
    orgViewersError ||
    orgContentError ||
    orgOwnersError
  ) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
            <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={classes.wrapper}>
      <OrganizationsPopup
        isOpen={manageArtistsIsOpen}
        closePopup={manageArtistsClosePopup}
      >
        <ManageGroupArtistsPopupForm
          closePopup={manageArtistsClosePopup}
          group={organizationGroup}
          artists={artists}
        />
      </OrganizationsPopup>
      <OrganizationsPopup
        isOpen={addAdminIsOpen}
        closePopup={addAdminClosePopup}
      >
        <AddAdminGroupPopupForm
          closePopup={addAdminClosePopup}
          group={organizationGroup}
        />
      </OrganizationsPopup>
      <Header>
        {orgDetails && (isInternalAdmin || hasOrgAdminAccess) ? (
          <Navigation breadcrumbs={renderBreadcrumbs(orgDetails)} />
        ) : null}
        <HeaderInfo>
          <div className={classes.header}>
            <PageTitle title={organizationGroup?.name} />
            {orgDetails ? (
              <p className={classes.subtitle}>
                A team within the {orgDetails?.name} organization.
              </p>
            ) : null}
          </div>
          {isInternalAdmin || hasOrgAdminAccess ? (
            <Actions>
              <ButtonOutline
                color={'pink'}
                size="medium"
                text={'ADD MEMBERS'}
                onClick={addAdminOpenPopup}
                disable={!isInternalAdmin && !hasOrgAdminAccess}
              />

              <ButtonOutline
                color={'pink'}
                size="medium"
                text={'MANAGE ARTISTS'}
                onClick={manageArtistsOpenPopup}
                disable={!isInternalAdmin && !hasOrgAdminAccess}
              />
            </Actions>
          ) : null}
        </HeaderInfo>
      </Header>
      <main className={classes.main}>
        {artists?.length ? (
          <div className={classes.section}>
            <div className={classes.section__header}>
              <h2 className={classes.section__title}>Associated Artists</h2>
              {allArtists > artistsCount ? (
                <ViewAll
                  path={`/organizations/${o_f_id}/teams/${g_f_id}/artists`}
                  count={allArtists}
                />
              ) : null}
            </div>
            <ul className={classes.artists} ref={artistsRef}>
              {artists.map((artist, index) => {
                return (
                  <li
                    ref={artistRef}
                    key={artist.f_id}
                    style={{
                      visibility:
                        index + 1 > artistsCount ? 'hidden' : 'visible',
                      position:
                        index + 1 > artistsCount ? 'absolute' : 'static',
                      top: index + 1 > artistsCount ? '-1000px' : 'unset',
                    }}
                  >
                    <ArtistPreview artist={artist} />
                  </li>
                )
              })}
            </ul>
          </div>
        ) : null}
        {users ? (
          <div className={classes.section}>
            <div className={classes.section__header}>
              <h2 className={classes.section__title}>Members</h2>
              {getUsersCount(users) ? (
                <ViewAll
                  path={`/organizations/${o_f_id}/teams/${g_f_id}/members`}
                  count={getUsersCount(users)}
                />
              ) : null}
            </div>
            <div className={classes.users}>
              <TeamUsersTable
                isCurrentUserAnAdmin={hasAdminAccess}
                currentUserRole={currentUserRole}
                users={users}
                getUserRole={getUserRole}
                options={groupRoles}
                onChangeRole={handleChangeOrganizationUsersRole}
                onDelete={handleDeleteGroupUser}
              />
            </div>
          </div>
        ) : null}
      </main>
    </Wrapper>
  )
}
