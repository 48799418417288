import React, { useEffect, useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { useAPI } from '../../../Contexts/APIContext'
import { useQueryClient } from '@tanstack/react-query'
import { sleep } from '../../../Helpers/Timer+Sleep'
import { ModalWindow } from '../../GeneralComponents/ModalWindow/ModalWindow'
import { ButtonOutline } from '../../GeneralComponents/ButtonOutline/ButtonOutline'
import PropTypes from 'prop-types'
import errorToast from '../../../Components/Toasts/ErrorToast'
import albumSocialIcon from '../../icons/social_icons/album_social_icon_no_bg.png'
import instaSocialIcon from '../../icons/social_icons/insta_social_icon_no_bg.png'
import merchSocialIcon from '../../icons/social_icons/merch_social_icon_no_bg.png'
import ticketsSocialIcon from '../../icons/social_icons/tickets_social_icon_no_bg.png'
import tiktokSocialIcon from '../../icons/social_icons/tiktok_social_icon_no_bg.png'
import xSocialIcon from '../../icons/social_icons/x-logo.png'
import youtubeSocialIcon from '../../icons/social_icons/youtube_social_icon_no_bg.png'
import ButtonIcon from '../../GeneralComponents/ButtonIcon'
import { ReactComponent as CameraIcon } from '../../icons/ph_camera.svg'
import classes from './index.module.css'

export default function ArtistEditData({
  isOpenModal,
  setIsOpenModal,
  artist,
}) {
  const { uploadArtistImage, uploadArtistData, getArtistImageUrl } = useAPI()
  const queryClient = useQueryClient()

  const [artistName, setArtistName] = useState('')
  const [youtubeUrl, setYoutubeUrl] = useState('')
  const [instaUrl, setInstaUrl] = useState('')
  const [twitterUrl, setTwitterUrl] = useState('')
  const [tikTokUrl, setTikTokUrl] = useState('')
  const [merchUrl, setMerchUrl] = useState('')
  const [tourUrl, setTourUrl] = useState('')
  const [impactUrl, setImpactUrl] = useState('')

  const [selectedProfileImageFile, setSelectedProfileImageFile] = useState(null)
  const [selectedProfileImageURL, setSelectecProfileImageURL] = useState(null)

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setArtistName(artist.name)
    setYoutubeUrl(
      artist?.social_media?.find(item => {
        return item.f_id === 'youtube'
      })?.url ?? ''
    )
    setInstaUrl(
      artist?.social_media?.find(item => {
        return item.f_id === 'instagram'
      })?.url ?? ''
    )
    setTwitterUrl(
      artist?.social_media?.find(item => {
        return item.f_id === 'twitter'
      })?.url ?? ''
    )
    setTikTokUrl(
      artist?.social_media?.find(item => {
        return item.f_id === 'tiktok'
      })?.url ?? ''
    )
    setMerchUrl(
      artist?.links?.find(item => {
        return item.f_id === 'merchandise'
      })?.url ?? ''
    )
    setTourUrl(
      artist?.links?.find(item => {
        return item.f_id === 'tour'
      })?.url ?? ''
    )
    setImpactUrl(
      artist?.links?.find(item => {
        return item.f_id === 'impact'
      })?.url ?? ''
    )
  }, [])

  const handleOnSave = async () => {
    setIsSaving(true)
    if (selectedProfileImageFile === null) {
      try {
        await uploadArtistData(
          artist.artist_name,
          artistName,
          instaUrl,
          twitterUrl,
          youtubeUrl,
          tikTokUrl,
          merchUrl,
          tourUrl,
          impactUrl
        )
        queryClient.clear()
      } catch (e) {
        errorToast(`Error: ${e.status ?? ''} ${e.message ?? ''}`)
      }
    } else {
      try {
        const uploadLink = await getArtistImageUrl(
          artist.artist_name,
          selectedProfileImageFile.type,
          selectedProfileImageFile.size
        )
        await uploadArtistImage(
          uploadLink.upload_url[0],
          selectedProfileImageFile,
          selectedProfileImageFile.type
        )
        await uploadArtistData(
          artist.artist_name,
          artistName,
          instaUrl,
          twitterUrl,
          youtubeUrl,
          tikTokUrl,
          merchUrl,
          tourUrl,
          impactUrl
        )
        queryClient.clear()
      } catch (e) {
        errorToast(`Error: ${e.status ?? ''} ${e.message ?? ''}`)
      }
    }
    setIsOpenModal(false)
    setIsSaving(false)
  }

  const handleClickUpload = () => {
    const fileInput = document.getElementById('file')
    if (fileInput) {
      fileInput.click()
    }
  }

  return (
    <ModalWindow
      open={isOpenModal}
      onClose={() => {
        setIsOpenModal(false)
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.headerWrapper}>
          <button
            onClick={() => {
              handleClickUpload()
            }}
            type="text"
            className={classes.imageContainer}
          >
            {selectedProfileImageURL || artist?.image?.url ? (
              <img
                className={classes.image}
                src={selectedProfileImageURL ?? artist?.image?.url}
              />
            ) : (
              <div className={classes.emptyImage}>
                <p className={classes.emptyImageText}>
                  {artist?.name.charAt(0).toUpperCase()}
                </p>
              </div>
            )}

            <ButtonIcon icon={<CameraIcon />} className={classes.photoButton} />
            <input
              style={{ display: 'none' }}
              type="file"
              id="file"
              name="file"
              accept="image/png, image/jpeg"
              onChange={e => {
                setSelectedProfileImageFile(e.target.files[0])
                setSelectecProfileImageURL(
                  URL.createObjectURL(e.target.files[0])
                )
              }}
            ></input>
          </button>
        </div>
        <div className={classes.fieldsWrapper}>
          <div>
            <p className={classes.fieldTitle}>Artist Name</p>
            <input
              className={classes.fieldInput}
              type="text"
              id="name"
              name="name"
              value={artistName}
              autoComplete="off"
              placeholder="Name is required"
              required
              onChange={e => setArtistName(e.target.value)}
            />
          </div>
          <div>
            <div className={classes.fieldTitleContainer}>
              <img className={classes.fieldTitleIcon} src={youtubeSocialIcon} />
              <p className={classes.fieldTitle}>Youtube</p>
            </div>
            <input
              className={classes.fieldInput}
              type="text"
              id="youtube"
              name="youtube"
              value={youtubeUrl}
              autoComplete="off"
              placeholder="Leave this empty to hide it from profile"
              required
              onChange={e => setYoutubeUrl(e.target.value)}
            />
          </div>
          <div>
            <div className={classes.fieldTitleContainer}>
              <img className={classes.fieldTitleIcon} src={instaSocialIcon} />
              <p className={classes.fieldTitle}>Instagram</p>
            </div>
            <input
              className={classes.fieldInput}
              type="text"
              id="insta"
              name="insta"
              value={instaUrl}
              autoComplete="off"
              placeholder="Leave this empty to hide it from profile"
              required
              onChange={e => setInstaUrl(e.target.value)}
            />
          </div>
          <div>
            <div className={classes.fieldTitleContainer}>
              <img className={classes.fieldTitleIcon} src={xSocialIcon} />
              <p className={classes.fieldTitle}>X</p>
            </div>
            <input
              className={classes.fieldInput}
              type="text"
              id="twitter"
              name="twitter"
              value={twitterUrl}
              autoComplete="off"
              placeholder="Leave this empty to hide it from profile"
              required
              onChange={e => setTwitterUrl(e.target.value)}
            />
          </div>
          <div>
            <div className={classes.fieldTitleContainer}>
              <img className={classes.fieldTitleIcon} src={tiktokSocialIcon} />
              <p className={classes.fieldTitle}>TikTok</p>
            </div>
            <input
              className={classes.fieldInput}
              type="text"
              id="tikTok"
              name="tikTok"
              value={tikTokUrl}
              autoComplete="off"
              placeholder="Leave this empty to hide it from profile"
              required
              onChange={e => setTikTokUrl(e.target.value)}
            />
          </div>
          <div>
            <div className={classes.fieldTitleContainer}>
              <img className={classes.fieldTitleIcon} src={merchSocialIcon} />
              <p className={classes.fieldTitle}>Merch</p>
            </div>
            <input
              className={classes.fieldInput}
              type="text"
              id="merch"
              name="merch"
              value={merchUrl}
              autoComplete="off"
              placeholder="Leave this empty to hide it from profile"
              required
              onChange={e => setMerchUrl(e.target.value)}
            />
          </div>
          <div>
            <div className={classes.fieldTitleContainer}>
              <img className={classes.fieldTitleIcon} src={ticketsSocialIcon} />
              <p className={classes.fieldTitle}>Tour</p>
            </div>
            <input
              className={classes.fieldInput}
              type="text"
              id="tickets"
              name="tickets"
              value={tourUrl}
              autoComplete="off"
              placeholder="Leave this empty to hide it from profile"
              required
              onChange={e => setTourUrl(e.target.value)}
            />
          </div>
          <div>
            <div className={classes.fieldTitleContainer}>
              <img className={classes.fieldTitleIcon} src={albumSocialIcon} />
              <p className={classes.fieldTitle}>Impact</p>
            </div>
            <input
              className={classes.fieldInput}
              type="text"
              id="album"
              name="album"
              value={impactUrl}
              autoComplete="off"
              placeholder="Leave this empty to hide it from profile"
              required
              onChange={e => setImpactUrl(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.saveWrapper}>
          {isSaving ? (
            <ScaleLoader color="#FF00FF" loading={isSaving} />
          ) : (
            <ButtonOutline
              onClick={() => {
                handleOnSave()
              }}
              background="transparent"
              fontWeight={500}
              size={'small'}
              text={'SAVE'}
              color={'pinkBlack'}
            />
          )}
        </div>
      </div>
    </ModalWindow>
  )
}

ArtistEditData.propTypes = {
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
  artist: PropTypes.object.isRequired,
}
