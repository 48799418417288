import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import classes from './index.module.css'
import { Link } from 'react-router-dom'

export default function ArtistPreview({
  artist: { image, name, artist_name },
}) {
  const textRef = useRef(null)
  const minFontSize = 12
  const initialFontSize = 16

  useEffect(() => {
    const adjustFontSize = () => {
      const element = textRef.current
      const parentWidth = element.parentElement.offsetWidth

      let currentFontSize = initialFontSize
      element.style.fontSize = `${currentFontSize}px`
      element.style.whiteSpace = 'normal'
      element.style.overflow = 'visible'
      element.style.textOverflow = 'clip'

      while (
        element.scrollWidth > parentWidth &&
        currentFontSize > minFontSize
      ) {
        currentFontSize--
        element.style.fontSize = `${currentFontSize}px`
      }

      if (
        element.scrollWidth > parentWidth &&
        currentFontSize === minFontSize
      ) {
        element.style.fontSize = `${initialFontSize}px`
        element.style.whiteSpace = 'nowrap'
        element.style.overflow = 'hidden'
        element.style.textOverflow = 'ellipsis'
      }
    }

    adjustFontSize()

    window.addEventListener('resize', adjustFontSize)
    return () => window.removeEventListener('resize', adjustFontSize)
  }, [name])
  return (
    <Link to={`/artist/${artist_name}`} className={classes.card}>
      {image.url ? (
        <div className={classes.image}>
          <img src={image.url} alt={name} width={100} height={100} />
        </div>
      ) : (
        <div className={classes.emptyImage}>
          <p className={classes.emptyImageText}>
            {name.charAt(0).toUpperCase()}
          </p>
        </div>
      )}
      <h3 ref={textRef} className={classes.title}>
        {name}
      </h3>
    </Link>
  )
}

ArtistPreview.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string,
  f_id: PropTypes.string,
  artist: PropTypes.object,
  artist_name: PropTypes.string,
}
