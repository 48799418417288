import React, { useEffect, useRef, useState, useCallback } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import image1 from './arrow-top.png'
import image2 from './arrow-bottom.png'
import Draggable from 'react-draggable'
import Loader from '../../GeneralComponents/Loader'
import PropTypes from 'prop-types'
import './VideoTimelineScrubber.css'

export default function VideoTimelineScrubber(props) {
  const [videoScrubber] = useHookWithRefCallback()
  const [scrubberWidth, setScrubberWidth] = useState(0)
  const scrubberMargins = {
    left: 20,
    right: 20,
  }
  const [thumbnails, setThumbnails] = useState()
  const [generatingThumbnails, setGeneratingThumbnails] = useState(false)

  useEffect(() => {
    const thumbnailsRefs = props.thumbnails
    if (
      thumbnailsRefs !== undefined &&
      thumbnailsRefs !== null &&
      thumbnailsRefs.length > 0
    ) {
      setGeneratingThumbnails(true)
      getThumbnails(thumbnailsRefs)
    }
  }, [])

  async function getThumbnails(thumbnailsRefs) {
    const pendingPromises = []
    for (let i = 0; i < thumbnailsRefs.length; i++) {
      pendingPromises.push(downloadThumbnail(thumbnailsRefs[i]))
    }
    Promise.all(pendingPromises)
      .then((downloadedThumbnails) => {
        setThumbnails(downloadedThumbnails)
        setGeneratingThumbnails(false)
      })
      .catch((err) => {
        console.log('well that didnt work...', err)
        setGeneratingThumbnails(false)
      })
  }

  function downloadThumbnail(thumbnailRef) {
    return new Promise((resolve, reject) => {
      const storage = getStorage()
      const gsReference = ref(storage, thumbnailRef)
      getDownloadURL(gsReference)
        .then((thumbnailUrl) => {
          resolve(thumbnailUrl)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const [millisecondsPerPixelRatio, setMillisecondsPerPixelRatio] = useState(0)

  const [topMin, setTopMin] = useState(0)
  const [topMax, setTopMax] = useState(0)
  const [bottomMin, setBottomMin] = useState(0)
  const [bottomMax, setBottomMax] = useState(0)
  const [topPosition, setTopPosition] = useState({ x: 0, y: 0 })
  const [bottomPosition, setBottomPosition] = useState({ x: 0, y: 0 })

  const [selectionBox, setSelectionBox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })

  useEffect(() => {
    setTopPosition({ x: props.startX, y: 0 })
    setBottomPosition({ x: props.endX, y: 0 })
    assignTopStop(props.startX, false)
    assignBottomStop(props.endX, false)
    processDrag(props.startX, props.endX, false)
  }, [props.startX, props.endX])

  function useHookWithRefCallback() {
    const ref = useRef(null)
    const setRef = useCallback((node) => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
      }

      if (node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        const scrubberWidth = node.clientWidth
        setScrubberWidth(scrubberWidth)
        const millisecondsPerPixelRatio =
          (props.videoElement.current.duration * 1000) / scrubberWidth
        setMillisecondsPerPixelRatio(millisecondsPerPixelRatio)
        props.setMillisecondsPerPixelRatio(millisecondsPerPixelRatio)
        setBottomMax(
          props.maximumAllowedDurationOfFeelInMilliseconds /
            millisecondsPerPixelRatio,
        )
        const selectionBoxFrame = {
          x: 0,
          y: node.offsetTop,
          width: 0,
          height: node.clientHeight,
        }
        setSelectionBox(selectionBoxFrame)
      }

      // Save a reference to the node
      ref.current = node
    }, [])

    return [setRef]
  }

  function processDrag(topX, bottomX, isDragging) {
    // const scrubber = document.getElementsByClassName('video-thumbnail-scrubber')
    // let scrubberRect = null
    // if (scrubber.length > 0) {
    //   scrubberRect = scrubber[0].getBoundingClientRect()
    // }
    // console.log('scrubberRect: ', scrubberRect)
    setSelectionBox({
      x: topX + scrubberMargins.left,
      y: selectionBox.y, //scrubberRect !== null ? scrubberRect.y : selectionBox.y,
      width: bottomX - topX,
      height: selectionBox.height, //scrubberRect !== null ? scrubberRect.height : selectionBox.height,
    })
    if (isDragging) {
      props.onScrubberSelectedTimeChange(
        topX,
        bottomX,
        millisecondsPerPixelRatio,
      )
    }
  }

  function handleTopDrag(e, data) {
    const { x } = data
    setTopPosition({ x: x, y: 0 })
    processDrag(x, bottomPosition.x, true)
  }

  function handleBottomDrag(e, data) {
    const { x } = data
    setBottomPosition({ x: x, y: 0 })
    processDrag(topPosition.x, x, true)
  }

  function handleTopStop(e, data) {
    let x = data.x
    if (x < 0) {
      x = 0
    } else if (x > scrubberWidth) {
      x = scrubberWidth
    } else if (x > bottomPosition.x) {
      x = bottomPosition.x
    } else if (x <= topMin) {
      x = topMin
    }
    assignTopStop(x, true)
  }

  function assignTopStop(x, wasDragging) {
    setTopPosition({ x: x, y: 0 })
    setBottomMin(x)
    const newBottomMax =
      x +
      props.maximumAllowedDurationOfFeelInMilliseconds /
        millisecondsPerPixelRatio
    setBottomMax(Math.min(newBottomMax, scrubberWidth))
    processDrag(x, bottomPosition.x, wasDragging)
  }

  function handleBottomStop(e, data) {
    let x = data.x
    if (x < 0) {
      x = 0
    } else if (x > scrubberWidth) {
      x = scrubberWidth
    } else if (x < topPosition.x) {
      x = topPosition.x
    } else if (x >= bottomMax) {
      x = bottomMax
    }
    assignBottomStop(x, true)
  }

  function assignBottomStop(x, wasDragging) {
    setBottomPosition({ x: x, y: 0 })
    setTopMax(x)
    const newTopMin =
      x -
      props.maximumAllowedDurationOfFeelInMilliseconds /
        millisecondsPerPixelRatio
    setTopMin(Math.max(0, newTopMin))
    processDrag(topPosition.x, x, wasDragging)
  }

  return (
    <>
      {thumbnails ? (
        <div style={{ marginTop: '10px', position: 'relative' }}>
          <Draggable
            axis="none"
            bounds={{ left: topMin, right: topMax }}
            position={topPosition}
            onDrag={handleTopDrag}
            onStop={handleTopStop}
          >
            <div className="top-arrow-component div-arrow-component">
              <img className="image-arrow-component" src={image1}></img>
            </div>
          </Draggable>
          <div
            className="video-thumbnail-scrubber"
            ref={videoScrubber}
            style={{
              marginLeft: `${scrubberMargins.left}px`,
              marginRight: `${scrubberMargins.right}px`,
            }}
          >
            {thumbnails.map((thumbnail, index) => (
              <img
                className="thumbnail"
                key={index}
                src={thumbnail}
                style={{
                  pointerEvents: 'none',
                  userSelect: 'none',
                }}
              />
            ))}
          </div>
          <div
            style={{
              left: `${selectionBox.x}px`,
              top: `${selectionBox.y}px`,
              width: `${selectionBox.width}px`,
              height: `${selectionBox.height}px`,
              backgroundColor: 'rgba(210, 39, 130, 0.4)',
              position: 'absolute',
            }}
          />
          <Draggable
            axis="none"
            bounds={{ left: bottomMin, right: bottomMax }}
            position={bottomPosition}
            onDrag={handleBottomDrag}
            onStop={handleBottomStop}
          >
            <div className="bottom-arrow-component div-arrow-component">
              <img className="image-arrow-component" src={image2}></img>
            </div>
          </Draggable>
        </div>
      ) : (
        <Loader isLoading={generatingThumbnails} />
      )}
    </>
  )
}

VideoTimelineScrubber.propTypes = {
  videoElement: PropTypes.object.isRequired,
  maximumAllowedDurationOfFeelInMilliseconds: PropTypes.number.isRequired,
  thumbnails: PropTypes.array.isRequired,
  videoSource: PropTypes.string.isRequired,
  onScrubberSelectedTimeChange: PropTypes.func.isRequired,
  setMillisecondsPerPixelRatio: PropTypes.func.isRequired,
  startX: PropTypes.number.isRequired,
  endX: PropTypes.number.isRequired,
}
