import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CreateOrganizationPopupContent({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className={classes.wrapper}>
      {children}
    </form>
  )
}

CreateOrganizationPopupContent.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
}
