import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function CreateOrganizationArtistsRow({ children, artists }) {
  return (
    <ul className={classes.list}>
      {artists.map(artist => {
        const childrenWithProps = React.Children.map(children, child =>
          React.cloneElement(child, { artist })
        )
        return (
          <li className={classes.list__item} key={artist.f_id}>
            {childrenWithProps}
          </li>
        )
      })}
    </ul>
  )
}

CreateOrganizationArtistsRow.propTypes = {
  artists: PropTypes.array,
  children: PropTypes.node,
}
