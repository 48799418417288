import { useQuery } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'

export function useGetOrganizationGroups({ o_f_id, n = '', ...rest }) {
  const { getOrganizationGroups } = useAPI()

  return useQuery({
    queryKey: ['organization', 'groups', o_f_id],
    queryFn: () => getOrganizationGroups({ o_f_id, n }),
    ...rest,
  })
}

