import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export function ButtonOutline({
  disable,
  onClick,
  text,
  icon, // 'createIcon'
  color, // 'pink'| 'green' | 'red' | 'pinkBlack'
  maxWidth = false, // true | false
  fontFamily = 'inherit', // 'inherit' | 'Noto Sans'...
  fontWeight = 600, // 100 | 200 | 300 | ... 900
  size = 'medium', // 'extraSmall' | 'small' | 'medium'
  type = 'button',
  className = '',
}) {
  const [isHover, setIsHover] = useState(false)
  const typeToClass = {
    pink: classes.buttonPink,
    green: classes.buttonGreen,
    red: classes.buttonRed,
    pinkBlack: classes.buttonPinkBlack,
  }
  const buttonClass = typeToClass[color] || typeToClass['red']

  let paddingValue
  let fontSizeValue
  let letterSpacingValue
  let fontWeightValue

  switch (size) {
    case 'extraSmall':
      paddingValue = '3px 11px'
      fontSizeValue = '12px'
      letterSpacingValue = '0px'
      break
    case 'small':
      paddingValue = '8px 16px'
      fontSizeValue = '12px'
      letterSpacingValue = '0px'
      break
    case 'medium':
      paddingValue = '12px 18px'
      fontSizeValue = '12px'
      letterSpacingValue = '0.75px'
      break
    default:
      paddingValue = '15px 18px'
      fontSizeValue = '12px'
      letterSpacingValue = '0.75px'
  }

  if (typeof fontWeight !== 'number' || isNaN(fontWeight)) {
    console.log('fontWeight must be a number (100, 200, ... 900)')
    fontWeight = 600
  }

  if (typeof fontFamily !== 'string') {
    console.log('fontFamily must be a string (Noto Sans...)')
    fontFamily = undefined
  }

  const buttonStyle = {
    padding: paddingValue,
    fontSize: fontSizeValue,
    letterSpacing: letterSpacingValue,
    fontWeight: fontWeight,
    fontFamily: fontFamily,
    width: maxWidth ? '100%' : 'fit-content',
  }

  return (
    <button
      className={`${buttonClass} ${className}`.trim()}
      disabled={disable}
      onClick={onClick}
      style={buttonStyle}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      type={type}
    >
      {icon === 'createIcon' && (
        <img
          className={classes.icon}
          width="24"
          height="24"
          src={require(isHover
            ? './icons/createHover.png'
            : './icons/create.png')}
        />
      )}
      {text}
    </button>
  )
}

ButtonOutline.propTypes = {
  disable: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  maxWidth: PropTypes.bool,
  fontWeight: PropTypes.number,
  fontFamily: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOf(['createIcon']),
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium']),
  color: PropTypes.oneOf(['pink', 'green', 'red', 'pinkBlack']).isRequired,
}
