import { useAuth } from '../../../Contexts/AuthContext'
import { orgRoles, orgRolesAdmin } from '../../../constants/roles'

export default function useAccessLevel(args) {
  const o_f_id = args && args.o_f_id
  const g_f_id = args && args.g_f_id
  const { getCurrentUser } = useAuth()

  const isInternal = getCurrentUser().is_admin
  const isOrganization = getCurrentUser().is_organization

  const currentOrg =
    isOrganization &&
    o_f_id &&
    getCurrentUser().organizations.find(
      organization => organization.f_id === o_f_id
    )
  const groupOrganization =
    currentOrg &&
    currentOrg.organization_user.organization_groups.find(
      group => group.name === 'Organization'
    )
  const isInternalAdmin =
    isInternal &&
    (getCurrentUser().roles[0].role === 'Super Administrator' ||
      getCurrentUser().roles[0].role === 'Administrator')
  const groupOrganizationUserRole =
    groupOrganization && groupOrganization.roles[0].role
  const hasOrgAccess =
    groupOrganizationUserRole && orgRoles.includes(groupOrganizationUserRole)
  const hasOrgAdminAccess =
    groupOrganizationUserRole &&
    orgRolesAdmin.includes(groupOrganizationUserRole)
  const hasOrgEditAccess =
    groupOrganizationUserRole &&
    groupOrganizationUserRole === 'Organization Editor'
  const hasGroupAccess =
    currentOrg &&
    g_f_id &&
    !!currentOrg.organization_user.organization_groups.find(
      group => group.f_id === g_f_id
    )

    const groupRole =
      hasGroupAccess &&
      currentOrg.organization_user.organization_groups.find(
        group => group.f_id === g_f_id
      ).roles[0].role 
  return {
    isInternal,
    hasOrgAdminAccess,
    hasOrgEditAccess,
    isOrganization,
    currentOrg,
    groupOrganization,
    groupOrganizationUserRole,
    hasOrgAccess,
    hasGroupAccess,
    isInternalAdmin,
    groupRole,
  }
}
