import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import CreateOrganizationConfirmationPopup from '../CreateOrganizationConfirmationPopup'
import usePopup from '../../hooks/usePopup'

export default function CreateOrganizationPopupArtistsCount({ count, action }) {
  const { openPopup, closePopup, isOpen } = usePopup()

  const removeAllArtists = () => {
    action()
    closePopup()
  }

  return (
    <div className={classes.wrapper}>
      <p className={classes.count}>
        {count} Artist{count === 1 ? '' : 's'} Assigned
      </p>
      {!!count && action ? (
        <div className={classes.actions}>
          <button
            type="button"
            onClick={openPopup}
            className={classes.actions__remove}
          >
            Unselect All Artists
          </button>
          {isOpen ? (
            <div className={classes.actions__popup}>
              <CreateOrganizationConfirmationPopup
                text={`Are you sure you want to unselect${
                  count === 1 ? '' : ' all'
                } ${count} artist${count === 1 ? '' : 's'}?`}
                buttonText="UNSELECT"
                confirmAction={removeAllArtists}
                closeAction={closePopup}
                isOpen={isOpen}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

CreateOrganizationPopupArtistsCount.propTypes = {
  count: PropTypes.number,
  action: PropTypes.func,
}
