import React from 'react'
import PropTypes from 'prop-types'
// import classes from './index.module.css'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
// import DebounceSearch from '../../../../Components/GeneralComponents/DebounceSearch/DebounceSearch'
import { Actions } from '../../../../Components/page/Actions'
// import { useOrganizationsStore } from '../../../../Storage/organizationsPage/useOrganizationsStore'

export default function CreateOrganizationHeaderActions({ openPopup }) {
  // const searchTerm = useOrganizationsStore(state => state.searchTerm)
  // const setSearchTerm = useOrganizationsStore(state => state.setSearchTerm)
  // const inputValue = useOrganizationsStore(state => state.inputValue)
  // const setInputValue = useOrganizationsStore(state => state.setInputValue)
  return (
    <Actions>
      {/* <div className={classes.search}>
        <DebounceSearch
          searchValue={searchTerm}
          inputValue={inputValue}
          setSearchValue={setSearchTerm}
          setInputValue={setInputValue}
          placeholder={'Search Organizations'}
          fullWidth
        />
      </div> */}
      <ButtonOutline
        type="button"
        disable={false}
        color={'pink'}
        size="small"
        text={'CREATE ORGANIZATION'}
        onClick={openPopup}
      />
    </Actions>
  )
}

CreateOrganizationHeaderActions.propTypes = {
  openPopup: PropTypes.func,
}
