import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function StickerNameInput({
  name,
  setName,
  initialValue,
  setInitialValue = () => {},
  setInitialFlag = () => {},
  setIsEdit = () => {},
}) {
  const [toggle, setToggle] = useState(true)
  const [text, setText] = useState('')
  const inputNameRef = useRef(null)

  useEffect(() => {
    if (name !== null && !initialValue) {
      setInitialValue(name)
    }
  }, [name, initialValue])

  useEffect(() => {
    if (name === null || name.trim() === '') {
      setText('')
      setToggle(false)
    } else if (name) {
      setText(name)
      setToggle(true)
    }
  }, [name])

  useEffect(() => {
    const handleClickOutsideName = event => {
      text !== initialValue ? setIsEdit(true) : setIsEdit(false)
      if (
        inputNameRef.current &&
        !inputNameRef.current.contains(event.target)
      ) {
        if (text.length > 0) {
          setName(text)
          if (text !== initialValue) {
            setInitialFlag(true)
          } else {
            setInitialFlag(false)
          }
        } else {
          setName(null)
        }
        setToggle(text.length > 0 && text.trim() !== '')
      }
    }

    document.addEventListener('mousedown', handleClickOutsideName)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideName)
    }
  }, [text, setName, initialValue])

  const handleChange = event => {
    setText(event.target.value)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (text.length > 0) {
        setName(text)
        if (text !== initialValue) {
          setInitialFlag(true)
        } else {
          setInitialFlag(false)
        }
      }
      setToggle(text.length > 0 && text.trim() !== '')
    }
  }

  return (
    <div ref={inputNameRef}>
      {toggle ? (
        <span
          className={classes.spanName}
          onDoubleClick={() => setToggle(false)}
        >
          {name}
        </span>
      ) : (
        <input
          className={classes.inputName}
          type="text"
          placeholder="Smart Stickers Set Name"
          value={text}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          maxLength={65}
        />
      )}
    </div>
  )
}

StickerNameInput.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  setInitialValue: PropTypes.func,
  setName: PropTypes.func.isRequired,
  setIsEdit: PropTypes.func,
  setInitialFlag: PropTypes.func,
}
