import React from 'react'
import PropTypes from 'prop-types'

import classes from './index.module.css'

export default function ButtonIcon({ icon, className = '', ...rest }) {
  return (
    <div
      className={`${classes.button}${className ? ' ' + className : ''}`}
      {...rest}
    >
      {icon}
    </div>
  )
}

ButtonIcon.propTypes = {
  icon: PropTypes.any,
  className: PropTypes.string,
}
