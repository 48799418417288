import React, { useState, useEffect } from 'react'
import { useStickersAddStore } from '../../../Storage/stickersPage/useStickersAddStore'
import {
  generatedToken,
  transformPackName,
} from '../../../Helpers/stickerGenerators'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import PropTypes from 'prop-types'
import StickerNameInput from '../StickerNameInput/StickerNameInput'
import StickerTextArea from '../StickerTextArea/StickerTextArea'
import StatusButton from '../../GeneralComponents/StatusButton/StatusButton'
import classes from './index.module.css'

export default function StickerPackAdd({
  setIsLoadingSave,
  setCurrentStickerPack,
  setCurrentStickerOnError,
  setForcedFetch,
}) {
  const { addStickersPack } = useAPI()
  const queryClient = useQueryClient()

  const [token, setToken] = useState(null)

  // zustand storage
  const packName = useStickersAddStore(state => state.packName)
  const setPackName = useStickersAddStore(state => state.setPackName)

  const packDescription = useStickersAddStore(state => state.packDescription)
  const setPackDescription = useStickersAddStore(
    state => state.setPackDescription
  )

  useEffect(() => {
    if (packName) {
      const transformedPackName = transformPackName(packName)
      setToken(generatedToken(transformedPackName))
    }
  }, [packName])

  const addStickerPackMutation = useMutation({
    mutationFn: data =>
      addStickersPack(token, packName, packDescription, 'emotion'),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['stickers'] })
      setForcedFetch(true)
      setPackName(null)
      setPackDescription(null)
      setCurrentStickerPack(data?.f_id)
    },
    onError: e => {
      setIsLoadingSave(false)
      setCurrentStickerOnError()
    },
  })

  const saveHandler = () => {
    if (
      packName &&
      packName.trim() !== '' &&
      packDescription &&
      packDescription.trim() !== ''
    ) {
      setIsLoadingSave(true)
      addStickerPackMutation.mutate()
    }
  }

  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>Add Smart Sticker Set</span>

      <StickerNameInput
        name={packName}
        setName={setPackName}
        initialValue={''}
      />
      <StickerTextArea
        description={packDescription}
        setDescription={setPackDescription}
        initialValue={''}
      />

      <div className={classes.buttonWrapper}>
        <StatusButton
          color={'yellow'}
          text={'create'}
          disabled={
            !packName ||
            packName.trim() === '' ||
            !packDescription ||
            packDescription.trim() === ''
          }
          onClick={saveHandler}
        />
      </div>
    </div>
  )
}

StickerPackAdd.propTypes = {
  setIsLoadingSave: PropTypes.func,
  setCurrentStickerPack: PropTypes.func,
  setCurrentStickerOnError: PropTypes.func,
  setForcedFetch: PropTypes.func,
}
