import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function DebounceSearch({
  inputValue,
  setInputValue,
  searchValue,
  setSearchValue,
  placeholder,
  fullWidth,
}) {
  const handleInputChange = event => {
    setInputValue(event.target.value)
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchValue(inputValue)
      clearTimeout(timeoutId)
    }, 700)
    return () => clearTimeout(timeoutId)
  }, [inputValue, 700])

  return (
    <input
      value={inputValue}
      className={classes.input}
      style={{ width: fullWidth ? '100%' : '' }}
      placeholder={placeholder}
      onChange={handleInputChange}
    />
  )
}

DebounceSearch.propTypes = {
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
}
