import React from 'react'
import PropTypes from 'prop-types'
import TeamUsersTableRow from '../TeamUsersTableRow'
// import InviteButton from '../InviteButton'
import classes from './index.module.css'

export default function TeamUsersTable({
  isCurrentUserAnAdmin,
  currentUserRole,
  users,
  getUserRole,
  options,
  onChangeRole,
  onDelete,
}) {
  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.row}>
          <th>
            <p className={classes.text}>Name</p>
          </th>
          <th>
            <p className={classes.text}>Email</p>
          </th>
          <th>
            <p className={classes.text}>Last Active</p>
          </th>
          <th>
            <p className={classes.text}>Access Level</p>
          </th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        {[...users.admin, ...users.editor, ...users.viewer].map(
          (user, index, self) => {
            return (
              <TeamUsersTableRow
                key={user.f_id + getUserRole(user)}
                user={user}
                currentRole={getUserRole(user)}
                currentUserRole={currentUserRole}
                isCurrentUserAdmin={isCurrentUserAnAdmin}
                options={options}
                onChangeRole={onChangeRole}
                onDelete={onDelete}
                zIndex={self.length - index}
              />
            )
          }
        )}
      </tbody>
    </table>
  )
}

TeamUsersTable.propTypes = {
  users: PropTypes.object,
  isCurrentUserAnAdmin: PropTypes.bool.isRequired,
  getUserRole: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  currentUserRole: PropTypes.string.isRequired,
}
