import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function TagInput({
  onTagInput,
  onEnterPressed,
  currentlyEnteredTag,
  disabled = false,
  isTagsUpdatingSuccess,
}) {
  const [isEnterPressed, setIsEnterPressed] = useState(false)
  const inputRef = useRef()
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      onEnterPressed()
      setIsEnterPressed(true)
    }
  }

  useEffect(() => {
    if (isTagsUpdatingSuccess && isEnterPressed) {
      inputRef.current.value = ''
      inputRef.current.focus()
      setIsEnterPressed(false)
    }
  }, [isTagsUpdatingSuccess])

  return (
    <input
      disabled={disabled}
      ref={inputRef}
      className={classes.input}
      placeholder="Add Tags for this Music Message"
      value={currentlyEnteredTag}
      maxLength={40}
      onInput={e => {
        onTagInput(e)
      }}
      onKeyDown={handleKeyDown}
      onBlur={() => setIsEnterPressed(false)}
    ></input>
  )
}

TagInput.propTypes = {
  onEnterPressed: PropTypes.func.isRequired,
  onTagInput: PropTypes.func.isRequired,
  currentlyEnteredTag: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isTagsUpdatingSuccess: PropTypes.bool,
}
