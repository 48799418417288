import { useEffect, useState } from 'react'

const useKeyboardEvents = ({
  lyrics,
  currentlySelectedLine,
  handleLyricSelected,
  onSaveClick,
  videoElement,
  isTeaseChanged,
  isLoopingLines,
  setIsLoopingLines,
  handleSetCurrentVideoPlayerToLineAction,
  handleIncrementDecrementButton,
  handleIncrementDecrementToLineAndFollowingLines,
  isEditing,
  songData,
}) => {
  const [isShiftDown, setIsShiftDown] = useState(false)
  const [isAltDown, setIsAltDown] = useState(false)
  const [isXDown, setIsXDown] = useState(false)

  let didAddEventListeners = false

  useEffect(() => {
    if (!didAddEventListeners && !isEditing) {
      document.onkeydown = e => {
        if (!isEditing) {
          e = e || window.event
          if (e.shiftKey) {
            setIsShiftDown(true)
          }
          if (e.key.toLowerCase() === 'z') {
            setIsAltDown(true)
          }
          if (e.key.toLowerCase() === 'x') {
            setIsXDown(true)
          }
          if (!isEditing && e.code === 'Space') {
            e.preventDefault()
          }
        }
      }

      document.onkeyup = e => {
        if (!isEditing) {
          e = e || window.event
          const indexOfSelectedLine = lyrics.indexOf(currentlySelectedLine)
          if (e.key === 'ArrowDown') {
            if (indexOfSelectedLine === -1 && lyrics.length > 0) {
              handleLyricSelected(lyrics[0])
            } else if (lyrics.length - 1 > indexOfSelectedLine) {
              for (var i = indexOfSelectedLine + 1; i < lyrics.length; i++) {
                if (lyrics[i].line !== '') {
                  handleLyricSelected(lyrics[i])
                  break
                }
              }
            }
          } else if (e.key === 'ArrowUp') {
            if (indexOfSelectedLine >= 1) {
              for (var j = indexOfSelectedLine - 1; j >= 0; j--) {
                if (lyrics[j].line !== '') {
                  handleLyricSelected(lyrics[j])
                  break
                }
              }
            } else if (indexOfSelectedLine === 0) {
              handleLyricSelected(lyrics[indexOfSelectedLine])
            }
          } else if (e.key.toLowerCase() === 's' && isTeaseChanged) {
            onSaveClick()
          } else if (e.key.toLowerCase() === 'm') {
            videoElement.current.muted = !videoElement.current.muted
          } else if (e.key.toLowerCase() === 'p') {
            if (videoElement.current.paused) {
              videoElement.current.play()
            } else {
              videoElement.current.pause()
            }
            videoElement.current.muted = !videoElement.current.muted
          } else if (e.key.toLowerCase() === 'y') {
            setIsLoopingLines(!isLoopingLines)
          } else if (e.key.toLowerCase() === 'u') {
            handleSetCurrentVideoPlayerToLineAction()
          } else if (
            (e.key.toLowerCase() === 'q' ||
              e.key.toLowerCase() === 'w' ||
              e.key.toLowerCase() === 'e' ||
              e.key.toLowerCase() === 'r') &&
            indexOfSelectedLine !== -1
          ) {
            handleIncrementDecrementButton(
              lyrics[indexOfSelectedLine],
              e.key.toLowerCase() === 'q' || e.key.toLowerCase() === 'w',
              e.key.toLowerCase() === 'q' || e.key.toLowerCase() === 'e',
              isShiftDown ? 1000 : isAltDown ? 20 : isXDown ? 300 : 100,
              true
            )
          } else if (
            (e.key.toLowerCase() === 'h' ||
              e.key.toLowerCase() === 'j' ||
              e.key.toLowerCase() === 'k' ||
              e.key.toLowerCase() === 'l') &&
            indexOfSelectedLine !== -1
          ) {
            handleIncrementDecrementToLineAndFollowingLines(
              lyrics[indexOfSelectedLine],
              e.key.toLowerCase() === 'h' || e.key.toLowerCase() === 'j',
              e.key.toLowerCase() === 'h' || e.key.toLowerCase() === 'k',
              isShiftDown ? 1000 : isAltDown ? 20 : isXDown ? 300 : 100,
              true
            )
          } else if (e.code === 'Space') {
            if (videoElement.current.paused) {
              videoElement.current.play()
            } else {
              videoElement.current.pause()
            }
            videoElement.current.muted = !videoElement.current.muted
          }
          if (e.key === 'Shift') {
            setIsShiftDown(false)
          }
          if (e.key.toLowerCase() === 'z') {
            setIsAltDown(false)
          }
          if (e.key.toLowerCase() === 'x') {
            setIsXDown(false)
          }
        }
      }
      didAddEventListeners = true
    }
    return () => {
      document.onkeydown = null
      document.onkeyup = null
    }
  }, [
    lyrics,
    songData,
    currentlySelectedLine,
    isTeaseChanged,
    isLoopingLines,
    isShiftDown,
    isAltDown,
    isXDown,
    isEditing,
  ])

  return { isShiftDown, isAltDown, isXDown }
}

export default useKeyboardEvents
