import React, { useState, useEffect } from 'react'
import {
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useAPI } from '../../../Contexts/APIContext'
import { useNavigate } from 'react-router-dom'
import { createStoreForCollection } from '../../../Storage/createStoreForCollection'
import { useInView } from 'react-intersection-observer'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CollectionDropdownList from '../CollectionDropdownList/CollectionDropdownList'
import FeelItemCard from '../FeelItemCard/FeelItemCard'
import FeelAddItem from '../FeelAddItem/FeelAddItem'
import Loader from '../../GeneralComponents/Loader'
import CollectionNameInput from '../CollectionNameInput/CollectionNameInput'
import CoverUploader from '../CoverUploader/CoverUploader'
import PropTypes from 'prop-types'
import PublicationBadge from '../../GeneralComponents/PublicationBadge'
import { ButtonOutline } from '../../GeneralComponents/ButtonOutline/ButtonOutline'
import classes from './index.module.css'

export default function Collection({
  name,
  location,
  collectionId,
  collectionLength,
  feelsCount,
  isPublic,
  onDeleteCollection,
  isFetchingCollections,
}) {
  const pageLimit = 10
  const feelsLimit = 25
  const navigate = useNavigate()
  const [ref, inView] = useInView()
  const queryClient = useQueryClient()
  const { getCollection, publishToggleCollection, updateCollection } = useAPI()

  // zustand storage
  const useCollectionStore = createStoreForCollection(
    collectionId,
    name,
    location
  )
  const feels = useCollectionStore(state => state.feels)
  const setFeels = useCollectionStore(state => state.setFeels)
  const collectionName = useCollectionStore(state => state.collectionName)
  const setCollectionName = useCollectionStore(state => state.setCollectionName)
  const collectionNameInfo = useCollectionStore(
    state => state.collectionNameInfo
  )
  const setCollectionNameInfo = useCollectionStore(
    state => state.setCollectionNameInfo
  )
  const collectionLocation = useCollectionStore(
    state => state.collectionLocation
  )
  const setCollectionLocation = useCollectionStore(
    state => state.setCollectionLocation
  )
  const collectionLocationInfo = useCollectionStore(
    state => state.collectionLocationInfo
  )
  const setCollectionLocationInfo = useCollectionStore(
    state => state.setCollectionLocationInfo
  )
  const showSave = useCollectionStore(state => state.showSave)
  const setShowSave = useCollectionStore(state => state.setShowSave)
  const removedFeels = useCollectionStore(state => state.removedFeels)
  const setRemovedFeels = useCollectionStore(state => state.setRemovedFeels)
  const isChangedFeels = useCollectionStore(state => state.isChangedFeels)
  const setIsChangedFeels = useCollectionStore(state => state.setIsChangedFeels)

  const [isDragging, setIsDragging] = useState(false)
  const [forcedFetch, setForcedFetch] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [coverImage, setCoverImage] = useState(null)

  const {
    data,
    isError,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['collection', collectionId, isPublic],
    queryFn: ({ pageParam = 1 }) =>
      getCollection(collectionId, pageParam, pageLimit),
    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.feels_count ? nextPage : undefined
      return newPage
    },
    enabled: feelsCount > 0,
  })

  useEffect(() => {
    if (inView && feelsCount > 0 && !isError) {
      fetchNextPage()
    }
  }, [inView])

  useEffect(() => {
    if (forcedFetch && hasNextPage && feelsCount > 0 && !isFetchingNextPage) {
      fetchNextPage()
    }
    if (!hasNextPage) {
      setDisabledButton(false)
    }
  }, [hasNextPage, forcedFetch, isFetchingNextPage])

  useEffect(() => {
    if (data && feelsCount > 0) {
      const paginatedFeels = data.pages.flatMap(page => page.feels)
      const existingFeels = feels.filter(feel =>
        paginatedFeels.some(paginatedFeel => paginatedFeel.f_id === feel.f_id)
      )

      const newFeels = paginatedFeels.filter(
        paginatedFeel =>
          !existingFeels.some(
            existingFeel => existingFeel.f_id === paginatedFeel.f_id
          ) && !removedFeels.includes(paginatedFeel.f_id)
      )
      setFeels([...feels, ...newFeels])

      if (Object.keys(data.pages[0].image).length) {
        setCoverImage(data.pages[0].image?.url)
      }
    }
  }, [data])

  useEffect(() => {
    if (
      isChangedFeels ||
      collectionLocationInfo.isSelected ||
      collectionNameInfo.isChanged
    ) {
      setShowSave(true)
    } else {
      setShowSave(false)
    }
  }, [
    isChangedFeels,
    collectionLocationInfo.isSelected,
    collectionNameInfo.isChanged,
  ])

  function getFeelsFIDs(feels) {
    const fIds = feels.map(feel => feel?.f_id)
    return fIds
  }

  function handleClickOnFeel(feel) {
    navigate('/feel/' + feel.f_id)
  }

  function handleOnDragStart() {
    setIsDragging(true)
    setIsEdit(true)
  }

  function handleOnDragEnd(result) {
    setIsDragging(false)
    setIsEdit(false)
    setForcedFetch(true)

    if (!result.destination) {
      return
    }
    const items = Array.from(feels)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setIsChangedFeels(true)
    const isOrderChanged = !items.every((item, index) => item === feels[index])
    if (isOrderChanged) {
      setIsChangedFeels(true)
    } else {
      setIsChangedFeels(false)
    }
    setFeels(items)
  }

  function handleOnDeleteFeel(feelToDelete) {
    const newFeels = feels.filter(feel => feel !== feelToDelete)
    setFeels(newFeels)
    setRemovedFeels([...removedFeels, feelToDelete.f_id])
    setIsChangedFeels(true)
  }

  function setToCollection(feel) {
    setFeels([...feels, feel])
    setIsChangedFeels(true)
  }

  // SAVE COLLECTION

  function onClickSave() {
    setDisabledButton(true)
    updateCollectionMutation.mutate()
  }

  const updateCollectionMutation = useMutation({
    mutationFn: () =>
      updateCollection(collectionId, collectionName, true, getFeelsFIDs(feels)),
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: ['collection', collectionId, isPublic],
      })
      setDisabledButton(false)
      setCollectionNameInfo({
        isChanged: false,
        initialValue: null,
      })
      setCollectionLocationInfo({
        isSelected: false,
        initialValue: null,
      })
      setRemovedFeels([])
      setIsChangedFeels(false)
    },
    onSettled: e => {
      setDisabledButton(false)
    },
  })

  // PUBLISH/UNPUBLISH COLLECTION
  function onClickPublish(isPublic) {
    setDisabledButton(true)
    publishToggleCollectionMutation.mutate(isPublic)
  }

  const publishToggleCollectionMutation = useMutation({
    mutationFn: isPublic => publishToggleCollection(collectionId, isPublic),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['collections'] })
      setDisabledButton(false)
    },
    onSettled: e => {
      setDisabledButton(false)
    },
  })

  return (
    <>
      <div className={classes.collectionHeader}>
        {!isError ? (
          <div className={classes.collectionNameContainer}>
            <CollectionNameInput
              initialValue={collectionNameInfo.initialValue}
              setInitialValue={state =>
                setCollectionNameInfo({
                  ...collectionNameInfo,
                  initialValue: state,
                })
              }
              setInitialFlag={state =>
                setCollectionNameInfo({
                  ...collectionNameInfo,
                  isChanged: state,
                })
              }
              collectionId={collectionId}
              setIsEdit={setIsEdit}
              name={collectionName}
              setName={setCollectionName}
            />
            <div className={classes.collectionNameWrapperButton}>
              <CollectionDropdownList
                initialValue={collectionLocationInfo.initialValue}
                setInitialValue={state =>
                  setCollectionLocationInfo({
                    ...collectionLocationInfo,
                    initialValue: state,
                  })
                }
                setInitialFlag={state =>
                  setCollectionLocationInfo({
                    ...collectionLocationInfo,
                    isSelected: state,
                  })
                }
                setIsEdit={setIsEdit}
                selectedOption={collectionLocation}
                setSelectedOption={setCollectionLocation}
                collectionLength={collectionLength}
              />
              <CoverUploader
                collectionId={collectionId}
                coverImage={coverImage}
                isPublic={isPublic}
              />
              {isEdit && (
                <PublicationBadge status={'published'} text={'Live Editing'} />
              )}
              {isPublic && (
                <PublicationBadge status={'published'} text={'Published'} />
              )}
              {!isPublic && (
                <PublicationBadge status={'unpublished'} text={'Unpublished'} />
              )}
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div className={classes.collectionStatusButtons}>
          {showSave && feels.length > 0 && (
            <ButtonOutline
              color={'green'}
              size="small"
              text={'SAVE'}
              disable={
                isLoading ||
                disabledButton ||
                isFetchingCollections ||
                isFetching ||
                isFetchingNextPage ||
                isError
              }
              onClick={onClickSave}
            />
          )}
          {isPublic && (
            <ButtonOutline
              color={'red'}
              size="small"
              text={'UNPUBLISH'}
              onClick={() => onClickPublish(false)}
              disable={
                isLoading ||
                disabledButton ||
                isFetchingCollections ||
                isFetching ||
                isFetchingNextPage ||
                isError
              }
            />
          )}
          {!isPublic && (
            <ButtonOutline
              color={'green'}
              size="small"
              text={'PUBLISH'}
              disable={
                isLoading ||
                disabledButton ||
                isFetchingCollections ||
                isFetching ||
                isFetchingNextPage ||
                isError
              }
              onClick={() => onClickPublish(true)}
            />
          )}
          <ButtonOutline
            color={'red'}
            size="small"
            text={'DELETE'}
            disable={
              isLoading ||
              disabledButton ||
              isFetchingCollections ||
              isFetching ||
              isFetchingNextPage
            }
            onClick={() => onDeleteCollection(collectionId)}
          />
        </div>
      </div>
      <div className={classes.collectionCarousel}>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={handleOnDragStart}
        >
          <Droppable droppableId="feels" direction="horizontal">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: 'flex' }}
              >
                {!isLoading &&
                  feels?.length > 0 &&
                  feels.map((feel, index) => (
                    <Draggable
                      key={feel.f_id}
                      draggableId={feel.f_id}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FeelItemCard
                            isDragging={isDragging}
                            feel={feel}
                            onClick={handleClickOnFeel}
                            onDelete={handleOnDeleteFeel}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {!isLoading && !isError && feels.length < feelsLimit && (
          <div ref={ref}>
            <FeelAddItem
              setIsEdit={setIsEdit}
              isDragging={isDragging}
              reservedFeels={getFeelsFIDs(feels)}
              setToNewCollection={setToCollection}
              disable={isFetchingNextPage}
            />
          </div>
        )}

        {!isError && <Loader isLoading={isLoading} />}
        {isError && (
          <h1 className={classes.errorText}>{'Oops, something went wrong'}</h1>
        )}
      </div>
    </>
  )
}

Collection.propTypes = {
  name: PropTypes.string,
  location: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  collectionId: PropTypes.string,
  collectionLength: PropTypes.number,
  feelsCount: PropTypes.number,
  isPublic: PropTypes.bool,
  onDeleteCollection: PropTypes.func,
  isFetchingCollections: PropTypes.bool,
}
