import React, { useState } from 'react'
import CreateOrganizationPopupContent from '../CreateOrganizationPopupContent'
import CreateOrganizationPopupTitle from '../CreateOrganizationPopupTitle'
import CreateOrganizationPopupFormActions from '../CreateOrganizationPopupFormActions'
import { ButtonOutline } from '../../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import errorToast from '../../../../Components/Toasts/ErrorToast'
import successToast from '../../../../Components/Toasts/SuccessToast'
import { useCreateOrganization } from '../../hooks/useCreateOrganization'
import { useQueryClient } from '@tanstack/react-query'
import EditOrganizationPopupFormStep from '../EditOrganizationPopupFormStep'
import PropTypes from 'prop-types'

export default function EditOrganizationPopupForm({
  organization,
  closePopup,
}) {
  const [data, setData] = useState({
    name: organization.name,
  })

  const queryClient = useQueryClient()

  const { mutate: createOrganization, isPending: isLoadingCreateOrganization } =
    useCreateOrganization()

  const handleEditOrganization = organizationData => {
    createOrganization(organizationData, {
      onSuccess: async () => {
        successToast('Organization edited successfully')
        closePopup()
        await queryClient.invalidateQueries(['organizations'])
      },
      onError: error => {
        errorToast('Error editing Organization', error)
      },
    })
  }

  const onSubmit = e => {
    e.preventDefault()
    const { name } = data

    if (!name) {
      errorToast('Organization Name is required')
      return
    }

    handleEditOrganization({
      name: data.name,
      o_f_id: organization.f_id,
    })
  }

  return (
    <CreateOrganizationPopupContent onSubmit={onSubmit}>
      <CreateOrganizationPopupTitle title={'Edit Organization'} />
      <EditOrganizationPopupFormStep setData={setData} data={data} />
      <CreateOrganizationPopupFormActions>
        <ButtonOutline
          color={'pink'}
          size="small"
          text={'SAVE'}
          type="submit"
          disable={isLoadingCreateOrganization}
        />
      </CreateOrganizationPopupFormActions>
    </CreateOrganizationPopupContent>
  )
}

EditOrganizationPopupForm.propTypes = {
  organization: PropTypes.object,
  closePopup: PropTypes.func,
}
