import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import TextField from '../../../../../Components/GeneralComponents/TextField'
import CreateTeamDynamicInputsTextFields from '../../CreateTeamDynamicInputsTextFields'

export default function CreateOrganizationPopupFormStep1({ setStepData }) {
  const onTextChange = e =>
    setStepData(prev => ({ ...prev, [e.target.name]: e.target.value }))

  const onArrayChange = newOwners =>
    setStepData(prev => {
      return {
        ...prev,
        owners: newOwners,
      }
    })

  return (
    <div className={classes.wrapper}>
      <TextField
        label={'Team Name'}
        name={'name'}
        onChange={onTextChange}
        isRequired
        variant={'rounded'}
        placeholder={'Enter team name'}
      />

      <CreateTeamDynamicInputsTextFields
        placeholder={'Enter email'}
        label={'Team Member'}
        onChange={onArrayChange}
      />
    </div>
  )
}

CreateOrganizationPopupFormStep1.propTypes = {
  setStepData: PropTypes.func,
}
