import React from 'react'
import classes from './index.module.css'
import Loader from '../../../Components/GeneralComponents/Loader'
import { Wrapper } from '../../../Components/page/Wrapper'
import { Header } from '../../../Components/page/Header'
import { HeaderInfo } from '../../../Components/page/HeaderInfo'
import { Actions } from '../../../Components/page/Actions'
import { ButtonOutline } from '../../../Components/GeneralComponents/ButtonOutline/ButtonOutline'
import PageTitle from '../components/PageTitle'
import { useParams } from 'react-router-dom'
import { useGetOrgDetails } from '../hooks/useGetOrgDetails'
import { useGetOrganizationGroups } from '../hooks/useGetOrganizationGroups'
import GroupPreview from '../components/GroupPreview'
import OrganizationsPopup from '../components/OrganizationsPopup'
import usePopup from '../hooks/usePopup'
import CreateTeamPopupForm from '../components/CreateTeamPopupForm'
import { Navigation } from '../../../Components/page/Navigation'
import { renderBreadcrumbs } from './breadcrumbs'
import useAccessLevel from '../hooks/useAccessLevel'

export default function OrganizationTeams() {
  const { id } = useParams()

  const { isInternalAdmin, hasOrgAdminAccess } = useAccessLevel({
    o_f_id: id,
  })

  const {
    openPopup: addTeamOpenPopup,
    closePopup: addTeamClosePopup,
    isOpen: addTeamIsOpen,
  } = usePopup()

  const {
    data: orgDetails,
    isError: orgDetailsError,
    isLoading: orgDetailsIsLoading,
    isFetching: orgDetailsFetching,
  } = useGetOrgDetails({
    id,
  })

  const {
    data: organizationGroups,
    isError: organizationGroupsError,
    isLoading: organizationGroupsIsLoading,
    isFetching: isOrganizationGroupsFetching,
  } = useGetOrganizationGroups({
    o_f_id: id,
  })

  const groups = organizationGroups?.organization_groups

  if (orgDetailsIsLoading || organizationGroupsIsLoading) {
    return (
      <Loader isLoading={orgDetailsIsLoading || organizationGroupsIsLoading} />
    )
  }

  if (orgDetailsError || organizationGroupsError) {
    return (
      <Wrapper className={classes.wrapper}>
        <Header>
          <HeaderInfo>
            <PageTitle title={'Error loading data'} />
          </HeaderInfo>
        </Header>
      </Wrapper>
    )
  }
  return (
    <Wrapper className={classes.wrapper}>
      <OrganizationsPopup isOpen={addTeamIsOpen} closePopup={addTeamClosePopup}>
        <CreateTeamPopupForm organization={orgDetails} />
      </OrganizationsPopup>

      <Header>
        {orgDetails ? (
          <Navigation breadcrumbs={renderBreadcrumbs(orgDetails)} />
        ) : null}
        <HeaderInfo>
          <PageTitle title={orgDetails?.name} />
          <Actions>
            <ButtonOutline
              color={'pink'}
              size="medium"
              text={'CREATE TEAM'}
              onClick={addTeamOpenPopup}
              disable={!isInternalAdmin && !hasOrgAdminAccess}
            />
          </Actions>
        </HeaderInfo>
      </Header>
      <main className={classes.main}>
        {groups?.length ? (
          <div className={classes.section}>
            <div className={classes.section__header}>
              <h2 className={classes.section__title}>Teams</h2>
            </div>
            <ul className={classes.groups}>
              {groups
                .filter(group => group.name !== 'Organization')
                .map((group) => (
                  <li key={group.f_id}>
                    <GroupPreview
                      disabled={!isInternalAdmin && !hasOrgAdminAccess}
                      group={group}
                    />
                  </li>
                ))}
            </ul>
          </div>
        ) : null}
      </main>
    </Wrapper>
  )
}
