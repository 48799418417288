import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import StatusPill from '../../GeneralComponents/StatusPill/StatusPill'
import calendarIcon from '../../icons/feels_icons/calendarIcon.png'
import songIcon from '../../icons/feels_icons/songIcon.png'
import artistIcon from '../../icons/feels_icons/artistIcon.png'
import mutedButton from '../../icons/feels_icons/mutedButton.png'
import unmutedButton from '../../icons/feels_icons/unmutedButton.png'
import classes from './index.module.css'
import PublicationBadge from '../../GeneralComponents/PublicationBadge'
import { usePreventFullscreen } from '../../../Helpers/hooks/usePreventFullscreen'

export default function FeelGridItem({
  feel,
  isMutedOnHover,
  onClick,
  onMuteUnmuteClick,
}) {
  const videoElement = useRef()
  usePreventFullscreen(videoElement)

  const [shouldShowVideo, setShouldShowVideo] = useState(false)

  function getPublishedDate() {
    let publishedDate = new Date(
      Date.parse(feel.date_published)
    ).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
    return publishedDate
  }

  function handleDetailsClicked() {
    if (onClick !== undefined && onClick !== null) {
      onClick(feel)
    }
  }

  function handleOnTimeUpdate() {
    if (videoElement.current.currentTime >= videoElement.current.duration) {
      videoElement.current.currentTime = 0
      videoElement.current.play()
    }
  }

  async function handleVideoPlay() {
    try {
      await videoElement.current.play()
    } catch (error) {
      // solution for error 'The play() request was interrupted by a call to pause()'
    }
  }

  function handleVideoPause() {
    if (!videoElement.current.paused) {
      videoElement.current.pause()
      videoElement.current.currentTime = 0
    }
  }

  return (
    <div
      className={classes.container}
      onMouseOver={() => {
        setShouldShowVideo(true)
        if (videoElement.current) {
          handleVideoPlay()
        }
      }}
      onMouseLeave={() => {
        setShouldShowVideo(false)
        if (videoElement.current) {
          handleVideoPause()
        }
      }}
    >
      <div className={classes.imageWrapper}>
        <img className={classes.image} src={feel?.preview?.image?.image?.url} />

        {shouldShowVideo && (
          <video
            ref={videoElement}
            className={classes.video}
            controls={false}
            autoPlay={false}
            muted={isMutedOnHover}
            hidden={!shouldShowVideo}
            //   onLoadedMetadata={() => setLoadedMetadata(true)}
            onTimeUpdate={() => {
              handleOnTimeUpdate()
            }}
            onClick={() => {
              handleDetailsClicked()
            }}
            src={feel?.preview?.video?.video?.url}
            controlsList="nofullscreen"
          />
        )}
        <img
          className={classes.muteIcon}
          src={isMutedOnHover ? mutedButton : unmutedButton}
          hidden={!shouldShowVideo}
          onClick={() => {
            isMutedOnHover = !isMutedOnHover
            onMuteUnmuteClick()
          }}
        />
      </div>

      <div
        className={classes.detailsContainer}
        onClick={() => {
          handleDetailsClicked()
        }}
      >
        <span className={classes.songTitle}>{feel.lyrics}</span>
        <div className={classes.infoWrapper}>
          <img className={classes.infoIcon} src={songIcon} />
          <span className={classes.infoText}>{feel.song_name}</span>
        </div>
        <div className={classes.infoWrapper}>
          <img className={classes.infoIcon} src={artistIcon} />
          <span className={classes.infoText}>{feel.artist.name}</span>
        </div>
        <div className={classes.infoWrapper}>
          <img className={classes.infoIcon} src={calendarIcon} />
          <p className={classes.publishedText}>
            {feel.date_published
              ? 'Published on ' + getPublishedDate()
              : feel.is_published
              ? 'No Publication date'
              : 'Not Published yet'}
          </p>
        </div>
        <div className={classes.badges}>
          <PublicationBadge
            size="sm"
            status={feel.is_published ? 'published' : 'unpublished'}
            text={feel.is_published ? 'Published' : 'Unpublished'}
          />
          {feel.is_promotional ? (
            <PublicationBadge size="sm" status="tease" text="Tease" />
          ) : null}
        </div>
      </div>
    </div>
  )
}

FeelGridItem.propTypes = {
  feel: PropTypes.object.isRequired,
  isMutedOnHover: PropTypes.bool,
  onClick: PropTypes.func,
  onMuteUnmuteClick: PropTypes.func,
}
