import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'

export default function StatusPill({ status }) {
  function getBackgroundColor(status) {
    switch (status.toLowerCase()) {
      case 'pending':
        return '#FFFF0010'
      case 'published':
        return '#FF00FF30'
      case 'rejected':
        return '#FF000010'
    }
  }

  function getTextColor(status) {
    switch (status.toLowerCase()) {
      case 'pending':
        return '#FFFF00'
      case 'published':
        return '#FF00FF'
      case 'rejected':
        return '#FF0000'
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.wrapperText}
        style={{ backgroundColor: `${getBackgroundColor(status)}` }}
      >
        <p
          className={classes.text}
          style={{ color: `${getTextColor(status)}` }}
        >
          {capitalizeFirstLetter(status)}
        </p>
      </div>
    </div>
  )
}

StatusPill.propTypes = {
  status: PropTypes.string.isRequired,
}
