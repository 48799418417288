import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './EditLyrics.css'
import './Lyrics.css'

export default function EditLyrics({
  onLyricSelected,
  lyrics,
  videOffset,
  currentlySelectedLine,
  currentlyHighlightedLine,
  handleIncrementDecrementButton,
  handleStartEndInputValueChange,
  isShiftDown,
  isAltDown,
  isXDown,
  isEnabled,
}) {
  const [startTimeFocusedValue, setStartTimeFocusedValue] = useState(null)
  const [endTimeFocusedValue, setEndTimeFocusedValue] = useState(null)
  const [currentlyFocusedLine, setCurrentlyFocusedLine] = useState(null)

  const [fontSizeForStartInput, setFontSizeForStartInput] = useState(null)
  const [fontSizeForEndInput, setFontSizeForEndInput] = useState(null)

  // const isOverflown = (element) => {
  //   console.log("element.scrollWidth: ", element.scrollWidth)
  //   console.log("element.clientWidth: ", element.clientWidth)
  //   return element.scrollWidth > element.clientWidth;
  // };

  // const adaptFontSize = () => {
  //   if (!div || !originalFontSize) {
  //     return;
  //   }

  //   let currentSize = parseInt(
  //     window.getComputedStyle(div, null).getPropertyValue("font-size"),
  //     10
  //   );
  //   console.log(currentSize)
  //   console.log(originalFontSize)
  //   if (isOverflown(div)) {
  //     console.log("Here")
  //     while (isOverflown(div)) {
  //       currentSize--;
  //       div.style.fontSize = `${currentSize}px`;
  //     }
  //   } else if (currentSize < originalFontSize) {
  //     console.log("There")
  //     while (!isOverflown(div)) {
  //       currentSize++;
  //       if (currentSize > originalFontSize) {
  //         break;
  //       }
  //       div.style.fontSize = `${currentSize}px`;
  //     }
  //     currentSize = Math.min(originalFontSize, currentSize - 1);
  //     div.style.fontSize = `${currentSize}px`;
  //   }
  // };

  function calculateFontForInput(value) {
    //minimum font size is 8px, between 16px and 8p, for every 1.5 numbers entered we go down 1px in fotn-size
    return Math.max(8, 16 - (Math.max(5, String(value).length) - 5) * 1.5)
  }

  function handleLyricsSelection(line) {
    console.log(line)
    onLyricSelected(line)
    if (startTimeFocusedValue != null) {
      handleOnStartTimeFocus(line)
    }
  }

  function startValueForLineInMilliseconds(line) {
    const calcVideoOffset =
      videOffset !== undefined && videOffset !== null ? parseInt(videOffset) : 0
    const calcLineStartAdj =
      line.start_adj !== undefined && line.start_adj !== null
        ? parseInt(line.start_adj)
        : 0
    return calcVideoOffset + parseInt(line.milliseconds) + calcLineStartAdj
  }

  function formatStartValueForLine(line) {
    const valueForLineInMilliseconds = startValueForLineInMilliseconds(line)
    return formatValueForLine(valueForLineInMilliseconds)
  }

  function formatValueForLine(valueInMilliseconds) {
    const valueForLineInSeconds = Math.floor(valueInMilliseconds / 1000)
    const valueForLineInMinutes = Math.floor(valueForLineInSeconds / 60)
    const restInSeconds = valueForLineInSeconds - valueForLineInMinutes * 60
    const restInMilliseconds =
      valueInMilliseconds - valueForLineInSeconds * 1000
    if (valueForLineInMinutes > 0) {
      return (
        String(valueForLineInMinutes).padStart(2, '0') +
        ':' +
        String(restInSeconds).padStart(2, '0') +
        '.' +
        String(restInMilliseconds).padStart(3, '0')
      )
    } else {
      return (
        String(valueForLineInSeconds).padStart(2, '0') +
        '.' +
        String(restInMilliseconds).padStart(3, '0')
      )
    }
  }

  function endValueForLineInMilliseconds(line) {
    const calcVideoOffset =
      videOffset !== undefined && videOffset !== null ? parseInt(videOffset) : 0
    const calcLineEndAdj =
      line.end_adj !== undefined && line.end_adj !== null
        ? parseInt(line.end_adj)
        : 0
    return calcVideoOffset + parseInt(line.t_end) + calcLineEndAdj
  }

  function formatEndValueForLine(line) {
    const valueForLineInMilliseconds = endValueForLineInMilliseconds(line)
    return formatValueForLine(valueForLineInMilliseconds)
  }

  function handleOnStartTimeFocus(line) {
    const value = startValueForLineInMilliseconds(line)
    setStartTimeFocusedValue(value)
    setEndTimeFocusedValue(null)
    setCurrentlyFocusedLine(line)
  }

  function handleOnStartLineTimeInput(line, e) {
    if (e.target.value !== '' && !isNaN(parseInt(e.target.value))) {
      const newStartTime = parseInt(e.target.value)
      handleStartEndInputValueChange(line, newStartTime, true)
    } else {
      handleStartEndInputValueChange(line, 0, true)
    }
    handleOnStartTimeFocus(line)
  }

  function handleOnEndTimeFocus(line) {
    const value = endValueForLineInMilliseconds(line)
    setEndTimeFocusedValue(value)
    setStartTimeFocusedValue(null)
    setCurrentlyFocusedLine(line)
  }

  function handleOnEndLineTimeInput(line, e) {
    if (e.target.value !== '' && !isNaN(parseInt(e.target.value))) {
      const newEndTime = parseInt(e.target.value)
      handleStartEndInputValueChange(line, newEndTime, false)
    } else {
      handleStartEndInputValueChange(line, 0, false)
    }
    handleOnEndTimeFocus(line)
  }

  return (
    <>
      {lyrics?.map((line, index) => (
        <div key={index}>
          {line.line !== '' && (
            <div className="edit-lyrics-line-container">
              <div className="edit-lyrics-line-container-subcontainer">
                {isEnabled && (
                  <div className="edit-lyrics-edit-buttons-container">
                    <button
                      className={
                        'increment-decrement-button edit-lyrics-button-fixed-width' +
                        (isXDown ? ' gold-button' : '') +
                        (isAltDown ? ' green-button' : '') +
                        (isShiftDown ? ' cyan-button' : '')
                      }
                      onClick={() => {
                        handleIncrementDecrementButton(
                          line,
                          true,
                          true,
                          isShiftDown,
                          isAltDown,
                          isXDown
                        )
                      }}
                    >
                      {isShiftDown
                        ? '-1.0s'
                        : isAltDown
                        ? '-0.02s'
                        : isXDown
                        ? '-0.3s'
                        : '-0.1s'}
                    </button>
                    <input
                      style={{
                        fontSize: `${calculateFontForInput(
                          line == currentlyFocusedLine &&
                            startTimeFocusedValue != null
                            ? startTimeFocusedValue
                            : formatStartValueForLine(line)
                        )}px`,
                      }}
                      className="edit-lyrics-timing-input-field"
                      placeholder={'00:00.000'}
                      onFocus={() => {
                        handleOnStartTimeFocus(line)
                      }}
                      onBlur={() => {
                        setStartTimeFocusedValue(null)
                      }}
                      onChange={e => {
                        handleOnStartLineTimeInput(line, e)
                      }}
                      pattern=".{0}(-)?[0-9]"
                      value={
                        line == currentlyFocusedLine &&
                        startTimeFocusedValue != null
                          ? startTimeFocusedValue
                          : formatStartValueForLine(line)
                      }
                    />
                    <button
                      className={
                        'increment-decrement-button edit-lyrics-button-fixed-width' +
                        (isXDown ? ' gold-button' : '') +
                        (isAltDown ? ' green-button' : '') +
                        (isShiftDown ? ' cyan-button' : '')
                      }
                      onClick={() => {
                        handleIncrementDecrementButton(
                          line,
                          true,
                          false,
                          isShiftDown,
                          isAltDown,
                          isXDown
                        )
                      }}
                    >
                      {isShiftDown
                        ? '+1.0s'
                        : isAltDown
                        ? '+0.02s'
                        : isXDown
                        ? '+0.3s'
                        : '+0.1s'}
                    </button>
                  </div>
                )}

                <div
                  className="lyrics-container"
                  onClick={() => {
                    if (isEnabled) {
                      handleLyricsSelection(line)
                    }
                  }}
                >
                  <span
                    className={
                      currentlySelectedLine !== null &&
                      currentlySelectedLine.identifier === line.identifier
                        ? currentlyHighlightedLine !== null &&
                          currentlyHighlightedLine.identifier ===
                            line.identifier &&
                          currentlySelectedLine.identifier !==
                            currentlyHighlightedLine.identifier
                          ? 'edit-timing-lyrics-text-selected highlighted-line'
                          : 'edit-timing-lyrics-text-selected'
                        : currentlyHighlightedLine !== null &&
                          currentlyHighlightedLine.identifier ===
                            line.identifier
                        ? 'edit-lyrics-text highlighted-line'
                        : 'edit-lyrics-text'
                    }
                  >
                    {line.line}
                  </span>
                </div>
              </div>
              {isEnabled && (
                <div className="edit-lyrics-edit-buttons-container">
                  <button
                    className={
                      'increment-decrement-button edit-lyrics-button-fixed-width' +
                      (isXDown ? ' gold-button' : '') +
                      (isAltDown ? ' green-button' : '') +
                      (isShiftDown ? ' cyan-button' : '')
                    }
                    onClick={() => {
                      handleIncrementDecrementButton(
                        line,
                        false,
                        true,
                        isShiftDown,
                        isAltDown,
                        isXDown
                      )
                    }}
                  >
                    {isShiftDown
                      ? '-1.0s'
                      : isAltDown
                      ? '-0.02s'
                      : isXDown
                      ? '-0.3s'
                      : '-0.1s'}
                  </button>
                  <input
                    className="edit-lyrics-timing-input-field"
                    style={{
                      fontSize: `${calculateFontForInput(
                        line == currentlyFocusedLine &&
                          endTimeFocusedValue != null
                          ? endTimeFocusedValue
                          : formatEndValueForLine(line)
                      )}px`,
                    }}
                    placeholder={'00:00.000'}
                    onFocus={() => {
                      handleOnEndTimeFocus(line)
                    }}
                    onBlur={() => {
                      setEndTimeFocusedValue(null)
                    }}
                    onChange={e => {
                      handleOnEndLineTimeInput(line, e)
                    }}
                    pattern=".{0}(-)?[0-9]"
                    value={
                      line == currentlyFocusedLine &&
                      endTimeFocusedValue != null
                        ? endTimeFocusedValue
                        : formatEndValueForLine(line)
                    }
                  />
                  <button
                    className={
                      'increment-decrement-button edit-lyrics-button-fixed-width' +
                      (isXDown ? ' gold-button' : '') +
                      (isAltDown ? ' green-button' : '') +
                      (isShiftDown ? ' cyan-button' : '')
                    }
                    onClick={() => {
                      handleIncrementDecrementButton(
                        line,
                        false,
                        false,
                        isShiftDown,
                        isAltDown,
                        isXDown
                      )
                    }}
                  >
                    {isShiftDown
                      ? '+1.0s'
                      : isAltDown
                      ? '+0.02s'
                      : isXDown
                      ? '+0.3s'
                      : '+0.1s'}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </>
  )
}

EditLyrics.propTypes = {
  onLyricSelected: PropTypes.func.isRequired,
  lyrics: PropTypes.array.isRequired,
  videOffset: PropTypes.number,
  currentlySelectedLine: PropTypes.object,
  currentlyHighlightedLine: PropTypes.object,
  handleIncrementDecrementButton: PropTypes.func.isRequired,
  handleStartEndInputValueChange: PropTypes.func.isRequired,
  isShiftDown: PropTypes.bool,
  isAltDown: PropTypes.bool,
  isXDown: PropTypes.bool,
  isEnabled: PropTypes.bool,
}
