import React, { useState } from 'react'
import PropTypes from 'prop-types'

import classes from './index.module.css'
import { Link, useParams } from 'react-router-dom'
import { useGetOrgGroupContent } from '../../hooks/useGetOrgGroupContent'

export default function GroupPreview({ group, disabled = false }) {
  const { artists_count, group_users_count, name, f_id, organization_f_id } =
    group

  const { id } = useParams()
  const isPlural = item => item !== 1
  const { data: artistsList, isLoading } = useGetOrgGroupContent({
    o_f_id: id,
    g_f_id: f_id,
    content_object: 'artist',
    n: 8,
  })

  const artists = artistsList?.pages.reduce((acc, page) => {
    return [...acc, ...page.artist].filter(artist => artist.f_id).slice(0, 7)
  }, [])

  if (isLoading) {
    return null
  }

  const CardContent = () => {
    return (
      <>
        <div className={classes.card__details}>
          <h3 className={classes.card__title}>{name}</h3>

          <p className={classes.card__text}>
            {group_users_count} Member{isPlural(group_users_count) ? 's' : ''}
          </p>
          <p className={classes.card__text}>
            {artists_count} Artist{isPlural(artists_count) ? 's' : ''}
          </p>
        </div>
        {artists && artists.length ? (
          <span>
            {artists.map(artist =>
              artist.image.url ? (
                <span key={artist.f_id} className={classes.image}>
                  <img src={artist.image.url} alt={artist.name} />
                </span>
              ) : (
                <span
                  key={artist.f_id}
                  className={`${classes.image} ${classes.image__empty}`}
                >
                  <span>{artist.name.charAt(0).toUpperCase()}</span>
                </span>
              )
            )}
          </span>
        ) : null}
      </>
    )
  }

  return disabled ? (
    <button disabled={disabled} className={`${classes.card}`}>
      <CardContent />
    </button>
  ) : (
    <Link
      to={`/organizations/${id}/teams/${f_id}`}
      className={`${classes.card}`}
    >
      <CardContent />
    </Link>
  )
}

GroupPreview.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  artists_count: PropTypes.string,
  group_users_count: PropTypes.string,
  f_id: PropTypes.string,
  group: PropTypes.object,
  disabled: PropTypes.bool,
}
