import React, { useEffect, useState } from 'react'
import { useAPI } from '../../../Contexts/APIContext'
import { useAuth } from '../../../Contexts/AuthContext'
import PromptWithText from '../../../Components/DashboardComponents/Prompt/PromptWithText'
import Prompt from '../../../Components/DashboardComponents/Prompt/Prompt'
import Loader from '../../../Components/GeneralComponents/Loader'
import errorToast from '../../../Components/Toasts/ErrorToast'
import successToast from '../../../Components/Toasts/SuccessToast'
import classes from './index.module.css'
import OrganizationUsersTable from '../OrganizationUsersTable'
import { adminRoles, orgRoles, roles } from '../../../constants/roles'
import { useQueryClient } from '@tanstack/react-query'
import { useFetchAccount, useFetchUsers } from '../hooks'
import { useNavigate } from 'react-router-dom'
import useAdminAccess from '../../../services/hooks/useAdminAccess'
import { getUserRole } from '../../../services/helpers/getUserRole.'
import { getUserPermissions } from '../../../services/helpers/getUserPermissions'

export default function OrganizationUsers() {
  const {
    createNewOrgUser,
    changeOrgRole,
    deleteOrgUser,
    createNewAdminUser,
    changeAdminRole,
    deleteAdminUser,
    cancelRequest,
  } = useAPI()

  const { getCurrentUser, isCurrenAuthUserLoading } = useAuth()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [isInviting, setIsInviting] = useState(false)
  const [isPromptDelete, setIsPromptDelete] = useState(false)
  const [userToDeleteIfConfirmed, setUserToDeleteIfConfirmed] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [controller, setController] = useState(null)

  const {
    data: userData,
    isError: userDataError,
    isLoading: userDataIsLoading,
    isFetching: userDataIsFetching,
  } = useFetchAccount()

  const currentUser = userData?.user
  const organization = userData?.organization
  const currentUserRole = getUserRole(currentUser)
  const { hasAdminAccess } = useAdminAccess(currentUser)

  const {
    data: users,
    isError: usersError,
    isLoading: usersIsLoading,
    isFetching: usersIsFetching,
  } = useFetchUsers(organization?.f_id, currentUser)

  function handleInvite() {
    setIsInviting(true)
  }

  function handleChangeRole(user, role, action) {
    if (getCurrentUser().is_admin) {
      setErrorMessage(null)
      cancelRequest(controller)
      setIsLoading(true)

      const previousRole = user.roles[0]?.role

      //Add new role first, and then delete the previous role;
      const newController = changeAdminRole(
        role,
        action,
        user.f_id,
        () => {
          setIsLoading(false)
          setController(null)
          if (action == 'add') {
            handleChangeRole(user, previousRole, 'delete')
          } else {
            successToast('Successfully updated!')
            queryClient.invalidateQueries(['currentAuthUser'])
            queryClient.invalidateQueries(['adminUsers'])
          }
        },
        error => {
          setController(null)
          setIsLoading(false)
          setErrorMessage(error)
        }
      )
      setController(newController)
    } else {
      setErrorMessage(null)
      cancelRequest(controller)
      setIsLoading(true)

      const previousRole = user.organization_groups[0]?.roles[0]?.role
      const groupFId = user.organization_groups[0]?.f_id

      //Add new role first, and then delete the previous role;
      const newController = changeOrgRole(
        organization.f_id,
        groupFId,
        role,
        action,
        user.f_id,
        () => {
          setIsLoading(false)
          setController(null)
          if (action == 'add') {
            handleChangeRole(user, previousRole, 'delete')
          } else {
            successToast('Successfully updated!')
            queryClient.invalidateQueries(['currentAuthUser'])
            queryClient.invalidateQueries(['orgUsers', organization?.f_id])
          }
        },
        error => {
          setController(null)
          setIsLoading(false)
          setErrorMessage(error)
        }
      )
      setController(newController)
    }
  }

  function handleOnInvited(email) {
    if (email.length > 0) {
      setIsInviting(false)
      if (getCurrentUser().is_admin) {
        setIsLoading(true)
        setErrorMessage(null)
        cancelRequest(controller)

        const newController = createNewAdminUser(
          email,
          () => {
            setIsLoading(false)
            successToast('Successfully invited!')
            setController(null)
            queryClient.invalidateQueries(['adminUsers'])
          },
          error => {
            setController(null)
            errorToast(error)
            setIsLoading(false)
            setErrorMessage(error)
          }
        )
        setController(newController)
      } else {
        if (organization.f_id !== null && organization.f_id !== undefined) {
          setIsLoading(true)
          setErrorMessage(null)
          cancelRequest(controller)

          const newController = createNewOrgUser(
            organization.f_id,
            email,
            () => {
              setIsLoading(false)
              successToast('Successfully invited!')
              setController(null)
              queryClient.invalidateQueries(['orgUsers', organization?.f_id])
            },
            error => {
              setController(null)
              errorToast(error)
              setIsLoading(false)
              setErrorMessage(error)
            }
          )
          setController(newController)
        }
      }
    }
  }

  console.log({ userToDeleteIfConfirmed })

  function handleDelete() {
    if (users.length > 1 && userToDeleteIfConfirmed !== null) {
      setErrorMessage(null)
      cancelRequest(controller)

      if (getCurrentUser().is_admin) {
        setIsLoading(true)
        const newController = deleteAdminUser(
          userToDeleteIfConfirmed.f_id,
          () => {
            queryClient.invalidateQueries(['adminUsers'])
            setUserToDeleteIfConfirmed(null)
            setIsLoading(false)
            successToast('Successfully removed!')
            setController(null)
          },
          error => {
            setUserToDeleteIfConfirmed(null)
            setController(null)
            setIsLoading(false)
            setErrorMessage(error)
          }
        )
        setController(newController)
      } else {
        if (organization.f_id !== null && organization.f_id !== undefined) {
          setIsLoading(true)
          const newController = deleteOrgUser(
            organization.f_id,
            userToDeleteIfConfirmed.f_id,
            () => {
              queryClient.invalidateQueries(['orgUsers', organization?.f_id])
              setUserToDeleteIfConfirmed(null)
              setIsLoading(false)
              setController(null)
            },
            error => {
              setUserToDeleteIfConfirmed(null)
              setController(null)
              setIsLoading(false)
              setErrorMessage(error)
            }
          )
          setController(newController)
        }
      }
    }
  }

  useEffect(() => {
    if (userDataError || usersError) {
      setErrorMessage(
        'Error fetching organization happened. Try refreshing the page.'
      )
    }
  }, [userDataError, usersError])

  const cancelQueries = () => {
    queryClient.cancelQueries({
      queryKey: ['orgUsers', organization?.f_id],
      exact: true,
    })
    queryClient.cancelQueries({ queryKey: ['adminUsers'], exact: true })
    navigate('/', { replace: true })
  }
  useEffect(() => {
    !isCurrenAuthUserLoading &&
      !hasAdminAccess &&
      !userDataIsLoading &&
      !userDataIsFetching &&
      cancelQueries()
  }, [
    hasAdminAccess,
    userDataIsLoading,
    userDataIsFetching,
    isCurrenAuthUserLoading,
  ])

  return (
    <div className={classes.container}>
      {isPromptDelete && (
        <Prompt
          title={'Are you sure?'}
          subtitle={`This will delete ${userToDeleteIfConfirmed?.email} from the team`}
          primaryAction={'CANCEL'}
          onPrimaryClick={() => {
            setIsPromptDelete(false)
            setUserToDeleteIfConfirmed(null)
          }}
          secondaryAction={'DELETE'}
          onSecondaryClick={() => {
            setIsPromptDelete(false)
            handleDelete()
          }}
          isSecondaryActionDestructive={true}
          onCloseClick={() => {
            setIsPromptDelete(false)
            setUserToDeleteIfConfirmed(null)
          }}
        />
      )}
      {hasAdminAccess ? (
        <>
          {isInviting && (
            <PromptWithText
              title={'Enter new user email'}
              subtitle={''}
              placeholder={'Email'}
              primaryAction={'CANCEL'}
              onPrimaryClick={() => {
                setIsInviting(false)
              }}
              secondaryAction={'INVITE'}
              onSecondaryClick={email => {
                handleOnInvited(email)
              }}
              isSecondaryActionDestructive={false}
              onCloseClick={() => {
                setIsInviting(false)
              }}
            />
          )}
        </>
      ) : (
        <></>
      )}
      {(isCurrenAuthUserLoading ||
        isLoading ||
        userDataIsLoading ||
        usersIsLoading ||
        userDataIsFetching ||
        usersIsFetching) && (
        <Loader
          isLoading={
            isCurrenAuthUserLoading ||
            isLoading ||
            userDataIsLoading ||
            usersIsLoading ||
            userDataIsFetching ||
            usersIsFetching
          }
        />
      )}
      {!!users?.length ? (
        <OrganizationUsersTable
          isCurrentUserAnAdmin={hasAdminAccess}
          currentUserRole={currentUserRole}
          users={users}
          handleInvite={handleInvite}
          getUserRole={getUserRole}
          options={getCurrentUser().is_admin ? adminRoles : orgRoles}
          handleChangeRole={handleChangeRole}
          setIsPromptDelete={setIsPromptDelete}
          setUserToDeleteIfConfirmed={setUserToDeleteIfConfirmed}
          showDelete={!!users.length}
        />
      ) : (
        !isCurrenAuthUserLoading &&
        !isLoading &&
        !userDataIsLoading &&
        !usersIsLoading &&
        !userDataIsFetching &&
        !usersIsFetching && (
          <h1 style={{ color: 'white' }}>
            {errorMessage ? errorMessage : 'No Results'}
          </h1>
        )
      )}
    </div>
  )
}
