import React, { useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useAPI } from '../../../Contexts/APIContext'
import { useInView } from 'react-intersection-observer'
import { formatStringInNav } from '../../../Helpers/String+Extension'
import { Breadcrumbs } from '../../../Components/GeneralComponents/Breadcrumbs/Breadcrumbs'
import DebounceSearch from '../../../Components/GeneralComponents/DebounceSearch/DebounceSearch'
import OptionsList from '../../../Components/GeneralComponents/OptionsList/OptionsList'
import Loader from '../../../Components/GeneralComponents/Loader'
import classes from './index.module.css'
import useGetArtistData from '../../../Helpers/hooks/useGetArtistData'
import { useArtistFeelsStore } from '../../../Storage/artistFeelsPage/useArtistFeelsStore'
import FeelGridItem from '../../../Components/DashboardComponents/FeelGridItem/FeelGridItem'
import useClearArtistSearchTerms from '../../../Helpers/hooks/useClearArtistSearchTerms'
import { StickyHeader } from '../../../Components/StickyHeader'

export default function ArtistFeels() {
  const pageLimit = 20
  const navigate = useNavigate()
  const { getArtistFeels, searchFeels } = useAPI()
  const { artist_name } = useParams()
  const [ref, inView] = useInView()

  // zustand storage
  const options = useArtistFeelsStore(state => state.options)

  const selectedOption = useArtistFeelsStore(state => state.selectedOption)
  const setSelectedOption = useArtistFeelsStore(
    state => state.setSelectedOption
  )

  const { searchTerm, setSearchTerm, inputValue, setInputValue } =
    useClearArtistSearchTerms({ artistName: artist_name })

  const {
    data: dataArtist,
    isLoading: isLoadingArtist,
    isFetching: isFetchingArtist,
    isError: isErrorArtist,
    error: errorArtist,
  } = useGetArtistData({ artistName: artist_name })

  const {
    data,
    isError,
    error,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['artistFeelsList', artist_name, searchTerm, selectedOption.id],

    queryFn: ({ pageParam = 1 }) =>
      searchTerm === ''
        ? getArtistFeels(artist_name, pageParam, pageLimit, selectedOption.id)
        : searchFeels(
            searchTerm,
            pageParam,
            pageLimit,
            undefined,
            dataArtist.f_id
          ),

    getNextPageParam: pageData => {
      const nextPage = pageData.p + 1
      const newPage =
        pageData.p * pageLimit < pageData.feels_count ? nextPage : undefined
      return newPage
    },
    enabled: !!dataArtist && !isLoadingArtist && !isFetchingArtist,
  })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <div className={classes.wrapper}>
      {!isLoading && !!dataArtist && !isLoadingArtist && !isFetchingArtist && (
        <StickyHeader>
          <Breadcrumbs
            items={[
              {
                text: 'Artists',
                onClick: () => {
                  navigate('/artists')
                },
              },
              {
                text: formatStringInNav(dataArtist.name),
                onClick: () => {
                  navigate('/artist/' + artist_name)
                },
              },
              {
                text: 'Music Messages',
                onClick: () => {},
              },
            ]}
          />
          <div className={classes.header}>
            <div>
              <h1
                className={classes.title}
              >{`Music Messages by ${dataArtist.name}`}</h1>
              <p className={classes.subtitle}>
                {data?.pages[0]
                  ? 'Music Messages ' + data?.pages[0]?.feels_count
                  : ''}
              </p>
            </div>
            <div className={classes.searchSortContainer}>
              {!searchTerm ? (
                <OptionsList
                  selectedOption={selectedOption}
                  setSelectedOption={option => setSelectedOption(option)}
                  options={options}
                  label={'Sort by '}
                  disabled={!!searchTerm}
                />
              ) : null}
              <DebounceSearch
                inputValue={inputValue}
                setInputValue={setInputValue}
                searchValue={searchTerm}
                setSearchValue={setSearchTerm}
                placeholder={'Search for Music Messages...'}
              />
            </div>
          </div>
        </StickyHeader>
      )}
      <div className={classes.songs}>
        {!isError &&
          data &&
          data.pages.map((page, index) => (
            <React.Fragment key={index}>
              {page.feels.length > 0 ? (
                page.feels.map(feel => (
                  <div key={feel.f_id} ref={ref}>
                    <FeelGridItem
                      feel={feel}
                      onMuteUnmuteClick={() => false}
                      onClick={feel => {
                        navigate('/feel/' + feel.f_id)
                      }}
                    />
                  </div>
                ))
              ) : (
                <h1 className={classes.emptyResultText}>No Results</h1>
              )}
            </React.Fragment>
          ))}
      </div>
      {(isError || isErrorArtist) && (
        <h1 className={classes.errorText}>
          Error {error.status || errorArtist.status}
        </h1>
      )}
      <Loader
        isLoading={
          isLoadingArtist || isFetchingArtist || isLoading || isFetchingNextPage
        }
      />
    </div>
  )
}
