/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import usePopup from '../../hooks/usePopup'
import RemovePopup from '../RemovePopup'

export default function DeleteBtn({
  user,
  onTeamUserDelete,
  onOrgUserDelete,
  group,
  disabled,
}) {
  const { closePopup, openPopup, isOpen } = usePopup()
  return (
    <button
      disabled={disabled}
      onClick={openPopup}
      className={`${classes.text}`}
    >
      ...
      <div className={classes.remove}>
        <RemovePopup
          closePopup={closePopup}
          isOpen={isOpen}
          action={() => {
            group?.name === 'Organization' ||
            user.organization_groups.length === 0
              ? onOrgUserDelete({
                  o_f_id: user.organization_f_id,
                  u_f_id: user.f_id,
                })
              : onTeamUserDelete({
                  role: group?.roles[0].role,
                  organization_users: [user.f_id],
                  g_f_id: group?.f_id,
                })
            setTimeout(closePopup, 500)
          }}
          title={'REMOVE TEAM ACCESS'}
        />
      </div>
    </button>
  )
}

DeleteBtn.propTypes = {
  user: PropTypes.object,
  group: PropTypes.object,
  onTeamUserDelete: PropTypes.func,
  onOrgUserDelete: PropTypes.func,
}
