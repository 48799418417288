import React from 'react'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import CreateOrganizationArtistBadge from './CreateOrganizationArtistBadge'
import CreateOrganizationArtistsRow from './CreateOrganizationArtistsRow'
import CreateOrganizationArtistsBlock from './CreateOrganizationArtistsBlock'

export default function CreateOrganizationSortedArtistsList({ artists, handleRemoveArtist }) {
  const sortedArtists = artists.reduce((acc, artist) => {
    const firstLetter = artist.name.charAt(0)
    const letterIndex = acc.findIndex(
      item => item.letter.toLowerCase() === firstLetter.toLowerCase()
    )
    if (letterIndex === -1) {
      acc.push({
        letter: firstLetter.toUpperCase(),
        artists: [artist],
      })
    } else {
      acc[letterIndex] = {
        ...acc[letterIndex],
        artists: [...acc[letterIndex].artists, artist].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      }
    }
    return acc.sort((a, b) => a.letter.localeCompare(b.letter))
  }, [])
  return (
    <ul className={classes.list}>
      {sortedArtists.map((block, index) => {
        return (
          <li className={classes.list__item} key={index}>
            <CreateOrganizationArtistsBlock title={block.letter}>
              <CreateOrganizationArtistsRow artists={block.artists}>
                <CreateOrganizationArtistBadge onClick={handleRemoveArtist} />
              </CreateOrganizationArtistsRow>
            </CreateOrganizationArtistsBlock>
          </li>
        )
      })}
    </ul>
  )
}

CreateOrganizationSortedArtistsList.propTypes = {
  artists: PropTypes.array,
  stepData: PropTypes.array,
  fetchNextPage: PropTypes.func,
  handleRemoveArtist: PropTypes.func,
  onChange: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
}
